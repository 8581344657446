import { QueryKey, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { DataUpdateFunction } from 'react-query/types/core/utils';

import { PK } from 'common/types';
import { ListResponse } from 'base/api/types';
import { useBusinessStore } from 'common/hooks';

import { StandardError } from './errors';
import { ApiUrl } from './urls';

export const PAGINATION_PARAM = { page_size: 999999 };

interface UseListQueryOptions<TData, TError>
  extends UseQueryOptions<TData, TError, TData> {
  isBusinessSensitive?: boolean;
}

export const getListQueryKey = (url: ApiUrl) => [url, null, PAGINATION_PARAM];

export function useListQuery<TData>(
  url: ApiUrl,
  options?: UseListQueryOptions<ListResponse<TData>, StandardError>,
) {
  const businessParam: { is_b2b?: boolean } = {};
  const { isBusiness } = useBusinessStore();
  if (options?.isBusinessSensitive) {
    businessParam.is_b2b = isBusiness;
  }
  return useQuery<ListResponse<TData>, StandardError>({
    queryKey: [url, null, { ...PAGINATION_PARAM, ...businessParam }],
    ...options,
  });
}

interface UseDetailsQueryOptions<TData, TError>
  extends UseQueryOptions<TData, TError, TData> {
  pk?: PK;
}

export function useDetailsQuery<TData>(
  options: UseDetailsQueryOptions<TData, StandardError>,
) {
  return useQuery({
    enabled: !!options.pk,
    ...options,
  });
}

export type SetQueryDataFn<T> = DataUpdateFunction<T | undefined, T>;

export const useSetQueryData = () => {
  const queryClient = useQueryClient();

  return <T>(
    queryKey: QueryKey,
    updaterFn: SetQueryDataFn<T>,
    invalidateCache?: boolean,
  ) => {
    const cachedQueryData = queryClient.getQueryData(queryKey);

    if (cachedQueryData) {
      queryClient.setQueryData(queryKey, updaterFn);
    }

    if (!cachedQueryData || invalidateCache) {
      queryClient.invalidateQueries(queryKey);
    }
  };
};
