import * as React from 'react';

import { PK } from 'common/types';
import { MEDIA_TYPES } from 'common/consts';
import { CollapsibleSection, MediaPicker, Title } from 'common/components';
import { useSeriesMedia, useSeriesMediaUpdate } from '../queries';

const fieldName = 'media_file_pks';

type Props = {
  pk?: PK;
};

const SeriesMediaPicker = ({ pk: seriesPk }: Props) => {
  const seriesMedia = useSeriesMedia(seriesPk);
  const { mutate: save, isSuccess, isLoading, isError } = useSeriesMediaUpdate();
  const saveStatus = { isSuccess, isLoading, isError };
  const defaultValues = seriesMedia.data?.images;

  const handleSave = (mediaPks: PK[]) => {
    if (seriesPk) {
      save({ pk: seriesPk, [fieldName]: mediaPks });
    }
  };

  return (
    <>
      <CollapsibleSection
        title="Add media"
        saveStatus={saveStatus}
        disabled={!seriesMedia.data}
      >
        <Title size="standard" marginBottom={1.5}>
          Linked image
        </Title>
        <MediaPicker
          name={fieldName}
          mediaType={MEDIA_TYPES.IMAGE}
          onSave={handleSave}
          defaultValues={defaultValues}
        />
      </CollapsibleSection>
    </>
  );
};

export default SeriesMediaPicker;
