import { HabitLevel } from 'base/api/types';
import { CollapsibleSection, Container, Textarea } from 'common/components';
import { PK } from 'common/types';
import React, { Dispatch, SetStateAction } from 'react';
import HabitsAssembler from './HabitsAssembler';
import { desctiptionToChange } from './HabitsCore';
import { fields } from '../consts';

export default function HabitsLevel(
  pk: PK | undefined,
  index: number,
  mappedLevel: HabitLevel,
  setNewDescription: Dispatch<SetStateAction<desctiptionToChange[]>>,
) {
  const handleTextChange = async function (e: React.ChangeEvent<HTMLTextAreaElement>) {
    await setNewDescription((prevState) => {
      const existingIndex = prevState.findIndex((item) => item.lvl === index);

      if (existingIndex >= 0) {
        return prevState.map((item, i) =>
          i === existingIndex ? { lvl: index, description: e.target.value } : item,
        );
      } else {
        return [
          ...prevState,
          {
            lvl: index,
            description: e.target.value,
          },
        ];
      }
    });
  };

  return (
    <CollapsibleSection
      isSmall
      key={index + mappedLevel.description}
      title={`Level ${index + 1}`}
      disabled={!pk}
      shouldOpen={index === 0 ? true : false}
    >
      <Container>
        <Textarea
          name={`${fields.levels.name}[${index}].description`}
          label={`Level ${index + 1} Description`}
          onChange={(e) => handleTextChange(e)}
        />
        <HabitsAssembler
          level={index}
          mini_pks_per_day={mappedLevel.minis_pks_per_day}
          pk={pk}
        />
      </Container>
    </CollapsibleSection>
  );
}
