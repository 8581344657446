import * as React from 'react';

import { CollapsibleSection } from 'common/components';
import { PK } from 'common/types';
import { TAGGING_CONTENT_TYPES } from 'modules/tagging/consts';
import { TagsForm } from 'modules/tagging/components';
import { useSeriesTags, useSeriesTagsUpdate } from '../queries';
import { seriesTagsValidationSchema } from '../consts';

type Props = {
  setIsTags: (isTags: boolean) => void;
  pk?: PK;
};

const SeriesTagsPicker = ({ pk, setIsTags }: Props) => {
  const { data: seriesTagData } = useSeriesTags(pk);
  const { mutate: updateSeries, isSuccess, isLoading, isError } = useSeriesTagsUpdate();
  const isTags =
    seriesTagData?.category &&
    Array.isArray(seriesTagData?.category) &&
    seriesTagData?.category.length > 0;

  React.useEffect(() => {
    setIsTags(!!isTags);
  }, [isTags, setIsTags]);

  const saveTags = (values: any) => {
    const payload = {
      ...(Array.isArray(values.category) &&
        values.category.length && {
          category: values.category.map(({ value }: any) => value),
        }),
      ...(values.subcategory.length && {
        subcategory: values.subcategory.map(({ value }: any) => value),
      }),
      ...(Array.isArray(values.habit) &&
        values.habit.length && { habit: values.habit.map(({ value }: any) => value) }),
      ...(values.frequency && { frequency: Number(values.frequency) }),
      ...(values.amount && { amount: Number(values.amount) }),
      ...(values.amount_unit && { amount_unit: values.amount_unit[0].value }),
      ...(Array.isArray(values.term) &&
        values.term.length && {
          term: values.term[0].value,
        }),
      pk,
    };
    updateSeries(payload as any);
  };

  return (
    <>
      <CollapsibleSection
        title="Add tags"
        saveStatus={{ isSuccess, isLoading, isError }}
        disabled={!pk}
        divider={false}
      >
        <TagsForm
          validationSchema={seriesTagsValidationSchema}
          contentType={TAGGING_CONTENT_TYPES.SERIES}
          defaultValues={seriesTagData}
          onSubmit={saveTags}
          isSubmitting={isLoading}
        />
      </CollapsibleSection>
    </>
  );
};

export default SeriesTagsPicker;
