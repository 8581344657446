import { Theme, ThemeColor } from './theme';

const themePresets = {
  color: {
    black: '#1d1d1d',
    blackAlpha: 'rgba(0,0,0,0.7)',
    blackSuperAlpha: 'rgba(29, 29, 29, 0.1)',
    white: '#ffffff',
    pinkDark: '#e8038b',
    pink: '#ff0098',
    pinkLight: '#ff99d6',
    pinkPale: '#fae0ef',
    pinkSuperLight: '#ffebf7',
    blueMediumDark: '#004feb',
    blue: '#0055ff',
    blueMediumLight: '#99bbff',
    blueLight: '#d6e4ff',
    blueGreyDark: '#cad7ee',
    blueGrayMediumDark: '#6E8EC8',
    blueGrey: '#e0e9fa',
    blueSuperLight: '#ebf2ff',
    blueAlpha: 'rgba(214, 228, 255, 0.5)',
    greenSuperDark: '#007474',
    greenLightDark: '#009b9b',
    greenDark: '#00c9a5',
    greenMediumDark: '#DAEBEB',
    green: '#00dfb8',
    greenLight: '#d6faf3',
    greenSuperLight: '#E5F8F6',
    greenExtraLight: '#f6fafa',
    yellowExtraDark: '#FEC102',
    yellowDark: '#e6c800',
    yellow: '#fde110',
    yellowLight: '#FCF9E5',
    yellowExtraLight: '#ffecb3',
    greyDark: '#666666',
    greyMediumDark: '#4C4D50',
    grey: '#919191',
    greyMedium: '#cbcbcb',
    greyLight: '#ececec',
    greySuperLight: '#f5f5f5',
    greyLightBlue: '#f6f9ff',
    none: 'none',
    error: '#ff2a50',
    transparent: 'transparent',
    shadowLight: 'rgba(0, 0, 0, 0.1)',
  },
  spacer: {
    '0': '0',
    tiny: '2px',
    small: '4px',
    standard: '8px',
    medium: '10px',
    big: '16px',
    times: (times: number) => `${times * 8}px`,
    auto: 'auto',
    primary: '0.5',
  },
  dims: {
    tiny: '16px',
    small: '24px',
    standard: '32px',
    medium: '40px',
    big: '48px',
    ample: '56px',
    large: '64px',
    massive: '72px',
    huge: '88px',
  },
  font: {
    size: {
      minuscule: '10px',
      superTiny: '12px',
      tiny: '14px',
      small: '16px',
      standard: '18px',
      moderate: '20px',
      medium: '24px',
      big: '32px',
      huge: '40px',
    },
    family: {
      sans: 'Gilroy',
      serif: 'serif',
    },
    weight: {
      normal: 'normal',
      bold: 600,
    },
  },
  media: {
    xs: '540px',
    sm: '720px',
    md: '960px',
    lg: '1260px',
    max: '2000px',
  },
  border: {
    radius: {
      tiny: '4px',
      small: '8px',
      standard: '12px',
      big: '24px',
      large: '32px',
      primary: '0.5',
    },
  },
  layer: {
    main: 0,
    top: 1000,
    above: (layer: number): number => layer + 1,
    below: (layer: number): number => layer - 1,
  },
};

export const themeAbstraction = {
  keys: {
    primaryColor: <ThemeColor>'greenSuperDark',
    secondaryColor: <ThemeColor>'greenSuperDark',
    tertiaryColor: <ThemeColor>'greyMediumDark',
    quaternaryColor: <ThemeColor>'blueGrayMediumDark',
    quinaryColor: <ThemeColor>'greenMediumDark',
    sixfoldColor: <ThemeColor>'yellow',
    components: {
      collapsibleSectionPrimaryColors: <[ThemeColor, ThemeColor]>[
        'greenSuperDark',
        'greyLight',
      ],
      businessTogglePrimaryColors: <[ThemeColor, ThemeColor]>[
        'yellowExtraDark',
        'greenSuperDark',
      ],
    },
  },
  themePadding: {
    cardPadding: [2, 5, 1],
  },
  themeColor: {
    primaryColor: themePresets.color.greenSuperDark,
    secondaryLightColor: themePresets.color.greenSuperLight,
    quaternaryColor: themePresets.color.greenSuperDark,
    tertiaryLightColor: themePresets.color.greenSuperLight,
    tertiarySuperDarkColor: themePresets.color.greenSuperDark,
    tertiaryColor: themePresets.color.greenMediumDark,
  },
  components: {
    teams: {
      primaryColor: themePresets.color.greenSuperDark,
      secondaryColor: themePresets.color.blueGrayMediumDark,
      tertiaryColor: themePresets.color.greenMediumDark,
      statusColors: ['blueGrayMediumDark', 'greenSuperDark', 'greenSuperDark'],
      statusBgColors: ['blueSuperLight', 'greenMediumDark', 'greenMediumDark'],
      navLink: {
        primaryColor: themePresets.color.blueGrayMediumDark,
        secondaryColor: themePresets.color.yellowExtraDark,
        tertiaryColor: themePresets.color.blueSuperLight,
      },
    },
    licenses: {
      licensesStatusColors: [
        'greenSuperDark',
        'blueGraySuperDark',
        'primaryButtonColor',
        'primaryButtonColor',
      ],
      licensesBgColors: ['greenMediumDark', 'blueGreyDark', 'yellowLight', 'yellowLight'],
    },
    button: {
      primaryColor: themePresets.color.yellowExtraDark,
      secondaryColor: themePresets.color.greenMediumDark,
      tertiaryColor: themePresets.color.blue,
      quaternaryColor: themePresets.color.greyMediumDark,
      quinaryColor: themePresets.color.greenSuperLight,
      transparent: themePresets.color.transparent,
      primaryTextColor: themePresets.color.black,
      secondaryTextColor: themePresets.color.greenSuperDark,
      primaryDisabledTextColor: themePresets.color.white,
      secondaryDisabledTextColor: themePresets.color.black,
      primaryDisabledColor: themePresets.color.yellowExtraLight,
      secondaryDisabledColor: themePresets.color.yellowExtraLight,
      tertiaryDisabledColor: themePresets.color.yellowExtraLight,
    },
    dropdown: {
      primaryColor: themePresets.color.greenMediumDark,
    },
    checkbox: {
      primaryColor: themePresets.color.greenSuperLight,
    },
    datePicker: {
      primaryBgColor: themePresets.color.pinkSuperLight,
    },
    statusChip: {
      statusChipColor: ['blueGrayMediumDark', 'greenSuperDark', 'greyMediumDark'],
      statusChipBgColor: ['blueSuperLight', 'greenMediumDark', 'greyLight'],
    },
    createDialog: {
      primaryBorderColor: themePresets.color.greenMediumDark,
    },
    dropdownSelect: {
      primaryBgColor: themePresets.color.greenExtraLight,
    },
    login: {
      backgroundPosition: 'bottom -5vh left 73%',
      logoWidth: '170px',
      logoHeight: '80px',
    },
  },

  backgroundColor: themePresets.color.greenExtraLight,
};

export const theme: Theme = {
  ...themeAbstraction,
  ...themePresets,
};
