import * as React from 'react';
import styled from 'styled-components';

import { ErrorWarning } from 'common/icons';

interface Props {
  message?: string;
}

const FormError = ({ message }: Props) =>
  message ? (
    <ErrorRow>
      <ErrorWarning />
      <ErrorMessage>{message}</ErrorMessage>
    </ErrorRow>
  ) : null;

const ErrorMessage = styled.span`
  margin-left: ${({ theme }) => theme.spacer.standard};
`;

const ErrorRow = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacer.small};
  margin-top: ${({ theme }) => theme.spacer.small};
  color: ${({ theme }) => theme.color.error};
  font-size: ${({ theme }) => theme.font.size.tiny};
`;

export default FormError;
