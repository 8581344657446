import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Speaker = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 17 17" {...props}>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Media"
          transform="translate(-253.000000, -631.000000)"
          stroke="#111111"
          strokeWidth="2"
        >
          <g id="Media_type_audio" transform="translate(20.000000, 520.000000)">
            <g id="volume-mute" transform="translate(234.000000, 112.000000)">
              <polyline
                id="Path"
                points="11.5909091 6.81818182 11.5909091 14.3181818 7.3725 11.1545455"
              ></polyline>
              <polyline
                id="Path"
                points="4.77272727 10.2272727 0.681818182 10.2272727 0.681818182 4.77272727 6.13636364 4.77272727 11.5909091 0.681818182 11.5909091 3.40909091"
              ></polyline>
              <line x1="15" y1="0" x2="0" y2="15" id="Path"></line>
            </g>
          </g>
        </g>
      </g>
    </Svg>
  );
};

Speaker.defaultProps = {
  size: '16px',
  color: 'black',
  hoverColor: 'black',
};

export default Speaker;
