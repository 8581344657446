import * as React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { StandardError } from 'base/api/errors';
import { Check } from 'common/icons';
import {
  Button,
  Checkbox,
  ErrorBox,
  Input,
  Wrapper,
  RemoveButton,
} from 'common/components';
import { adminFields, AdminFields, AdminFormReset, adminsSchema } from './consts';
import {
  AdminContainer,
  AdminHeader,
  AdminTitle,
  ContactLabel,
} from './CompanyAdmins.styled';
import { BUTTON_SIZES } from 'common/consts';

const margins = {
  fields: [2, 0],
  container: [0, 3, 1],
  icon: [0, 2, 0, 0],
};

type FormProps = {
  onSubmit: (values: AdminFields, reset: AdminFormReset) => void;
  onRemove?: () => void;
  defaultValues?: AdminFields;
  isRemoveButtonVisible?: boolean;
  error?: StandardError | null;
  isLoading?: boolean;
  children?: React.ReactNode;
  isRegistered?: boolean;
  adminsNumber?: number;
};

const Form = ({
  defaultValues,
  error,
  isLoading,
  onSubmit,
  onRemove,
  isRemoveButtonVisible = true,
  children,
  isRegistered,
  adminsNumber,
}: FormProps) => {
  const [isOpen, setOpen] = React.useState(false);
  const form = useForm<AdminFields>({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(adminsSchema),
  });
  const {
    reset,
    setError,
    formState: { isDirty },
  } = form;
  const values = form.watch(['first_name', 'last_name', 'is_contact_person']);
  const adminName =
    (values.first_name || values.last_name) && `${values.first_name} ${values.last_name}`;

  const toggleOpen = () => {
    setOpen((isOpen) => !isOpen);
  };

  React.useEffect(() => {
    if (error) {
      error.validation.forEach(({ type, name, message }) => {
        setError(name as keyof AdminFields, { type, message });
      });
    }
  }, [error, setError]);

  const handleSubmit: SubmitHandler<AdminFields> = (values) => {
    onSubmit(values, reset);
  };

  return (
    <AdminContainer isOpen={isOpen}>
      <AdminHeader>
        <AdminTitle onClick={toggleOpen}>
          {isRegistered ? (
            <Wrapper margin={margins.icon}>
              <Check size="20px" />
            </Wrapper>
          ) : null}
          <span>{adminName || 'Admin'}</span>
          {values.is_contact_person && <ContactLabel>contact person</ContactLabel>}
        </AdminTitle>
        {onRemove && isRemoveButtonVisible && <RemoveButton onRemove={onRemove} />}
      </AdminHeader>
      <Wrapper padding={margins.container}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Wrapper>
              <Input
                name={adminFields.email.name}
                label={adminFields.email.label}
                readOnly={isRegistered}
              />
            </Wrapper>
            <Wrapper margin={margins.fields}>
              <Input
                name={adminFields.first_name.name}
                label={adminFields.first_name.label}
                readOnly={isRegistered}
              />
            </Wrapper>
            <Wrapper margin={margins.fields}>
              <Input
                name={adminFields.last_name.name}
                label={adminFields.last_name.label}
                readOnly={isRegistered}
              />
            </Wrapper>
            <Wrapper margin={margins.fields}>
              <Input
                name={adminFields.phone_number.name}
                label={adminFields.phone_number.label}
                type="number"
              />
            </Wrapper>
            {isRegistered && adminsNumber && adminsNumber > 1 ? (
              <Wrapper margin={margins.fields}>
                <Checkbox
                  name={adminFields.is_contact_person.name}
                  label={adminFields.is_contact_person.label}
                />
              </Wrapper>
            ) : null}
            <ErrorBox error={error} />
            <Wrapper margin={margins.fields}>
              <Button
                type="submit"
                size={BUTTON_SIZES.SMALL}
                isLoading={isLoading}
                disabled={!isDirty}
              >
                {isDirty ? 'Save admin' : 'Saved'}
              </Button>
            </Wrapper>
            {children}
          </form>
        </FormProvider>
      </Wrapper>
    </AdminContainer>
  );
};

export default Form;
