import * as React from 'react';

import appUrls from 'base/app/urls';
import { Container, Wrapper, TopBar } from 'common/components';
import { DetailsSwitch } from 'templates/details';
import {
  ContentProvider,
  ContentList,
  FiltersForm,
  Sort,
  StatusFilter,
  SearchInput,
} from 'templates/content';
import CompanyDetails from './CompanyDetails';
import { useCompaniesList } from '../queries';

const fields = {
  search: 'search',
  filter: 'filter',
  sort: 'sort',
};

const CompaniesList = () => {
  const { data, isError } = useCompaniesList();
  const context = {
    data: data?.values,
    path: {
      details: appUrls.COMPANIES.DETAILS,
      list: appUrls.COMPANIES.LIST,
    },
  };

  return (
    <Container>
      <ContentProvider {...context}>
        <TopBar title="Companies">
          <FiltersForm
            sort={['title', fields.sort]}
            search={['title', fields.search]}
            filter={['status', fields.filter]}
          >
            <Wrapper margin={[0, 0.5]}>
              <SearchInput name={fields.search} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <StatusFilter name={fields.filter} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <Sort name={fields.sort} />
            </Wrapper>
          </FiltersForm>
        </TopBar>
        <ContentList isError={isError} />
        <DetailsSwitch>
          <CompanyDetails />
        </DetailsSwitch>
      </ContentProvider>
    </Container>
  );
};

export default CompaniesList;
