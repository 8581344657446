import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getYear, parseISO, isAfter } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';

import { BUTTON_SIZES, DATE_FORMATS } from 'common/consts';
import { PK } from 'common/types';
import { formatDate, distinct } from 'common/utils';
import { useMappedValues } from 'common/hooks';
import {
  CollapsibleSection,
  DatePicker,
  Button,
  Wrapper,
  Select,
  ErrorBox,
} from 'common/components';
import { useScenariosList } from 'modules/scenarios/queries';
import { Section } from 'templates/core';

import {
  useCompanyProgramDetails,
  useCompanyProgramUpdate,
  useProgramStartDates,
} from '../../queries';
import { programFields, ProgramFields, programSchema } from './consts';

type Props = {
  pk?: PK;
};

const ProgramSettings = ({ pk }: Props) => {
  const [year, setYear] = React.useState(getYear(new Date()));
  const form = useForm<ProgramFields>({
    mode: 'onSubmit',
    resolver: yupResolver(programSchema),
    shouldFocusError: false,
  });
  const { reset } = form;

  const program = useCompanyProgramDetails(pk);
  const scenarioList = useScenariosList();
  const options = useMappedValues(scenarioList.data?.values, ({ pk, title }) => ({
    label: title,
    value: pk,
  }));
  const companyScenario = scenarioList.data?.values.find(
    ({ pk }) => pk === program.data?.scenario_pk,
  );
  const startDate = program.data?.start_date;

  const updateProgram = useCompanyProgramUpdate();
  const saveStatus = {
    isSuccess: updateProgram.isSuccess,
    isLoading: updateProgram.isLoading,
    isError: updateProgram.isError,
  };

  const startDates = useProgramStartDates([year, year + 1]);
  const includeDates = startDates
    .flatMap(({ data }) => data?.values || [])
    .filter(distinct)
    .map((date) => parseISO(date))
    .filter((date) => isAfter(date, new Date()));

  const defaultValues = React.useMemo(
    () =>
      companyScenario &&
      startDate && {
        start_date: new Date(startDate),
        scenario_pk: [
          {
            label: companyScenario.title,
            value: companyScenario.pk,
          },
        ],
      },
    [companyScenario, startDate],
  );
  React.useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [reset, defaultValues]);

  const handleMonthChange = (date: Date) => {
    setYear(getYear(date));
  };

  const handleSave = (values: ProgramFields) => {
    if (pk) {
      const payload = {
        pk,
        start_date: formatDate(values.start_date, DATE_FORMATS.DATABASE),
        scenario_pk: values.scenario_pk[0]?.value ?? null,
      };

      updateProgram.mutate(payload);
    }
  };

  return (
    <CollapsibleSection title="Program" saveStatus={saveStatus} disabled={!pk}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSave)}>
          <Wrapper margin={[2, 0]}>
            <DatePicker
              name={programFields.start_date.name}
              label={programFields.start_date.label}
              onMonthChange={handleMonthChange}
              includeDates={includeDates}
            />
          </Wrapper>
          <Wrapper margin={[2, 0]}>
            <Select
              options={options}
              name={programFields.scenario_pk.name}
              label={programFields.scenario_pk.label}
              isLoading={scenarioList.isLoading}
              isFloating={false}
            />
          </Wrapper>
          {updateProgram.error && (
            <Section>
              <ErrorBox error={updateProgram.error} />
            </Section>
          )}
          <Wrapper margin={[4, 0]}>
            <Button
              size={BUTTON_SIZES.SMALL}
              isLoading={updateProgram.isLoading}
              type="submit"
            >
              Save
            </Button>
          </Wrapper>
        </form>
      </FormProvider>
    </CollapsibleSection>
  );
};

export default ProgramSettings;
