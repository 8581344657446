import * as React from 'react';
import { MediaObject } from 'base/api/types';
import { Input, MediaPicker } from 'common/components';
import { MEDIA_TYPES } from 'common/consts';
import { useFormContext } from 'react-hook-form';

type BadgeMediaUrlPickerProps = {
  name: string;
  defaultValue: MediaObject[] | null;
};

const BadgeMediaUrlPicker = ({ name, defaultValue }: BadgeMediaUrlPickerProps) => {
  const formContext = useFormContext();

  return (
    <>
      <Input name={name} type="hidden" />
      <MediaPicker
        name={`${name}_mediaPicker`}
        onSave={(mediaPks, media) => {
          if (!mediaPks.length) {
            formContext.setValue(name, null);

            return;
          }

          const { url } = media.find((match) => match.pk === mediaPks[0]) || {};

          if (!url) {
            formContext.setValue(name, null);

            return;
          }

          formContext.setValue(name, url);
        }}
        mediaType={MEDIA_TYPES.ICON}
        fileType={['.svg']}
        defaultValues={defaultValue}
      />
    </>
  );
};

export default BadgeMediaUrlPicker;
