import { keyframes } from 'styled-components';

export const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`;

export const bounceIn = keyframes`
  0% {
    transform: scale(0);
  }
  75% {
    transform :scale(1.2);
  }
  100% {
    transform :scale(1);
  }
`;

export const spin = (offset = 0) => keyframes`
  0% {
    transform: rotate(${offset}deg);
  }
  100% {
    transform: rotate(${360 + offset}deg);
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
