import * as React from 'react';

const useEnabled = <TCallback>(callback: TCallback, disabled: boolean) => {
  if (disabled) {
    return () => {};
  } else {
    return callback;
  }
};

type UseOpenOptions = {
  disabled?: boolean;
  defaultOpen?: boolean;
};

const defaultOptions = {
  disabled: false,
  defaultOpen: false,
};

const useOpen = (options: UseOpenOptions = defaultOptions) => {
  const [isOpen, setOpen] = React.useState<boolean>(!!options.defaultOpen);

  const open = useEnabled(() => {
    setOpen(true);
  }, !!options.disabled);

  const close = useEnabled(() => {
    setOpen(false);
  }, !!options.disabled);

  const toggleOpen = useEnabled(() => {
    setOpen((isOpen) => !isOpen);
  }, !!options.disabled);

  return {
    isOpen,
    open,
    close,
    toggleOpen,
  };
};

export default useOpen;
