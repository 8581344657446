import axios from 'axios';
import format from 'date-fns/format';
import Route from 'route-parser';
import queryString from 'query-string';
import * as Yup from 'yup';

import { DATE_FORMATS, ProjectSettings, PROJECT_NAME } from 'common/consts';
import { DateString, Field, Fields, URLOptions, URLParams, URLQuery } from 'common/types';
import apiUrls from 'base/api/urls';
import { BlogMetadata } from 'modules/blogs/types';

import { FRIESLAND_CONTENT, CONTENT } from '../consts';
import { getIsFriesland } from './config';

export const matchUrl = (path?: string, pathname?: string) => {
  if (!path || !pathname) {
    return false;
  }
  const route = new Route(path);
  return route.match(pathname);
};

export const resolveUrl = <TParams extends URLParams>(
  path: string,
  params: TParams,
): string => {
  const route = new Route<TParams>(path);
  const url = route.reverse(params);
  return url || '';
};

export const resolveQuery = <TQuery extends URLQuery>(
  url: string,
  query: TQuery,
): string => {
  return query ? `${url}?${queryString.stringify(query)}` : url;
};

export const composeUrl = (path: string, options: URLOptions): string => {
  const { query, params = {} } = options;
  let url = resolveUrl(path, params);
  if (query) {
    url = resolveQuery(url, query);
  }
  return url;
};

export const isString = (item: any): item is string => typeof item === 'string';
// eslint-disable-next-line @typescript-eslint/ban-types
export const isObject = (item: any): item is object => typeof item === 'object';
export const isNumber = (item: any): item is number => typeof item === 'number';
// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = <T extends Function>(item: any): item is T =>
  typeof item === 'function';
export const isBool = (item: any): item is boolean => typeof item === 'boolean';
export const isEnum = <T>(e: T) => {
  return (token: any): token is T[keyof T] =>
    Object.values(e).includes(token as T[keyof T]);
};

export const ifVariant = <V extends unknown[]>(variants: V, defaultVariant = 0) => <T>(
  variant: V[number] | undefined | null,
  values: T[], // TODO: Try to do variadic tuple here
): T => {
  let result: T | null = null;
  variants.forEach((elem, index) => {
    if (elem === variant) {
      result = values[index];
    }
  });
  return result || values[defaultVariant];
};

export function assertFC<P>(component: React.FC<P>): asserts component is React.FC<P> {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const assertUnreachable = (x: never): never => {
  throw new Error();
};

export function copyTextToClipboard(text: string): boolean {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed';
  textArea.style.left = '-999px';
  textArea.style.opacity = '0';

  document.body.appendChild(textArea);
  textArea.select();

  try {
    return document.execCommand('copy');
  } catch (err) {
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
}

export const formatDate = <TFormat extends DATE_FORMATS>(
  date: Date,
  toFormat: TFormat,
): DateString<TFormat> => {
  return format(date, toFormat) as DateString<TFormat>;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function combineSchemas<TField extends object>(fields: Fields<TField>) {
  const vali = {} as Yup.ObjectSchemaDefinition<TField>;
  (Object.values(fields) as Field<keyof TField>[]).forEach(({ label, name, schema }) => {
    if (schema) {
      vali[name] = schema.label(label);
    }
  });
  return vali;
}

export const composeHandlers = (...handlers: unknown[]) => {
  return (...args: unknown[]) => {
    handlers.forEach((handler) => {
      if (isFunction(handler)) {
        handler(...args);
      }
    });
  };
};

export const getProjectName = () => {
  return getIsFriesland() ? PROJECT_NAME.FRIESLAND : PROJECT_NAME.ACTIFY;
};

export const getProjectSettings = () => {
  return ProjectSettings[getProjectName()];
};

export const getProjectContent = () => {
  const isFrieslandProject = getIsFriesland();
  return isFrieslandProject ? FRIESLAND_CONTENT : CONTENT;
};

export const getMetadata = (url: string) => {
  return axios.get<BlogMetadata>(
    composeUrl(apiUrls.BLOGS.METADATA, {
      query: {
        url: url,
      },
    }),
  );
};
export const parseBlogContent = (data: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(data, 'text/html');
  const styledDoc = applyStylesForBlogPreview(doc);
  const content = styledDoc.querySelector('body')?.innerHTML;
  return content;
};

export const applyStylesForBlogPreview = (doc: Document) => {
  doc.querySelectorAll('a').forEach((elem) => {
    elem.style.color = '#00dfb8';
    elem.style.textDecoration = 'none';
    elem.style.pointerEvents = 'none';
  });

  doc.querySelectorAll('p').forEach((elem) => {
    elem.style.fontSize = '12px';
  });
  doc.querySelectorAll('h2').forEach((elem) => {
    elem.style.fontSize = '14px';
  });

  return doc;
};

export const changeFormValueToLabel = (value: string): string => {
  const firstLetterUpperCase = value.charAt(0).toUpperCase() + value.slice(1);
  const splitWords = firstLetterUpperCase.split('_');
  const combineWords = splitWords.join(' ');
  return combineWords;
};

export const getIsMinisPage = (location: string) => {
  const minisUrl = 'minis';
  return location.includes(minisUrl);
};

export const getIsFunFactsPage = (location: string) => {
  const minisUrl = 'fun-facts';
  return location.includes(minisUrl);
};

export { distinct } from './distinct';
