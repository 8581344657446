import * as Yup from 'yup';

import { Fields, PK, SelectOption } from 'common/types';
import { combineSchemas } from 'common/utils';
import { STATUSES } from 'common/consts';

export type RecipeFields = {
  title: string;
  content_goals: SelectOption<PK>[];
  description: string;
  author: SelectOption<PK>[];
  description_author?: SelectOption<PK>[] | null;
  status: SelectOption<STATUSES>[];
  portions: number;
  calories: number;
  preparation_time: number;
  oven_time: number | null;
  waiting_time: number | null;
  date_active_start: Date | null;
  date_active_end: Date | null;
};

export const fields: Fields<RecipeFields> = {
  title: {
    name: 'title',
    label: 'Title',
    schema: Yup.string().required(),
  },
  content_goals: {
    label: 'Categories',
    name: 'content_goals',
    schema: Yup.array().required(),
  },
  description: {
    name: 'description',
    label: 'Description',
    schema: Yup.string().required(),
  },
  description_author: {
    name: 'description_author',
    label: 'Author',
    schema: Yup.array(),
  },
  author: {
    name: 'author',
    label: 'Source',
    schema: Yup.array().required(),
  },
  status: {
    name: 'status',
    label: 'Status',
    schema: Yup.array().required(),
  },
  portions: {
    name: 'portions',
    label: 'Portions',
    schema: Yup.number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .required()
      .min(1)
      .integer(),
  },
  calories: {
    name: 'calories',
    label: 'Calories',
    schema: Yup.number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .required()
      .min(0),
  },
  preparation_time: {
    name: 'preparation_time',
    label: 'Preparation time',
    schema: Yup.number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .required()
      .min(0)
      .integer(),
  },
  oven_time: {
    name: 'oven_time',
    label: 'Oven time',
    schema: Yup.number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .min(0)
      .integer(),
  },
  waiting_time: {
    name: 'waiting_time',
    label: 'Waiting time',
    schema: Yup.number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .min(0)
      .integer(),
  },
  date_active_start: {
    name: 'date_active_start',
    label: 'Active from',
  },
  date_active_end: {
    name: 'date_active_end',
    label: 'Active to',
  },
};

export const validationSchema = Yup.object().shape<RecipeFields>(combineSchemas(fields));

export type RecipesTagsFields = {
  amount: number;
  amount_unit: SelectOption<string>;
  category: SelectOption<string>[];
  frequency: SelectOption<string>;
  habit: SelectOption<string>[];
  subcategory: SelectOption<string>[];
  term: SelectOption<string>;
  cousine: SelectOption<string>;
  meal_type: SelectOption<string>;
  recipe_category: SelectOption<string>[];
  diet: SelectOption<string>;
};

export const tagsFields: Fields<RecipesTagsFields> = {
  amount: {
    label: 'Amount',
    name: 'amount',
    schema: Yup.number()
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .test('Is positive?', 'Amount must be greater than 0.', (value) => {
        return typeof value === 'object' || (typeof value === 'number' && value > 0);
      }),
  },
  amount_unit: {
    label: 'Amount unit',
    name: 'amount_unit',
    schema: Yup.array()
      .nullable()
      .when('amount', {
        is: (amount) => {
          return typeof amount === 'number';
        },
        then: Yup.array()
          .nullable()
          .required('Amount and Unit fields must be set together'),
      }),
  },
  category: {
    label: 'Category',
    name: 'category',
    schema: Yup.array().required('Category is a required field'),
  },

  frequency: {
    label: 'Frequency',
    name: 'frequency',
    schema: Yup.number()
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .test('Is positive?', 'Frequency must be greater than 0.', (value) => {
        return typeof value === 'object' || (typeof value === 'number' && value > 0);
      }),
  },
  habit: {
    label: 'Habit',
    name: 'habit',
    schema: Yup.array().required('Habit is a required field'),
  },
  subcategory: {
    label: 'Subcategory',
    name: 'subcategory',
    schema: Yup.array().required('Subcategory is a required field'),
  },
  term: {
    label: 'Term',
    name: 'term',
    schema: Yup.array().nullable(),
  },
  cousine: {
    label: 'Cousine',
    name: 'cousine',
    schema: Yup.array().nullable(),
  },
  meal_type: {
    label: 'Meal type',
    name: 'meal_type',
    schema: Yup.array().required('Meal type is a required field'),
  },
  recipe_category: {
    label: 'Recipe category',
    name: 'recipe_category',
    schema: Yup.array().required('Recipe category is a required field'),
  },
  diet: {
    label: 'Diet',
    name: 'diet',
    schema: Yup.array().required('Diet is a required field'),
  },
};

export const recipesTagsValidationSchema = Yup.object().shape<RecipesTagsFields>(
  combineSchemas(tagsFields),
);
