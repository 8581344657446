import * as React from 'react';
import { useParams } from 'react-router';

import { RecipesCategory } from 'base/api/types';
import { PK } from 'common/types';
import { DetailsCard, DetailsSuspense } from 'templates/details';

import CategoriesCore from '../components/CategoriesCore';
import { fields, CategoriesFields, CATEGORY_TYPE_OPTIONS } from '../consts';
import { useCategoriesDetails } from '../queries';

const CategoriesDetails = () => {
  const { pk } = useParams<{ pk: PK }>();
  const { data, ...query } = useCategoriesDetails(pk);
  const transformCategories = (raw: RecipesCategory): CategoriesFields => ({
    [fields.name.name]: raw.name,
    [fields.type.name]: CATEGORY_TYPE_OPTIONS.filter(
      (typeOption) => typeOption.value === raw.category_type,
    ),
  });
  const defaultValues = data && transformCategories(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading} error={query.error}>
        <CategoriesCore pk={pk} key={pk} defaultValues={defaultValues} />
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default CategoriesDetails;
