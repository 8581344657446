import * as React from 'react';

import { Button, ErrorBox, Form, Input, SaveStatus, Wrapper } from 'common/components';
import { BUTTON_VARIANTS } from 'common/consts';
import { Section } from 'templates/core';

import { useRemoveAccount } from '../../queries';
import Heading from '../Heading';
import Strong from '../Strong';
import { fields, RemoveAccountFields, validationSchema } from './consts';

const RemoveAccount = () => {
  const {
    mutate: removeAccount,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useRemoveAccount();
  const saveStatus = { isLoading, isError, isSuccess };

  const handleSubmit = (values: RemoveAccountFields) => {
    const payload = {
      email: values.email,
    };
    removeAccount(payload);
  };
  return (
    <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
      <Heading>
        Delete account
        <SaveStatus {...saveStatus} />
      </Heading>
      <p>
        If you want to delete an account, please provide an <Strong>existing</Strong>{' '}
        user&apos;s e-mail address and click the button.
      </p>
      <Input name={fields.email.name} label={fields.email.label} />
      {error && (
        <Section>
          <ErrorBox error={error} />
        </Section>
      )}
      <Wrapper margin={[2, 0]}>
        <Button type="submit" variant={BUTTON_VARIANTS.TERTIARY} isLoading={isLoading}>
          Delete account
        </Button>
      </Wrapper>
    </Form>
  );
};

export default RemoveAccount;
