import * as React from 'react';

import { Title } from 'common/components';
import { Section } from 'templates/core';
import LogoPicker from '../components/LogoPicker';
import ProgramSettings from '../components/ProgramSettings';
import CompanyAdmins from '../components/CompanyAdmins';
import BillingEmails from '../components/BillingEmails';
import CompanyCore from '../components/CompanyCore';
import { fields } from '../consts';

const defaultValues = {
  [fields.min_team_size.name]: 10,
  [fields.max_team_size.name]: 25,
};

const CompanyCreate = () => {
  return (
    <>
      <Section>
        <Title size="big">Create company</Title>
      </Section>
      <CompanyCore defaultValues={defaultValues} layout="creation">
        <CompanyAdmins />
        <BillingEmails />
        <LogoPicker />
        <ProgramSettings />
      </CompanyCore>
    </>
  );
};

export default CompanyCreate;
