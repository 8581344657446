import * as React from 'react';

import { MediaObject } from 'base/api/types';
import { useAutoSize } from 'common/hooks';
import { MEDIA_OPTIONS } from 'common/consts';
import { Wrapper, Select, TopBar } from 'common/components';
import { ContentProvider, FiltersForm, SearchInput } from 'templates/content';
import MediaGrid from '../components/MediaGrid';
import { useMediaList } from '../queries';

const options = MEDIA_OPTIONS.map((option) => ({ ...option, value: option.type }));

const fields = {
  search: 'search',
  type: 'type',
};

const MediaLibrary = () => {
  const { data, error } = useMediaList();
  const context = { data: data?.values };
  const { width, height } = useAutoSize([23 * 8, 0]);

  return (
    <ContentProvider<MediaObject> {...context}>
      <TopBar title="Media" disableNav>
        <FiltersForm<MediaObject>
          search={['name', fields.search]}
          filter={['media_type', fields.type]}
        >
          <Wrapper margin={[0, 0.5]}>
            <Select
              name={fields.type}
              placeholder="All types"
              options={options}
              isMulti
            />
          </Wrapper>
          <Wrapper margin={[0, 0.5]}>
            <SearchInput name={fields.search} />
          </Wrapper>
        </FiltersForm>
      </TopBar>
      <MediaGrid width={width} height={height} error={error} />
    </ContentProvider>
  );
};

export default MediaLibrary;
