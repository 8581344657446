import * as React from 'react';
import styled from 'styled-components';
import { FieldError, useFormContext, useWatch } from 'react-hook-form';
import _get from 'lodash/get';

import { resolveSpacersArray } from 'common/styles/utils';
import { Spinner, FormError } from 'common/components';
import { Check, Eye, EyeCrossed } from 'common/icons';
import { composeHandlers, isNumber } from 'common/utils';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  error?: FieldError;
  label?: string;
  check?: boolean;
  isLoading?: boolean;
  frontIcon?: React.ReactNode;
  backIcon?: React.ReactNode;
  customError?: React.ReactNode;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  passValue?: React.Dispatch<React.SetStateAction<unknown>>;
}

const Input = ({ name, passValue, ...props }: InputProps) => {
  const methods = useFormContext();
  const { register, errors } = methods;
  const value = useWatch({ name });
  const isValue = isNumber(value) ? value === 0 || !!value : !!value;
  const error = _get(errors, name);

  React.useEffect(() => {
    if (passValue) {
      passValue(value);
    }
  }, [passValue, value]);

  return (
    <PlainInput name={name} isValue={isValue} error={error} {...props} ref={register} />
  );
};

interface PlainInputProps extends InputProps {
  isValue: boolean;
}

export const PlainInput = React.forwardRef<HTMLInputElement, PlainInputProps>(
  (
    {
      className,
      label,
      error,
      name,
      check,
      isValue,
      isLoading,
      customError,
      frontIcon,
      backIcon,
      type = 'text',
      onWheel,
      ...props
    },
    ref,
  ) => {
    const [showPass, setShowPass] = React.useState(false);
    const isError = !!error;
    const isLabel = !!label;
    const isPassword = type === 'password';
    const isFrontIcon = React.isValidElement(frontIcon);
    const iconsNumber = [isLoading, isPassword, check, backIcon].filter((item) => item)
      .length;
    const toggleShowPassword = () => {
      setShowPass((show) => !show);
    };
    const wheelHandler =
      type === 'number'
        ? {
            onWheel: composeHandlers(
              (event: React.WheelEvent<HTMLInputElement>) => event.currentTarget.blur(),
              onWheel,
            ),
          }
        : null;

    return (
      <>
        <InputContainer
          isValue={isValue}
          isError={isError}
          isFrontIcon={isFrontIcon}
          className={className}
          isLabel={isLabel}
          hidden={type === 'hidden'}
          iconsNumber={iconsNumber}
        >
          {isFrontIcon && <FrontIconContainer>{frontIcon}</FrontIconContainer>}
          <input
            ref={ref}
            name={name}
            type={showPass ? 'text' : type}
            {...props}
            {...wheelHandler}
          />

          <Label isValue={isValue} isError={isError}>
            {label}
          </Label>
          <IconsContainer>
            {isLoading ? (
              <Spinner circleColor="greenLight" color="green" margin="8px" />
            ) : null}
            {backIcon ? backIcon : null}
            {isPassword ? (
              showPass ? (
                <Eye title="hide password" onClick={toggleShowPassword} />
              ) : (
                <EyeCrossed title="show password" onClick={toggleShowPassword} />
              )
            ) : null}
            {check ? <Check size="32px" /> : null}
          </IconsContainer>
        </InputContainer>
        {error &&
          (customError ? (
            React.isValidElement(customError) &&
            React.cloneElement(customError, { error })
          ) : (
            <FormError message={error.message} />
          ))}
      </>
    );
  },
);
PlainInput.displayName = 'PlainInput';

type ContainerProps = {
  iconsNumber: number;
  hidden: boolean;
  isValue: boolean;
  isError: boolean;
  isLabel: boolean;
  isFrontIcon: boolean;
};

const Label = styled.div<Pick<ContainerProps, 'isError' | 'isValue'>>`
  display: block;
  position: absolute;
  top: ${({ theme }) => theme.spacer.times(3)};
  left: ${({ theme }) => theme.spacer.times(3)};
  color: ${({ theme, isError }) =>
    isError ? theme.color.error : theme.themeColor.quaternaryColor};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  transform: ${({ isValue }) => (isValue ? 'scale(0.87) translateY(-16px)' : '')};
  transform-origin: center left;
  transition: transform 0.2s ease, color 0.2s ease;
  user-select: none;
`;

const IconsContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${({ theme }) => theme.spacer.big};
  z-index: 1;
  margin: 0 ${({ theme }) => theme.spacer.small};
  display: flex;
  align-items: center;

  & > svg {
    margin: ${({ theme }) => theme.spacer.small};
  }
`;

const FrontIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${({ theme }) => theme.spacer.times(7)};
`;

const InputContainer = styled.label<ContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  input {
    appearance: none;
    width: 100%;
    padding: ${({ theme, isLabel, iconsNumber }) =>
      resolveSpacersArray(theme, [
        isLabel ? 3.5 : 2,
        3 + 4 * iconsNumber,
        isLabel ? 1.5 : 2,
        0,
      ])};
    border: 1px solid
      ${({ theme, isError }) =>
        isError ? theme.color.error : theme.themeColor.tertiaryColor};
    border-radius: ${({ theme }) => theme.border.radius.tiny};
    padding-left: ${({ theme, isFrontIcon }) =>
      isFrontIcon ? theme.spacer.times(7) : theme.spacer.times(3)};
    font-family: ${({ theme }) => theme.font.family.sans};
    font-size: ${({ theme }) => theme.font.size.small};
    font-weight: ${({ theme }) => theme.font.weight.normal};
    line-height: 22px;
    background-color: ${({ theme }) => theme.color.white};

    &::placeholder {
      color: ${({ theme }) => theme.themeColor.quaternaryColor};
    }

    &:read-only {
      background-color: ${({ theme }) => theme.color.blueSuperLight};
      color: ${({ theme }) => theme.color.blueMediumLight};

      & ~ ${Label} {
        color: ${({ theme }) => theme.color.blueMediumLight};
      }
    }

    &:focus {
      outline: none;

      & ~ ${Label} {
        transform: scale(0.87) translateY(-16px);
      }

      &:not(:read-only) {
        border-color: ${({ theme }) => theme.themeColor.tertiarySuperDarkColor};

        & ~ ${Label} {
          color: ${({ theme }) => theme.themeColor.tertiarySuperDarkColor};
        }
      }
    }

    &[type='hidden'] ~ ${IconsContainer} {
      display: none;
    }
  }
`;

export default Input;
