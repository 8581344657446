import * as React from 'react';

import { Title } from 'common/components';
import { Section } from 'templates/core';
import RecipesCore from '../components/RecipesCore';
import { useCreationDefaults } from 'common/hooks';

const RecipeCreate = () => {
  const defaultValues = useCreationDefaults();

  return (
    <>
      <Section>
        <Title size="big">Create Recipe</Title>
      </Section>
      <RecipesCore defaultValues={defaultValues} layout="creation" />
    </>
  );
};

export default RecipeCreate;
