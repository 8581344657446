import * as React from 'react';
import styled from 'styled-components';

import { SelectOption } from 'common/types';
import { useOutsideClick } from 'common/hooks';
import { Arrow } from 'common/icons';
import { resolveSpacersArray } from 'common/styles/utils';

const openRotation = -45;
const closeRotation = 135;

type PlainSelectProps<TOption extends SelectOption> = {
  label: string;
  options: TOption[];
  onChange: (value: TOption) => void;
};

const PlainSelect = <TOption extends SelectOption>({
  label,
  options,
  onChange,
}: PlainSelectProps<TOption>) => {
  const [isOpen, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<TOption | null>(null);
  const { containerRef } = useOutsideClick<HTMLDivElement>(() => setOpen(false));
  const renderOptionList = isOpen && options.length > 0;
  const isValue = selected !== null;

  const handlePick = (value: TOption) => {
    setSelected(value);
    onChange(value);
    setOpen(false);
  };

  return (
    <SelectContainer ref={containerRef}>
      <SelectButton onClick={() => setOpen((isOpen) => !isOpen)} $isOpen={isOpen}>
        <Label $isValue={isValue}>{label}</Label>
        <SelectValuesContainer>
          {selected !== null && (
            <SelectValueLabel key={selected.value}>{selected.label}</SelectValueLabel>
          )}
        </SelectValuesContainer>
        <IconsContainer>
          <Arrow
            $width="2px"
            $color="pink"
            $size="10px"
            $rotate={isOpen ? openRotation : closeRotation}
          />
        </IconsContainer>
      </SelectButton>
      {renderOptionList && (
        <SelectList>
          <Divider />
          {options.map((item) => (
            <Option key={item.value} onClick={() => handlePick(item)}>
              <OptionLabel>{item.label}</OptionLabel>
            </Option>
          ))}
        </SelectList>
      )}
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  position: relative;
  min-width: ${({ theme }) => theme.spacer.times(25)};
`;

const Label = styled.label<{ $isValue: boolean }>`
  position: absolute;
  top: ${({ theme }) => theme.spacer.times(3)};
  left: ${({ theme }) => theme.spacer.times(3)};
  color: ${({ theme }) => theme.color.greyDark};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-right: ${({ theme }) => theme.spacer.standard};
  transform: ${({ $isValue }) => ($isValue ? 'scale(0.87) translateY(-16px)' : '')};
  transform-origin: center left;
  transition: transform 0.2s ease, color 0.2s ease;
  cursor: inherit;
`;

const SelectButton = styled.button.attrs({
  type: 'button',
})<{
  $isOpen: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  width: 100%;

  border: 1px solid
    ${({ theme, $isOpen }) => ($isOpen ? theme.color.blue : theme.color.blueLight)};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  background-color: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => resolveSpacersArray(theme, [2, 2, 2, 3])};
  font-size: ${({ theme }) => theme.font.size.small};
  font-family: ${({ theme }) => theme.font.family.sans};

  &:focus {
    border-color: ${({ theme }) => theme.color.blue};
  }
`;

const Divider = styled.div`
  position: relative;
  top: -5px;
  border-top: 1px solid ${({ theme }) => theme.color.blueSuperLight};
`;

const SelectList = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  padding: ${({ theme }) => theme.spacer.standard};
  background-color: ${({ theme }) => theme.color.white};
  border-left: 1px solid ${({ theme }) => theme.color.blue};
  border-right: 1px solid ${({ theme }) => theme.color.blue};
  border-bottom: 1px solid ${({ theme }) => theme.color.blue};
  border-bottom-left-radius: ${({ theme }) => theme.border.radius.tiny};
  border-bottom-right-radius: ${({ theme }) => theme.border.radius.tiny};
  transform: translateY(${({ theme }) => theme.spacer.times(-0.5)});
`;

const IconsContainer = styled.div`
  margin: 0 ${({ theme }) => theme.spacer.small};
  display: flex;
  align-items: center;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacer.standard};
  margin: ${({ theme }) => theme.spacer.small} 0;
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color.pink};
    background-color: ${({ theme }) => theme.color.pinkSuperLight};
  }
`;

const OptionLabel = styled.span`
  margin-left: ${({ theme }) => theme.spacer.standard};
  font-size: ${({ theme }) => theme.font.size.small};
`;

export const SelectValuesContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: ${({ theme }) => theme.spacer.standard};
`;

export const SelectValueLabel = styled.div`
  margin-right: ${({ theme }) => theme.spacer.standard};
  font-size: ${({ theme }) => theme.font.size.small};
`;

export default PlainSelect;
