import { isString } from 'common/utils';

export type ValidationError = {
  type: 'apiError';
  name: string;
  message: string;
};

export type StandardError = {
  status: number | null;
  message: string;
  validation: ValidationError[];
};

export type ApiError = {
  response?: {
    status: number;
    data?: {
      title: string;
      description: Record<string, string> | string;
    };
  };
};

export const getValidationErros = (errors: Record<string, string>): ValidationError[] => {
  // Just no nested fields case is covered for now.
  const validation: ValidationError[] = [];
  (Object.keys(errors) as Array<keyof typeof errors>).forEach((error) => {
    if (isString(error)) {
      validation.push({
        type: 'apiError',
        name: error,
        message: errors[error][0],
      });
    }
  });
  return validation;
};

export const getError = (error: ApiError): StandardError => {
  const errObj: StandardError = {
    status: null,
    message: 'Something went wrong',
    validation: [],
  };
  if (error.response) {
    errObj.status = error.response.status;

    if (error.response.data) {
      if (isString(error.response.data.description)) {
        errObj.message = error.response.data.description;
      } else if (error.response.data.title === 'Validation Error') {
        errObj.validation = getValidationErros(error.response.data.description);
      }
    }
  }
  return errObj;
};
