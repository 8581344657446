import * as React from 'react';

import { PK } from 'common/types';
import { useMappedValues } from 'common/hooks';
import { RelationSelect, CollapsibleSection } from 'common/components';
import { RelationOption } from 'common/components/RelationSelect';
import { useMeditationsList } from 'modules/meditations/queries';
import { useMinisMeditations, useMinisMeditationsUpdate } from '../queries';

type Props = {
  pk?: PK;
};

const MeditationsPicker = ({ pk }: Props) => {
  const { mutate, isSuccess, isLoading, isError } = useMinisMeditationsUpdate();
  const saveStatus = { isSuccess, isLoading, isError };
  const meditationsList = useMeditationsList();
  const minisMeditations = useMinisMeditations(pk);

  const options = useMappedValues(
    meditationsList.data?.values,
    ({ pk, title, status }) => ({
      label: title,
      value: pk,
      status,
    }),
  );

  const defaults = useMappedValues(
    minisMeditations.data?.values,
    ({ pk, title, status }) => ({
      label: title,
      value: pk,
      status,
    }),
  );

  const handleSave = (values: RelationOption[]) => {
    if (pk) {
      const payload = {
        pk: pk,
        media_content_pks: values.map(({ value }) => value),
      };
      mutate(payload);
    }
  };

  return (
    <>
      <CollapsibleSection title="Add meditations" saveStatus={saveStatus} disabled={!pk}>
        <RelationSelect
          onSave={handleSave}
          options={options}
          defaultValues={defaults}
          isLoading={meditationsList.isLoading}
        />
      </CollapsibleSection>
    </>
  );
};

export default MeditationsPicker;
