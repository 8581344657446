import * as React from 'react';
import { useTheme } from 'styled-components';

import { Radio, Row, Title, Wrapper } from 'common/components';

const BusinessRadio = () => {
  const theme = useTheme();
  return (
    <Wrapper margin={[1, 0]}>
      <Title size="small" marginBottom={2}>
        A b2b content item?
      </Title>
      <Row>
        <Radio name="is_b2b" value="true" label="Yes" color={theme.keys.primaryColor} />
        <Radio name="is_b2b" value="false" label="No" color={theme.keys.primaryColor} />
      </Row>
    </Wrapper>
  );
};

export default BusinessRadio;
