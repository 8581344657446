import * as React from 'react';

import appUrls from 'base/app/urls';
import { Authors, Categories, Ingredients, Nutrition, Tags, Units } from 'common/icons';

export enum RECIPE_SUBSECTIONS {
  MAIN = 'MAIN',
  AUTHORS = 'AUTHORS',
  CATEGORIES = 'CATEGORIES',
  UNITS = 'UNITS',
  INGREDIENTS = 'INGREDIENTS',
  NUTRITION = 'NUTRITION',
  TAGS = 'TAGS',
}

export type RecipeSubsectionOption = {
  label: string;
  value: RECIPE_SUBSECTIONS;
  icon?: React.SVGProps<SVGElement> | null;
  link: string;
};

export const RECIPE_DROPDOWN_OPTIONS: RecipeSubsectionOption[] = [
  { label: '...', value: RECIPE_SUBSECTIONS.MAIN, link: appUrls.RECIPES.LIST },
  {
    label: 'Authors',
    value: RECIPE_SUBSECTIONS.AUTHORS,
    icon: <Authors />,
    link: appUrls.AUTHORS.LIST,
  },
  {
    label: 'Categories',
    value: RECIPE_SUBSECTIONS.CATEGORIES,
    icon: <Categories />,
    link: appUrls.CATEGORIES.LIST,
  },
  {
    label: 'Units',
    value: RECIPE_SUBSECTIONS.UNITS,
    icon: <Units />,
    link: appUrls.UNITS.LIST,
  },
  {
    label: 'Ingredients',
    value: RECIPE_SUBSECTIONS.INGREDIENTS,
    icon: <Ingredients />,
    link: appUrls.INGREDIENTS.LIST,
  },
  {
    label: 'Nutrition',
    value: RECIPE_SUBSECTIONS.NUTRITION,
    icon: <Nutrition />,
    link: appUrls.NUTRITIONAL_VALUES.LIST,
  },
  {
    label: 'Tags',
    value: RECIPE_SUBSECTIONS.TAGS,
    icon: <Tags />,
    link: appUrls.TAGS.LIST,
  },
];
