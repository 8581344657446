import * as React from 'react';
import styled from 'styled-components';

import { Button, Row } from 'common/components';
import { BUTTON_VARIANTS } from 'common/consts';
import { CircleChevron } from 'common/icons';
import { resolveSpacersArray } from 'common/styles/utils';
import { PK } from 'common/types';

interface Props {
  weeks?: PK[];
  selected?: PK;
  onSelect: (pk: PK) => void;
}

const DISPLAY_LIMIT = 7;

const WeekSelector = ({ weeks, selected, onSelect }: Props) => {
  const [offset, setOffset] = React.useState(0);
  const weekCount = weeks?.length || 0;
  const maxOffset = weekCount - DISPLAY_LIMIT;
  const weekList = weeks?.slice(offset, offset + DISPLAY_LIMIT);

  return (
    <Row>
      {weekCount > DISPLAY_LIMIT && (
        <Button
          onClick={() => setOffset((offset) => Math.max(0, offset - 1))}
          variant={BUTTON_VARIANTS.ICON}
          noNegativeMargin
        >
          <CircleChevron
            color={offset === 0 ? 'grey' : 'pink'}
            rotate={180}
            size="32px"
          />
        </Button>
      )}
      <Slider>
        {weekList?.map((week, index) => (
          <WeekPill
            key={week}
            isSelected={selected === week}
            onClick={() => onSelect(week)}
          >
            Week {offset + index + 1}
          </WeekPill>
        ))}
      </Slider>
      {weekCount > DISPLAY_LIMIT && (
        <Button
          onClick={() => setOffset((offset) => Math.min(maxOffset, offset + 1))}
          variant={BUTTON_VARIANTS.ICON}
          noNegativeMargin
        >
          <CircleChevron color={offset >= maxOffset ? 'grey' : 'pink'} size="32px" />
        </Button>
      )}
    </Row>
  );
};

const WeekPill = styled.button<{ isSelected: boolean }>`
  cursor: pointer;
  line-height: 150%;
  white-space: nowrap;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.color.white : theme.color.pink};
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.color.greenDark : theme.color.white};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  border: 1px solid ${({ theme }) => theme.color.blueSuperLight};
  border-radius: ${({ theme }) => theme.border.radius.large};
  padding: ${({ theme }) => resolveSpacersArray(theme, [1, 3])};
  margin: ${({ theme }) => theme.spacer.times(0.5)};
`;

const Slider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export default WeekSelector;
