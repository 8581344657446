import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { PK } from 'common/types';
import { matchUrl } from 'common/utils';
import ContentListContext, { ContentContext } from '../context';

type CommonData = {
  pk: PK;
};

type Props<TData extends CommonData> = {
  data: TData[] | undefined;
  children: React.ReactNode;
  path?: {
    details: string;
    list: string;
    create?: string;
  };
  isDetailsView?: boolean;
};

function ContentProvider<TData extends CommonData>({
  data: initialData,
  path,
  isDetailsView,
  children,
}: Props<TData>) {
  const [data, setData] = React.useState<TData[] | null>(null);
  const [filteredData, setFiltered] = React.useState<TData[]>([]);
  const [renderer, setRenderer] = React.useState<number>(0);

  // This method forces react-window list to rerender by changing renderer prop on demand
  // Source: https://github.com/bvaughn/react-window/issues/357
  const rerender = () => {
    setRenderer((renderer) => renderer + 1);
  };

  const handleSetFiltered = React.useCallback((data: TData[]) => {
    setFiltered(data);
    rerender();
  }, []);

  const location = useLocation();
  const match = matchUrl(path?.details, location.pathname);

  const value: ContentContext<TData> = React.useMemo(
    () => ({
      data,
      filteredData,
      setFiltered: handleSetFiltered,
      renderer,
      path,
      isDetailsView,
      active: data?.find(({ pk }) => match && pk === match.pk),
    }),
    [filteredData, data, handleSetFiltered, renderer, path, match, isDetailsView],
  );

  React.useEffect(() => {
    if (initialData && initialData.length > 0) {
      setData(initialData);
      setFiltered(initialData);
    }
  }, [initialData]);

  return (
    <ContentListContext.Provider value={value}>{children}</ContentListContext.Provider>
  );
}

export default ContentProvider;
