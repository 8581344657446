import * as React from 'react';

import { useCreationDefaults } from 'common/hooks';
import { translate as t } from 'common/utils/translate';

import { Title } from 'common/components';
import { Section } from 'templates/core';
import SeriesCore from '../components/SeriesCore';

const SeriesCreate = () => {
  const defaultValues = useCreationDefaults();

  return (
    <>
      <Section>
        <Title size="big">{t('Create series')}</Title>
      </Section>
      <SeriesCore defaultValues={defaultValues} layout="creation" />
    </>
  );
};

export default SeriesCreate;
