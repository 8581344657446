import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Fact } from 'base/api/types';
import { transformContentGoals } from 'base/api/utils';
import { STATUSES_OPTIONS } from 'common/consts';
import { PK } from 'common/types';
import { DetailsCard, DetailsSuspense } from 'templates/details';

import FactCore from '../components/FactCore';
import { fields, FactFields } from '../consts';
import { useFactDetails } from '../queries';

const transformFact = (raw: Fact): FactFields => ({
  [fields.fact_text.name]: raw.fact_text,
  [fields.status.name]: STATUSES_OPTIONS.filter(({ value }) => value === raw.status),
  [fields.content_goals.name]:
    raw.content_goals && transformContentGoals(raw.content_goals),
});

const FactsDetails = () => {
  const { pk } = useParams<{ pk?: PK }>();
  const { data, ...query } = useFactDetails(pk);
  const defaultValues = data && transformFact(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading} error={query.error}>
        <FactCore key={pk} pk={pk} defaultValues={defaultValues} />
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default FactsDetails;
