import * as React from 'react';
import { useParams } from 'react-router-dom';

import { transformContentGoals } from 'base/api/utils';
import { Meditation } from 'base/api/types';
import { STATUSES_OPTIONS } from 'common/consts';
import { PK } from 'common/types';
import { DetailsCard, DetailsSuspense } from 'templates/details';

import PodcastsCore from '../components/PodcastCore';
import { fields, PodcastFields } from '../consts';
import { usePodcastDetails } from '../queries';

const transformPodcast = (raw: Meditation): PodcastFields => ({
  [fields.title.name]: raw.title,
  [fields.status.name]: STATUSES_OPTIONS.filter(({ value }) => value === raw.status),
  [fields.content_goals.name]:
    raw.content_goals && transformContentGoals(raw.content_goals),
  [fields.media_file_pk.name]: [raw.media_file],
});

const PodcastDetails = () => {
  const { pk } = useParams<{ pk?: PK }>();
  const { data, ...query } = usePodcastDetails(pk);
  const defaultValues = data && transformPodcast(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading} error={query.error}>
        <PodcastsCore key={pk} pk={pk} defaultValues={defaultValues} />
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default PodcastDetails;
