import * as React from 'react';

import { Select } from 'common/components';
import { useAuthorsList } from 'modules/authors/queries';
import { PK, SelectOption } from 'common/types';
import { SelectProps } from 'common/components/Select';

type AuthorOption = SelectOption<PK>;
type AuthorSelectProps = Omit<SelectProps<AuthorOption>, 'options'>;

const AuthorSelect = (props: AuthorSelectProps) => {
  const { data } = useAuthorsList();

  const options: AuthorOption[] =
    data?.values.map(({ name, pk }) => ({ label: name, value: pk })) || [];

  return <Select {...props} options={options} />;
};

export default AuthorSelect;
