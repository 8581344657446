import * as React from 'react';
import styled from 'styled-components';

import { Arrow, Padlock } from 'common/icons';
import { Link, Title } from 'common/components';
import { resolveSpacersArray } from 'common/styles/utils';

const AuthorizationErrorMessage = () => {
  return (
    <>
      <Title center size="big" marginBottom="big">
        User not authorized
      </Title>
      <IconContainer>
        <Padlock
          hoverColor="greenSuperDark"
          color="greenSuperDark"
          size="56px"
          padlockColor="white"
        />
      </IconContainer>
      <StyledTitle weight="normal" size="standard">
        You don’t have access to the CMS yet. Please contact admin to get access
      </StyledTitle>
      <StyledLink underlined to="/login">
        <Arrow $size="7px" $margin={[0.5, 0, 0, 0]} />
        <StyledTitle weight="normal" size="standard">
          Go back to login page
        </StyledTitle>
      </StyledLink>
    </>
  );
};
const IconContainer = styled.div`
  width: ${({ theme }) => theme.spacer.times(7)};
  margin: ${({ theme }) => resolveSpacersArray(theme, [2, 'auto'])};
`;

const StyledTitle = styled(Title)`
  margin-left: ${({ theme }) => theme.spacer.times(2)};
`;

const StyledLink = styled(Link)`
  margin-top: ${({ theme }) => theme.spacer.times(3)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default AuthorizationErrorMessage;
