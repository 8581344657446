import { useMutation, useQuery, useQueryClient } from 'react-query';
import Axios from 'axios';

import { StandardError } from 'base/api/errors';
import { useListQuery } from 'base/api/hooks';
import apiUrls from 'base/api/urls';
import { CmsAdmin, Configuration, ConfigurationVars } from 'base/api/types';
import { PK } from 'common/types';

export const useAdminsList = () => {
  return useListQuery<CmsAdmin>(apiUrls.ADMINS.LIST);
};

export const useConfiguration = () => {
  return useQuery<Configuration, StandardError>(apiUrls.CONFIGURATION.BASE);
};

export const useSetMaintenanceMode = () => {
  const queryClient = useQueryClient();

  return useMutation<void, StandardError, ConfigurationVars>(
    (data) => Axios.patch(apiUrls.CONFIGURATION.BASE, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.CONFIGURATION.BASE);
      },
    },
  );
};

interface BaseUserVars {
  email: string;
}

export const useAdminSet = () => {
  const queryClient = useQueryClient();

  return useMutation<void, StandardError, BaseUserVars>(
    (data) => Axios.post(apiUrls.ADMINS.SET, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.ADMINS.LIST);
      },
    },
  );
};

interface UnsetAdminVars {
  user_pk: PK;
}

export const useAdminUnset = () => {
  const queryClient = useQueryClient();

  return useMutation<void, StandardError, UnsetAdminVars>(
    (data) => Axios.post(apiUrls.ADMINS.UNSET, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.ADMINS.LIST);
      },
    },
  );
};

export const useRemoveAccount = () => {
  const queryClient = useQueryClient();

  return useMutation<void, StandardError, BaseUserVars>(
    (data) => Axios.post(apiUrls.USERS.DELETE, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.ADMINS.LIST);
      },
    },
  );
};

export const useClearCache = () => {
  return useMutation<void, StandardError>(() => Axios.post(apiUrls.CACHE.CLEAR));
};
