import * as React from 'react';

//File prepared to create Creating habits in top menu (TODO if BE ready)

import { translate as t } from 'common/utils/translate';

import { Title } from 'common/components';
import { Section } from 'templates/core';

import HabitsCore from '../components/HabitsCore';

const HabitsCreate = () => {
  return (
    <>
      <Section>
        <Title size="big">{t('Create habits')}</Title>
      </Section>
      <HabitsCore layout="creation" />
    </>
  );
};

export default HabitsCreate;
