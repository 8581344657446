import styled from 'styled-components';
import { Title } from 'common/components';

const Text = styled(Title)`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

export default Text;
