import * as React from 'react';
import styled from 'styled-components';

import { IconContainer } from 'common/components';
import { ThemeColor } from 'shared/theme';
import Highlight from './Highlight';

interface IndicatorProps {
  color: ThemeColor;
  highlightColor: ThemeColor;
  children: React.ReactNode;
  icon: React.ReactNode;
}

const Indicator = ({ children, color, highlightColor, icon }: IndicatorProps) => (
  <Highlight $color={color} $highlightColor={highlightColor}>
    <AdjustedIconContainer size="16px" margin={[0, 1, 0, 0]}>
      {icon}
    </AdjustedIconContainer>
    {children}
  </Highlight>
);

const AdjustedIconContainer = styled(IconContainer)`
  position: relative;
  top: -2px;
`;

export default Indicator;
