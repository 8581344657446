import * as React from 'react';
import styled from 'styled-components';

import {
  Row,
  Input,
  Textarea,
  CollapsibleSection,
  Button,
  Img,
  ErrorBox,
} from 'common/components';
import { FieldsGrid, GridItem, SaveButton } from 'templates/core';
import { BlogMobilePreview } from './BlogMobilePreview/BlogMobilePreview';
import ImagePlaceholder from './BlogMobilePreview/ImagePlaceholder';
import { BlogFields, fields } from 'modules/blogs/consts';
import { resolveSpacersArray } from 'common/styles/utils';
import { PK } from 'common/types';
import { getIsFriesland } from 'common/utils/config';

type Props = {
  pk?: PK;
  onSubmit: () => void;
  handleCheckClick: () => void;
  isSubmitting: boolean;
  defaultValues?: BlogFields;
  disabled: boolean;
  blogHtml: string;
  imageUrl?: string;
  isError: boolean;
};

const BlogPickerForm = ({
  onSubmit,
  isSubmitting,
  handleCheckClick,
  defaultValues,
  blogHtml,
  disabled,
  imageUrl,
  isError,
  pk,
}: Props) => {
  const isFriesland = getIsFriesland();
  const image = imageUrl || defaultValues?.image_url;

  return (
    <form onSubmit={onSubmit}>
      <FieldsGrid>
        <GridItem>
          <Row>
            <Input
              name={fields.url.name}
              label={'Blog url'}
              defaultValue={defaultValues?.url}
            />
            <StyledButton width={13} onClick={handleCheckClick}>
              Get Data
            </StyledButton>
          </Row>
          {isError && <ErrorBox isError={isError} />}
        </GridItem>
        <GridItem>
          <Input
            name={fields.title.name}
            label={'Blog title'}
            disabled={disabled}
            defaultValue={defaultValues?.title}
          />
        </GridItem>
        <GridItem>
          <Textarea
            name={'description'}
            label={'Blog description'}
            disabled={disabled}
            defaultValue={defaultValues?.description}
          />
        </GridItem>
        <GridItem>
          <Row>
            <Input
              name={fields.image_url.name}
              label={'Blog image url'}
              disabled={disabled}
              defaultValue={defaultValues?.image_url}
            />
          </Row>
        </GridItem>
        <GridItem>
          <GridItem>
            <ImagePreview>
              {image ? <StyledImg src={image} alt="Blog image" /> : <ImagePlaceholder />}
            </ImagePreview>
          </GridItem>
        </GridItem>
        {isFriesland && (
          <GridItem>
            <CollapsibleSection title="Show preview" disabled={!pk || disabled}>
              <BlogMobilePreview imageUrl={image} pageHtml={blogHtml} />
            </CollapsibleSection>
          </GridItem>
        )}
      </FieldsGrid>
      <SaveButton label="Save blog" isLoading={isSubmitting} />
    </form>
  );
};

const StyledImg = styled(Img)`
  max-height: 240px;
  object-fit: contain;
`;

const StyledButton = styled(Button)`
  margin-left: ${({ theme }) => theme.spacer.medium};
`;

const ImagePreview = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.color.blueLight};
  padding: ${({ theme }) =>
    resolveSpacersArray(theme, [5, 'medium', 'medium', 'medium'])};
  margin: 0 auto;
  max-width: 400px;
  max-height: 300px;
  ${Img} {
    width: 100%;
    height: 100%;
  }

  &:after {
    content: 'Blog Image';
    position: absolute;
    top: 0;
    left: 0;
    padding: ${({ theme }) => theme.spacer.medium};
    font-size: ${({ theme }) => theme.font.size.tiny};
    color: ${({ theme }) => theme.color.greyDark};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`;

export default BlogPickerForm;
