import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useClickAway } from 'react-use';
import { fadeOut } from 'common/styles/animations';
import { useAnimation } from 'common/hooks';

interface Props {
  className?: string;
  message?: string;
  children: React.ReactNode;
  iconSize?: number;
}

const TOOLTIP_MESSAGE_SPACE = 16;

const Tooltip = ({ className, message, children, iconSize = 16 }: Props) => {
  const { playState, setPlayState, count, setCount } = useAnimation();

  const ref = React.useRef(null);
  const timer = React.useRef(0);

  const [isOpen, setOpen] = useState(false);

  const handleClear = () => {
    clearTimeout(timer.current);
  };

  const handleReopen = () => {
    setPlayState('running');
    handleClear();
    timer.current = setTimeout(handleClose, 4000);
  };

  const handleStop = () => {
    setCount(count + 1);
    setPlayState('paused');
    handleClear();
  };

  const handleClose = () => {
    handleClear();
    setOpen(false);
  };

  const handleOpen = () => {
    handleReopen();
    setOpen(true);
  };

  useEffect(() => {
    return handleClear;
  }, []);

  useClickAway(ref, handleClose);

  return (
    <Wrapper className={className} ref={ref} onClick={handleOpen}>
      {children}
      <TooltipContainer
        iconSize={iconSize}
        isOpen={isOpen}
        onMouseEnter={handleStop}
        onMouseLeave={handleReopen}
        playState={playState}
      >
        <Square />
        <Message>{message}</Message>
      </TooltipContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

type TooltipContainerProps = {
  isOpen: boolean;
  playState: string;
  iconSize: number;
};

const TooltipContainer = styled.div<TooltipContainerProps>`
  position: absolute;
  left: ${({ iconSize }) => iconSize + TOOLTIP_MESSAGE_SPACE}px;
  top: ${-TOOLTIP_MESSAGE_SPACE}px;
  z-index: ${({ theme }) => theme.layer.top};
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  padding: ${({ theme }) => theme.spacer.times(2)};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  background-color: ${({ theme }) => theme.color.blueSuperLight};

  animation: ${fadeOut} 1s linear 3s both;
  animation-play-state: ${({ playState }) => playState};
`;

const Square = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.layer.below(theme.layer.top)};
  top: 6px;
  left: -2px;
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  background-color: ${({ theme }) => theme.color.blueSuperLight};
  transform: rotate(45deg);
`;

const Message = styled.span`
  z-index: ${({ theme }) => theme.layer.top};
  margin-right: ${({ theme }) => theme.spacer.times(3)};
  color: ${({ theme }) => theme.color.blue};
`;

export default Tooltip;
