import * as React from 'react';

import { CONTENT_TYPES } from 'common/consts';
import { PK } from 'common/types';
import { useMinisList } from 'modules/minis/queries';
import { translate as t } from 'common/utils/translate';

import StaticPicker from './StaticPicker';

interface PickerProps {
  pk?: PK;
}

const MinisPicker = ({ pk }: PickerProps) => {
  const { data: minisListData } = useMinisList();
  const minisList = minisListData?.values;

  return (
    <StaticPicker
      pk={pk}
      divider={false}
      sectionTitle={t("Add mini's")}
      contentList={minisList ?? []}
      contentType={CONTENT_TYPES.MINIS}
    />
  );
};

export default MinisPicker;
