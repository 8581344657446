import * as React from 'react';
import { useParams } from 'react-router';

import { RecipeBase } from 'base/api/types';
import { transformContentGoals } from 'base/api/utils';
import { PK } from 'common/types';
import { STATUSES_OPTIONS } from 'common/consts';
import { DetailsCard, DetailsSuspense } from 'templates/details';
import RecipesCore from '../components/RecipesCore';

import { useRecipeDetails } from '../queries';
import { RecipeFields } from '../consts';

const transformRecipe = (raw: RecipeBase): RecipeFields => ({
  title: raw.title,
  description: raw.why_its_healthy,
  description_author: raw.why_its_healthy_author
    ? [
        {
          label: raw.why_its_healthy_author.name,
          value: raw.why_its_healthy_author.pk,
        },
      ]
    : [],
  author: [
    {
      label: raw.author.name,
      value: raw.author.pk,
    },
  ],
  status: STATUSES_OPTIONS.filter(({ value }) => value === raw.status),
  portions: raw.portions,
  calories: raw.calories,
  preparation_time: raw.preparation_time || 0,
  oven_time: raw.oven_time,
  waiting_time: raw.waiting_time,
  date_active_start: raw.date_active_start ? new Date(raw.date_active_start) : null,
  date_active_end: raw.date_active_end ? new Date(raw.date_active_end) : null,
  content_goals: transformContentGoals(raw.content_goals),
});

const RecipeDetails = () => {
  const { pk } = useParams<{ pk?: PK }>();
  const { data, ...query } = useRecipeDetails(pk);
  const defaultValues = data && transformRecipe(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading} error={query.error}>
        <RecipesCore pk={pk} key={pk} defaultValues={defaultValues} />
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default RecipeDetails;
