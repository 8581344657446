import * as React from 'react';
import styled from 'styled-components';

import { TITLE_VARIANTS } from 'common/consts';
import { Container, Title } from 'common/components';
import { useBusinessStore } from 'common/hooks';
import { getProjectContent } from 'common/utils';

import Card from '../components/Card';
import { useDashboardContent } from '../queries';

const Dashboard = () => {
  const { isBusiness } = useBusinessStore();
  const { data: content, isLoading } = useDashboardContent();
  const CONTENT = getProjectContent();

  return (
    <Container padding={[5, 4]}>
      <Title variant={TITLE_VARIANTS.HEADER}>Dashboard</Title>
      <CardsGrid>
        {CONTENT.map(({ type, icon, title, path, isBusiness: isB2Bcontent, isMixed }) => {
          let numbers;

          if (content) {
            numbers = content.overview.find(({ content_type }) => content_type === type);
          }

          return (
            (isMixed || isBusiness === isB2Bcontent) && (
              <Card
                key={type}
                path={path}
                icon={icon}
                title={title}
                numbers={numbers}
                isLoading={isLoading}
              />
            )
          );
        })}
      </CardsGrid>
    </Container>
  );
};

const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${({ theme }) => theme.spacer.times(6)};
  padding: ${({ theme }) => theme.spacer.times(3)} 0;

  @media (min-width: ${({ theme }) => theme.media.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${({ theme }) => theme.media.lg}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export default Dashboard;
