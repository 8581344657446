import * as React from 'react';

import { PK } from 'common/types';
import { ContentRow } from 'templates/content';
import { ContentRowProps } from 'templates/content/components/ContentRow';

import RecipesMenu from './RecipesMenu';

export interface RecipesRow {
  pk: PK;
  title: string;
  isDetailsView?: boolean;
}

const RecipesContentRow: React.FC<ContentRowProps<RecipesRow>> = (props) => {
  const recipeData = props.data;

  return <ContentRow {...props} data={recipeData} menuComponent={RecipesMenu} />;
};

export default RecipesContentRow;
