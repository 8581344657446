import * as React from 'react';
import { useLocation } from 'react-router-dom';

import appUrls from 'base/app/urls';
import { Container, Wrapper } from 'common/components';
import { matchUrl } from 'common/utils';
import {
  RecipeDetailsSwitch,
  RecipeTopBar,
  CreateButton,
} from 'modules/recipe-subsections';
import { RECIPE_SUBSECTIONS } from 'modules/recipe-subsections/consts';
import {
  ContentProvider,
  ContentList,
  FiltersForm,
  SearchInput,
} from 'templates/content';

import AuthorsDetails from './AuthorsDetails';
import AuthorsContentRow from '../components/AuthorsContentRow';
import { useAuthorsList } from '../queries';

const fields = {
  search: 'search',
  sort: 'sort',
};

const AuthorsList = () => {
  const { data, isError } = useAuthorsList();
  const path = appUrls.AUTHORS.DETAILS;
  const location = useLocation();
  const detailsMatch = matchUrl(path, location.pathname);
  const isDetailsViews = !!detailsMatch;

  const context = {
    data: data?.values,
    path: {
      list: appUrls.AUTHORS.LIST,
      details: appUrls.AUTHORS.DETAILS,
      create: appUrls.AUTHORS.CREATE,
    },
    isDetailsView: isDetailsViews,
  };

  return (
    <Container>
      <ContentProvider {...context}>
        <RecipeTopBar subsection={RECIPE_SUBSECTIONS.AUTHORS}>
          <CreateButton path={appUrls.AUTHORS.CREATE}>New item</CreateButton>
          <FiltersForm search={['name', fields.search]}>
            <Wrapper margin={[0, 0.5]}>
              <SearchInput name={fields.search} />
            </Wrapper>
          </FiltersForm>
        </RecipeTopBar>
        <ContentList isError={isError}>{AuthorsContentRow}</ContentList>
        <RecipeDetailsSwitch>
          <AuthorsDetails />
        </RecipeDetailsSwitch>
      </ContentProvider>
    </Container>
  );
};

export default AuthorsList;
