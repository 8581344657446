import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const EyeCrossed = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 22 20" {...props}>
      <g
        id="WEB-|-Visual"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Onboarding_2-5"
          transform="translate(-1029.000000, -454.000000)"
          stroke="currentColor"
          strokeWidth="2"
        >
          <g id="b-preview" transform="translate(1030.000000, 455.000000)">
            <path
              d="M0,9 C0,9 4,2 10,2 C16,2 20,9 20,9 C20,9 16,16 10,16 C4,16 0,9 0,9 Z"
              id="Path"
            ></path>
            <circle id="Oval" cx="10" cy="9" r="3"></circle>
            <line x1="1" y1="18" x2="19" y2="0" id="Path"></line>
          </g>
        </g>
      </g>
    </Svg>
  );
};

EyeCrossed.defaultProps = {
  size: '22px',
  color: 'black',
  hoverColor: 'black',
};

export default EyeCrossed;
