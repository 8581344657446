import styled from 'styled-components';

const TopBarContainer = styled.div`
  position: fixed;
  z-index: 10;
  top: ${({ theme }) => theme.spacer.times(11)};
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.blueLight};
  height: ${({ theme }) => theme.spacer.times(12)};
`;

export default TopBarContainer;
