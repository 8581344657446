import * as React from 'react';

import { PK } from 'common/types';
import { useMappedValues } from 'common/hooks';
import { RelationSelect, CollapsibleSection } from 'common/components';
import { RelationOption } from 'common/components/RelationSelect';

import { useMinisPodcasts, useMinisPodcastsUpdate } from '../queries';
import { usePodcastList } from 'modules/podcasts/queries';

type Props = {
  pk?: PK;
};

const PodcastPicker = ({ pk }: Props) => {
  const { mutate, isSuccess, isLoading, isError } = useMinisPodcastsUpdate();
  const saveStatus = { isSuccess, isLoading, isError };
  const podcastList = usePodcastList();
  const minisMeditations = useMinisPodcasts(pk);

  const options = useMappedValues(podcastList.data?.values, ({ pk, title, status }) => ({
    label: title,
    value: pk,
    status,
  }));

  const defaults = useMappedValues(
    minisMeditations.data?.values,
    ({ pk, title, status }) => ({
      label: title,
      value: pk,
      status,
    }),
  );

  const handleSave = (values: RelationOption[]) => {
    if (pk) {
      const payload = {
        pk: pk,
        media_content_pks: values.map(({ value }) => value),
      };
      mutate(payload);
    }
  };

  return (
    <>
      <CollapsibleSection title="Add podcasts" saveStatus={saveStatus} disabled={!pk}>
        <RelationSelect
          onSave={handleSave}
          options={options}
          defaultValues={defaults}
          isLoading={podcastList.isLoading}
        />
      </CollapsibleSection>
    </>
  );
};

export default PodcastPicker;
