import * as React from 'react';

import { Container, Wrapper, TopBar } from 'common/components';
import {
  ContentProvider,
  ContentList,
  FiltersForm,
  Sort,
  SearchInput,
} from 'templates/content';
import { useFunfactsList } from '../queries';
import { DetailsSwitch } from 'templates/details';
import FunfactsDetails from './FunfactsDetails';
import appUrls from 'base/app/urls';

const fields = {
  search: 'search',
  sort: 'sort',
};

const FunfactsList = () => {
  const { data, isError } = useFunfactsList();
  const context = {
    data: data?.values,
    path: {
      details: appUrls.FUNFACTS.DETAILS,
      list: appUrls.FUNFACTS.LIST,
    },
  };

  return (
    <Container>
      <ContentProvider {...context}>
        <TopBar title="Fun facts">
          <FiltersForm sort={['title', fields.sort]} search={['title', fields.search]}>
            <Wrapper margin={[0, 0.5]}>
              <SearchInput name={fields.search} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <Sort name={fields.sort} />
            </Wrapper>
          </FiltersForm>
        </TopBar>
        <ContentList isError={isError} />
        <DetailsSwitch>
          <FunfactsDetails />
        </DetailsSwitch>
      </ContentProvider>
    </Container>
  );
};

export default FunfactsList;
