import styled, { DefaultTheme } from 'styled-components';

import { TITLE_VARIANTS } from 'common/consts';
import { ThemeColor, ThemeFontSize, ThemeFontWeight, ThemeSpacer } from 'shared/theme';
import { resolveSpacer } from 'common/styles/utils';

type Props = {
  color?: ThemeColor;
  size?: ThemeFontSize;
  weight?: ThemeFontWeight;
  variant?: TITLE_VARIANTS;
  center?: boolean;
  marginBottom?: ThemeSpacer;
};

const resolveMargin = ({
  theme,
  marginBottom,
  center,
}: Props & { theme: DefaultTheme }): string => {
  const margin: [string | number, string | number] = [0, 0];
  if (center) {
    margin[0] = 'auto';
  }
  if (marginBottom) {
    margin[1] = resolveSpacer(theme, marginBottom);
  }
  return margin.join(' ');
};

const Title = styled.p<Props>`
  flex-shrink: 0;
  color: ${({ theme, color, variant }) =>
    color
      ? theme.color[color]
      : variant === TITLE_VARIANTS.SUBTITLE
      ? theme.color.greyDark
      : theme.color.black};
  font-family: ${({ theme }) => theme.font.family.sans};
  font-size: ${({ theme, size, variant }) =>
    size
      ? theme.font.size[size]
      : variant === TITLE_VARIANTS.HEADER
      ? theme.font.size.big
      : variant === TITLE_VARIANTS.SUBTITLE
      ? theme.font.size.small
      : theme.font.size.medium};
  line-height: ${({ variant }) => (variant === TITLE_VARIANTS.SUBTITLE ? 1.33 : 1.5)};
  font-weight: ${({ theme, weight, variant }) =>
    weight
      ? theme.font.weight[weight]
      : variant === TITLE_VARIANTS.SUBTITLE
      ? theme.font.weight.normal
      : theme.font.weight.bold};
  margin: 0 ${resolveMargin};
  text-align: ${({ center }) => center && 'center'};
  overflow-wrap: break-word;
  border: none;
  appearance: none;
  background: none;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};

  &:focus {
    outline: none;
  }
`;

export default Title;
