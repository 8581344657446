import * as Yup from 'yup';

import { combineSchemas } from 'common/utils';
import { Fields, PK, SelectOption } from 'common/types';

export type IngredientsFields = {
  name_singular: string;
  name_plural: string;
  category: SelectOption<PK>[];
  unit: SelectOption<PK>[] | null;
};

export const fields: Fields<IngredientsFields> = {
  name_singular: {
    label: 'Name (singular)',
    name: 'name_singular',
    schema: Yup.string().required(),
  },
  name_plural: {
    label: 'Name (plural)',
    name: 'name_plural',
    schema: Yup.string().required(),
  },
  unit: {
    label: 'Unit',
    name: 'unit',
    schema: Yup.array(),
  },
  category: {
    label: 'Category',
    name: 'category',
    schema: Yup.array().required(),
  },
};

export const validationSchema = Yup.object().shape<IngredientsFields>(
  combineSchemas(fields),
);
