import styled from 'styled-components';

import { ThemeColor } from 'shared/theme';
import { spin } from 'common/styles/animations';

type Props = {
  circleColor?: ThemeColor;
  color?: ThemeColor;
  margin?: string;
  size?: string;
};

const Spinner = styled.div<Props>`
  border: 2px solid
    ${({ theme, circleColor }) =>
      circleColor ? theme.color[circleColor] : theme.color.white};
  border-top: 2px solid
    ${({ theme, color }) => (color ? theme.color[color] : 'transparent')};
  border-radius: 50%;
  margin: ${({ margin }) => margin};
  background-color: transparent;
  width: ${({ size }) => size || '24px'};
  height: ${({ size }) => size || '24px'};
  animation: ${spin()} 1s linear infinite;
`;

export default Spinner;
