import * as React from 'react';
import styled from 'styled-components';
import { useFormContext, useWatch } from 'react-hook-form';

import { resolveSpacersArray } from 'common/styles/utils';
import { SelectOption } from 'common/types';

import Title from './Title';
import InfoLabel from './InfoLabel';
import FormError from './FormError';

export interface ContentRadioProps {
  name: string;
  options: SelectOption<string>[];
  label?: string;
  infoLabel?: string;
  isMulti?: boolean;
  unmarkable?: boolean;
}

const ContentRadio = ({
  name,
  label,
  infoLabel,
  options,
  isMulti,
  unmarkable = false,
}: ContentRadioProps) => {
  const { register, unregister, setValue, errors } = useFormContext();

  const error = errors[name];
  const isError = !!error;

  const values: SelectOption<string>[] = useWatch({ name }) || [];

  const getId = ({ value }: SelectOption<string>) => value;
  const selectedIds = values.map(getId);

  const isSelected = (id: string) => selectedIds.includes(id);

  const handleSetValue = (values: SelectOption<string>[]) => {
    setValue(name, values, { shouldValidate: true });
  };

  const handlePick = (item: SelectOption<string>) => {
    const id = getId(item);
    const wasSelected = isSelected(id);
    if (isMulti) {
      const newValue = wasSelected
        ? values.filter((value) => getId(value) !== id)
        : [...values, item];
      handleSetValue(newValue);
    } else {
      if (unmarkable) {
        const newValue = wasSelected ? [] : [item];
        handleSetValue(newValue);
      } else {
        handleSetValue([item]);
      }
    }
  };

  React.useEffect(() => {
    register(name);
    return () => unregister(name);
  }, [name, register, unregister]);

  return (
    <Container>
      <LabelsContainer>
        {label && <StyledTitle>{label}</StyledTitle>}
        {infoLabel && <InfoLabel message={infoLabel} />}
      </LabelsContainer>
      {options.map((item: SelectOption<string>) => {
        const id = getId(item);
        const selected = isSelected(id);

        return (
          <React.Fragment key={item.value}>
            <StyledCheckbox
              name={item.value}
              ref={register}
              checked={selected}
              readOnly
            />
            <Label onClick={() => handlePick(item)} htmlFor={item.label}>
              {item.label}
            </Label>
          </React.Fragment>
        );
      })}
      {isError && <FormError message={error.message} />}
    </Container>
  );
};

const LabelsContainer = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacer.big};
`;

const Label = styled.label`
  display: inline-block;
  padding: ${({ theme }) => resolveSpacersArray(theme, [1, 2])};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  margin-right: ${({ theme }) => theme.spacer.standard};
  cursor: pointer;
  color: ${({ theme }) => theme.color.blue};
  background-color: ${({ theme }) => theme.color.blueLight};
  user-select: none;
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  z-index: ${({ theme }) => theme.layer.below(theme.layer.main)};

  &:checked + ${Label} {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.blue};
  }
`;

const StyledTitle = styled(Title)`
  font-size: ${({ theme }) => theme.font.size.small};
  margin-right: ${({ theme }) => theme.spacer.standard};
`;

const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacer.times(3)};
`;

export default ContentRadio;
