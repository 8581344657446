import * as React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { ThemeColor } from 'shared/theme';

export interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  color?: ThemeColor;
}

const Radio = ({ label, className, ...props }: RadioProps) => {
  const methods = useFormContext();
  const ref = methods ? { ref: methods.register } : {};
  return (
    <label className={className}>
      <input {...ref} {...props} type="radio" />
      <div />
      {label && <Label>{label}</Label>}
    </label>
  );
};

const StyledRadio = styled(Radio)`
  position: relative;
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  transition: color 0.1s ease;
  pointer-events: ${({ readOnly }) => readOnly && 'none'};
  width: 100%;

  input {
    opacity: 0;
    position: absolute;
    height: 0.1px;
  }

  input + div {
    display: block;
    position: relative;
    left: 0;
    flex: 0 0 ${({ theme }) => theme.spacer.times(3)};
    width: ${({ theme }) => theme.spacer.times(3)};
    height: ${({ theme }) => theme.spacer.times(3)};
    border: 1px solid ${({ theme }) => theme.color.blueLight};
    border-radius: 50%;

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      padding: ${({ theme }) => theme.spacer.standard};
      position: absolute;
      top: 3px;
      left: 3px;
    }
  }

  input:checked + div {
    &::after {
      background-color: ${({ theme, color = 'blue' }) => theme.color[color]};
    }
  }

  input:focus + div {
    box-shadow: 0 0 0 1px ${({ theme, color = 'blue' }) => theme.color[color]};
  }
`;

const Label = styled.div<{ disabled?: boolean }>`
  width: 100%;
  margin-left: ${({ theme }) => theme.spacer.standard};
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`;

export default StyledRadio;
