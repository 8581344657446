import { getConfig } from 'common/config';
import { PROJECT_NAME } from 'common/consts';

export const getIsFriesland = (): boolean => {
  return getConfig().REACT_APP_PROJECT_NAME === PROJECT_NAME.FRIESLAND;
};

export const getIsProduction = (): boolean => {
  return getConfig().REACT_APP_ENV === 'prod';
};

export const getFrieslandSSOLoginPage = () => {
  return getConfig().FRIESLAND_SSO_LOGIN;
};
