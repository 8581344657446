import * as React from 'react';
import styled from 'styled-components';

import { pulse } from 'common/styles/animations';
import { resolveSpacer } from 'common/styles/utils';

type Props = {
  className?: string;
};

const SkeletonRow = ({ className }: Props) => {
  return (
    <div className={className}>
      <SkeletonBox>
        <SkeletonText width={20} />
      </SkeletonBox>
      <SkeletonBox>
        <SkeletonText bg width={13} />
      </SkeletonBox>
    </div>
  );
};

type SkeletonProps = {
  bg?: boolean;
  width?: number;
};

const SkeletonBox = styled.div<SkeletonProps>`
  display: flex;
  align-items: center;
  width: ${({ theme, width }) => resolveSpacer(theme, width) || 'auto'};
  margin-right: ${({ theme }) => theme.spacer.small};

  &:first-of-type {
    margin-left: ${({ theme }) => theme.spacer.times(4)};
  }

  &:last-of-type {
    margin: 0 ${({ theme }) => theme.spacer.times(10)} 0 auto;
  }
`;

const SkeletonText = styled.div<SkeletonProps>`
  position: relative;
  width: ${({ theme, width }) => resolveSpacer(theme, width) || '30px'};
  border-radius: 20px;
  height: 40px;
  background-color: ${({ theme, bg }) =>
    bg ? theme.color.blueSuperLight : 'transparent'};

  &::after {
    content: '';
    position: absolute;
    left: ${({ bg }) => (bg ? '20px' : 0)};
    right: ${({ bg }) => (bg ? '20px' : 0)};
    top: 16px;
    height: 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.color.blueLight};
  }
`;

const StyledSkeletonRow = styled(SkeletonRow)`
  padding: 0;
  display: flex;
  height: ${({ theme }) => theme.spacer.times(9)};
  align-items: stretch;
  border: 2px dashed ${({ theme }) => theme.color.blueLight};
  margin-bottom: ${({ theme }) => theme.spacer.small};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  animation: ${pulse} 1s ease infinite;
`;

export default StyledSkeletonRow;
