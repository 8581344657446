import * as React from 'react';
import styled, { css } from 'styled-components';

import { isNumber } from 'common/utils';
import { pulse } from 'common/styles/animations';
import { ThemeSpacer } from 'shared/theme';
import { resolveSpacer, resolveSpacersArray } from 'common/styles/utils';

type Props = {
  className?: string;
};

const Skeleton: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <div className={className}>
      <Placeholder />
      <TypeChip />
      <MediaCardFooter>
        <SkeletonText pulse width="75%" height={24} marginBottom="standard" />
        <SkeletonText pulse width="90%" height={14} />
      </MediaCardFooter>
    </div>
  );
};

const StyledSkeleton = styled(Skeleton)`
  position: relative;
  height: 280px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.border.radius.standard};
  overflow: hidden;
  box-shadow: 0 1px 1px 1px ${({ theme }) => theme.color.blueSuperLight};
`;

const MediaCardFooter = styled.div`
  padding: ${({ theme }) => resolveSpacersArray(theme, [2, 3])};
`;

const Placeholder = styled.div`
  width: 100%;
  height: 200px;
  background-color: ${({ theme }) => theme.color.blueGreyDark};
  animation: ${pulse} 1.5s ease infinite;
`;

const TypeChip = styled.div`
  position: absolute;
  z-index: 3;
  top: ${({ theme }) => theme.spacer.times(3)};
  left: ${({ theme }) => theme.spacer.times(3)};
  height: ${({ theme }) => theme.spacer.times(3)};
  width: ${({ theme }) => theme.spacer.times(9)};
  border-radius: ${({ theme }) => theme.border.radius.standard};
  background-color: ${({ theme }) => theme.color.white};

  &:after {
    content: '';
    position: absolute;
    top: ${({ theme }) => theme.spacer.standard};
    left: ${({ theme }) => theme.spacer.standard};
    right: ${({ theme }) => theme.spacer.standard};
    height: ${({ theme }) => theme.spacer.standard};
    background-color: ${({ theme }) => theme.color.blueGreyDark};
    border-radius: ${({ theme }) => theme.border.radius.tiny};
    animation: ${pulse} 1.5s ease infinite;
  }
`;

type SkeletonTextProps = {
  height: number;
  width: number | string;
  marginBottom?: ThemeSpacer;
  pulse?: boolean;
};

const SkeletonText = styled.div<SkeletonTextProps>`
  width: ${({ width }) => (isNumber(width) ? `${width}px` : width)};
  height: ${({ height }) => `${height}px`};
  border-radius: ${({ height }) => `${height / 2}px`};
  background-color: ${({ theme }) => theme.color.blueGreyDark};
  margin-bottom: ${({ theme, marginBottom }) =>
    marginBottom && resolveSpacer(theme, marginBottom)};

  ${({ pulse: isPulse }) =>
    isPulse &&
    css`
      animation: ${pulse} 1.5s ease infinite;
    `}
`;

export default StyledSkeleton;
