import styled from 'styled-components';

const SelectOption = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacer.big};
  cursor: pointer;

  &:nth-child(2n) {
    background-color: ${({ theme }) => theme.components.dropdownSelect.primaryBgColor};
  }

  &:hover {
    color: ${({ theme }) => theme.themeColor.primaryColor};
  }
`;

export default SelectOption;
