import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { PK } from 'common/types';
import { MEDIA_TYPES } from 'common/consts';
import { useMediaOptions } from 'common/hooks';
import { Title, Wrapper, FileInput, CollapsibleSection } from 'common/components';
import MediaItem from 'common/components/MediaField/MediaItem';
import { useCompanyLogo, useCompanyLogoDelete, useCompanyLogoUpload } from '../queries';

const field = 'media';

type Fields = {
  [field]: File[];
};

type Props = {
  pk?: PK;
};

const LogoPicker = ({ pk }: Props) => {
  const form = useForm<Fields>();
  const mediaOption = useMediaOptions(MEDIA_TYPES.IMAGE);
  const logo = useCompanyLogo(pk);
  const update = useCompanyLogoUpload();
  const remove = useCompanyLogoDelete();
  const saveStatus = {
    isSuccess: update.isSuccess || remove.isSuccess,
    isLoading: update.isLoading || remove.isLoading,
    isError: update.isError || remove.isError,
  };

  const handleUpload = () => {
    if (pk) {
      const payload = {
        pk,
        file: form.getValues(field)[0],
      };
      update.mutate(payload);
    }
  };

  const handleDelete = () => {
    if (pk) {
      remove.mutate({ pk });
    }
  };

  const handleReset = () => {
    update.reset();
    update.setProgress(0);
  };

  return (
    <CollapsibleSection title="Add media" saveStatus={saveStatus} disabled={!pk}>
      <FormProvider {...form}>
        <Title size="standard" marginBottom={1.5}>
          Company logo
        </Title>
        <Wrapper padding={[0, 0, 3]}>
          <Wrapper margin={[0, 0, 2]}>
            <FileInput
              name={field}
              label="Drop your file here"
              accept={mediaOption.accept}
              onUpload={handleUpload}
              onRemove={handleReset}
              onDrop={handleReset}
              progress={update.progress}
              isSuccess={update.isSuccess}
              isLoading={update.isLoading}
              error={update.error}
            />
          </Wrapper>
          {logo.data?.original && (
            <MediaItem url={logo.data.original} onRemove={handleDelete} />
          )}
        </Wrapper>
      </FormProvider>
    </CollapsibleSection>
  );
};

export default LogoPicker;
