import * as React from 'react';

import { PK } from 'common/types';
import { isNumber } from 'common/utils';
import { BUTTON_SIZES, BUTTON_VARIANTS } from 'common/consts';
import { Button, ErrorBox, Wrapper } from 'common/components';
import { companyAdminsContext } from './context';
import { AdminFields, AdminFormReset } from './consts';
import { ResendInfo } from './CompanyAdmins.styled';
import {
  useCompanyInvitationDelete,
  useCompanyInvitationsResend,
  useCompanyInvitationUpdate,
} from '../../queries';
import Form from './Form';
import { useModal } from 'common/hooks';
import ConfirmationDialog from './components/ConfirmationDialog';

const Invitations = () => {
  const { companyPk, invitations } = React.useContext(companyAdminsContext);

  return companyPk ? (
    <>
      {invitations.map(({ pk, phone_number, ...values }) => {
        const defaultValues = {
          ...values,
          phone_number: phone_number ? Number(phone_number) : undefined,
        };
        return (
          <InvitationForm
            key={`admin-invitation-${pk}`}
            companyPk={companyPk}
            invitationPk={pk}
            defaultValues={defaultValues}
          />
        );
      })}
    </>
  ) : null;
};

type FormProps = {
  companyPk: PK;
  invitationPk: PK;
  defaultValues?: AdminFields;
};

const InvitationForm = ({ companyPk, defaultValues, invitationPk }: FormProps) => {
  const {
    mutate: update,
    error: updateError,
    isLoading: isUpdateLoading,
  } = useCompanyInvitationUpdate();
  const {
    mutate: remove,
    error: removeError,
    isLoading: isRemoveLoading,
  } = useCompanyInvitationDelete();
  const { isOpen, open: openConfirmation, close } = useModal();

  const error = updateError || removeError;

  const handleSubmit = (values: AdminFields, reset: AdminFormReset) => {
    update(
      {
        companyPk,
        invitationPk,
        body: {
          ...values,
          phone_number: String(values.phone_number),
        },
      },
      {
        onSuccess: ({ phone_number, ...values }) => {
          const defaultValues = {
            ...values,
            phone_number: phone_number ? Number(phone_number) : undefined,
          };
          reset(defaultValues);
        },
      },
    );
  };

  const handleRemove = () => {
    openConfirmation();
  };

  const handleConfirm = (confirmed: boolean) => {
    if (confirmed) {
      remove({ companyPk, invitationPk });
    }
  };

  return (
    <>
      <Form
        onRemove={handleRemove}
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        isLoading={isUpdateLoading || isRemoveLoading}
        error={error}
      >
        {!isNumber(invitationPk) ? (
          <ResendSection companyPk={companyPk} invitationPk={invitationPk} />
        ) : null}
      </Form>
      <ConfirmationDialog isOpen={isOpen} close={close} onClose={handleConfirm} />
    </>
  );
};

type ResendProps = {
  companyPk: PK;
  invitationPk: PK;
};

const ResendSection = ({ companyPk, invitationPk }: ResendProps) => {
  const { mutate: resend, isLoading, error, isSuccess } = useCompanyInvitationsResend();

  const handleResend = () => {
    resend({ companyPk, invitationPk });
  };

  return (
    <Wrapper margin={[2, 0]}>
      <ResendInfo>Send the registration email again</ResendInfo>
      <Button
        onClick={handleResend}
        size={BUTTON_SIZES.SMALL}
        variant={BUTTON_VARIANTS.TERTIARY}
        isLoading={isLoading}
        disabled={isSuccess}
      >
        {isSuccess ? 'Sent' : 'Resend'}
      </Button>
      <ErrorBox error={error} />
    </Wrapper>
  );
};

export default Invitations;
