import * as React from 'react';
import styled from 'styled-components';
import { useWatch } from 'react-hook-form';
import { ThemeColor } from 'shared/theme';
import { SelectOption } from 'common/types';

type BadgeCollectionColorProps = {
  name: string;
  colorMap: Record<string, ThemeColor>;
};

export const BadgeCollectionColor = (props: BadgeCollectionColorProps) => {
  const inputValue = useWatch<SelectOption[]>({ name: props.name });
  const color: ThemeColor = inputValue ? props.colorMap[inputValue[0].label] : 'grey';

  return (
    <Container>
      <ColorBox color={color} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: ${({ theme }) => theme.dims.large};
  height: ${({ theme }) => theme.dims.large};
`;

const ColorBox = styled.div<{ color: ThemeColor }>`
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: ${({ theme }) => theme.dims.big};
  height: ${({ theme }) => theme.dims.big};
  border: solid 1px ${({ theme }) => theme.themeColor.tertiaryLightColor};
  background-color: ${({ theme, color }) => theme.color[color]};
`;
