import * as React from 'react';

import { PK } from 'common/types';
import { AdminFields, AdminFormReset } from './consts';
import { companyAdminsContext } from './context';
import { useCompanyAdminDelete, useCompanyAdminUpdate } from '../../queries';
import Form from './Form';
import { useModal } from 'common/hooks';
import ConfirmationDialog from './components/ConfirmationDialog';

const Admins = () => {
  const { companyPk, admins } = React.useContext(companyAdminsContext);
  const isLastAdmin = admins.length === 1;

  return companyPk ? (
    <>
      {admins.map(({ pk, phone_number, ...values }) => {
        const defaultValues = {
          ...values,
          phone_number: phone_number ? Number(phone_number) : undefined,
        };
        return (
          <AdminForm
            key={pk}
            adminPk={pk}
            companyPk={companyPk}
            defaultValues={defaultValues}
            adminsNumber={admins.length}
            isLastAdmin={isLastAdmin}
          />
        );
      })}
    </>
  ) : null;
};

type FormProps = {
  adminPk: PK;
  companyPk: PK;
  defaultValues?: AdminFields;
  adminsNumber: number;
  isLastAdmin: boolean;
};

const AdminForm = ({
  companyPk,
  adminPk,
  defaultValues,
  adminsNumber,
  isLastAdmin,
}: FormProps) => {
  const {
    mutate: update,
    isLoading: isUpdateLoading,
    error: updateError,
  } = useCompanyAdminUpdate();
  const {
    mutate: remove,
    isLoading: isRemoveLoading,
    error: removeError,
  } = useCompanyAdminDelete();
  const { isOpen, open: openConfirmation, close } = useModal();

  const error = updateError || removeError;

  const handleSubmit = (values: AdminFields, reset: AdminFormReset) => {
    update(
      {
        companyPk,
        adminPk,
        phone_number: String(values.phone_number),
        is_contact_person: values.is_contact_person,
      },
      {
        onSuccess: ({ phone_number, ...values }) => {
          const defaultValues = {
            ...values,
            phone_number: phone_number ? Number(phone_number) : undefined,
          };
          reset(defaultValues);
        },
      },
    );
  };

  const handleRemove = () => {
    openConfirmation();
  };

  const handleConfirm = (confirmed: boolean) => {
    if (confirmed) {
      remove({ companyPk, adminPk });
    }
  };

  return (
    <>
      <Form
        onRemove={handleRemove}
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        isRemoveButtonVisible={!isLastAdmin}
        isLoading={isUpdateLoading || isRemoveLoading}
        error={error}
        isRegistered
        adminsNumber={adminsNumber}
      />
      <ConfirmationDialog isOpen={isOpen} close={close} onClose={handleConfirm} />
    </>
  );
};

export default Admins;
