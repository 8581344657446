import React from 'react';
import { Select, Title } from 'common/components';
import MinisPicker from 'common/components/MinisPicker/MinisPicker';
import { FieldsGrid, GridItem, Section } from 'templates/core';
import { MiniOption } from 'common/components/MinisPicker/consts';
import { useMinisList } from 'modules/minis/queries';
import { useMappedValues } from 'common/hooks';
import { BadgeFields, CriteriaMinimumCountOptions, fields } from '../consts';
import styled from 'styled-components';
import { Badge } from 'common/components/Badge';
import { useFormContext } from 'react-hook-form';
import { PK } from 'common/types';
import { STATUSES } from 'common/consts';

const BadgeMinisPicker = ({ defaultValues }: { defaultValues: Partial<BadgeFields> }) => {
  const minisList = useMinisList();
  const minisListValues = minisList.data?.values;
  const { unregister, register, setValue, getValues } = useFormContext();
  const values = getValues();
  const isBadgeActive =
    defaultValues.status && defaultValues.status[0].value === STATUSES.ACTIVE;

  const options = useMappedValues(
    minisListValues,
    ({ pk, title, status, created_at, modified_at, is_b2b, punctation }) => ({
      isB2B: is_b2b,
      punctation: punctation,
      label: title,
      value: pk,
      status,
      created: created_at,
      lastUpdate: modified_at,
    }),
  );

  const miniPks: string[] = values[fields.criteria_possible_pks.name] || [];
  const miniOptions: MiniOption[] = miniPks
    .map<MiniOption | undefined>((pk, index) => {
      const mini = minisList.data?.values.find((match) => match.pk === pk);

      if (!mini) {
        return;
      }

      return {
        value: pk as PK,
        label: mini.title,
        punctation: index,
        created: mini.created_at,
        lastUpdate: mini.modified_at,
        status: mini.status,
        isB2B: mini.is_b2b,
      };
    })
    .filter<MiniOption>((item): item is MiniOption => Boolean(item));

  const handleUpdate = (miniOptions: MiniOption[]) => {
    setValue(
      fields.criteria_possible_pks.name,
      miniOptions.map(({ value }) => value),
      {
        shouldDirty: true,
        shouldValidate: true,
      },
    );
  };

  const handlePick = (minis: MiniOption[], reinit: (minis: MiniOption[]) => void) => {
    reinit(minis);
    handleUpdate(minis);
  };

  React.useEffect(() => {
    register(fields.criteria_possible_pks.name);

    setValue(
      fields.criteria_possible_pks.name,
      defaultValues[fields.criteria_possible_pks.name] || [],
      {
        shouldDirty: true,
        shouldValidate: true,
      },
    );

    return () => unregister(fields.criteria_possible_pks.name);
  }, [register, unregister]);

  return (
    <Section>
      <FieldsGrid>
        <GridItem>
          <Title marginBottom={2.5}>Minis</Title>
          <Container>
            <ContainerTitle>
              {fields.criteria_possible_pks.label}
              {minisList.isLoading ? (
                <Badge>Loading</Badge>
              ) : (
                !!miniOptions.length && <Badge>{miniOptions.length}</Badge>
              )}
            </ContainerTitle>
            <MinisPicker
              options={options}
              defaultValues={miniOptions}
              onUpdate={handleUpdate}
              onPick={handlePick}
              frozen={isBadgeActive}
            />
          </Container>
        </GridItem>
        <GridItem compact>
          <Select
            name={fields.criteria_minimum_count.name}
            label={fields.criteria_minimum_count.label}
            options={CriteriaMinimumCountOptions.map((count) => ({
              label: `${count}`,
              value: count,
            }))}
            isDisabled={isBadgeActive}
          />
        </GridItem>
      </FieldsGrid>
    </Section>
  );
};

const Container = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.spacer.standard} 0;
  background-color: ${({ theme }) => theme.color.greySuperLight};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  overflow: hidden;
`;

const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacer.times(8)};
  padding: 0 ${({ theme }) => theme.spacer.times(3)};
  font-size: ${({ theme }) => theme.font.size.standard};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export default BadgeMinisPicker;
