import * as Yup from 'yup';

import { Fields } from 'common/types';
import { combineSchemas } from 'common/utils';

export type CompanyFields = {
  title: string;
  branch: string;
  kvk_number: number;
  min_team_size: number;
  max_team_size: number;
};

export const fields: Fields<CompanyFields> = {
  title: {
    label: 'Title',
    name: 'title',
    schema: Yup.string().required(),
  },
  branch: {
    label: 'Branch',
    name: 'branch',
    schema: Yup.string().required(),
  },
  kvk_number: {
    label: 'KVK number',
    name: 'kvk_number',
    props: {
      type: 'number',
    },
    schema: Yup.number()
      .required()
      .transform((value) => (isNaN(value) ? undefined : value))
      .test({
        name: 'is8Digits',
        // eslint-disable-next-line no-template-curly-in-string
        message: '${path} must be 8 digits',
        test: (value: any) => {
          return value && String(value).length === 8;
        },
      }),
  },
  min_team_size: {
    label: 'Min team size',
    name: 'min_team_size',
    props: {
      type: 'number',
    },
    schema: Yup.number()
      .required()
      .transform((value) => (isNaN(value) ? undefined : value)),
  },
  max_team_size: {
    label: 'Max team size',
    name: 'max_team_size',
    props: {
      type: 'number',
    },
    schema: Yup.number()
      .required()
      .transform((value) => (isNaN(value) ? undefined : value))
      .positive()
      .when(['min_team_size'], {
        is: (value) => !isNaN(value) && value > 0,
        then: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .test(
            'isLessThan',
            'Max team size must be more than minimum',
            function (value) {
              return this.parent.min_team_size && value
                ? value > this.parent.min_team_size
                : false;
            },
          ),
      }),
  },
};

export const validationSchema = Yup.object().shape<CompanyFields>(combineSchemas(fields));
