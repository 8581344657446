import * as React from 'react';

import { FieldsGrid, GridItem } from 'templates/core';
import { ContentRadio, Input, Select } from 'common/components';
import { SelectOption } from 'common/types';

import { getComponentTypeFromSchema, TAGS_COMPONENT } from '../consts';
import { TaggingSchema } from 'base/api/types';
import { changeFormValueToLabel } from 'common/utils';

interface TagsFormsProps {
  taggingSchema?: TaggingSchema['metadata'];
}

const TagsFields = ({ taggingSchema }: TagsFormsProps) => {
  const [areUnitsAvailable, setAreUnitsAvailable] = React.useState<unknown>(null);

  const tagComponentList = taggingSchema
    ? Object.entries(taggingSchema).map(([tagName, tagSchema]) => ({
        name: tagName,
        label: changeFormValueToLabel(tagName),
        ...tagSchema,
      }))
    : [];

  return (
    <FieldsGrid>
      {tagComponentList.map((tagComponent) => {
        const options: SelectOption<string>[] | null =
          tagComponent.values &&
          tagComponent.values.map((value) => ({
            label: value,
            value,
          }));

        const componentType: TAGS_COMPONENT = getComponentTypeFromSchema(tagComponent);

        return (
          <React.Fragment key={tagComponent.name}>
            {componentType === TAGS_COMPONENT.INPUT_NUMBER && (
              <GridItem>
                <Input
                  name={tagComponent.name}
                  label={
                    tagComponent.is_required
                      ? tagComponent.label + ' *'
                      : tagComponent.label
                  }
                  type="number"
                />
              </GridItem>
            )}
            {componentType === TAGS_COMPONENT.RADIO_BUTTON && (
              <GridItem>
                <ContentRadio
                  name={tagComponent.name}
                  label={
                    tagComponent.is_required
                      ? tagComponent.label + ' *'
                      : tagComponent.label
                  }
                  options={options ?? []}
                  isMulti={!tagComponent.single_choice}
                  unmarkable
                />
              </GridItem>
            )}
            {componentType === TAGS_COMPONENT.LIST && (
              <GridItem>
                <Select
                  multiRows
                  visibleFields={4}
                  name={tagComponent.name}
                  label={
                    tagComponent.is_required
                      ? tagComponent.label + ' *'
                      : tagComponent.label
                  }
                  options={options ?? []}
                  isColored
                  isMulti={!tagComponent.single_choice}
                  isSingleChoiceResettable={true}
                />
              </GridItem>
            )}
            {componentType === TAGS_COMPONENT.AMOUNT && (
              <>
                <GridItem compact>
                  <Input
                    name="amount"
                    label="Amount"
                    type="number"
                    passValue={setAreUnitsAvailable}
                  />
                </GridItem>
                <GridItem compact>
                  <Select
                    visibleFields={4}
                    name={tagComponent.name}
                    label="Unit"
                    options={options ?? []}
                    isMulti={!tagComponent.single_choice}
                    isDisabled={!areUnitsAvailable}
                  />
                </GridItem>
              </>
            )}
          </React.Fragment>
        );
      })}
    </FieldsGrid>
  );
};

export default TagsFields;
