import * as React from 'react';

import { PK } from 'common/types';
import { ContentRow } from 'templates/content';
import { ContentRowProps } from 'templates/content/components/ContentRow';

import TagsMenu from './TagsMenu';

export interface RecipesTagRow {
  pk: PK;
  title: string;
  isDetailsView?: boolean;
}

const TagsContentRow: React.FC<ContentRowProps<RecipesTagRow>> = (props) => {
  const tagData = props.data;

  return <ContentRow {...props} data={tagData} menuComponent={TagsMenu} />;
};

export default TagsContentRow;
