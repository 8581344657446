import * as React from 'react';
import styled from 'styled-components';

import { pulse } from 'common/styles/animations';

const ImagePlaceholder: React.FunctionComponent = ({ ...props }) => {
  return <Placeholder {...props} />;
};

const Placeholder = styled.div`
  width: 100%;
  height: 200px;
  background-color: ${({ theme }) => theme.color.blueGreyDark};
  animation: ${pulse} 1.5s ease infinite;
`;

export default ImagePlaceholder;
