import * as React from 'react';

import { CoreProps } from 'common/types';
import { Input, Select } from 'common/components';
import {
  CoreFormLayout,
  FieldsGrid,
  GridItem,
  Section,
  SectionsWrapper,
} from 'templates/core';
import { TitleInput } from 'templates/details';

import {
  fields,
  CategoriesFields,
  validationSchema,
  CATEGORY_TYPE_OPTIONS,
} from '../consts';
import { useSave } from '../hooks';

const NutritionalValuesCore = ({
  pk,
  defaultValues,
  children,
  layout = 'overview',
}: CoreProps<CategoriesFields>) => {
  const { save, isSaving, error, created } = useSave(pk);

  if (!pk) {
    layout = 'creation';
  }

  return (
    <>
      <CoreFormLayout
        onSubmit={save}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isSubmitting={isSaving}
        error={error}
      >
        <Section>
          {layout === 'overview' && <TitleInput name={fields.name.name} />}
          {layout === 'creation' && (
            <Input name={fields.name.name} label={fields.name.label} />
          )}
        </Section>
        <Section>
          <FieldsGrid>
            <GridItem>
              <Select
                name={fields.type.name}
                label={fields.type.label}
                options={CATEGORY_TYPE_OPTIONS}
                readOnly={layout === 'overview'}
              />
            </GridItem>
          </FieldsGrid>
        </Section>
      </CoreFormLayout>
      <SectionsWrapper pk={created}>{children}</SectionsWrapper>
    </>
  );
};

export default NutritionalValuesCore;
