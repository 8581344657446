import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Habits } from 'base/api/types';

import { PK } from 'common/types';
import { DetailsCard, DetailsSuspense } from 'templates/details';

import { fields, HabitsFields } from '../consts';
import { useHabitsDetails } from '../queries';
import HabitsCore from '../components/HabitsCore';

const transformHabits = (raw: Habits): HabitsFields => ({
  [fields.description.name]: raw.description,
  [fields.levels.name]: raw.levels,
});

const HabitsDetails = () => {
  const { pk } = useParams<{ pk?: PK }>();
  const { data, ...query } = useHabitsDetails(pk);
  const defaultValues = data && transformHabits(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading} error={query.error}>
        <HabitsCore pk={pk} defaultValues={defaultValues} />
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default HabitsDetails;
