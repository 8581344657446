import * as React from 'react';

import appUrls from 'base/app/urls';
import { Container, Wrapper } from 'common/components';
import {
  RecipeDetailsSwitch,
  RecipeTopBar,
  CreateButton,
} from 'modules/recipe-subsections';
import { RECIPE_SUBSECTIONS } from 'modules/recipe-subsections/consts';
import {
  ContentProvider,
  ContentList,
  FiltersForm,
  Sort,
  SearchInput,
} from 'templates/content';

import CategoriesDetails from './CategoriesDetails';
import CategoriesContentRow from '../components/CategoriesContentRow';
import { useCategoriesList } from '../queries';

const fields = {
  search: 'search',
  sort: 'sort',
};

const CategoriesList = () => {
  const { data, isError } = useCategoriesList();
  const context = {
    data: data?.values.map(({ name, ...category }) => ({ ...category, title: name })),
    path: {
      list: appUrls.CATEGORIES.LIST,
      details: appUrls.CATEGORIES.DETAILS,
      create: appUrls.CATEGORIES.CREATE,
    },
  };

  return (
    <Container>
      <ContentProvider {...context}>
        <RecipeTopBar subsection={RECIPE_SUBSECTIONS.CATEGORIES}>
          <CreateButton path={appUrls.CATEGORIES.CREATE}>New item</CreateButton>
          <FiltersForm sort={['title', fields.sort]} search={['title', fields.search]}>
            <Wrapper margin={[0, 0.5]}>
              <SearchInput name={fields.search} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <Sort name={fields.sort} />
            </Wrapper>
          </FiltersForm>
        </RecipeTopBar>
        <ContentList isError={isError}>{CategoriesContentRow}</ContentList>
        <RecipeDetailsSwitch>
          <CategoriesDetails />
        </RecipeDetailsSwitch>
      </ContentProvider>
    </Container>
  );
};

export default CategoriesList;
