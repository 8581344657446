import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Button } from 'common/components';
import { Upload } from 'common/icons';
import { BUTTON_VARIANTS } from 'common/consts';
import { FileInputLabelProps } from 'common/components/FileInput';

interface Props extends Partial<FileInputLabelProps> {
  onMediaOpen?: () => void;
}

export const UploadLabel = ({ onBrowse, onMediaOpen }: Props) => {
  return (
    <LabelContainer>
      <LabelMessage>
        <span>Drop or </span>
        <Button onClick={onMediaOpen} variant={BUTTON_VARIANTS.ICON} noNegativeMargin>
          select icon
        </Button>
      </LabelMessage>
      <Button onClick={onBrowse} variant={BUTTON_VARIANTS.ICON} noNegativeMargin>
        <Upload size="20px" color="black" hoverColor="black" />
      </Button>
    </LabelContainer>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${css`
    animation: ${fadeIn} 0.4s ease;
  `}
`;

const LabelMessage = styled.p`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 ${({ theme }) => theme.spacer.big} 0 0;
  color: ${({ theme }) => theme.color.greyDark};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.small};
`;

export default UploadLabel;
