import * as React from 'react';
import { ListChildComponentProps } from 'react-window';
import styled, { css } from 'styled-components';
import isAfter from 'date-fns/isAfter';

import { composeUrl, formatDate } from 'common/utils';
import { DATE_FORMATS, STATUSES_OPTIONS, STATUS_VARIANTS } from 'common/consts';
import { DateChip, Link, PrimaryKey, StatusChip, Title } from 'common/components';
import { ifStatusVariant } from 'common/components/StatusChip';
import { resolveSpacersArray } from 'common/styles/utils';
import { ThemeColor } from 'shared/theme';
import { Calendar } from 'common/icons';

import { ContentListContext } from '..';
import { CommonData, MenuComponent, Schedule } from '../const';
import PointsDisplay from 'modules/series/components/PointsDisplay';
import { DateString } from 'common/types';

export interface ContentRowProps<TData extends CommonData>
  extends ListChildComponentProps {
  data: TData[];
  menuComponent?: MenuComponent;
}

const getClosestDate = (schedule: Schedule[] | undefined) => {
  const target = Date.now() - 86400000; // Yesterday date

  if (schedule && schedule.length) {
    let closestDate = 2200000000000;
    schedule.map(({ date }: Schedule) => {
      const candidate = Date.parse(date);
      if (candidate > target && candidate < closestDate) closestDate = candidate;
    });

    return closestDate !== 2200000000000
      ? ((closestDate as unknown) as DateString<DATE_FORMATS.DATABASE>)
      : null;
  }
  return null;
};

class ContentRow<TData extends CommonData> extends React.PureComponent<
  ContentRowProps<TData>
> {
  render() {
    const { data, style, index, children, menuComponent: MenuComponent } = this.props;
    const itemData = data[index];
    const {
      pk,
      title,
      titleSuffix,
      status,
      description,
      punctation,
      is_b2b,
      is_minis_page,
      is_fun_facts_page,
      schedule,
      getDraggableProps,
    } = itemData;
    const variant =
      STATUSES_OPTIONS.find(({ value }) => value === status)?.variant ||
      STATUS_VARIANTS.ACTIVE;
    const color = ifStatusVariant<ThemeColor>(variant, [
      'blue',
      'greenDark',
      'pink',
      'yellowDark',
    ]);

    return (
      <ContentListContext.Consumer>
        {(context) => {
          const isActive = context.active?.pk === pk;
          const url = context.path?.details
            ? composeUrl(context.path.details, { params: { pk } })
            : undefined;
          const closestDate = getClosestDate(schedule);
          return (
            <ContentRowContainer
              style={style}
              {...getDraggableProps?.({ position: index })}
            >
              <StyledLink to={url}>
                <ContentRowItem
                  $color={color}
                  $isNested={!!url}
                  $isActive={isActive}
                  $isLastItem={!MenuComponent}
                >
                  {children || (
                    <>
                      <TitleCell>
                        <TitleRow>
                          <Title size="standard">{title ? title : description}</Title>
                          {titleSuffix && (
                            <TitleSuffix $color="greenDark">{titleSuffix}</TitleSuffix>
                          )}
                        </TitleRow>
                        <PrimaryKey pk={pk} />
                      </TitleCell>
                      {is_minis_page && is_b2b && punctation && (
                        <PointsDisplayContainer>
                          <PointsDisplay value={punctation} fontSize={16} />
                        </PointsDisplayContainer>
                      )}
                      {is_fun_facts_page && schedule && schedule.length > 0 && (
                        <>
                          {closestDate && <StyledDateChip date={closestDate} />}
                          <CalendarContainer>
                            <DatesSchedule>
                              {schedule
                                .sort((a, b) =>
                                  isAfter(new Date(a.date), new Date(b.date)) ? 1 : -1,
                                )
                                .map((item) => {
                                  const date = new Date(item.date);
                                  return (
                                    <SingleScheduleDate key={item.pk}>
                                      {formatDate(date, DATE_FORMATS.SCHEDULED)}
                                    </SingleScheduleDate>
                                  );
                                })}
                            </DatesSchedule>
                            <Calendar size="22px" color={'pink'} hoverColor={'pink'} />
                          </CalendarContainer>
                        </>
                      )}
                      {status && (
                        <StatusCell>
                          <StatusChip status={status} />
                        </StatusCell>
                      )}
                    </>
                  )}
                </ContentRowItem>
              </StyledLink>
              {MenuComponent && (
                <MenuCell $isActive={isActive}>
                  <MenuComponent pk={pk} itemData={itemData} />
                </MenuCell>
              )}
            </ContentRowContainer>
          );
        }}
      </ContentListContext.Consumer>
    );
  }
}

const StyledDateChip = styled(DateChip)`
  margin-right: ${({ theme }) => theme.spacer.times(2)};
  background-color: #f5f9ff;
  text-align: center;
`;

const SingleScheduleDate = styled.span`
  display: inline-block;
  margin: 4px;
`;

const DatesSchedule = styled.div`
  pointer-events: none;
  padding: 16px;
  position: absolute;
  top: 0;
  left: 24px;
  z-index: 9;
  background-color: #f5f9ff;
  display: block;
  border-radius: 8px;
  display: none;
  flex-direction: column;
  width: 145px;
`;

const CalendarContainer = styled.div`
  position: relative;
  margin-right: ${({ theme }) => theme.spacer.times(2)};
  &:hover ${DatesSchedule} {
    display: flex;
  }
`;

interface RowProps {
  $isActive: boolean;
  $isNested: boolean;
  $isLastItem: boolean;
  $color: ThemeColor;
}

const PointsDisplayContainer = styled.div`
  margin-right: 10px;
  margin-left: auto;
`;

const displaySettings = css<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  left: ${({ theme, $isActive }) => $isActive && theme.spacer.times(3)};
`;

const ContentRowItem = styled.div<RowProps>`
  ${displaySettings}
  ${({ $isNested }) =>
    !$isNested &&
    css`
      width: 100%;
      min-width: 0;
    `}

  ${({ theme, $isLastItem }) =>
    !$isLastItem
      ? css`
          border-radius: ${theme.border.radius.tiny};
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        `
      : css`
          border-radius: ${theme.border.radius.tiny};
        `}

  height: ${({ theme }) => theme.spacer.times(9)};
  padding: ${({ theme }) => resolveSpacersArray(theme, ['small', 'small', 'small', 4])};
  margin: ${({ theme, $isLastItem }) =>
    resolveSpacersArray(theme, [0, $isLastItem ? 3 : 0, 'standard', 3])};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme, $isActive }) =>
    $isActive && css`0 1px 24px 0 ${theme.color.blackSuperAlpha}`};

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${({ theme }) => theme.spacer.standard};
    background-color: ${({ theme, $color }) => theme.color[$color]};
    border-top-left-radius: ${({ theme }) => theme.border.radius.tiny};
    border-bottom-left-radius: ${({ theme }) => theme.border.radius.tiny};
  }
`;

const MenuCell = styled.div<{ $isActive: boolean }>`
  ${displaySettings}

  flex-shrink: 0;
  width: ${({ theme }) => theme.spacer.times(5)};
  height: ${({ theme }) => theme.spacer.times(9)};
  padding: ${({ theme }) => resolveSpacersArray(theme, ['small', 0])};
  margin: ${({ theme }) => resolveSpacersArray(theme, [0, 3, 'standard', 0])};
  border-top-right-radius: ${({ theme }) => theme.border.radius.tiny};
  border-bottom-right-radius: ${({ theme }) => theme.border.radius.tiny};
  background-color: ${({ theme }) => theme.color.white};
`;

const ContentRowContainer = styled.div`
  position: relative;
  display: flex;

  ${ContentRowItem}, ${MenuCell} {
    top: ${({ theme }) => theme.spacer.times(3)};
  }
`;

const StatusCell = styled.div`
  flex-shrink: 0;
  display: flex;
  width: 160px;
`;

const TitleCell = styled.div`
  margin-right: auto;
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleRow = styled.div`
  display: inline-flex;
  align-items: baseline;
  gap: 5px;
`;

const TitleSuffix = styled.div<{ $color: ThemeColor }>`
  color: ${({ theme, $color }) => theme.color[$color]};
  font-size: 0.85rem;
`;

const StyledLink = styled(Link)`
  width: 100%;
  min-width: 0;
`;

export default ContentRow;
