import Axios from 'axios';
import { useQueryClient, useMutation } from 'react-query';

import { useDetailsQuery } from 'base/api/hooks';
import { StandardError } from 'base/api/errors';
import apiUrls from 'base/api/urls';
import { PK } from 'common/types';
import { composeUrl } from 'common/utils';
import { Blog, BlogQueryVars } from './types';

export const useBlogDetails = (pk?: PK) => {
  return useDetailsQuery<Blog>({
    queryKey: [apiUrls.BLOGS.DETAILS, { pk }],
    pk,
  });
};

export const useBlogUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<Blog, StandardError, BlogQueryVars>(
    ({ pk, ...data }) =>
      Axios.patch(composeUrl(apiUrls.BLOGS.DETAILS, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData([apiUrls.BLOGS.DETAILS, { pk: data.pk }], data);
        queryClient.invalidateQueries(apiUrls.BLOGS.LIST);
      },
    },
  );
};

export const useBlogCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<Blog, StandardError, BlogQueryVars>(
    ({ pk, ...data }) =>
      Axios.post(composeUrl(apiUrls.BLOGS.LIST, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData([apiUrls.BLOGS.LIST, { pk: data.pk }], data);
      },
    },
  );
};
