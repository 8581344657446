import appUrls from 'base/app/urls';

export const nav = [
  {
    label: 'Dashboard',
    url: appUrls.DASHBOARD,
  },
  {
    label: 'Media',
    url: appUrls.MEDIA,
  },
  {
    label: 'Settings',
    url: appUrls.SETTINGS,
  },
];
