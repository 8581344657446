import * as React from 'react';

import { CoreProps } from 'common/types';
import { Input, Select } from 'common/components';
import { RelationOption } from 'common/components/RelationSelect';
import { useCategoriesList } from 'modules/categories/queries';
import { useUnitsList } from 'modules/units/queries';
import {
  CoreFormLayout,
  FieldsGrid,
  GridItem,
  Section,
  SectionsWrapper,
} from 'templates/core';
import { TitleInput } from 'templates/details';

import { fields, IngredientsFields, validationSchema } from '../consts';
import { useSave } from '../hooks';

const IngredientsCore = ({
  pk,
  defaultValues,
  children,
  layout = 'overview',
}: CoreProps<IngredientsFields>) => {
  const unitList = useUnitsList();
  const categoryList = useCategoriesList();
  const { save, isSaving, error, created } = useSave(pk);

  if (!pk) {
    layout = 'creation';
  }

  const unitOptions: RelationOption[] =
    unitList.data?.values.map((unit) => ({
      label: unit.name_singular,
      value: unit.pk,
    })) || [];

  const categoryOptions: RelationOption[] =
    categoryList.data?.values
      .filter((category) => category.category_type === 'INGREDIENT')
      .map((category) => ({
        label: category.name,
        value: category.pk,
      })) || [];

  return (
    <>
      <CoreFormLayout
        onSubmit={save}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isSubmitting={isSaving}
        error={error}
      >
        <Section>
          {layout === 'overview' && <TitleInput name={fields.name_singular.name} />}
          {layout === 'creation' && (
            <Input name={fields.name_singular.name} label={fields.name_singular.label} />
          )}
        </Section>
        <Section>
          <FieldsGrid>
            <GridItem>
              <Input name={fields.name_plural.name} label={fields.name_plural.label} />
            </GridItem>
            <GridItem>
              <Select
                name={fields.unit.name}
                label={fields.unit.label}
                options={unitOptions}
              />
            </GridItem>
            <GridItem>
              <Select
                name={fields.category.name}
                label={fields.category.label}
                options={categoryOptions}
              />
            </GridItem>
          </FieldsGrid>
        </Section>
      </CoreFormLayout>
      <SectionsWrapper pk={created}>{children}</SectionsWrapper>
    </>
  );
};

export default IngredientsCore;
