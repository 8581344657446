import styled from 'styled-components';

import { resolveSpacersArray } from 'common/styles/utils';

type Props = {
  divider?: boolean;
};

const Section = styled.div<Props>`
  padding: ${({ theme }) => resolveSpacersArray(theme, [1.5, 5])};
  border-bottom: ${({ theme, divider }) =>
    divider && `1px solid ${theme.color.blueLight}`};
`;

export default Section;
