import * as React from 'react';
import styled from 'styled-components';

import useSearchParams from 'common/hooks/useSearchParams';
import { useFrieslandSSOLogin } from '../queries';

import LoginBackground from 'modules/auth/components/LoginBackground';
import { DotsLoader } from 'common/components';
import { resolveSpacersArray } from 'common/styles/utils';
import AuthorizationErrorMessage from '../components/AuthorizationErrorMessage';
import GenericErrorMessage from '../components/GenericErrorMessage';

const noAuthorizationStatus = 412;

const FrieslandSSO = () => {
  const { code } = useSearchParams();
  const { mutate: login, isLoading, error } = useFrieslandSSOLogin();

  React.useEffect(() => {
    login({ verification_key: code });
  }, []);

  const isAuthorizationError = error?.status === noAuthorizationStatus;
  const isError = !!error && !isAuthorizationError;

  return (
    <Container>
      <LoginBackground />
      {isLoading && <DotsLoader fullScreen center />}
      {(isAuthorizationError || isError) && (
        <LoginCardContainer>
          <LoginCard>
            {isAuthorizationError ? (
              <AuthorizationErrorMessage />
            ) : (
              <GenericErrorMessage />
            )}
          </LoginCard>
        </LoginCardContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100vh;
  padding: ${({ theme }) => `${theme.spacer.times(9)} ${theme.spacer.big}`};
`;

const LoginCardContainer = styled.div`
  z-index: 1;
  width: 480px;
  margin: auto;
`;

const LoginCard = styled.div`
  position: relative;
  margin-top: ${({ theme }) => theme.spacer.times(7)};
  padding: ${({ theme }) => resolveSpacersArray(theme, [4, 8])};
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.border.radius.standard};
`;

export default FrieslandSSO;
