import * as React from 'react';
import { Button, Logo, Title } from 'common/components';
import styled from 'styled-components';
import { getFrieslandSSOLoginPage } from 'common/utils/config';
import { BUTTON_VARIANTS, LOGO_VARIANTS } from 'common/consts';
import { LoginCard, LoginCardContainer, LoginContainer } from './LoginEmail';
import LoginBackground from '../components/LoginBackground';

const FrieslandLogin = () => {
  return (
    <LoginContainer>
      <LoginBackground />
      <LoginCardContainer>
        <Logo variant={LOGO_VARIANTS.TEXT} />
        <LoginCard>
          <FrieslandLoginContainer>
            <Title center size="big" marginBottom="big">
              Login with Mendix
            </Title>
            <Button
              variant={BUTTON_VARIANTS.SECONDARY}
              width="100%"
              as={ExternalLoginForm}
              href={getFrieslandSSOLoginPage()}
            >
              Login
            </Button>
          </FrieslandLoginContainer>
        </LoginCard>
      </LoginCardContainer>
    </LoginContainer>
  );
};
const ExternalLoginForm = styled.a`
  text-decoration: none;
`;

const FrieslandLoginContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacer.times(4)};
`;

export default FrieslandLogin;
