import { TaggingSchemaMetadata, TAGS_DISPLAY_TYPE } from 'base/api/types';

export enum TAGGING_CONTENT_TYPES {
  MINI = 'MINI',
  SERIES = 'SERIES',
  MEDITATION = 'MEDITATION',
  WORKOUT = 'WORKOUT',
  RECIPE = 'RECIPE',
  PODCAST = 'PODCAST',
}

export enum TAGS_COMPONENT {
  UNDEFINED,
  INPUT_NUMBER,
  LIST,
  RADIO_BUTTON,
  AMOUNT,
}

export interface TagSchema extends TaggingSchemaMetadata {
  name: string;
}

const MAXIMUM_RADIO_ELEMENTS = 5;

export const getComponentTypeFromSchema = (schema: TagSchema): TAGS_COMPONENT => {
  // We need to have special handling for amount fields because they come in pairs
  if (schema.name === 'amount') {
    return TAGS_COMPONENT.UNDEFINED;
  } else if (schema.name === 'amount_unit') {
    return TAGS_COMPONENT.AMOUNT;
  }

  switch (schema.display_type) {
    case TAGS_DISPLAY_TYPE.LIST:
      if ((schema.values?.length ?? 0) <= MAXIMUM_RADIO_ELEMENTS) {
        return TAGS_COMPONENT.RADIO_BUTTON;
      } else {
        return TAGS_COMPONENT.LIST;
      }
    case TAGS_DISPLAY_TYPE.NUMBER:
      return TAGS_COMPONENT.INPUT_NUMBER;
    default:
      return TAGS_COMPONENT.UNDEFINED;
  }
};
