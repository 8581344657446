import * as React from 'react';
import { useParams } from 'react-router-dom';

import { FunFact } from 'base/api/types';
import { transformContentGoals } from 'base/api/utils';
import { STATUSES_OPTIONS } from 'common/consts';
import { PK } from 'common/types';
import { DetailsCard, DetailsSuspense } from 'templates/details';

import FunfactCore from '../components/FunfactCore';
import FunfactScheduler from '../components/FunfactScheduler';
import { fields, FunfactFields } from '../consts';
import { useFunfactDetails } from '../queries';

const transformFunfact = (raw: FunFact): FunfactFields => ({
  [fields.title.name]: raw.title,
  [fields.description.name]: raw.description,
  [fields.content_goals.name]:
    raw.content_goals && transformContentGoals(raw.content_goals),
  [fields.status.name]: STATUSES_OPTIONS.filter(({ value }) => value === raw.status),
});

const FunfactsDetails = () => {
  const { pk } = useParams<{ pk?: PK }>();
  const { data, ...query } = useFunfactDetails(pk);
  const defaultValues = data && transformFunfact(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading} error={query.error}>
        <FunfactCore key={pk} pk={pk} defaultValues={defaultValues}>
          <FunfactScheduler />
        </FunfactCore>
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default FunfactsDetails;
