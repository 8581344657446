import * as React from 'react';
import styled, { css, keyframes, useTheme } from 'styled-components';

import { Button } from 'common/components';
import { Upload } from 'common/icons';
import { BUTTON_VARIANTS } from 'common/consts';
import { FileInputLabelProps } from 'common/components/FileInput';

interface Props extends Partial<FileInputLabelProps> {
  onMediaOpen?: () => void;
}

export const MediaDropzoneLabel = ({ onBrowse, onMediaOpen }: Props) => {
  const theme = useTheme();
  return (
    <LabelContainer>
      <Upload
        size="20px"
        color={theme.keys.secondaryColor}
        hoverColor={theme.keys.secondaryColor}
      />
      <LabelMessgae>
        <span>Drop your files here or select from </span>
        <Button onClick={onMediaOpen} variant={BUTTON_VARIANTS.ICON} noNegativeMargin>
          Media Library
        </Button>
        <span> or </span>
        <Button onClick={onBrowse} variant={BUTTON_VARIANTS.ICON} noNegativeMargin>
          Browse
        </Button>
      </LabelMessgae>
    </LabelContainer>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;

  ${css`
    animation: ${fadeIn} 0.4s ease;
  `}
`;

const LabelMessgae = styled.p`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 0 ${({ theme }) => theme.spacer.big};
  color: ${({ theme }) => theme.color.greyDark};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.small};
`;

export default MediaDropzoneLabel;
