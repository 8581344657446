import * as React from 'react';

import appUrls from 'base/app/urls';
import { RouteProps } from './Route';
import { LayoutProps } from 'common/styles/Layout';
import Login from 'modules/auth/views/Login';
import Dashboard from 'modules/dashboard/views/Dashboard';
import MinisList from 'modules/minis/views/MinisList';
import SeriesList from 'modules/series/views/SeriesList';
import MediaLibrary from 'modules/media/views/MediaLibrary';
import CollectionsList from 'modules/collections/views/CollectionsList';
import FunfactsList from 'modules/funfacts/views/FunfactsList';
import MeditationsList from 'modules/meditations/views/MeditationsList';
import WorkoutsList from 'modules/workouts/views/WorkoutsList';
import RecipesList from 'modules/recipes/views/RecipesList';
import FactsList from 'modules/facts/views/FactsList';
import TagsList from 'modules/recipe-tags/views/TagsList';
import UnitsList from 'modules/units/views/UnitsList';
import CategoriesList from 'modules/categories/views/CategoriesList';
import IngredientsList from 'modules/ingredients/views/IngredientsList';
import NutritionalValuesList from 'modules/nutritional-values/views/NutritionalValuesList';
import AuthorsList from 'modules/authors/views/AuthorsList';
import CompaniesList from 'modules/companies/views/CompaniesList';
import ScenariosList from 'modules/scenarios/views/ScenariosList';
import TasksList from 'modules/tasks/views/TasksList';
import Settings from 'modules/settings/views/Settings';
import FrieslandSSO from 'modules/friesland-sso/views/FrieslandSSO';
import ScenarioWeekList from 'modules/scenarios/views/ScenarioWeekList';
import LoginConfirmation from 'modules/auth/views/LoginConfirmation';
import PodcastsList from 'modules/podcasts/views/PodcastsList';
import HabitsList from 'modules/habits/views/HabitsList';
import BadgesList from 'modules/badges/views/BadgesList';

type RouteConfig = {
  component: React.ReactNode;
  routeProps: RouteProps;
  layoutProps?: LayoutProps;
};

const routes: RouteConfig[] = [
  {
    component: <Dashboard />,
    routeProps: {
      exact: true,
      path: appUrls.DASHBOARD,
    },
    layoutProps: {
      topbar: false,
    },
  },
  {
    component: <MediaLibrary />,
    routeProps: {
      exact: true,
      path: appUrls.MEDIA,
    },
  },
  {
    component: <Settings />,
    routeProps: {
      exact: true,
      path: appUrls.SETTINGS,
    },
  },
  {
    component: <MinisList />,
    routeProps: {
      path: appUrls.MINIS.LIST,
    },
  },
  {
    component: <SeriesList />,
    routeProps: {
      path: appUrls.SERIES.LIST,
    },
  },
  {
    component: <CompaniesList />,
    routeProps: {
      path: appUrls.COMPANIES.LIST,
    },
  },
  {
    component: <CollectionsList />,
    routeProps: {
      path: appUrls.COLLECTIONS.LIST,
    },
  },
  {
    component: <FunfactsList />,
    routeProps: {
      path: appUrls.FUNFACTS.LIST,
    },
  },
  {
    component: <MeditationsList />,
    routeProps: {
      path: appUrls.MEDITATIONS.LIST,
    },
  },
  {
    component: <PodcastsList />,
    routeProps: {
      path: appUrls.PODCASTS.LIST,
    },
  },
  {
    component: <HabitsList />,
    routeProps: {
      path: appUrls.HABITS.LIST,
    },
  },
  {
    component: <WorkoutsList />,
    routeProps: {
      path: appUrls.WORKOUTS.LIST,
    },
  },
  {
    component: <RecipesList />,
    routeProps: {
      path: appUrls.RECIPES.LIST,
    },
  },
  {
    component: <FactsList />,
    routeProps: {
      path: appUrls.FACTS.LIST,
    },
  },
  {
    component: <BadgesList />,
    routeProps: {
      path: appUrls.BADGES.LIST,
    },
  },
  {
    component: <TagsList />,
    routeProps: {
      path: appUrls.TAGS.LIST,
    },
  },
  {
    component: <TasksList />,
    routeProps: {
      path: appUrls.TASKS.LIST,
    },
  },
  {
    component: <UnitsList />,
    routeProps: {
      path: appUrls.UNITS.LIST,
    },
  },
  {
    component: <CategoriesList />,
    routeProps: {
      path: appUrls.CATEGORIES.LIST,
    },
  },
  {
    component: <IngredientsList />,
    routeProps: {
      path: appUrls.INGREDIENTS.LIST,
    },
  },
  {
    component: <NutritionalValuesList />,
    routeProps: {
      path: appUrls.NUTRITIONAL_VALUES.LIST,
    },
  },
  {
    component: <AuthorsList />,
    routeProps: {
      path: appUrls.AUTHORS.LIST,
    },
  },
  {
    component: <ScenarioWeekList />,
    routeProps: {
      path: appUrls.SCENARIOS.WEEK.LIST,
    },
  },
  {
    component: <ScenariosList />,
    routeProps: {
      path: appUrls.SCENARIOS.LIST,
    },
  },
  {
    component: <FrieslandSSO />,
    routeProps: {
      path: appUrls.SSO.LOGIN,
      authRequired: false,
    },
    layoutProps: {
      header: false,
      topbar: false,
    },
  },
  {
    component: <Login />,
    routeProps: {
      path: appUrls.AUTH.LOGIN,
      exact: true,
      authRequired: false,
    },
    layoutProps: {
      header: false,
      topbar: false,
    },
  },
  {
    component: <LoginConfirmation />,
    routeProps: {
      path: appUrls.AUTH.LOGIN_CONFIRMATION,
      exact: false,
      authRequired: false,
    },
    layoutProps: {
      header: false,
      topbar: false,
    },
  },
].filter(Boolean) as RouteConfig[];

export default routes;
