import * as React from 'react';
import styled from 'styled-components';

import { Mini } from 'base/api/types';
import { DropdownPicker } from 'common/components';
import { RelationOption } from 'common/components/RelationSelect';
import { useMinisList } from 'modules/minis/queries';
import { STATUSES } from 'common/consts';

interface Props {
  selected: RelationOption[];
  onPick: (values: RelationOption[]) => void;
}

const MiniPicker = ({ selected, onPick }: Props) => {
  const { data: miniList } = useMinisList();
  const options: RelationOption[] =
    miniList?.values.filter(isPublished).map((mini) => ({
      label: mini.title,
      value: mini.pk,
    })) || [];

  return (
    <StyledDropdownPicker
      label="Search..."
      options={options}
      selected={selected}
      onPick={onPick}
    />
  );
};

const isPublished = (mini: Mini) => mini.status === STATUSES.ACTIVE;

const StyledDropdownPicker = styled(DropdownPicker)`
  width: 100%;
`;

export default MiniPicker;
