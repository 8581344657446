import * as React from 'react';

import { PK } from 'common/types';
import { useMappedValues } from 'common/hooks';
import { RelationSelect, CollapsibleSection } from 'common/components';
import { RelationOption } from 'common/components/RelationSelect';
import { useRecipesList } from 'modules/recipes/queries';
import { useMinisRecipes, useMinisRecipesUpdate } from '../queries';

type Props = {
  pk?: PK;
};

const RecipesPicker = ({ pk }: Props) => {
  const { mutate, isSuccess, isLoading, isError } = useMinisRecipesUpdate();
  const saveStatus = { isSuccess, isLoading, isError };
  const recipesList = useRecipesList();
  const minisRecipes = useMinisRecipes(pk);

  const options = useMappedValues(recipesList.data?.values, ({ pk, title }) => ({
    label: title,
    value: pk,
  }));

  const defaults = useMappedValues(
    minisRecipes.data?.values,
    ({ recipe_pk, recipe_title }) => ({
      label: recipe_title,
      value: recipe_pk,
    }),
  );

  const handleSave = (values: RelationOption[]) => {
    if (pk) {
      const payload = {
        pk: pk,
        recipe_pks: values.map(({ value }) => value),
      };
      mutate(payload);
    }
  };

  return (
    <>
      <CollapsibleSection title="Add recipes" saveStatus={saveStatus} disabled={!pk}>
        <RelationSelect
          onSave={handleSave}
          options={options}
          defaultValues={defaults}
          isLoading={recipesList.isLoading}
        />
      </CollapsibleSection>
    </>
  );
};

export default RecipesPicker;
