import * as React from 'react';

export type ContentContext<TData> = {
  data: TData[] | null;
  filteredData: TData[];
  setFiltered: (data: TData[]) => void;
  renderer: number;
  path?: {
    details: string;
    list: string;
    create?: string;
  };
  active?: TData;
  isDetailsView?: boolean;
};

const initialContentContext: ContentContext<any> = {
  data: null,
  filteredData: [],
  setFiltered: () => {},
  renderer: 0,
  isDetailsView: false,
};

const ContentListContext = React.createContext<ContentContext<any>>(
  initialContentContext,
);

export function useContentContext<TData = any>() {
  return React.useContext<ContentContext<TData>>(ContentListContext);
}

export default ContentListContext;
