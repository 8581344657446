import styled from 'styled-components';

import { ThemeMedia, ThemeSpacer } from 'shared/theme';
import { resolveSpacersArray } from 'common/styles/utils';

type Props = {
  size?: ThemeMedia;
  padding?: ThemeSpacer[] | ThemeSpacer;
  margin?: ThemeSpacer[] | ThemeSpacer;
};

const Container = styled.div<Props>`
  position: relative;
  max-width: ${({ theme, size }) => (size ? theme.media[size] : 'auto')};
  padding: ${({ padding, theme }) => resolveSpacersArray(theme, padding)};
  margin: ${({ theme, margin }) => resolveSpacersArray(theme, margin) || '0 auto'};
`;

export default Container;
