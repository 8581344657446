import * as Yup from 'yup';

import { combineSchemas } from 'common/utils';
import { Fields, PK, SelectOption, StatusOption } from 'common/types';

export type FunfactFields = {
  title: string;
  description: string;
  content_goals?: SelectOption<PK>[];
  status: StatusOption[];
};

export const fields: Fields<FunfactFields> = {
  title: {
    label: 'Title',
    name: 'title',
    schema: Yup.string().required().max(40),
  },
  description: {
    label: 'Description',
    name: 'description',
    schema: Yup.string().required().max(90),
  },
  content_goals: {
    label: 'Categories',
    name: 'content_goals',
    schema: Yup.array(),
  },
  status: {
    label: 'Status',
    name: 'status',
    schema: Yup.array().required(),
  },
};

export const validationSchema = Yup.object().shape<FunfactFields>(combineSchemas(fields));
