import * as React from 'react';
import styled from 'styled-components';

import loupe from 'assets/graphics/loupe_icon.svg';
import { Graphics, Title, Wrapper } from 'common/components';
import { TITLE_VARIANTS } from 'common/consts';

type Props = {
  width: number;
  height: number;
  className?: string;
};

const EmptyState = ({ className }: Props) => {
  return (
    <div className={className}>
      <Graphics src={loupe} height="200px" />
      <Wrapper margin={[7, 0, 20]}>
        <Title center marginBottom={2}>
          <span role="img" aria-label="man detective">
            🕵️‍♂️
          </span>{' '}
          No results found
        </Title>
        <Title center variant={TITLE_VARIANTS.SUBTITLE}>
          Woow! What you are searching for must be totally new.
        </Title>
        <Title center variant={TITLE_VARIANTS.SUBTITLE}>
          Please browse and add the new media to the CMS. Thanks!
        </Title>
      </Wrapper>
    </div>
  );
};

const StyledEmptyState = styled(EmptyState)`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  padding: ${({ theme }) => theme.spacer.times(6)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default StyledEmptyState;
