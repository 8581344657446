import * as React from 'react';
import styled from 'styled-components';
import {
  Control,
  FieldErrors,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import _get from 'lodash/get';

import { yupResolver } from '@hookform/resolvers/yup';

import {
  Button,
  CollapsibleSection,
  Wrapper,
  DropdownPicker,
  RemoveButton,
} from 'common/components';
import { PlainInput } from 'common/components/Input';
import { PlainTextarea } from 'common/components/Textarea';
import { BUTTON_SIZES } from 'common/consts';
import { PK } from 'common/types';
import { useNutritionalValuesList } from 'modules/nutritional-values/queries';

import {
  useRecipeNutritionalValues,
  useRecipeNutritionalValuesUpdate,
} from '../../queries';
import {
  fieldNames,
  NutritionalValuesFields,
  NutritionalValuesOption,
  NutritionalValuesType,
  NUTRITIONAL_VALUE_PLACEHOLDER_NAME,
  schema,
} from './consts';

type NutritionalValuesProps = {
  pk?: PK;
};

const NutritionalValues = ({ pk }: NutritionalValuesProps) => {
  const { data: nutritionalValuesList } = useNutritionalValuesList();
  const { data: recipeNutritionalValues } = useRecipeNutritionalValues(pk);
  const { mutate, isSuccess, isLoading, isError } = useRecipeNutritionalValuesUpdate();
  const saveStatus = { isSuccess, isLoading, isError };

  const defaultValues = recipeNutritionalValues &&
    nutritionalValuesList && {
      [fieldNames.nutritionalValues]: recipeNutritionalValues.nutritional_values.map(
        (nutritionalValueRow) => ({
          pk: nutritionalValueRow.nutritional_value_pk,
          amount: nutritionalValueRow.amount,
          name:
            nutritionalValuesList.values.find(
              (nutritionalValue) =>
                nutritionalValue.pk === nutritionalValueRow.nutritional_value_pk,
            )?.name || NUTRITIONAL_VALUE_PLACEHOLDER_NAME,
          specified: nutritionalValueRow.specified,
        }),
      ),
      [fieldNames.nutritionalValuesFormatted]: recipeNutritionalValues.formatted,
    };

  const handleSave: SubmitHandler<NutritionalValuesFields> = (values) => {
    if (pk) {
      const payload = {
        pk,
        nutritional_values: (values[fieldNames.nutritionalValues] || []).map(
          ({ pk, amount, specified }) => ({
            nutritional_value_pk: pk,
            specified: specified || [],
            amount,
          }),
        ),
      };

      mutate(payload);
    }
  };

  return (
    <CollapsibleSection
      title="Add nutritional values"
      saveStatus={saveStatus}
      disabled={!pk}
    >
      {defaultValues && (
        <NutritionalValuesForm
          defaultValues={defaultValues}
          onSubmit={handleSave}
          isLoading={isLoading}
        />
      )}
    </CollapsibleSection>
  );
};

interface NutritionalValuesFormProps {
  defaultValues?: NutritionalValuesFields;
  onSubmit: SubmitHandler<NutritionalValuesFields>;
  isLoading?: boolean;
}

const NutritionalValuesForm = ({
  defaultValues,
  onSubmit,
  isLoading,
}: NutritionalValuesFormProps) => {
  const nutritionalValuesList = useNutritionalValuesList();
  const form = useForm<NutritionalValuesFields>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const { control, register, handleSubmit, setValue } = form;
  const { fields, remove, append } = useFieldArray({
    control: control,
    name: fieldNames.nutritionalValues,
  });

  React.useEffect(() => {
    if (defaultValues?.nutritional_values_formatted) {
      setValue(
        fieldNames.nutritionalValuesFormatted,
        defaultValues.nutritional_values_formatted,
      );
    }
  }, [setValue, defaultValues?.nutritional_values_formatted]);

  const options: NutritionalValuesOption[] =
    nutritionalValuesList.data?.values
      .filter(({ pk }) => !fields.some((field) => field.pk === pk))
      .map(({ pk, name }) => ({
        label: name,
        value: pk,
      })) || [];

  const handleRemove = (index: number) => () => {
    remove(index);
  };

  const handleAppend = (value: NutritionalValuesType) => {
    append(value);
  };

  const handlePick = (values: NutritionalValuesOption[]) => {
    const { label, value } = values[0];

    handleAppend({ pk: value, name: label, amount: 0 });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper margin={[2, 0, 5]}>
          <Wrapper padding={[2, 3, 3]}>
            {fields.map((field, index) => {
              const { id, amount, name, pk } = field;
              const fieldName = `${fieldNames.nutritionalValues}[${index}]`;
              return (
                <Section key={id}>
                  <FieldsRow>
                    <Label>{name}</Label>
                    <InputWrapper>
                      <PlainInput
                        isValue
                        name={`${fieldName}.amount`}
                        type="number"
                        step="0.01"
                        label="Amount"
                        error={_get(form.errors, `${fieldName}.amount`)}
                        defaultValue={amount}
                        ref={register()}
                      />
                    </InputWrapper>
                    <Wrapper float="left" padding={[1.5, 0]}>
                      <RemoveButton onRemove={handleRemove(index)} />
                    </Wrapper>
                    <input
                      type="hidden"
                      defaultValue={pk}
                      name={`${fieldName}.pk`}
                      ref={register()}
                    />
                  </FieldsRow>
                  <SpecifiedList
                    control={control}
                    register={register}
                    errors={form.errors}
                    baseFieldName={`${fieldName}.specified`}
                  />
                </Section>
              );
            })}
            <Wrapper padding={[0, 0, 3]}>
              <DropdownPicker
                label="Search..."
                options={options}
                selected={[]}
                onPick={handlePick}
              />
            </Wrapper>
            <Button type="submit" isLoading={isLoading} size={BUTTON_SIZES.SMALL}>
              Save
            </Button>
            <Wrapper padding={[2, 0]}>
              <PlainTextarea
                isValue
                readOnly={true}
                label="Formatted"
                ref={register()}
                name={`${fieldNames.nutritionalValuesFormatted}`}
              />
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </form>
    </FormProvider>
  );
};

interface SpecifiedListProps {
  control: Control<NutritionalValuesFields>;
  baseFieldName: string;
  errors: FieldErrors<NutritionalValuesFields>;
  register: any;
}

const SpecifiedList = ({
  control,
  errors,
  register,
  baseFieldName,
}: SpecifiedListProps) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: baseFieldName,
  });

  const handleRemove = (index: number) => () => {
    remove(index);
  };

  const handleAppend = () => {
    append({ name: '', amount: 0 });
  };

  return (
    <Wrapper padding={[1]}>
      <FieldsRow>
        <FieldsColumn>
          <FieldsColumn>
            {fields.map((field, index) => {
              const { id, name, amount } = field;
              const fieldName = `${baseFieldName}[${index}]`;

              return (
                <Wrapper key={id} margin={[0, 5]}>
                  <FieldsRow>
                    <InputWrapper>
                      <PlainInput
                        isValue
                        name={`${fieldName}.name`}
                        type="text"
                        label="Name"
                        error={_get(errors, `${fieldName}.name`)}
                        defaultValue={name}
                        ref={register()}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <PlainInput
                        isValue
                        name={`${fieldName}.amount`}
                        type="number"
                        step="0.01"
                        label="Amount"
                        error={_get(errors, `${fieldName}.amount`)}
                        defaultValue={amount}
                        ref={register()}
                      />
                    </InputWrapper>
                    <Wrapper padding={[0.5, 1]}>
                      <RemoveButton onRemove={handleRemove(index)} />
                    </Wrapper>
                  </FieldsRow>
                </Wrapper>
              );
            })}
          </FieldsColumn>
          <Wrapper padding={[1]}>
            <Button type="button" onClick={handleAppend} size={BUTTON_SIZES.SMALL}>
              Add specified
            </Button>
          </Wrapper>
        </FieldsColumn>
      </FieldsRow>
    </Wrapper>
  );
};

const FieldsRow = styled.fieldset`
  display: flex;
  border: 0;
  padding: 0;
  align-items: center;
`;

const FieldsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const Section = styled.div`
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  background-color: ${({ theme }) => theme.color.greySuperLight};

  padding: ${({ theme }) => theme.spacer.times(2)};
  padding-bottom: 0;
  margin-bottom: ${({ theme }) => theme.spacer.times(2)};
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacer.times(0.5)};
`;

export default NutritionalValues;
