import Axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import apiUrls from 'base/api/urls';
import { Tags, Workout } from 'base/api/types';
import { useDetailsQuery, useListQuery } from 'base/api/hooks';
import { StandardError } from 'base/api/errors';
import { CommonVars, PK } from 'common/types';
import { STATUSES } from 'common/consts';
import { composeUrl } from 'common/utils';

export const useWorkoutsList = () => {
  return useListQuery<Workout>(apiUrls.WORKOUTS.LIST);
};

export const useWorkoutDetails = (pk?: PK) => {
  return useDetailsQuery<Workout>({
    queryKey: [apiUrls.WORKOUTS.DETAILS, { pk }],
    pk,
  });
};

type CreateWorkoutVars = {
  title: string;
  media_file_pk: PK;
  content_goal_pks: PK[];
};

export const useWorkoutCreate = () => {
  const queryClient = useQueryClient();
  return useMutation<Workout, StandardError, CreateWorkoutVars>(
    (data) => Axios.post(apiUrls.WORKOUTS.LIST, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.WORKOUTS.LIST);
        queryClient.invalidateQueries(apiUrls.CONTENT.LIST);
      },
    },
  );
};

interface UpdateWorkoutVars extends CommonVars, CreateWorkoutVars {
  status: STATUSES;
}

export const useWorkoutUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<Workout, StandardError, UpdateWorkoutVars>(
    ({ pk, ...data }) =>
      Axios.patch(composeUrl(apiUrls.WORKOUTS.DETAILS, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.WORKOUTS.LIST);
        queryClient.setQueryData([apiUrls.WORKOUTS.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

export const useWorkoutsTags = (pk?: PK) => {
  return useDetailsQuery<Tags>({ pk, queryKey: [apiUrls.WORKOUTS.TAGS, { pk }] });
};

interface WorkoutsTagsUpdateVars extends CommonVars {
  payload: Tags;
}

export const useWorkoutsTagsUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, StandardError, WorkoutsTagsUpdateVars>(
    ({ pk, ...payload }) =>
      Axios.put(composeUrl(apiUrls.WORKOUTS.TAGS, { params: { pk } }), payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.WORKOUTS.TAGS);
      },
    },
  );
};
