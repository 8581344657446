import * as React from 'react';
import styled from 'styled-components';

import { PK } from 'common/types';
import { RelationOption } from '../RelationSelect';

type DropdownSelectListProps<TOption extends RelationOption> = {
  isOpen: boolean;
  options: TOption[];
  selected: TOption[];
  onPick: (values: TOption[]) => void;
  isMulti?: boolean;
  children: React.ReactElement;
};

function DropdownSelectList<TOption extends RelationOption>(
  props: DropdownSelectListProps<TOption>,
) {
  const { isOpen, options, onPick, selected, isMulti, children } = props;

  const getId = ({ value }: TOption) => value;
  const selectedIds = selected.map(getId);
  const isSelected = (id: PK) => selectedIds.includes(id);

  const handlePick = (item: TOption) => {
    if (isMulti) {
      const id = getId(item);
      const wasSelected = isSelected(id);
      const newValue = wasSelected
        ? selected.filter((value: any) => getId(value) !== id)
        : [...selected, item];
      onPick(newValue);
    } else {
      onPick([item]);
      close();
    }
  };

  return (
    <SelectList isOpen={isOpen}>
      {options.map((item: TOption) => {
        const id = getId(item);
        const selected = isSelected(id);

        return React.cloneElement(children, {
          isMulti: isMulti,
          handlePick: handlePick,
          selected: selected,
          item: item,
          key: id,
        });
      })}
    </SelectList>
  );
}

const SelectList = styled.div<{ isOpen: boolean }>`
  background-color: ${({ theme }) => theme.color.white};
  border-width: 1px;
  border-style: ${({ isOpen }) => (isOpen ? 'solid' : 'none')};
  border-color: ${({ theme, isOpen }) =>
    isOpen ? theme.themeColor.tertiarySuperDarkColor : 'transparent'};
  border-top-color: transparent;
  border-bottom-left-radius: ${({ theme }) => theme.border.radius.tiny};
  border-bottom-right-radius: ${({ theme }) => theme.border.radius.tiny};
  max-height: ${({ isOpen }) => (isOpen ? '250px' : 0)};
  overflow: auto;
`;

export default DropdownSelectList;
