import * as React from 'react';

import appUrls from 'base/app/urls';
import { Container, Wrapper } from 'common/components';
import { RecipeTopBar } from 'modules/recipe-subsections';
import {
  ContentProvider,
  ContentList,
  FiltersForm,
  Sort,
  StatusFilter,
  SearchInput,
} from 'templates/content';
import { DetailsSwitch } from 'templates/details';

import RecipesContentRow from '../components/RecipesContentRow';
import { useRecipesList } from '../queries';
import RecipeDetails from './RecipeDetails';

const fields = {
  search: 'search',
  filter: 'filter',
  sort: 'sort',
};

const RecipesList = () => {
  const { data, isError } = useRecipesList();
  const context = {
    data: data?.values,
    path: {
      list: appUrls.RECIPES.LIST,
      details: appUrls.RECIPES.DETAILS,
    },
  };

  return (
    <Container>
      <ContentProvider {...context}>
        <RecipeTopBar>
          <FiltersForm
            sort={['title', fields.sort]}
            search={['title', fields.search]}
            filter={['status', fields.filter]}
          >
            <Wrapper margin={[0, 0.5]}>
              <SearchInput name={fields.search} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <StatusFilter name={fields.filter} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <Sort name={fields.sort} />
            </Wrapper>
          </FiltersForm>
        </RecipeTopBar>
        <ContentList isError={isError}>{RecipesContentRow}</ContentList>
        <DetailsSwitch>
          <RecipeDetails />
        </DetailsSwitch>
      </ContentProvider>
    </Container>
  );
};

export default RecipesList;
