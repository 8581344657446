import * as React from 'react';

import { Title } from 'common/components';
import { Section } from 'templates/core';
import CollectionsCore from '../components/CollectionsCore';
import { useCreationDefaults } from 'common/hooks';
import MinisPicker from '../components/MinisPicker';
import SeriesPicker from '../components/SeriesPicker';

const CollectionCreate = () => {
  const defaultValues = useCreationDefaults();

  return (
    <>
      <Section>
        <Title size="big">Create Collection</Title>
      </Section>
      <CollectionsCore defaultValues={defaultValues} layout="creation">
        <MinisPicker />
        <SeriesPicker />
      </CollectionsCore>
    </>
  );
};

export default CollectionCreate;
