import * as Yup from 'yup';

import { Fields } from 'common/types';
import { HabitLevel } from 'base/api/types';
import { combineSchemas } from 'common/utils';

export type HabitsFields = {
  description: string;
  levels: HabitLevel[];
};

export const fields: Fields<HabitsFields> = {
  description: {
    label: 'Description',
    name: 'description',
  },

  levels: {
    label: 'Levels',
    name: 'levels',
    schema: Yup.array()
      .of(
        Yup.object({
          description: Yup.string()
            .required('Level description is required')
            .max(50, 'Description must be at most 50 characters long'),
        }),
      )
      .required('Levels are required'),
  },
};

export const validationSchema = Yup.object().shape<HabitsFields>(combineSchemas(fields));
