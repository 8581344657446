import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { isFunction } from 'common/utils';
import { useContentContext } from '../context';
import { ContentList, createContentList, List } from '../utils';

type Props<TData, TConstructor extends List<TData>> = {
  sort?: [keyof TData, string];
  search?: [keyof TData, string];
  filter?: [keyof TData, string];
  children?: React.ReactNode;
  listConstructor?: (data: TData[]) => TConstructor;
  defaultValues?: Record<string, any>;
};

function FiltersForm<TData, TConstructor extends List<TData> = ContentList<TData>>({
  children,
  sort,
  search,
  filter,
  listConstructor,
  defaultValues,
}: Props<TData, TConstructor>) {
  const { data, setFiltered } = useContentContext<TData>();
  const form = useForm({ defaultValues });

  const sortBy = sort ? sort[0] : undefined;
  const sortField = sort ? sort[1] : null;
  const sortValue = sortField ? form.watch(sortField) : null;
  const searchBy = search ? search[0] : undefined;
  const searchField = search ? search[1] : null;
  const searchValue = searchField ? form.watch(searchField) : null;
  const filterBy = filter ? filter[0] : undefined;
  const filterField = filter ? filter[1] : null;
  const filterValue = filterField ? form.watch(filterField) : null;

  React.useEffect(() => {
    if (data && data.length > 0) {
      let filteredData: List<TData>;
      if (isFunction(listConstructor)) {
        filteredData = listConstructor(data);
      } else {
        filteredData = createContentList<TData, ContentList<TData>>(ContentList)(data);
      }
      setFiltered(
        filteredData
          .search(searchValue, searchBy)
          .filter(filterValue, filterBy)
          .sort(sortValue, sortBy).data,
      );
    }
  }, [
    sortBy,
    sortValue,
    searchBy,
    searchValue,
    filterBy,
    filterValue,
    setFiltered,
    listConstructor,
    data,
  ]);

  return <FormProvider {...form}>{children}</FormProvider>;
}

export default FiltersForm;
