import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const CircleCross = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-1004 -661) translate(380 640) translate(624 21)">
              <circle cx="12" cy="12" r="12" fill="currentColor" />
              <g
                stroke="#FFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              >
                <path
                  d="M9.375.625L.625 9.375M.625.625L9.375 9.375"
                  transform="translate(7 7)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </Svg>
  );
};

CircleCross.defaultProps = {
  size: '22px',
  color: 'error',
  hoverColor: 'error',
};

export default CircleCross;
