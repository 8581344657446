import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { DateString } from 'common/types';
import { isFunction } from 'common/utils';
import { DraggableProps } from 'common/hooks/useDnDList';
import {
  Button,
  DateChip,
  Row,
  StatusChip,
  Wrapper,
  IconContainer,
} from 'common/components';
import { BUTTON_VARIANTS, DATE_FORMATS, STATUSES, STATUS_SIZES } from 'common/consts';
import { Bin, DragHandler } from 'common/icons';

type Props = {
  label?: string;
  punctation?: number;
  status?: STATUSES;
  created?: DateString<DATE_FORMATS.DATABASE>;
  lastUpdate?: DateString<DATE_FORMATS.DATABASE>;
  onDelete?: (position: number) => void;
  className?: string;
  children?: React.ReactNode;
  isDivider?: boolean;
  hideBinIcon?: boolean;
};

const SelectedItem = ({
  label,
  status,
  created,
  lastUpdate,
  draggable,
  position,
  onDelete,
  isDivider,
  children,
  hideBinIcon,
  ...props
}: DraggableProps<Props>) => {
  const theme = useTheme();
  const isExtended = !!(created || lastUpdate || status);

  const handleDelete = () => {
    if (isFunction(onDelete)) {
      onDelete(position);
    }
  };
  return (
    <ItemContainer draggable={draggable} {...props}>
      {draggable && <DragHandler />}
      <ContentContainer>
        {children || (
          <>
            {isDivider && <Divider />}
            <Label isExtended={isExtended}>{label}</Label>
            <Row>
              <Wrapper margin={[0, 1, 0, 0]}>
                <DateChip label="created" date={created} />
              </Wrapper>
              <Wrapper margin={[0, 1, 0, 0]}>
                <DateChip label="last update" date={lastUpdate || created} />
              </Wrapper>
              {status && <StatusChip size={STATUS_SIZES.SMALL} status={status} />}
            </Row>
          </>
        )}
      </ContentContainer>
      {isFunction(onDelete) && (
        <IconContainer>
          <Button variant={BUTTON_VARIANTS.ICON} onClick={handleDelete}>
            {!hideBinIcon && <Bin size="22px" color={theme.keys.secondaryColor} />}
          </Button>
        </IconContainer>
      )}
    </ItemContainer>
  );
};

const ItemContainer = styled.div<Partial<DraggableProps<Props>>>`
  display: flex;
  position: relative;
  align-items: center;
  padding: ${({ theme }) => theme.spacer.times(3)};
  margin: ${({ theme }) => theme.spacer.standard} 0;
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  background-color: ${({ theme, isOver }) =>
    isOver ? theme.color.blueLight : theme.color.greySuperLight};
  cursor: ${({ draggable }) => (draggable ? 'grab' : 'inherit')};
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  margin: 0 ${({ theme }) => theme.spacer.big};
  font-size: ${({ theme }) => theme.font.size.small};
  line-height: ${({ theme }) => theme.font.size.small};
`;

const Label = styled.div<{ isExtended: boolean }>`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: ${({ theme, isExtended }) => (isExtended ? theme.spacer.standard : 0)};
`;

const Divider = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.spacer.times(3)};
  right: ${({ theme }) => theme.spacer.times(3)};
  top: 0;
  height: 1px;
  background-color: ${({ theme }) => theme.color.blueGrey};
`;

export default SelectedItem;
