import * as React from 'react';

import { Button } from 'common/components';
import { BUTTON_SIZES, BUTTON_VARIANTS } from 'common/consts';
import { Check, PaperClip } from 'common/icons';
import { PK } from 'common/types';
import { copyTextToClipboard } from 'common/utils';
import styled from 'styled-components';

interface PrimaryKeyProps {
  pk: PK;
  className?: string;
}

const PrimaryKey = ({ pk, className }: PrimaryKeyProps) => {
  const [isCopied, setCopied] = React.useState<boolean>(false);
  const timer = React.useRef<number>(0);

  const handleCopy = () => {
    const isSuccess = copyTextToClipboard(pk);
    setCopied(isSuccess);
  };

  React.useEffect(() => {
    timer.current = setTimeout(() => {
      setCopied(false);
    }, 3000);
    return () => clearTimeout(timer.current);
  }, [isCopied]);

  return (
    <div className={className}>
      <Button
        variant={BUTTON_VARIANTS.TEXT}
        size={BUTTON_SIZES.SMALL}
        onClick={handleCopy}
      >
        {isCopied ? <Check size="12px" title="copied" /> : <PaperClip title="copy" />}
        <KeyText>{pk}</KeyText>
      </Button>
    </div>
  );
};

const StyledPrimaryKey = styled(PrimaryKey)`
  ${Button} {
    padding: ${({ theme }) => theme.spacer.small} 0;
    margin: 0;
    max-width: 100%;
    justify-content: left;

    &:hover {
      background: none;
    }
  }
`;

const KeyText = styled.span`
  margin: 0 0 0 ${({ theme }) => theme.spacer.small};
  font-size: ${({ theme }) => theme.font.size.superTiny};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  line-height: ${({ theme }) => theme.font.size.superTiny};
  color: ${({ theme }) => theme.color.greyDark};
  letter-spacing: 0.5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default StyledPrimaryKey;
