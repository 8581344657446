import * as React from 'react';
import { generatePath, useHistory } from 'react-router';

import appUrls from 'base/app/urls';
import { PK } from 'common/types';

import { fields, CategoriesFields } from './consts';
import { useCategoriesCreate, useCategoriesUpdate } from './queries';

export const useSave = (pk?: PK) => {
  const [created, setCreated] = React.useState(pk);
  const create = useCategoriesCreate();
  const update = useCategoriesUpdate();
  const history = useHistory();

  const isSaving = create.isLoading || update.isLoading;
  const error = create.error || update.error;

  const save = (values: CategoriesFields) => {
    const payload = {
      name: values[fields.name.name],
      category_type: values[fields.type.name][0].value,
    };

    if (created) {
      update.mutate({
        ...payload,
        pk: created,
      });
    } else {
      create.mutate(payload, {
        onSuccess: ({ pk }) => {
          setCreated(pk);
          history.push(generatePath(appUrls.CATEGORIES.DETAILS, { pk }));
        },
      });
    }
  };

  return { save, created, isSaving, error };
};
