import Axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import apiUrls from 'base/api/urls';
import { FunFact } from 'base/api/types';
import { StandardError } from 'base/api/errors';
import { useDetailsQuery, useListQuery } from 'base/api/hooks';
import { CommonVars, DateString, PK } from 'common/types';
import { DATE_FORMATS, STATUSES } from 'common/consts';
import { composeUrl } from 'common/utils';

export const useFunfactsList = () => {
  return useListQuery<FunFact>(apiUrls.FUNFACTS.LIST);
};

export const useFunfactDetails = (pk?: PK) => {
  return useDetailsQuery<FunFact>({
    queryKey: [apiUrls.FUNFACTS.DETAILS, { pk }],
    pk,
  });
};

interface FunfactCreateVars {
  title: string;
  description: string;
  content_goal_pks: PK[];
  status: STATUSES;
}

export const useFunfactCreate = () => {
  const queryClient = useQueryClient();
  return useMutation<FunFact, StandardError, FunfactCreateVars>(
    (data) => Axios.post(apiUrls.FUNFACTS.LIST, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.FUNFACTS.LIST);
        queryClient.invalidateQueries(apiUrls.CONTENT.LIST);
      },
    },
  );
};

interface FunfactUpdateVars extends FunfactCreateVars, CommonVars {}

export const useFunfactUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<FunFact, StandardError, FunfactUpdateVars>(
    ({ pk, ...data }) =>
      Axios.put(composeUrl(apiUrls.FUNFACTS.DETAILS, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.FUNFACTS.LIST);
        queryClient.setQueryData([apiUrls.FUNFACTS.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

interface FunfactsDateAddVars extends CommonVars {
  date: DateString<DATE_FORMATS.DATABASE>;
}

export const useFunfactDateAdd = () => {
  const queryClient = useQueryClient();
  return useMutation<FunFact, StandardError, FunfactsDateAddVars>(
    ({ pk, ...data }) =>
      Axios.put(composeUrl(apiUrls.FUNFACTS.SCHEDULE, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.FUNFACTS.LIST);
        queryClient.setQueryData([apiUrls.FUNFACTS.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

interface FunfactDateRemoveVars extends CommonVars {
  funfact_date_pk: PK;
}

export const useFunfactDateDelete = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, StandardError, FunfactDateRemoveVars>(
    ({ pk, ...data }) =>
      Axios({
        url: composeUrl(apiUrls.FUNFACTS.SCHEDULE, { params: { pk } }),
        method: 'DELETE',
        data,
      }),
    {
      onSuccess: (_, { pk }) => {
        queryClient.invalidateQueries(apiUrls.FUNFACTS.LIST);
        queryClient.invalidateQueries([apiUrls.FUNFACTS.DETAILS, { pk }]);
      },
    },
  );
};
