import * as React from 'react';
import styled from 'styled-components';

import { RecipeCategoryType } from 'base/api/types';
import { PrimaryKey, Row, Title } from 'common/components';
import { TITLE_VARIANTS } from 'common/consts';
import Column from 'common/components/Column';
import { PK } from 'common/types';
import { ContentRow } from 'templates/content';
import { ContentRowProps } from 'templates/content/components/ContentRow';

import CategoriesMenu from './CategoriesMenu';
import { CATEGORY_TYPE_OPTIONS } from '../consts';

export interface CategoriesRow {
  pk: PK;
  title: string;
  category_type: RecipeCategoryType;
  isDetailsView?: boolean;
}

const CategoriesContentRow: React.FC<ContentRowProps<CategoriesRow>> = (props) => {
  const { index } = props;
  const { pk, title, category_type } = props.data[index];
  const categoryData = props.data;
  const categoryType = CATEGORY_TYPE_OPTIONS.find(
    (typeOption) => typeOption.value === category_type,
  );

  return (
    <ContentRow {...props} data={categoryData} menuComponent={CategoriesMenu}>
      <StyledColumn>
        <StyledRow>
          <TextCell>
            <Text size="standard">{title}</Text>
          </TextCell>
          <TextCell>
            <Text variant={TITLE_VARIANTS.SUBTITLE}>{categoryType?.label}</Text>
          </TextCell>
        </StyledRow>
        <PrimaryKey pk={pk} />
      </StyledColumn>
    </ContentRow>
  );
};

const Text = styled(Title)`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

const TextCell = styled.div<{ $width?: string }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 0;
  margin-right: ${({ theme }) => theme.spacer.big};
  flex-basis: ${({ $width }) => ($width ? $width : '100%')};
`;

const StyledRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
`;

const StyledColumn = styled(Column)`
  width: 100%;
  min-width: 0;
  align-items: flex-start;
`;

export default CategoriesContentRow;
