import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { Arrow } from 'common/icons';
import { resolveSpacersArray } from 'common/styles/utils';
import { useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  className?: string;
};

const Sort = ({ name, className }: Props) => {
  const theme = useTheme();
  const { register, unregister, setValue, getValues } = useFormContext();
  const isDesc = getValues(name);

  const toggleSort = () => {
    setValue(name, !getValues(name));
  };

  React.useEffect(() => {
    register(name);
    return () => unregister(name);
  }, [name, register, unregister]);

  return (
    <div className={className}>
      <SortButton onClick={toggleSort}>
        <SortLabel>Sort</SortLabel>
        <Arrow
          $width="2px"
          $color={theme.keys.secondaryColor}
          $size="10px"
          $rotate={isDesc ? 135 : -45}
        />
      </SortButton>
    </div>
  );
};

const StyledSort = styled(Sort)`
  position: relative;
  min-width: ${({ theme }) => theme.spacer.times(15)};
`;

const SortButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${({ theme }) => theme.spacer.times(7)};
  padding: ${({ theme }) => resolveSpacersArray(theme, ['big', 3, 'big', 'big'])};
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.blueLight};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  font-size: ${({ theme }) => theme.font.size.standard};
  font-family: ${({ theme }) => theme.font.family.sans};
  cursor: pointer;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
`;

const SortLabel = styled.div`
  margin-right: ${({ theme }) => theme.spacer.standard};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.themeColor.primaryColor};
`;

export default StyledSort;
