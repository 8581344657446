import * as React from 'react';

import { PK } from 'common/types';
import { useMappedValues } from 'common/hooks';
import { RelationSelect, CollapsibleSection } from 'common/components';
import { RelationOption } from 'common/components/RelationSelect';
import { useWorkoutsList } from 'modules/workouts/queries';
import { useMinisWorkouts, useMinisWorkoutsUpdate } from '../queries';

type Props = {
  pk?: PK;
};

const WorkoutsPicker = ({ pk }: Props) => {
  const { mutate, isSuccess, isLoading, isError } = useMinisWorkoutsUpdate();
  const saveStatus = { isSuccess, isLoading, isError };
  const workoutsList = useWorkoutsList();
  const minisWorkouts = useMinisWorkouts(pk);

  const options = useMappedValues(workoutsList.data?.values, ({ pk, title, status }) => ({
    label: title,
    value: pk,
    status,
  }));

  const defaults = useMappedValues(
    minisWorkouts.data?.values,
    ({ pk, title, status }) => ({
      label: title,
      value: pk,
      status,
    }),
  );

  const handleSave = (values: RelationOption[]) => {
    if (pk) {
      const payload = {
        pk: pk,
        workout_pks: values.map(({ value }) => value),
      };
      mutate(payload);
    }
  };

  return (
    <>
      <CollapsibleSection title="Add workouts" saveStatus={saveStatus} disabled={!pk}>
        <RelationSelect
          onSave={handleSave}
          options={options}
          defaultValues={defaults}
          isLoading={workoutsList.isLoading}
        />
      </CollapsibleSection>
    </>
  );
};

export default WorkoutsPicker;
