import * as React from 'react';
import { useWatch } from 'react-hook-form';

import { CollectionHandler, CollectionHandlers } from 'base/api/types';
import { Input, Select, IconPicker } from 'common/components';
import {
  getCategoryOptions,
  HANDLER_TYPES,
  HANDLER_TYPE_OPTIONS,
  STATUSES_OPTIONS,
} from 'common/consts';
import { CoreProps, SelectOption } from 'common/types';
import {
  CoreFormLayout,
  FieldsGrid,
  GridItem,
  Section,
  SectionsWrapper,
} from 'templates/core';
import { TitleInput } from 'templates/details';

import DynamicPicker from './DynamicPicker';
import { CollectionFields, fields, validationSchema } from '../consts';
import { useSaveDetails } from '../hooks';
import { useHandlersList } from '../queries';
import { getIsFriesland } from 'common/utils/config';

const transformCollectionHandlers = (
  raw: CollectionHandlers,
): SelectOption<CollectionHandler>[] =>
  raw.collection_handlers.map((value) => ({
    label: String(value),
    value,
  }));

const CollectionsCore = ({
  pk,
  defaultValues,
  children,
  layout = 'overview',
}: CoreProps<CollectionFields>) => {
  const { save, isSaving, error, created } = useSaveDetails(pk);
  const [handlerType, setHandlerType] = React.useState<HANDLER_TYPES | null>(null);

  const collectionSections =
    handlerType === HANDLER_TYPES.STATIC ? (
      children
    ) : layout === 'overview' ? (
      <DynamicPicker />
    ) : null;

  return (
    <>
      <CoreFormLayout
        onSubmit={save}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isSubmitting={isSaving}
        error={error}
      >
        <FormContent
          layout={layout}
          created={!!created}
          onHandlerTypeChange={setHandlerType}
        />
      </CoreFormLayout>
      <SectionsWrapper pk={created}>{collectionSections}</SectionsWrapper>
    </>
  );
};

type FormContentProps = {
  created: boolean;
  layout: 'creation' | 'overview';
  onHandlerTypeChange: (handlerType: HANDLER_TYPES | null) => void;
};

const FormContent = ({ layout, created, onHandlerTypeChange }: FormContentProps) => {
  const { data: collectionHandlers } = useHandlersList();
  const handlerOptions = collectionHandlers
    ? transformCollectionHandlers(collectionHandlers)
    : [];
  const watchedHandlerType = useWatch<CollectionFields['handlerType']>({
    name: fields.handlerType.name,
  });
  const selectedHandlerType = watchedHandlerType?.[0]?.value;
  const isFriesland = getIsFriesland();
  const CATEGORY_OPTIONS = getCategoryOptions(isFriesland);

  React.useEffect(() => onHandlerTypeChange(selectedHandlerType ?? null), [
    onHandlerTypeChange,
    selectedHandlerType,
  ]);

  return (
    <>
      {layout === 'overview' && (
        <Section>
          <TitleInput name={fields.title.name} />
        </Section>
      )}
      <Section>
        <FieldsGrid>
          <GridItem compact>
            <Select
              name={fields.status.name}
              options={STATUSES_OPTIONS}
              readOnly={!created}
            />
          </GridItem>
          <GridItem compact>
            <Select
              name={fields.color.name}
              label={fields.color.label}
              options={CATEGORY_OPTIONS}
            />
          </GridItem>
          {layout === 'creation' && (
            <GridItem compact>
              <Input name={fields.title.name} label={fields.title.label} />
            </GridItem>
          )}
          <GridItem compact={layout === 'creation'}>
            <IconPicker name={fields.icon.name} />
          </GridItem>
          <GridItem>
            <Select
              name={fields.handlerType.name}
              label={fields.handlerType.label}
              options={HANDLER_TYPE_OPTIONS}
            />
          </GridItem>
          {selectedHandlerType === HANDLER_TYPES.DYNAMIC && (
            <GridItem>
              <Select
                name={fields.handler.name}
                label={fields.handler.label}
                options={handlerOptions}
              />
            </GridItem>
          )}
        </FieldsGrid>
      </Section>
    </>
  );
};

export default CollectionsCore;
