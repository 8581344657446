import * as React from 'react';
import styled from 'styled-components';

import { IconContainer, Row } from 'common/components';
import { ChainLink } from 'common/icons';

interface Props {
  children: string;
}

const LinkedCompany = ({ children }: Props) => (
  <Row>
    <IconContainer margin={[0, 1, 0, 0]}>
      <ChainLink size="16px" color="greyDark" />
    </IconContainer>
    <CompanyName>{children}</CompanyName>
  </Row>
);

const CompanyName = styled.span`
  line-height: 150%;
  color: ${({ theme }) => theme.color.greyDark};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.normal};
`;

export default LinkedCompany;
