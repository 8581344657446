import * as React from 'react';
import styled from 'styled-components';

import { Container, Panel, TopBar } from 'common/components';
import { resolveSpacersArray } from 'common/styles/utils';

import AdminsList from '../components/AdminsList';
import RemoveAccount from '../components/RemoveAccount';
import SetAdmin from '../components/SetAdmin';
import MaintenanceMode from '../components/MaintenanceMode';
import ClearCache from '../components/ClearCache';

const Settings = () => {
  return (
    <Container>
      <TopBar title="Settings" />
      <LayoutContainer>
        <Panel>
          <SetAdmin />
          <Separator />
          <RemoveAccount />
          <Separator />
          <MaintenanceMode />
          <Separator />
          <ClearCache />
        </Panel>
        <Pane>
          <AdminsList />
        </Pane>
      </LayoutContainer>
    </Container>
  );
};

const Pane = styled.div``;

const Separator = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.color.blueGrey};
  margin: ${({ theme }) => theme.spacer.times(3)} 0;
`;

const LayoutContainer = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacer.times(5)};
  padding: ${({ theme }) => resolveSpacersArray(theme, [3])};
`;

export default Settings;
