import styled from 'styled-components';

import { ThemeSpacer } from 'shared/theme';
import { resolveSpacer } from 'common/styles/utils';

type Props = {
  reverse?: boolean;
  marginBottom?: ThemeSpacer;
};

const Row = styled.div<Props>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  margin-bottom: ${({ theme, marginBottom }) => resolveSpacer(theme, marginBottom)};
`;

export default Row;
