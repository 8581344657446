import * as React from 'react';

import { STATUS_SIZES } from 'common/consts';
import { StatusChip } from 'common/components';
import { PlainCheckbox } from 'common/components/Checkbox';
import { SelectOption, SelectOptionLabel } from 'common/components/DropdownPicker';
import { B2B } from 'common/icons';

import { MiniOption } from './consts';

interface MiniSelectOptionProps {
  item?: MiniOption;
  selected?: boolean;
  isMulti?: boolean;
  handlePick?: (item: MiniOption) => void;
}

const MiniSelectOption = ({
  item,
  selected,
  isMulti,
  handlePick,
}: MiniSelectOptionProps) =>
  item ? (
    <SelectOption onClick={() => handlePick?.(item)}>
      {isMulti && <PlainCheckbox name={item.label} checked={selected} readOnly />}
      <SelectOptionLabel>
        {item.label}
        {item.isB2B && <B2B color="black" backgroundColor="blackSuperAlpha" margin={1} />}
      </SelectOptionLabel>
      {item.status && <StatusChip status={item.status} size={STATUS_SIZES.SMALL} />}
    </SelectOption>
  ) : null;

export default MiniSelectOption;
