import * as Yup from 'yup';

import { combineSchemas } from 'common/utils';
import { Fields } from 'common/types';

export type AuthorsFields = {
  name: string;
  description: string;
  link: string;
  image?: string;
};

export const fields: Fields<AuthorsFields> = {
  name: {
    label: 'Name',
    name: 'name',
    schema: Yup.string(),
  },
  description: {
    label: 'Description',
    name: 'description',
    schema: Yup.string(),
  },
  link: {
    label: 'Link',
    name: 'link',
    schema: Yup.string(),
  },
  image: {
    label: 'Image',
    name: 'image',
    schema: Yup.string(),
  },
};

export const validationSchema = Yup.object().shape<AuthorsFields>(combineSchemas(fields));
