import styled from 'styled-components';

const TextCell = styled.div<{ $width?: string }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 0;
  margin-right: ${({ theme }) => theme.spacer.big};
  flex-basis: ${({ $width }) => $width || '100%'};
`;

export default TextCell;
