import * as React from 'react';
import styled from 'styled-components';

import { Img } from 'common/components';
import ImagePlaceholder from './ImagePlaceholder';
import phoneHeaderImage from 'assets/graphics/phone-header.png';
import BlogPlaceholder from './BlogPlaceholder';
import BlogContainer from './BlogContainer';

interface Props {
  imageUrl?: string;
  pageHtml: string;
}
export const BlogMobilePreview = ({ imageUrl, pageHtml }: Props) => {
  return (
    <BlogContainer>
      <Content>
        <ContentInner>
          {imageUrl && <MobileHeader />}
          <BlogPlaceholder>
            {imageUrl ? (
              <Img src={imageUrl} alt="Blog image" />
            ) : (
              <StyledImagePlaceholder />
            )}
          </BlogPlaceholder>
          <Iframe srcDoc={pageHtml} sandbox="allow-scripts" />
        </ContentInner>
      </Content>
    </BlogContainer>
  );
};

const Iframe = styled.iframe`
  height: 284px;
  width: 355px;
  padding: 0 ${({ theme }) => theme.spacer.medium};
`;

const ContentInner = styled.div`
  font-size: ${({ theme }) => theme.font.size.minuscule};
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const StyledImagePlaceholder = styled(ImagePlaceholder)`
  height: 500px;
  margin-top: ${({ theme }) => theme.spacer.medium};
`;

const Content = styled.div`
  width: 328px;
  height: 520px;
  background: ${({ theme }) => theme.color.white};
`;

const MobileHeader = styled.div`
  height: 70px;
  background-color: white;
  background-image: url(${phoneHeaderImage});
  background-size: contain;
  background-repeat: no-repeat;
`;
