import * as React from 'react';
import styled from 'styled-components';

import { Collection } from 'base/api/types';
import { PrimaryKey, Row, Title } from 'common/components';
import { HANDLER_TYPES, STATUS_SIZES } from 'common/consts';
import { resolveSpacersArray } from 'common/styles/utils';
import { composeUrl, ifVariant } from 'common/utils';
import { useContentContext } from 'templates/content';
import ContentRow, { ContentRowProps } from 'templates/content/components/ContentRow';

import Chip from './Chip';
import StatusCell from './StatusCell';
import CollectionsMenu from './CollectionsMenu';

const ifHandlerType = ifVariant(Object.values(HANDLER_TYPES));

const CollectionRow = (props: ContentRowProps<Collection>) => {
  const content = useContentContext();
  const { index, data } = props;
  const { pk, title, status, minis_count, series_count, handler } = data[index];
  const showCell = status || minis_count > 0 || series_count > 0;
  const handlerType = handler === null ? HANDLER_TYPES.STATIC : HANDLER_TYPES.DYNAMIC;
  const isActiveRow = content.active?.pk === pk;
  const urlPath =
    content.path?.details &&
    composeUrl(content.path.details, {
      params: { pk },
    });

  return (
    <StyledContentRow {...props} menuComponent={CollectionsMenu}>
      <TitleCell>
        <Row>
          <TypeChip size={STATUS_SIZES.SMALL}>
            {ifHandlerType(handlerType, Object.values(HANDLER_TYPES))}
          </TypeChip>
          <Title size="standard">{title}</Title>
        </Row>
        <PrimaryKey pk={pk} />
      </TitleCell>

      {showCell && (
        <StatusCell
          urlPath={urlPath}
          isActive={isActiveRow}
          minisCount={minis_count}
          seriesCount={series_count}
          status={status}
        />
      )}
    </StyledContentRow>
  );
};

const StyledContentRow = styled(ContentRow)`
  justify-content: space-between;
`;

const TitleCell = styled.div`
  flex-shrink: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: ${({ theme }) => theme.spacer.times(3)};
`;

const TypeChip = styled(Chip)`
  color: ${({ theme }) => theme.color.blue};
  background-color: ${({ theme }) => theme.color.blueGrey};
  font-size: ${({ theme }) => theme.font.size.superTiny};
  padding: ${({ theme }) => resolveSpacersArray(theme, [0, 1])};
`;

export default CollectionRow;
