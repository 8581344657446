import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import _get from 'lodash/get';

import PlainIconPicker, { PlainIconPickerProps } from './PlainIconPicker';
import { MediaObject } from 'base/api/types';

type IconPickerProps = Omit<PlainIconPickerProps, 'error' | 'value'>;

const IconPicker = ({ name, ...rest }: IconPickerProps) => {
  const { register, unregister, errors } = useFormContext();
  const value = useWatch<MediaObject>({ name });
  const error = _get(errors, name) || _get(errors, `${name}_pk`);

  React.useEffect(() => {
    register(name);

    return () => unregister(name);
  }, [name, register, unregister]);

  return <PlainIconPicker name={name} error={error} value={value} {...rest} />;
};

export default IconPicker;
