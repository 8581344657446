import * as React from 'react';
import styled from 'styled-components';

import { StandardError } from 'base/api/errors';

export interface ErrorBoxProps {
  className?: string;
  children?: string;
  isError?: boolean;
  error?: Partial<StandardError> | null;
}

const ErrorBox = ({ error, isError, children, className }: ErrorBoxProps) => {
  return error || isError ? (
    <div className={className}>
      {children || error?.message || 'Something went wrong'}
    </div>
  ) : null;
};

const StyledErrorBox = styled(ErrorBox)`
  width: 100%;
  padding: ${({ theme }) => theme.spacer.big};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  margin: ${({ theme }) => theme.spacer.small} 0;
  background-color: ${({ theme }) => theme.themeColor.secondaryLightColor};
  color: ${({ theme }) => theme.color.pink};
  font-size: ${({ theme }) => theme.font.size.small};
  line-height: 1.5;
`;

export default StyledErrorBox;
