import * as React from 'react';
import { useParams } from 'react-router';

import { BaseAuthor } from 'base/api/types';
import { PK } from 'common/types';
import { DetailsCard, DetailsSuspense } from 'templates/details';

import AuthorsCore from '../components/AuthorsCore';
import { AuthorsFields, fields } from '../consts';
import AuthorsMediaPicker from '../components/AuthorsMediaPicker';
import { useAuthorsDetails } from '../queries';

const AuthorsDetails = () => {
  const { pk } = useParams<{ pk: PK }>();
  const { data, ...query } = useAuthorsDetails(pk);
  const transformAuthors = (raw: BaseAuthor): AuthorsFields => ({
    [fields.name.name]: raw.name,
    [fields.description.name]: raw.description,
    [fields.link.name]: raw.link,
  });
  const defaultValues = data && transformAuthors(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading} error={query.error}>
        <AuthorsCore pk={pk} key={pk} defaultValues={defaultValues}>
          <AuthorsMediaPicker />
        </AuthorsCore>
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default AuthorsDetails;
