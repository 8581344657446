import { useMutation, useQueryClient } from 'react-query';
import Axios from 'axios';

import apiUrls from 'base/api/urls';
import {
  RecipeCategories,
  RecipeIngredients,
  RecipeMedia,
  RecipeBase,
  RecipeNutritionalValues,
  RecipePreparation,
  Tags,
} from 'base/api/types';
import { StandardError } from 'base/api/errors';
import { useDetailsQuery, useListQuery } from 'base/api/hooks';
import { CommonVars, DateString, PK } from 'common/types';
import { composeUrl } from 'common/utils';
import { DATE_FORMATS, STATUSES } from 'common/consts';

export const useRecipesList = () => {
  return useListQuery<RecipeBase>(apiUrls.RECIPES.LIST);
};

export const useRecipeDetails = (pk?: PK) => {
  return useDetailsQuery<RecipeBase>({
    queryKey: [apiUrls.RECIPES.DETAILS, { pk }],
    pk,
  });
};

interface RecipeCreateVars {
  title: string;
  why_its_healthy: string;
  why_its_healthy_author_pk: PK | null;
  author_pk: PK;
  status: STATUSES;
  portions: number;
  calories: number;
  preparation_time: number;
  oven_time: number | null;
  waiting_time: number | null;
  image_pk?: PK | null;
  video_pk?: PK | null;
  date_active_start: DateString<DATE_FORMATS.DATABASE> | null;
  date_active_end: DateString<DATE_FORMATS.DATABASE> | null;
  content_goal_pks: PK[];
}

export const useRecipeCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<RecipeBase, StandardError, RecipeCreateVars>(
    (data) => Axios.post(apiUrls.RECIPES.LIST, data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.RECIPES.LIST);
        queryClient.invalidateQueries(apiUrls.CONTENT.LIST);
        queryClient.setQueryData([apiUrls.RECIPES.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

export interface RecipeUpdateVars extends RecipeCreateVars, CommonVars {
  ingredients?: any;
  nutritional_values?: any;
}

export const useRecipeUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<RecipeBase, StandardError, RecipeUpdateVars>(
    ({ pk, ...data }) =>
      Axios.patch(composeUrl(apiUrls.RECIPES.DETAILS, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.RECIPES.LIST);
        queryClient.setQueryData<RecipeBase>(
          [apiUrls.RECIPES.DETAILS, { pk: data.pk }],
          data,
        );
      },
    },
  );
};

export const useRecipeDelete = () => {
  const queryClient = useQueryClient();

  return useMutation<void, StandardError, CommonVars>(
    ({ pk }) => Axios.delete(composeUrl(apiUrls.RECIPES.DETAILS, { params: { pk } })),
    {
      onSuccess: (data, { pk }) => {
        queryClient.invalidateQueries(apiUrls.RECIPES.LIST);
        queryClient.removeQueries([apiUrls.RECIPES.DETAILS, { pk }]);
      },
    },
  );
};

export const useRecipeCategories = (pk?: PK) => {
  return useDetailsQuery<RecipeCategories>({
    queryKey: [apiUrls.RECIPES.CATEGORIES, { pk }],
    pk,
  });
};

interface RecipeCategoriesUpdateVars extends CommonVars, RecipeCategories {}

export const useRecipeCategoriesUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<
    RecipeCategories,
    StandardError,
    RecipeCategoriesUpdateVars,
    RecipeCategories | undefined
  >(
    ({ pk, ...data }) =>
      Axios.put(composeUrl(apiUrls.RECIPES.CATEGORIES, { params: { pk } }), data),
    {
      onMutate: ({ pk, ...vars }) => {
        const queryKey = [apiUrls.RECIPES.CATEGORIES, { pk }];
        const oldVars = queryClient.getQueryData<RecipeCategories>(queryKey);
        queryClient.setQueryData<RecipeCategories>(queryKey, vars);

        return oldVars;
      },
      onSuccess: (data, { pk }) => {
        queryClient.setQueryData<RecipeCategories>(
          [apiUrls.RECIPES.CATEGORIES, { pk }],
          data,
        );
      },
      onError: (error, { pk }, context) => {
        if (context) {
          queryClient.setQueryData<RecipeCategories>(
            [apiUrls.RECIPES.CATEGORIES, { pk }],
            context,
          );
        }
        queryClient.invalidateQueries([apiUrls.RECIPES.CATEGORIES, { pk }]);
      },
    },
  );
};

export const useRecipeTags = (pk?: PK) => {
  return useDetailsQuery<Tags>({
    queryKey: [apiUrls.RECIPES.TAGS, { pk }],
    pk,
  });
};

interface RecipesTagsUpdateVars extends CommonVars {
  payload: Tags;
}

export const useRecipesTagsUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, StandardError, RecipesTagsUpdateVars>(
    ({ pk, ...payload }) =>
      Axios.put(composeUrl(apiUrls.RECIPES.TAGS, { params: { pk } }), payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.RECIPES.TAGS);
      },
    },
  );
};

export const useRecipePreparationSteps = (pk?: PK) => {
  return useDetailsQuery<RecipePreparation>({
    queryKey: [apiUrls.RECIPES.PREPARATIION, { pk }],
    pk,
  });
};

interface RecipePreparationUpdateVars extends CommonVars, RecipePreparation {}

export const useRecipePreparationStepsUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<
    RecipePreparation,
    StandardError,
    RecipePreparationUpdateVars,
    RecipePreparation | undefined
  >(
    ({ pk, ...data }) =>
      Axios.put(composeUrl(apiUrls.RECIPES.PREPARATIION, { params: { pk } }), data),
    {
      onMutate: ({ pk, ...vars }) => {
        const queryKey = [apiUrls.RECIPES.PREPARATIION, { pk }];
        const oldVars = queryClient.getQueryData<RecipePreparation>(queryKey);
        queryClient.setQueryData<RecipePreparation>(queryKey, vars);

        return oldVars;
      },
      onSuccess: (data, { pk }) => {
        queryClient.setQueryData<RecipePreparation>(
          [apiUrls.RECIPES.PREPARATIION, { pk }],
          data,
        );
      },
      onError: (error, { pk }, context) => {
        const queryKey = [apiUrls.RECIPES.PREPARATIION, { pk }];

        if (context) {
          queryClient.setQueryData<RecipePreparation>(queryKey, context);
        }
        queryClient.invalidateQueries(queryKey);
      },
    },
  );
};

export const useRecipeIngredients = (pk?: PK) => {
  return useDetailsQuery<RecipeIngredients>({
    queryKey: [apiUrls.RECIPES.INGREDIENTS, { pk }],
    pk,
  });
};

interface RecipeIngredientsUpdateVars extends CommonVars {
  ingredients: {
    amount: number;
    fixed_amount: boolean;
    ingredient_pk: PK;
  }[];
}

export const useRecipeIngredientsUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<RecipeIngredients, StandardError, RecipeIngredientsUpdateVars>(
    ({ pk, ...data }) =>
      Axios.put(composeUrl(apiUrls.RECIPES.INGREDIENTS, { params: { pk } }), data),
    {
      onSuccess: (data, { pk }) => {
        queryClient.invalidateQueries([apiUrls.RECIPES.INGREDIENTS, { pk }]);
      },
    },
  );
};

export const useRecipeNutritionalValues = (pk?: PK) => {
  return useDetailsQuery<RecipeNutritionalValues>({
    queryKey: [apiUrls.RECIPES.NUTRITIONAL_VALUES, { pk }],
    pk,
  });
};

interface RecipeNutritionalValuesUpdateVars extends CommonVars {
  nutritional_values: {
    amount: number;
    nutritional_value_pk: PK;
    specified: {
      name: string;
      amount: number;
    }[];
  }[];
}

export const useRecipeNutritionalValuesUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<
    RecipeNutritionalValues,
    StandardError,
    RecipeNutritionalValuesUpdateVars
  >(
    ({ pk, ...data }) =>
      Axios.put(composeUrl(apiUrls.RECIPES.NUTRITIONAL_VALUES, { params: { pk } }), data),
    {
      onSuccess: (data, { pk }) => {
        queryClient.invalidateQueries([apiUrls.RECIPES.NUTRITIONAL_VALUES, { pk }]);
      },
    },
  );
};

export const useRecipeMedia = (pk?: PK) => {
  return useDetailsQuery<RecipeMedia>({
    queryKey: [apiUrls.RECIPES.MEDIA, { pk }],
    pk,
  });
};

interface RecipeMediaUpdateVars extends CommonVars {
  image: PK | null;
  video: PK | null;
}

export const useRecipeMediaUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<void, StandardError, RecipeMediaUpdateVars>(
    ({ pk, ...data }) =>
      Axios.put(composeUrl(apiUrls.RECIPES.MEDIA, { params: { pk } }), data),
    {
      onSuccess: (data, { pk }) => {
        queryClient.invalidateQueries([apiUrls.RECIPES.MEDIA, { pk }]);
      },
    },
  );
};
