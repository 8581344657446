import * as React from 'react';

import { PK } from 'common/types';
import { MEDIA_TYPES } from 'common/consts';
import { CollapsibleSection, MediaPicker, Title } from 'common/components';
import { useAuthorsDetails, useAuthorsUpdate } from '../queries';
import { MediaObject } from 'base/api/types';

const transformValues = (
  image: Pick<MediaObject, 'pk' | 'name' | 'url'>,
): MediaObject => {
  const { pk, url, name } = image;
  return {
    pk,
    url,
    name,
    duration: null,
    thumbnail: null,
    media_type: MEDIA_TYPES.IMAGE,
  };
};

const fieldName = 'media_file_pk';

type Props = {
  pk?: PK;
};

const AuthorsMediaPicker = ({ pk }: Props) => {
  const { mutate, isError, isSuccess, isLoading } = useAuthorsUpdate();
  const details = useAuthorsDetails(pk);
  const handleSave = (mediaPks: PK[]) => {
    if (pk && mediaPks) {
      mutate({
        image_pk: mediaPks[0] ?? null,
        pk: pk,
      });
    }
  };
  const defaultValues = details?.data?.image && [transformValues(details.data.image)];
  return (
    <CollapsibleSection
      title="Add media"
      divider={false}
      disabled={!pk}
      saveStatus={{ isError, isSuccess, isLoading }}
    >
      <Title size="standard" marginBottom={1.5}>
        Linked image
      </Title>
      <MediaPicker
        onSave={handleSave}
        name={fieldName}
        mediaType={MEDIA_TYPES.IMAGE}
        defaultValues={defaultValues}
      />
    </CollapsibleSection>
  );
};

export default AuthorsMediaPicker;
