import * as React from 'react';
import styled from 'styled-components';
import { FixedSizeGrid, GridChildComponentProps } from 'react-window';

import { MediaObject } from 'base/api/types';
import { StandardError } from 'base/api/errors';
import { ErrorBox } from 'common/components';
import { resolveSpacersArray } from 'common/styles/utils';
import { useContentContext } from 'templates/content';
import MediaCard from '../components/MediaCard';
import Skeleton from '../components/Skeleton';
import EmptyState from './EmptyState';

type Props = {
  width: number;
  height: number;
  error?: StandardError | null;
  children?: React.ComponentType<GridChildComponentProps>;
};

const MediaGrid = ({ error, width, height, children }: Props) => {
  const { filteredData, data } = useContentContext<MediaObject>();

  const itemKey = ({
    data,
    rowIndex,
    columnIndex,
  }: {
    data: MediaObject[];
    rowIndex: number;
    columnIndex: number;
  }) => {
    const index = columnIndex + rowIndex * 4;
    const item = data[index];
    return item ? item.pk : index;
  };

  return (
    <>
      {data === null ? (
        <SkeletonGrid>
          {Array.from({ length: 16 }).map((_, index) => (
            <Skeleton key={`skeleton-${index}`} />
          ))}
        </SkeletonGrid>
      ) : error ? (
        <ErrorBox error={error} />
      ) : filteredData.length > 0 ? (
        <FixedSizeGrid
          columnCount={4}
          columnWidth={width / 4}
          height={height}
          width={width}
          rowCount={Math.ceil(filteredData.length / 4)}
          rowHeight={280 + 4 * 8}
          itemData={filteredData}
          itemKey={itemKey}
        >
          {children || MediaCard}
        </FixedSizeGrid>
      ) : (
        <EmptyState width={width} height={height} />
      )}
    </>
  );
};

const SkeletonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${({ theme }) => theme.spacer.times(4)};
  margin: ${({ theme }) => resolveSpacersArray(theme, [2, 4])};
`;

export default MediaGrid;
