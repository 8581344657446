import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { useContentContext } from 'templates/content/context';

type Props = {
  children?: React.ReactNode;
};

const RecipeDetailsSwitch = ({ children }: Props) => {
  const { path } = useContentContext();

  return (
    <Switch>
      <Route exact path={path?.create}>
        {children}
      </Route>
      <Route path={path?.details}>{children}</Route>
    </Switch>
  );
};

export default RecipeDetailsSwitch;
