import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Nutrition = ({ size = '16px', color = 'none', ...rest }: IconProps) => {
  return (
    <Svg viewBox="0 0 16 16" size={size} color={color} {...rest}>
      <path
        d="M.5.5h3l1 11"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 4.5h-6v11h6v-11zM10.5 5.5a5 5 0 1 1 0 10"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 0v1a3 3 0 0 1-3 3V3a3 3 0 0 1 3-3z"
        stroke="currentColor"
        fill="none"
      />
    </Svg>
  );
};

export default Nutrition;
