import * as Yup from 'yup';

import { Fields, PK, SelectOption, StatusOption } from 'common/types';
import { combineSchemas } from 'common/utils';

export type MiniFields = {
  title: string;
  status: StatusOption[];
  description: string;
  short_description: string;
  punctation: SelectOption<number>[];
  content_goals: SelectOption<PK>[];
  is_b2b: 'true' | 'false';
};

export const fields: Fields<MiniFields> = {
  title: {
    label: 'Title',
    name: 'title',
    schema: Yup.string().required(),
  },
  short_description: {
    label: 'Short description',
    name: 'short_description',
    schema: Yup.string().required(),
  },
  description: {
    label: 'Description',
    name: 'description',
    schema: Yup.string().required(),
  },
  status: {
    label: 'Status',
    name: 'status',
    schema: Yup.array().required(),
  },
  content_goals: {
    label: 'Categories',
    name: 'content_goals',
    schema: Yup.array().required(),
  },
  punctation: {
    label: 'Points',
    name: 'punctation',
    schema: Yup.array().required(),
  },
  is_b2b: {
    label: 'Is B2B',
    name: 'is_b2b',
    schema: Yup.string().required(),
  },
};

export const validationSchema = Yup.object().shape<MiniFields>(combineSchemas(fields));

export type MiniTagsFields = {
  amount: number;
  amount_unit: SelectOption<string>;
  category: SelectOption<string>[];
  contribution_rating: SelectOption<string>;
  frequency: SelectOption<string>;
  habit: SelectOption<string>[];
  habit_replacement: SelectOption<string>;
  holiday_dependant: SelectOption<string>;
  location_dependent: SelectOption<string>;
  min_number_people: SelectOption<string>;
  subcategory: SelectOption<string>[];
  term: SelectOption<string>;
  weather_condition: SelectOption<string>;
  time_bound: SelectOption<string>[];
  season: SelectOption<string>[];
  requisites: SelectOption<string>[];
};

export const tagsFields: Fields<MiniTagsFields> = {
  amount: {
    label: 'Amount',
    name: 'amount',
    schema: Yup.number()
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .test('Is positive?', 'Amount must be greater than 0.', (value) => {
        return typeof value === 'object' || (typeof value === 'number' && value > 0);
      }),
  },
  amount_unit: {
    label: 'Amount unit',
    name: 'amount_unit',
    schema: Yup.array()
      .nullable()
      .when('amount', {
        is: (amount) => {
          return typeof amount === 'number';
        },
        then: Yup.array()
          .nullable()
          .required('Amount and Unit fields must be set together'),
      }),
  },
  category: {
    label: 'Category',
    name: 'category',
    schema: Yup.array().required('Category is a required field'),
  },
  contribution_rating: {
    label: 'Contribution Rating',
    name: 'contribution_rating',
    schema: Yup.array().required('Contribution Rating is a required field').nullable(),
  },
  frequency: {
    label: 'Frequency',
    name: 'frequency',
    schema: Yup.number()
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .test('Is positive?', 'Frequency must be greater than 0.', (value) => {
        return typeof value === 'object' || (typeof value === 'number' && value > 0);
      }),
  },
  habit: {
    label: 'Habit',
    name: 'habit',
    schema: Yup.array().required('Habit is a required field'),
  },
  habit_replacement: {
    label: 'Habit replacement',
    name: 'habit_replacement',
    schema: Yup.array().nullable(),
  },
  holiday_dependant: {
    label: 'Holiday dependant',
    name: 'holiday_dependant',
    schema: Yup.array().nullable(),
  },
  location_dependent: {
    label: 'Location dependent',
    name: 'location_dependent',
    schema: Yup.array().nullable(),
  },
  min_number_people: {
    label: 'Min number people',
    name: 'min_number_people',
    schema: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Min number people is a required field')
      .test(
        'Is positive?',
        'Min number people must be greater than 0.',
        (value) => typeof value === 'number' && value > 0,
      ),
  },
  subcategory: {
    label: 'Subcategory',
    name: 'subcategory',
    schema: Yup.array().required('Subcategory is a required field'),
  },
  term: {
    label: 'Term',
    name: 'term',
    schema: Yup.array().nullable(),
  },
  weather_condition: {
    label: 'Weather condition',
    name: 'weather_condition',
    schema: Yup.array().nullable(),
  },
  time_bound: {
    label: 'Time bound',
    name: 'time_bound',
    schema: Yup.array(),
  },
  season: {
    label: 'Season',
    name: 'season',
    schema: Yup.array(),
  },
  requisites: {
    label: 'Requisites',
    name: 'requisites',
    schema: Yup.array(),
  },
};

export const minisTagsValidationSchema = Yup.object().shape<MiniTagsFields>(
  combineSchemas(tagsFields),
);
