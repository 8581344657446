import * as React from 'react';

import { Button } from 'common/components';
import { BUTTON_VARIANTS } from 'common/consts';
import { Cross } from 'common/icons';

interface CloseButtonProps {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
}

const CloseButton = ({ onClose }: CloseButtonProps) => (
  <Button variant={BUTTON_VARIANTS.ICON} noNegativeMargin onClick={onClose}>
    <Cross size="16px" title="close" />
  </Button>
);

export default CloseButton;
