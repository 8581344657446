import * as React from 'react';
import styled from 'styled-components';

import { BUTTON_VARIANTS } from 'common/consts';
import { Button } from 'common/components';
import { EyeFilled, EyeCrossedFilled } from 'common/icons';

interface ContentItemProps {
  label: string;
  isVisible: boolean;
  isLoading?: boolean;
  onVisibilityToggle: () => void;
}

const ContentItem = ({
  label,
  isVisible,
  isLoading,
  onVisibilityToggle,
}: ContentItemProps) => {
  return (
    <Row>
      <Label>{label}</Label>
      <Button
        width={5}
        isLoading={isLoading}
        spinnerColor="greyDark"
        variant={BUTTON_VARIANTS.ICON}
        onClick={onVisibilityToggle}
      >
        {isVisible ? (
          <EyeCrossedFilled color="black" title="Hide item" />
        ) : (
          <EyeFilled color="greyDark" title="Show item" />
        )}
      </Button>
    </Row>
  );
};

const Label = styled.label`
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default ContentItem;
