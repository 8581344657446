import * as React from 'react';

import { CoreProps } from 'common/types';
import { Input } from 'common/components';
import {
  CoreFormLayout,
  FieldsGrid,
  GridItem,
  Section,
  SectionsWrapper,
} from 'templates/core';
import { TitleInput } from 'templates/details';

import { fields, UnitsFields, validationSchema } from '../consts';
import { useSave } from '../hooks';

const UnitsCore = ({
  pk,
  defaultValues,
  children,
  layout = 'overview',
}: CoreProps<UnitsFields>) => {
  const { save, isSaving, error, created } = useSave(pk);

  if (!pk) {
    layout = 'creation';
  }

  return (
    <>
      <CoreFormLayout
        onSubmit={save}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isSubmitting={isSaving}
        error={error}
      >
        <Section>
          {layout === 'overview' && <TitleInput name={fields.name_singular.name} />}
          {layout === 'creation' && (
            <Input name={fields.name_singular.name} label={fields.name_singular.label} />
          )}
        </Section>
        <Section>
          <FieldsGrid>
            <GridItem>
              <Input name={fields.name_plural.name} label={fields.name_plural.label} />
            </GridItem>
          </FieldsGrid>
        </Section>
      </CoreFormLayout>
      <SectionsWrapper pk={created}>{children}</SectionsWrapper>
    </>
  );
};

export default UnitsCore;
