import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { MediaObject } from 'base/api/types';
import { PK } from 'common/types';
import { MEDIA_TYPES } from 'common/consts';
import { useMediaOptions, useModal } from 'common/hooks';
import { FileInput } from 'common/components';
import { usePostMedia } from 'modules/media/queries';
import MediaLibraryPicker from 'modules/media/views/MediaLibraryPicker';
import MediaDropzoneLabel from './MediaDropzoneLabel';
import MediaValues from './MediaValues';
import Wrapper from '../Wrapper';
import Title from '../Title';

const margins = {
  filed: [1, 0],
};

type Fields = Record<string, (MediaObject | File)[] | undefined>;

const isMedia = (items?: any[]): items is MediaObject[] => {
  return items ? items.every((item) => (item?.media_type ? true : false)) : false;
};

const isFile = (items?: any[]): items is File[] => {
  return !isMedia(items);
};

export interface MediaFieldProps {
  name: string;
  mediaType: MEDIA_TYPES;

  /**
   * NOTE String, array, or a comma-separated list of file extensions compatible with "accept"
   *      attribute of <input />
   *      Takes precedence over mediaType.
   *
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
   */
  fileType?: string | string[];
  onUpload?: (pk: PK) => void;
  onRemove?: (pk: PK) => void;
  onPick?: (media: MediaObject) => void;
}

const MediaField = ({
  name: fieldName,
  mediaType,
  fileType,
  onUpload,
  onRemove,
  onPick,
}: MediaFieldProps) => {
  const {
    errors,
    register,
    unregister,
    setValue,
    getValues,
    setError,
  } = useFormContext<Fields>();

  const error = errors[fieldName];
  const mediaPicker = useModal();
  const mediaOptions = useMediaOptions(mediaType);
  const isVideo = mediaType === MEDIA_TYPES.VIDEO;
  const filesName = `${fieldName}_files`;
  const thumbnailsName = `${fieldName}_thumbnails`;
  const files = getValues(filesName);
  const isVideoSelected = isVideo && files && files.length > 0;

  React.useEffect(() => {
    register(fieldName);
    return () => unregister(fieldName);
  }, [fieldName, register, unregister]);

  React.useEffect(() => {
    if (error) {
      setError(filesName, { ...error, shouldFocus: false });
    }
  }, [setError, filesName, error]);

  const setMediaValue = (value: MediaObject[]) => {
    setValue(fieldName, value);
  };

  const {
    mutate: addMedia,
    progress,
    setProgress,
    isLoading,
    isSuccess,
    error: apiError,
    reset: resetMutation,
  } = usePostMedia({
    onSuccess: (data) => {
      setMediaValue([data]);
      setTimeout(() => {
        setProgress(0);
        setValue(filesName, [], { shouldValidate: true });
        setValue(thumbnailsName, [], { shouldValidate: true });
      }, 1000);
      onUpload?.(data.pk);
    },
    onError: () => {
      setProgress(0);
    },
  });

  const handleUpload = () => {
    const files = getValues(filesName);
    const file = isFile(files) && files[0];
    if (
      file &&
      mediaOptions.regexp.test(file.type) &&
      mediaOptions.type !== MEDIA_TYPES.VIDEO
    ) {
      const payload = {
        file,
        media_type: mediaOptions.type,
      };
      addMedia(payload);
    } else {
      setError(fieldName, { message: 'Invalid file type' });
    }
  };

  const handleUploadVideo = () => {
    const { [filesName]: files, [thumbnailsName]: thumbnails } = getValues();
    const file = files && isFile(files) && files[0];
    const thumbnail = thumbnails && isFile(thumbnails) && thumbnails[0];
    if (thumbnail && file) {
      if (file && thumbnail) {
        const payload = {
          file,
          thumbnail,
          media_type: MEDIA_TYPES.VIDEO,
        };
        addMedia(payload);
      }
    } else {
      setError(thumbnailsName, { message: 'Thumbnail is required' });
    }
  };

  const handleRemove = (pk: PK) => () => {
    const values = getValues(fieldName);
    const medias = isMedia(values) && values;
    if (medias) {
      setMediaValue(medias.filter((media) => media.pk !== pk));
      onRemove?.(pk);
    }
  };

  const handlePick = (item: MediaObject) => {
    setMediaValue([item]);
    onUpload?.(item.pk);
    onPick?.(item);
  };

  return (
    <>
      {isVideo ? (
        <>
          <Wrapper margin={margins.filed}>
            <FileInput
              name={filesName}
              label={<MediaDropzoneLabel onMediaOpen={mediaPicker.open} />}
              accept={mediaOptions.accept}
              onDrop={resetMutation}
              onUpload={handleUploadVideo}
              isSuccess={isSuccess}
              isLoading={isLoading}
              progress={progress}
              error={apiError}
            />
          </Wrapper>
          {isVideoSelected && (
            <Wrapper margin={margins.filed}>
              <Title size="small" marginBottom={1}>
                Select thumbnail
              </Title>
              <FileInput name={thumbnailsName} label="Drop your file here" />
            </Wrapper>
          )}
        </>
      ) : (
        <Wrapper margin={margins.filed}>
          <FileInput
            name={filesName}
            label={<MediaDropzoneLabel onMediaOpen={mediaPicker.open} />}
            accept={
              fileType
                ? typeof fileType === 'string'
                  ? fileType
                  : fileType.join(', ')
                : mediaOptions.accept
            }
            onDrop={resetMutation}
            onUpload={handleUpload}
            isSuccess={isSuccess}
            isLoading={isLoading}
            progress={progress}
            error={apiError}
          />
        </Wrapper>
      )}
      <MediaValues name={fieldName} onRemove={handleRemove} mediaType={mediaType} />
      <MediaLibraryPicker
        mediaType={mediaType}
        fileType={fileType}
        isOpen={mediaPicker.isOpen}
        close={mediaPicker.close}
        onPick={handlePick}
      />
    </>
  );
};

export default MediaField;
