import * as React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

import { MediaObject } from 'base/api/types';
import { AZURE_PARAM, BUTTON_VARIANTS } from 'common/consts';
import { Cross, Pause, Play, Speaker, SpeakerMute } from 'common/icons';
import { Button, Modal } from 'common/components';
import { ModalProps } from 'common/components/Modal';

interface Props extends ModalProps {
  url: MediaObject['url'];
}

const Lightbox = ({ url, isOpen, close }: Props) => {
  const [playing, setPlaying] = React.useState(true);
  const [muted, setMuted] = React.useState(false);

  const togglePlay = () => {
    setPlaying((playing) => !playing);
  };

  const toggleMute = () => {
    setMuted((muted) => !muted);
  };

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      background="blackAlpha"
      dialogColor="transparent"
      autoWidth
    >
      <LightboxContainer>
        <CloseContainer>
          <Button variant={BUTTON_VARIANTS.ICON} onClick={() => close && close()}>
            <Cross size="16px" color="white" />
          </Button>
        </CloseContainer>
        <VideoWrapper>
          <ReactPlayer
            url={url + AZURE_PARAM}
            playing={playing}
            muted={muted}
            height="auto"
            width="auto"
          />
          <ControlsContainer>
            <Button variant={BUTTON_VARIANTS.ICON} onClick={togglePlay}>
              {playing ? (
                <Pause title="pause" />
              ) : (
                <Play title="play" size="16px" color="black" />
              )}
            </Button>
            <Button variant={BUTTON_VARIANTS.ICON} onClick={toggleMute}>
              {muted ? <SpeakerMute /> : <Speaker />}
            </Button>
          </ControlsContainer>
        </VideoWrapper>
      </LightboxContainer>
    </Modal>
  );
};

const LightboxContainer = styled.div`
  position: relative;

  video {
    max-height: 80vh;
    max-width: 80vw;
  }
`;

const ControlsContainer = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacer.times(4)};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${({ theme }) => theme.spacer.times(7)};
  height: ${({ theme }) => theme.spacer.times(7)};
  padding: 0 ${({ theme }) => theme.spacer.standard};
  border-radius: ${({ theme }) => theme.spacer.times(3.5)};
  background-color: ${({ theme }) => theme.color.white};
  transition: opacity 0.2s ease, transform 0.2s ease;
  opacity: 0;
  transform: translateY(100%);

  ${Button} {
    margin: 0 ${({ theme }) => theme.spacer.standard};
  }
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;

  &:hover {
    ${ControlsContainer} {
      opacity: 1;
      transform: translateY(0%);
    }
  }
`;

const CloseContainer = styled.div`
  position: absolute;
  top: -${({ theme }) => theme.spacer.times(4)};
  right: 0;
`;

export default Lightbox;
