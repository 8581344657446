import { useState } from 'react';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueries,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import Axios from 'axios';

import apiUrls from 'base/api/urls';
import {
  Company,
  CompanyAdmin,
  CompanyAdminInvitation,
  CompanyProgram,
  ListResponse,
} from 'base/api/types';
import { useDetailsQuery, useListQuery } from 'base/api/hooks';
import { StandardError } from 'base/api/errors';
import { CommonVars, DateString, PK } from 'common/types';
import { composeUrl } from 'common/utils';
import { DATE_FORMATS } from 'common/consts';

export const useCompaniesList = () => {
  return useListQuery<Company>(apiUrls.COMPANIES.LIST);
};

export const useCompanyDetails = (pk?: PK) => {
  return useDetailsQuery<Company>({ queryKey: [apiUrls.COMPANIES.DETAILS, { pk }], pk });
};

interface CompanyCreateVars {
  title: string;
  branch: string;
  kvk_number: string;
  min_team_size: number;
  max_team_size: number;
}

export const useCompanyCreate = () => {
  const queryClient = useQueryClient();
  return useMutation<Company, StandardError, CompanyCreateVars>(
    (data) => Axios.post(apiUrls.COMPANIES.LIST, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.COMPANIES.LIST);
        queryClient.invalidateQueries(apiUrls.CONTENT.LIST);
      },
    },
  );
};

interface CompanyUpdateVars extends CommonVars {
  title: string;
  branch: string;
  kvk_number: string;
}

export const useCompanyUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<Company, StandardError, CompanyUpdateVars>(
    ({ pk, ...data }) =>
      Axios.patch(composeUrl(apiUrls.COMPANIES.DETAILS, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.COMPANIES.LIST);
        queryClient.setQueryData([apiUrls.COMPANIES.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

export const useCompanyActivate = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, StandardError, CommonVars>(
    ({ pk }) => Axios.post(composeUrl(apiUrls.COMPANIES.ACTIVATE, { params: { pk } })),
    {
      onSuccess: (_, { pk }) => {
        queryClient.invalidateQueries(apiUrls.COMPANIES.LIST);
        queryClient.invalidateQueries([apiUrls.COMPANIES.DETAILS, { pk }]);
      },
    },
  );
};

export const useCompanyDeactivate = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, StandardError, CommonVars>(
    ({ pk }) => Axios.post(composeUrl(apiUrls.COMPANIES.DEACTIVATE, { params: { pk } })),
    {
      onSuccess: (_, { pk }) => {
        queryClient.invalidateQueries(apiUrls.COMPANIES.LIST);
        queryClient.invalidateQueries([apiUrls.COMPANIES.DETAILS, { pk }]);
      },
    },
  );
};

export const useCompanyProgramDetails = (pk?: PK) => {
  return useDetailsQuery<CompanyProgram>({
    queryKey: [apiUrls.COMPANIES.PROGRAM, { pk }],
    pk,
  });
};

interface CompanyProgramVars extends CommonVars {
  start_date: DateString<DATE_FORMATS.DATABASE>;
  scenario_pk: PK | null;
}

export const useCompanyProgramUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, StandardError, CompanyProgramVars>(
    ({ pk, ...data }) =>
      Axios.put(composeUrl(apiUrls.COMPANIES.PROGRAM, { params: { pk } }), data),
    {
      onSuccess: (data, { pk }) => {
        queryClient.invalidateQueries([apiUrls.COMPANIES.DETAILS, { pk }]);
        queryClient.invalidateQueries([apiUrls.COMPANIES.PROGRAM, { pk }]);
      },
    },
  );
};

type CompanyLogo = {
  original: string;
  small: string;
  large: string;
};

export const useCompanyLogo = (pk?: PK) => {
  return useDetailsQuery<CompanyLogo>({ queryKey: [apiUrls.COMPANIES.LOGO, { pk }], pk });
};

interface CompanyLogoVars extends CommonVars {
  file: File;
}

interface MutationResultProgress<TResult, TError = StandardError>
  extends UseMutationResult<TResult, TError, CompanyLogoVars> {
  progress: number;
  setProgress: (value: number) => void;
}

export const useCompanyLogoUpload = (
  config?: UseMutationOptions<unknown, StandardError, CompanyLogoVars>,
): MutationResultProgress<unknown> => {
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState<number>(0);
  const handleProgress = (event: ProgressEvent) => {
    const percent = Math.floor((100 * event.loaded) / event.total);
    setProgress(percent);
  };
  const mutation = useMutation<unknown, StandardError, CompanyLogoVars>(
    async ({ pk, file }: CompanyLogoVars) => {
      setProgress(0);
      const payload = new FormData();
      payload.append('image', file);
      const contentData = await Axios.put(
        composeUrl(apiUrls.COMPANIES.LOGO, { params: { pk } }),
        payload,
        {
          onUploadProgress: handleProgress,
        },
      );
      return contentData;
    },
    {
      ...config,
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.COMPANIES.LOGO);
      },
    },
  );
  return { ...mutation, progress, setProgress };
};

export const useCompanyLogoDelete = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, StandardError, CommonVars>(
    ({ pk }) => Axios.delete(composeUrl(apiUrls.COMPANIES.LOGO, { params: { pk } })),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.COMPANIES.LOGO);
      },
    },
  );
};

export const useCompanyAdmins = (pk?: PK) => {
  return useDetailsQuery<ListResponse<CompanyAdmin>>({
    queryKey: [apiUrls.COMPANIES.ADMINS.LIST, { pk }],
    pk,
  });
};

interface CompanyAdminVars {
  companyPk: PK;
  adminPk: PK;
  phone_number?: string;
  is_contact_person?: boolean;
}

export const useCompanyAdminUpdate = () => {
  return useMutation<CompanyAdmin, StandardError, CompanyAdminVars>(
    ({ companyPk, adminPk, ...data }) =>
      Axios.put(
        composeUrl(apiUrls.COMPANIES.ADMINS.DETAILS, { params: { companyPk, adminPk } }),
        data,
      ),
  );
};

interface CompanyAdminDeleteVars {
  companyPk: PK;
  adminPk: PK;
}

export const useCompanyAdminDelete = () => {
  const queryClient = useQueryClient();
  return useMutation<CompanyAdmin, StandardError, CompanyAdminDeleteVars>(
    ({ companyPk, adminPk }) =>
      Axios.delete(
        composeUrl(apiUrls.COMPANIES.ADMINS.DETAILS, { params: { companyPk, adminPk } }),
      ),
    {
      onSuccess: (data, vars) => {
        queryClient.invalidateQueries([
          apiUrls.COMPANIES.ADMINS.LIST,
          { pk: vars.companyPk },
        ]);
      },
    },
  );
};

export const useCompanyInvitations = (pk?: PK) => {
  return useDetailsQuery<ListResponse<CompanyAdminInvitation>>({
    queryKey: [apiUrls.COMPANIES.INVITATIONS.LIST, { pk }],
    pk,
  });
};

interface CompanyInvitationVars {
  pk: PK;
  body: {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
  };
}

export const useCompanyAdminInvite = () => {
  const queryClient = useQueryClient();
  return useMutation<CompanyAdminInvitation, StandardError, CompanyInvitationVars>(
    ({ pk, body }) =>
      Axios.post(
        composeUrl(apiUrls.COMPANIES.INVITATIONS.LIST, { params: { pk } }),
        body,
      ),
    {
      onSuccess: (invitation, vars) => {
        queryClient.setQueryData<ListResponse<CompanyAdminInvitation>>(
          [apiUrls.COMPANIES.INVITATIONS.LIST, { pk: vars.pk }],
          (data) =>
            data
              ? { ...data, values: [...data.values, invitation] }
              : { values: [invitation] },
        );
      },
    },
  );
};

interface CompanyInvitationUpdateVars extends Partial<CompanyInvitationVars> {
  companyPk: PK;
  invitationPk: PK;
}

export const useCompanyInvitationUpdate = () => {
  return useMutation<CompanyAdminInvitation, StandardError, CompanyInvitationUpdateVars>(
    ({ companyPk, invitationPk, body }) =>
      Axios.put(
        composeUrl(apiUrls.COMPANIES.INVITATIONS.DETAILS, {
          params: { companyPk, invitationPk },
        }),
        body,
      ),
  );
};

export const useCompanyInvitationDelete = () => {
  const queryClient = useQueryClient();
  return useMutation<CompanyAdminInvitation, StandardError, CompanyInvitationUpdateVars>(
    ({ companyPk, invitationPk }) =>
      Axios.delete(
        composeUrl(apiUrls.COMPANIES.INVITATIONS.DETAILS, {
          params: { companyPk, invitationPk },
        }),
      ),
    {
      onSuccess: (data, vars) => {
        queryClient.invalidateQueries([
          apiUrls.COMPANIES.INVITATIONS.LIST,
          { pk: vars.companyPk },
        ]);
      },
    },
  );
};

export const useCompanyInvitationsResend = () => {
  return useMutation<unknown, StandardError, CompanyInvitationUpdateVars>(
    ({ companyPk, invitationPk }) =>
      Axios.post(
        composeUrl(apiUrls.COMPANIES.INVITATIONS.RESEND, {
          params: { companyPk, invitationPk },
        }),
      ),
  );
};

interface CompanyEmails {
  emails: string[];
}

export const useCompanyEmails = (pk?: PK) => {
  return useDetailsQuery<CompanyEmails>({
    queryKey: [apiUrls.COMPANIES.EMAILS, { pk }],
    pk,
  });
};

interface CompanyEmailsVars extends CompanyEmails, CommonVars {}

export const useCompanyEmailsUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<CompanyEmails, StandardError, CompanyEmailsVars>(
    ({ pk, ...data }) =>
      Axios.put(composeUrl(apiUrls.COMPANIES.EMAILS, { params: { pk } }), data),
    {
      onSuccess: (data, vars) => {
        queryClient.invalidateQueries([apiUrls.COMPANIES.EMAILS, { pk: vars.pk }]);
      },
    },
  );
};

const START_DATE_STALE_TIME = 5 * 60 * 1000;

export const useProgramStartDates = (years: number[]) => {
  const queryFnFactory = (year: string) => () =>
    Axios.get(
      composeUrl(apiUrls.COMPETITIONS.START_DATES, {
        query: { year },
      }),
    );

  const queries = years.map((year) => ({
    queryKey: [apiUrls.COMPETITIONS.START_DATES, {}, { year }],
    staleTime: START_DATE_STALE_TIME,
    queryFn: queryFnFactory(year.toString()),
  }));

  return useQueries(queries) as UseQueryResult<
    ListResponse<DateString<DATE_FORMATS.DATABASE>>,
    StandardError
  >[];
};
