import { useMutation, useQueryClient } from 'react-query';
import Axios from 'axios';

import apiUrls from 'base/api/urls';
import { Fact } from 'base/api/types';
import { StandardError } from 'base/api/errors';
import { useListQuery } from 'base/api/hooks';
import { CommonVars, PK } from 'common/types';
import { composeUrl } from 'common/utils';
import { STATUSES } from 'common/consts';

export const useFactsList = () => {
  return useListQuery<Fact>(apiUrls.FACTS.LIST);
};

export const useFactDetails = (pk?: PK) => {
  // There are no endpoint to get fact's details. This should be replaced with proper API call.
  const { data, ...rest } = useFactsList();
  return { ...rest, data: data?.values.find((fact) => fact.pk === pk) };
};

interface FactCreateVars {
  fact_text: string;
  status: STATUSES;
  content_goal_pks: PK[];
}

export const useFactCreate = () => {
  const queryClient = useQueryClient();
  return useMutation<Fact, StandardError, FactCreateVars>(
    (data) => Axios.post(apiUrls.FACTS.LIST, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.FACTS.LIST);
        queryClient.invalidateQueries(apiUrls.CONTENT.LIST);
      },
    },
  );
};

interface FactUpdateVars extends FactCreateVars, CommonVars {}

export const useFactUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<Fact, StandardError, FactUpdateVars>(
    ({ pk, ...data }) =>
      Axios.patch(composeUrl(apiUrls.FACTS.DETAILS, { params: { pk } }), data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.FACTS.LIST);
      },
    },
  );
};
