import * as React from 'react';
import { useLocation } from 'react-router';

import appUrls from 'base/app/urls';
import { Series } from 'base/api/types';
import { Container, Wrapper, TopBar } from 'common/components';
import {
  ContentProvider,
  ContentList,
  FiltersForm,
  Sort,
  StatusFilter,
  SearchInput,
  ContentRow,
} from 'templates/content';
import { ContentRowProps } from 'templates/content/components/ContentRow';
import { DetailsSwitch } from 'templates/details';
import { translate as t } from 'common/utils/translate';

import SeriesDetails from './SeriesDetails';
import { useSeriesList } from '../queries';
import SeriesMenu from '../components/SeriesMenu';
import { FeatureFlags } from 'common/utils/featureFlags';

const { challenges } = FeatureFlags;

const fields = {
  search: 'search',
  filter: 'filter',
  sort: 'sort',
};

const SeriesList = () => {
  const location = useLocation();
  const { data, isError } = useSeriesList();
  const context = {
    data: data?.values.map((series) => {
      return {
        ...series,
        titleSuffix: challenges() ? (series.is_challenge ? 'challenge' : null) : null,
      };
    }),
    path: {
      details: appUrls.SERIES.DETAILS,
      list: appUrls.SERIES.LIST,
    },
  };

  return (
    <Container>
      <ContentProvider {...context}>
        <TopBar title={t("Serie's")}>
          <FiltersForm
            sort={['title', fields.sort]}
            search={['title', fields.search]}
            filter={['status', fields.filter]}
          >
            <Wrapper margin={[0, 0.5]}>
              <SearchInput name={fields.search} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <StatusFilter name={fields.filter} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <Sort name={fields.sort} />
            </Wrapper>
          </FiltersForm>
        </TopBar>
        <ContentList isError={isError}>{SeriesContentRow}</ContentList>
        <DetailsSwitch>
          <SeriesDetails key={location.pathname} />
        </DetailsSwitch>
      </ContentProvider>
    </Container>
  );
};

const SeriesContentRow = (props: ContentRowProps<Series>) => (
  <ContentRow {...props} menuComponent={SeriesMenu} />
);

export default SeriesList;
