import * as React from 'react';

const useCountdown = (initialTime: number, steps: number) => {
  const [remainingTime, setRemainingTime] = React.useState(initialTime);

  React.useEffect(() => {
    const interval = setInterval(
      () => setRemainingTime((prevTime) => Math.max(prevTime - steps, 0)),
      steps,
    );

    return () => clearInterval(interval);
  }, [steps]);

  return remainingTime;
};

export default useCountdown;
