import * as React from 'react';

import { RecipesUnit } from 'base/api/types';
import { PrimaryKey } from 'common/components';
import { TITLE_VARIANTS } from 'common/consts';
import { PK } from 'common/types';
import { Text, TextCell, SectionColumn, SectionRow } from 'modules/recipe-subsections';
import { ContentRow } from 'templates/content';
import { ContentRowProps } from 'templates/content/components/ContentRow';

import NutritionalValuesMenu from './NutritionalValuesMenu';

export interface NutritionalValuesRow {
  pk: PK;
  title: string;
  unit: RecipesUnit;
  isDetailsView?: boolean;
}

const NutritionalValuesContentRow: React.FC<ContentRowProps<NutritionalValuesRow>> = (
  props,
) => {
  const { index } = props;
  const { pk, title, unit } = props.data[index];
  const nutritionalValueData = props.data;

  return (
    <ContentRow
      {...props}
      data={nutritionalValueData}
      menuComponent={NutritionalValuesMenu}
    >
      <SectionColumn>
        <SectionRow>
          <TextCell>
            <Text size="standard">{title}</Text>
          </TextCell>
          <TextCell>
            <Text variant={TITLE_VARIANTS.SUBTITLE}>{unit.name_singular}</Text>
          </TextCell>
        </SectionRow>
        <PrimaryKey pk={pk} />
      </SectionColumn>
    </ContentRow>
  );
};

export default NutritionalValuesContentRow;
