import * as React from 'react';
import styled, { css } from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';

import { StandardError } from 'base/api/errors';
import { BUTTON_VARIANTS } from 'common/consts';
import { bounceIn } from 'common/styles/animations';
import { resolveSpacersArray } from 'common/styles/utils';
import { Button, FormError, Wrapper, Row } from 'common/components';
import { Check, Cross, Upload } from 'common/icons';
import ProgressRing from './ProgressRing';

export interface FileInputLabelProps {
  onBrowse: () => void;
}

export interface FileInputProps {
  name: string;
  accept?: string;
  className?: string;
  label?: React.ReactElement<FileInputLabelProps> | string;
  progress?: number;
  isMulti?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  error?: StandardError | null;
  onDrop?: () => void;
  onUpload?: () => void;
  onRemove?: (index: number) => void;
}

const FileInput = ({
  label,
  name,
  accept,
  onDrop,
  onUpload,
  onRemove,
  isLoading,
  isSuccess,
  progress,
  error,
  className,
  isMulti = false,
  ...props
}: FileInputProps) => {
  const {
    register,
    unregister,
    errors,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useFormContext();
  const formError = errors[name];
  const value: File[] = watch(name) || [];

  const handleDrop = (acceptedFiles: File[]) => {
    onDrop?.();
    if (acceptedFiles.length > 0) {
      setValue(name, acceptedFiles, { shouldValidate: true });
    }
  };

  const {
    open,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    onDrop: handleDrop,
    multiple: isMulti,
    noClick: true,
  });

  const handleRemove = (index: number) => {
    onRemove?.(index);
    const values = getValues(name);
    const updatedValues = values.filter(
      (_: any, valueIndex: number) => valueIndex !== index,
    );
    setValue(name, updatedValues);
    clearErrors(name);
  };

  React.useEffect(() => {
    if (error) {
      setError(name, error);
    } else {
      clearErrors(name);
    }
  }, [error, name, setError, clearErrors]);

  React.useEffect(() => {
    register(name);

    return () => unregister(name);
  }, [register, unregister, name]);

  return (
    <>
      <InputContainer
        className={className}
        {...getRootProps({
          isDragActive,
          isDragAccept,
          isDragReject,
          error: !!formError,
        })}
      >
        <input {...getInputProps({ id: name, name, ...props })} />
        {value && value.length > 0 ? (
          <>
            {value.map(({ name }, index) => (
              <FileInputItem key={`${name}-${index}`}>
                <Row>
                  <FileName>{name}</FileName>
                  <Button
                    variant={BUTTON_VARIANTS.ICON}
                    onClick={() => handleRemove(index)}
                  >
                    <Cross size="16px" color="pink" title="remove" />
                  </Button>
                </Row>
                <IconContainer>
                  {isLoading || (progress && progress > 0) ? (
                    <>
                      <ProgressRing radius={16} stroke={2} progress={progress} />
                      <CheckmarkContainer isSuccess={isSuccess}>
                        <Check size="32px" />
                      </CheckmarkContainer>
                    </>
                  ) : onUpload ? (
                    <Button
                      variant={BUTTON_VARIANTS.ICON}
                      onClick={onUpload}
                      noNegativeMargin
                    >
                      <Wrapper padding={[0, 0, 0, 1]}>Upload</Wrapper>
                    </Button>
                  ) : null}
                </IconContainer>
              </FileInputItem>
            ))}
          </>
        ) : React.isValidElement(label) ? (
          React.cloneElement(label, { onBrowse: open })
        ) : (
          <LabelButton onClick={open}>
            <Upload size="20px" color="pink" hoverColor="pink" />
            <LabelText>{label}</LabelText>
          </LabelButton>
        )}
      </InputContainer>
      <FormError message={formError?.message} />
    </>
  );
};

const CheckmarkContainer = styled.div<{ isSuccess?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  display: ${({ isSuccess }) => (isSuccess ? 'block' : 'none')};

  ${css`
    animation: ${bounceIn} 0.4s both ease;
  `}
`;

type InputProps = {
  isDragAccept?: boolean;
  isDragReject?: boolean;
  error?: boolean;
};

const InputContainer = styled.div<InputProps>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => resolveSpacersArray(theme, [2, 3])};
  width: 100%;
  height: ${({ theme }) => theme.spacer.times(8)};
  border: 1px dashed
    ${({ isDragAccept, isDragReject, error, theme }) =>
      isDragAccept
        ? theme.color.blue
        : isDragReject
        ? theme.color.pink
        : error
        ? theme.color.error
        : theme.color.blueLight};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  background-color: ${({ isDragAccept, isDragReject, theme }) =>
    isDragAccept
      ? theme.color.blueSuperLight
      : isDragReject
      ? theme.color.pinkSuperLight
      : 'transparent'};

  :focus {
    outline: none;
  }

  input {
    position: absolute;
    visibility: hidden;
  }
`;

const FileInputItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FileName = styled.span`
  margin-right: ${({ theme }) => theme.spacer.big};
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.font.size.small};
  overflow-wrap: break-word;
`;

const LabelText = styled.span`
  margin-left: ${({ theme }) => theme.spacer.big};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-family: ${({ theme }) => theme.font.family.sans};
  color: ${({ theme }) => theme.color.greyDark};
`;

const LabelButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: ${({ theme }) => theme.spacer.small};
  cursor: pointer;
`;

const IconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export default FileInput;
