import * as React from 'react';

import DropdownMenu from './DropdownMenu';
import MenuItem, { MenuItemProps } from './MenuItem';

interface MenuProps {
  menuItems: MenuItemProps[];
}

const Menu = ({ menuItems }: MenuProps) => {
  return (
    <DropdownMenu>
      {menuItems.map((item, index) => (
        <MenuItem key={index} {...item} />
      ))}
    </DropdownMenu>
  );
};

export default Menu;
