import { PK, SelectOption } from 'common/types';
import { ContentGoal } from './types';

export const transformContentGoals = (goals: ContentGoal[]): SelectOption<PK>[] =>
  goals.map((goal) => ({
    label: goal.label,
    value: goal.pk,
  }));

export type Flatten<T> = T extends Record<string, infer V> ? Flatten<V> : T;
