import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Tags = ({ size = '16px', color = 'none', ...rest }: IconProps) => {
  return (
    <Svg viewBox="0 0 16 16" size={size} color={color} {...rest}>
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="m.727 3.615 6.864 11.89M14.81 6.331c-2.784 2.493-6.164-.56-8.232 3.083l-3.25-5.63C5.393.137 8.8 3.231 11.557.7l3.252 5.631z" />
      </g>
    </Svg>
  );
};

export default Tags;
