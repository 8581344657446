import * as React from 'react';
import styled from 'styled-components';

import { RecipesAuthor } from 'base/api/types';
import { TITLE_VARIANTS } from 'common/consts';
import { Img, PrimaryKey } from 'common/components';
import { PaperClip } from 'common/icons';
import { Text, TextCell, SectionColumn, SectionRow } from 'modules/recipe-subsections';
import { ContentRow, useContentContext } from 'templates/content';
import { ContentRowProps } from 'templates/content/components/ContentRow';

import AuthorsMenu from './AuthorsMenu';

export interface RecipesAuthorRow extends RecipesAuthor {
  isDetailsView?: boolean;
}

const AuthorsContentRow: React.FC<ContentRowProps<RecipesAuthorRow>> = (props) => {
  const { isDetailsView } = useContentContext();
  const { index } = props;
  const { pk, name, description, link, image } = props.data[index];
  const data = props.data;

  return (
    <ContentRow {...props} data={data} menuComponent={AuthorsMenu}>
      <ImageWrapper>{image?.url && <Img src={image?.url} alt="image" />}</ImageWrapper>
      <SectionColumn>
        <SectionRow>
          <TextCell>
            <Text size="standard">{name}</Text>
          </TextCell>
          <TextCell>
            <Text variant={TITLE_VARIANTS.SUBTITLE}>{description}</Text>
          </TextCell>
          {!isDetailsView && <AuthorLink>{link}</AuthorLink>}
        </SectionRow>
        <PrimaryKey pk={pk} />
      </SectionColumn>
    </ContentRow>
  );
};

interface AuthorLinkProps {
  children: string;
}

const AuthorLink = ({ children }: AuthorLinkProps) => (
  <TextCell>
    <IconWrapper>
      <PaperClip />
    </IconWrapper>
    <Text variant={TITLE_VARIANTS.SUBTITLE}>{children}</Text>
  </TextCell>
);

const IconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacer.standard};
  height: 20px;
`;

const IMAGE_SIZE = 6;
const ImageWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.greyLight};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  width: ${({ theme }) => theme.spacer.times(IMAGE_SIZE)};
  height: ${({ theme }) => theme.spacer.times(IMAGE_SIZE)};
  margin-right: ${({ theme }) => theme.spacer.times(3)};

  ${Img} {
    height: 100%;
    width: 100%;
  }
`;

export default AuthorsContentRow;
