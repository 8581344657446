import * as React from 'react';

import appUrls from 'base/app/urls';
import { Container, Wrapper } from 'common/components';
import {
  RecipeDetailsSwitch,
  RecipeTopBar,
  CreateButton,
} from 'modules/recipe-subsections';
import { RECIPE_SUBSECTIONS } from 'modules/recipe-subsections/consts';

import {
  ContentProvider,
  ContentList,
  FiltersForm,
  Sort,
  SearchInput,
} from 'templates/content';

import UnitsDetails from './UnitsDetails';
import { useUnitsList } from '../queries';
import UnitsContentRow from '../components/UnitsContentRow';

const fields = {
  search: 'search',
  sort: 'sort',
};

const UnitsList = () => {
  const { data, isError } = useUnitsList();
  const context = {
    data: data?.values.map(({ name_singular, ...unit }) => ({
      ...unit,
      title: name_singular,
    })),
    path: {
      list: appUrls.UNITS.LIST,
      details: appUrls.UNITS.DETAILS,
      create: appUrls.UNITS.CREATE,
    },
  };

  return (
    <Container>
      <ContentProvider {...context}>
        <RecipeTopBar subsection={RECIPE_SUBSECTIONS.UNITS}>
          <CreateButton path={appUrls.UNITS.CREATE}>New item</CreateButton>
          <FiltersForm sort={['title', fields.sort]} search={['title', fields.search]}>
            <Wrapper margin={[0, 0.5]}>
              <SearchInput name={fields.search} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <Sort name={fields.sort} />
            </Wrapper>
          </FiltersForm>
        </RecipeTopBar>
        <ContentList isError={isError}>{UnitsContentRow}</ContentList>
        <RecipeDetailsSwitch>
          <UnitsDetails />
        </RecipeDetailsSwitch>
      </ContentProvider>
    </Container>
  );
};

export default UnitsList;
