import Axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { useDetailsQuery, useListQuery } from 'base/api/hooks';
import {
  Scenario,
  ScenarioListItem,
  ScenarioWeek,
  ScenarioWeekList,
} from 'base/api/types';
import apiUrls from 'base/api/urls';
import { StandardError } from 'base/api/errors';
import { WEEK_DAYS } from 'common/consts';
import { CommonVars, PK } from 'common/types';
import { composeUrl } from 'common/utils';

export const useScenariosList = () => {
  return useListQuery<ScenarioListItem>(apiUrls.SCENARIOS.LIST);
};

export const useScenarioDetails = (pk?: PK) => {
  return useDetailsQuery<Scenario>({ queryKey: [apiUrls.SCENARIOS.DETAILS, { pk }], pk });
};

interface ScenarioCreationVars {
  title: string;
}

export const useScenarioCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<Scenario, StandardError, ScenarioCreationVars>(
    (data) => Axios.post(apiUrls.SCENARIOS.LIST, data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.SCENARIOS.LIST);
        queryClient.invalidateQueries(apiUrls.CONTENT.LIST);
        queryClient.setQueryData<Scenario>(
          [apiUrls.SCENARIOS.DETAILS, { pk: data.pk }],
          data,
        );
      },
    },
  );
};

interface ScenarioMutationVars extends CommonVars, ScenarioCreationVars {}

export const useScenarioUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<Scenario, StandardError, ScenarioMutationVars>(
    ({ pk, ...rest }) =>
      Axios.patch(composeUrl(apiUrls.SCENARIOS.DETAILS, { params: { pk } }), rest),
    {
      onSuccess: (data, vars) => {
        queryClient.invalidateQueries(apiUrls.SCENARIOS.LIST);
        queryClient.invalidateQueries([apiUrls.SCENARIOS.DETAILS, { pk: vars.pk }]);
        queryClient.setQueryData<Scenario>(
          [apiUrls.SCENARIOS.DETAILS, { pk: vars.pk }],
          data,
        );
      },
    },
  );
};

export const useScenarioDelete = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, StandardError, CommonVars>(
    ({ pk }) => Axios.delete(composeUrl(apiUrls.SCENARIOS.DETAILS, { params: { pk } })),
    {
      onSuccess: (data, vars) => {
        queryClient.invalidateQueries(apiUrls.SCENARIOS.LIST);
        queryClient.invalidateQueries(apiUrls.CONTENT.LIST);
        queryClient.removeQueries([apiUrls.SCENARIOS.DETAILS, { pk: vars.pk }]);
      },
    },
  );
};

export const useScenarioDuplicate = () => {
  const queryClient = useQueryClient();

  return useMutation<Scenario, StandardError, CommonVars>(
    ({ pk, ...data }) =>
      Axios.post(composeUrl(apiUrls.SCENARIOS.DUPLICATE, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.SCENARIOS.LIST);
        queryClient.invalidateQueries(apiUrls.CONTENT.LIST);
        queryClient.setQueryData<Scenario>(
          [apiUrls.SCENARIOS.DETAILS, { pk: data.pk }],
          data,
        );
      },
    },
  );
};

export const useScenarioWeeksList = (pk?: PK) => {
  return useDetailsQuery<ScenarioWeekList>({
    queryKey: [apiUrls.SCENARIOS.WEEKS.LIST, { pk }],
    pk,
  });
};

export const useScenarioWeeksAdd = () => {
  const queryClient = useQueryClient();

  return useMutation<Scenario, StandardError, CommonVars>(
    ({ pk, ...data }) =>
      Axios.post(composeUrl(apiUrls.SCENARIOS.WEEKS.LIST, { params: { pk } }), data),
    {
      onSuccess: (data, { pk }) => {
        queryClient.invalidateQueries(apiUrls.SCENARIOS.LIST);
        queryClient.invalidateQueries([apiUrls.SCENARIOS.WEEKS.LIST, { pk }]);
        queryClient.setQueryData<Scenario>([apiUrls.SCENARIOS.DETAILS, { pk }], data);
      },
    },
  );
};

interface ScenarioWeeksRemoveVars extends CommonVars {
  weekPk: PK;
}

export const useScenarioWeeksRemove = () => {
  const queryClient = useQueryClient();

  return useMutation<Scenario, StandardError, ScenarioWeeksRemoveVars>(
    ({ pk, weekPk, ...data }) =>
      Axios.delete(
        composeUrl(apiUrls.SCENARIOS.WEEKS.DETAILS, { params: { pk, weekPk } }),
        data,
      ),
    {
      onSuccess: (data, { pk }) => {
        queryClient.invalidateQueries(apiUrls.SCENARIOS.LIST);
        queryClient.invalidateQueries([apiUrls.SCENARIOS.WEEKS.LIST, { pk }]);
        queryClient.setQueryData<Scenario>([apiUrls.SCENARIOS.DETAILS, { pk }], data);
      },
    },
  );
};

interface ScenarioWeeksOrderVars extends CommonVars {
  weeks_pks: PK[];
}

export const useScenarioWeeksOrder = () => {
  const queryClient = useQueryClient();

  return useMutation<
    Scenario,
    StandardError,
    ScenarioWeeksOrderVars,
    Scenario | undefined
  >(
    ({ pk, ...data }) =>
      Axios.put(composeUrl(apiUrls.SCENARIOS.WEEKS.ORDER, { params: { pk } }), data),
    {
      onMutate: ({ pk, weeks_pks }) => {
        const queryKey = [apiUrls.SCENARIOS.DETAILS, { pk }];
        const oldScenario = queryClient.getQueryData<Scenario>(queryKey);
        const newScenario = oldScenario && { ...oldScenario, weeks: weeks_pks };

        if (newScenario) {
          queryClient.setQueryData<Scenario>(queryKey, newScenario);
        }

        return oldScenario;
      },
      onSuccess: (data, { pk }) => {
        queryClient.setQueryData<Scenario>([apiUrls.SCENARIOS.DETAILS, { pk }], data);
      },
      onError: (error, { pk }, oldScenario) => {
        if (oldScenario) {
          queryClient.setQueryData<Scenario>(
            [apiUrls.SCENARIOS.DETAILS, { pk }],
            oldScenario,
          );
        }
      },
    },
  );
};

interface ScenarioMinisModifyVars {
  dayNo: WEEK_DAYS;
  scenarioPk: PK;
  weekPk: PK;
  miniPk: PK;
}

export const useScenarioMinisAdd = () => {
  const queryClient = useQueryClient();

  return useMutation<ScenarioWeek, StandardError, ScenarioMinisModifyVars>(
    ({ scenarioPk: pk, weekPk, dayNo, miniPk, ...data }) =>
      Axios.post(
        composeUrl(apiUrls.SCENARIOS.MINIS.LIST, {
          params: { pk, weekPk, dayNo: dayNo.toString() },
        }),
        { ...data, mini_pk: miniPk },
      ),
    {
      onSuccess: (data, vars) => {
        const queryKey = [apiUrls.SCENARIOS.WEEKS.LIST, { pk: vars.scenarioPk }];
        const weekList = queryClient.getQueryData<ScenarioWeekList>(queryKey);

        if (weekList) {
          queryClient.setQueryData<ScenarioWeekList>(queryKey, {
            weeks: replaceWeek(weekList.weeks, data),
          });
        }
      },
    },
  );
};

export const useScenarioMinisRemove = () => {
  const queryClient = useQueryClient();

  return useMutation<ScenarioWeek, StandardError, ScenarioMinisModifyVars>(
    ({ scenarioPk: pk, weekPk, dayNo, miniPk, ...data }) =>
      Axios.delete(
        composeUrl(apiUrls.SCENARIOS.MINIS.DETAILS, {
          params: { pk, weekPk, dayNo: dayNo.toString(), miniPk },
        }),
        data,
      ),
    {
      onSuccess: (data, vars) => {
        const queryKey = [apiUrls.SCENARIOS.WEEKS.LIST, { pk: vars.scenarioPk }];
        const weekList = queryClient.getQueryData<ScenarioWeekList>(queryKey);

        if (weekList) {
          queryClient.setQueryData<ScenarioWeekList>(queryKey, {
            weeks: replaceWeek(weekList.weeks, data),
          });
        }
      },
    },
  );
};

interface ScenarioMinisOrderVars {
  dayNo: WEEK_DAYS;
  scenarioPk: PK;
  weekPk: PK;
  minis: PK[];
}

export const useScenarioMinisReorder = () => {
  const queryClient = useQueryClient();

  return useMutation<ScenarioWeek, StandardError, ScenarioMinisOrderVars>(
    ({ scenarioPk: pk, weekPk, dayNo, ...data }) =>
      Axios.put(
        composeUrl(apiUrls.SCENARIOS.MINIS.ORDER, {
          params: { pk, weekPk, dayNo: dayNo.toString() },
        }),
        data,
      ),
    {
      onSuccess: (data, vars) => {
        const queryKey = [apiUrls.SCENARIOS.WEEKS.LIST, { pk: vars.scenarioPk }];
        const weekList = queryClient.getQueryData<ScenarioWeekList>(queryKey);

        if (weekList) {
          queryClient.setQueryData<ScenarioWeekList>(queryKey, {
            weeks: replaceWeek(weekList.weeks, data),
          });
        }
      },
    },
  );
};

const replaceWeek = (list: ScenarioWeek[], newWeek: ScenarioWeek) =>
  list.map((week) => (week.pk === newWeek.pk ? newWeek : week));
