import * as React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { isNumber } from 'common/utils';
import { CloseModal } from 'common/hooks/useModal';
import { ThemeColor, ThemeMedia } from 'shared/theme';

interface DialogProps {
  width?: ThemeMedia | number;
  autoWidth?: boolean;
  dialogColor?: ThemeColor;
}

export interface ModalProps extends DialogProps {
  isOpen: boolean;
  close?: CloseModal;
  background?: ThemeColor;
  children?: React.ReactNode;
}

const Modal = ({
  isOpen,
  close,
  children,
  width,
  autoWidth,
  background,
  dialogColor,
}: ModalProps) => {
  const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
    if (close && event.target === event.currentTarget) {
      close();
    }
  };
  return isOpen
    ? createPortal(
        <>
          <ModalContainer background={background} onClick={handleClose}>
            <ModalDialog width={width} autoWidth={autoWidth} dialogColor={dialogColor}>
              {children}
            </ModalDialog>
          </ModalContainer>
        </>,
        document.body,
      )
    : null;
};

export const ModalContainer = styled.div<Pick<ModalProps, 'background'>>`
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 998;
  overflow: auto;
  padding: ${({ theme }) => theme.spacer.times(6)};
  background-color: ${({ background, theme }) =>
    background ? theme.color[background] : 'rgba(0, 0, 0, 0.1)'};
`;

export const ModalDialog = styled.div<DialogProps>`
  position: relative;
  width: ${({ autoWidth }) => (autoWidth ? 'auto' : '100%')};
  max-width: ${({ theme, width, autoWidth }) =>
    autoWidth
      ? 'auto'
      : width
      ? isNumber(width)
        ? `${width}px`
        : theme.media[width]
      : theme.media.md};
  padding: 0;
  margin: auto;
  border-radius: ${({ theme }) => theme.border.radius.standard};
  background-color: ${({ theme, dialogColor }) =>
    dialogColor ? theme.color[dialogColor] : theme.color.white};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
`;

export default Modal;
