import * as React from 'react';
import styled from 'styled-components';

import { SelectedItem } from 'common/components';
import { useDnDList } from 'common/hooks';

import ContentItem from './ContentItem';
import { CollectionOption } from '../consts';

const noValues: CollectionOption[] = [];

interface DraggableListProps {
  isDraggable: boolean;
  defaultValues: CollectionOption[];
  onChangeVisibility: (item: CollectionOption) => void;
  onReorder: (items: CollectionOption[]) => void;
}

const DraggableList = ({
  isDraggable,
  defaultValues,
  onChangeVisibility,
  onReorder,
}: DraggableListProps) => {
  const { list: selected, getDraggableProps } = useDnDList<CollectionOption>({
    initList: defaultValues || noValues,
    onReorder,
  });

  const handleVisibilityToggle = (position: number) => {
    const item = selected[position];

    onChangeVisibility({ ...item, isVisible: !item.isVisible });
  };

  return (
    <SelectedList>
      {selected.map(
        (
          { value, label, status, created, lastUpdate, isVisible, isLoading },
          position,
        ) => (
          <SelectedItem
            key={value}
            {...getDraggableProps({
              label,
              status,
              created,
              lastUpdate,
              position,
              draggable: isDraggable,
            })}
          >
            <ContentItem
              label={label}
              isVisible={isVisible}
              isLoading={isLoading}
              onVisibilityToggle={() => handleVisibilityToggle(position)}
            />
          </SelectedItem>
        ),
      )}
    </SelectedList>
  );
};

const SelectedList = styled.div`
  padding-bottom: ${({ theme }) => theme.spacer.times(5)};
`;

export default DraggableList;
