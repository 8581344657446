import * as React from 'react';
import styled, { css } from 'styled-components';

import { IconProps } from 'common/types';
import { resolveSpacersArray } from 'common/styles/utils';

const Svg = ({
  children,
  title,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  // We're skipping some props here so they won't get passed down to <svg>
  fill,
  color,
  rotate,
  hoverColor,
  /* eslint-enable @typescript-eslint/no-unused-vars */
  ...props
}: IconProps) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg">
      {title && <title>{title}</title>}
      {children}
    </svg>
  );
};

const StyledSvg = styled(Svg)`
  fill: ${({ theme, fill, disabled }) =>
    disabled ? theme.color.grey : fill ? theme.color[fill] : theme.color.none};
  color: ${({ theme, color, disabled }) =>
    disabled ? theme.color.grey : color ? theme.color[color] : theme.color.black};
  width: ${({ size }) => size || '24px'};
  height: ${({ size }) => size || '24px'};
  margin: ${({ theme, margin = 0 }) => resolveSpacersArray(theme, margin)};
  transform: ${({ rotate = 0 }) => `rotate(${rotate}deg)`};
  transition: fill 0.1s ease, transform 0.2s ease;
  flex-shrink: 0;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
  pointer-events: inherit;

  ${({ theme, hoverColor, disabled }) =>
    hoverColor &&
    css`
      &:hover {
        fill: ${!disabled && theme.color[hoverColor]};
        color: ${!disabled && theme.color[hoverColor]};
      }
    `};
`;

export default StyledSvg;
