import styled from 'styled-components';

export const AdminContainer = styled.div<{ isOpen: boolean }>`
  width: 100%;
  margin: ${({ theme }) => theme.spacer.times(2)} 0;
  height: ${({ theme, isOpen }) => (isOpen ? 'auto' : theme.spacer.times(8))};
  background-color: ${({ theme }) => theme.color.greySuperLight};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  overflow: hidden;
`;

export const AdminHeader = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacer.times(8)};
  padding-right: ${({ theme }) => theme.spacer.times(3)};
`;

export const AdminTitle = styled.div`
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: ${({ theme }) => theme.font.size.standard};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  padding-left: ${({ theme }) => theme.spacer.times(3)};
  margin-right: ${({ theme }) => theme.spacer.times(3)};
  cursor: pointer;
`;

export const ResendInfo = styled.div`
  margin-bottom: ${({ theme }) => theme.spacer.big};
  color: ${({ theme }) => theme.color.greyDark};
  font-size: ${({ theme }) => theme.font.size.small};
`;

export const ContactLabel = styled.div`
  margin-left: ${({ theme }) => theme.spacer.big};
  padding: 0 ${({ theme }) => theme.spacer.standard};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  background-color: ${({ theme }) => theme.color.blueGrey};
  color: ${({ theme }) => theme.color.blue};
  height: ${({ theme }) => theme.spacer.times(3)};
  line-height: ${({ theme }) => theme.spacer.times(3)};
  font-size: 10px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 1px;
  text-transform: uppercase;
`;
