import * as React from 'react';
import styled from 'styled-components';

import { Mini } from 'base/api/types';
import { PK } from 'common/types';
import { isNumber } from 'common/utils';
import { useDnDList, useMappedValues } from 'common/hooks';
import { BUTTON_SIZES, BUTTON_VARIANTS } from 'common/consts';
import { Button, Container, Wrapper, Row, SelectedItem } from 'common/components';
import { useMinisList } from 'modules/minis/queries';

import { useHabitsAllDaysUpdate } from '../queries';
import { MiniOption, MINIS_PICKER_ERROR } from 'common/components/MinisPicker/consts';
import MinisPicker from 'common/components/MinisPicker/MinisPicker';
import { useToast } from 'modules/toast';
import { toastMessages } from 'common/toastMessages';
import { Badge } from 'common/components/Badge';

type DaysList = {
  minis: MiniOption[];
}[];

type Props = {
  level: number;
  pk?: PK;
  mini_pks_per_day: PK[][];
};

const HabitsAssembler = ({ level, pk: seriesPk, mini_pks_per_day }: Props) => {
  const { mutate } = useHabitsAllDaysUpdate();
  const [isDraggable] = React.useState<boolean>(false);
  const [opened, setOpen] = React.useState<number[]>([]);

  const minisList = useMinisList();
  const minisListValues = minisList.data?.values;

  const { toast } = useToast();

  const options = useMappedValues(
    minisListValues,
    ({ pk, title, status, created_at, modified_at, is_b2b, punctation }) => ({
      isB2B: is_b2b,
      punctation: punctation,
      label: title,
      value: pk,
      status,
      created: created_at,
      lastUpdate: modified_at,
    }),
  );

  const minisLookup = React.useMemo(
    () =>
      minisListValues?.reduce<Record<string, Mini>>((acc, mini) => {
        acc[mini.pk] = mini;

        return acc;
      }, {}),
    [minisListValues],
  );

  const objectsDict = Object.fromEntries(
    (minisListValues ?? []).map((obj) => [obj.pk, obj]),
  );

  const daysMinisList = mini_pks_per_day.map((subList, index) => {
    const minis = subList
      .map((key: PK) => objectsDict[key] || null)
      .filter((mini) => mini !== null) as Mini[];
    return {
      day: index + 1,
      minis,
    };
  });

  const initList: DaysList = React.useMemo(
    () =>
      daysMinisList
        ? daysMinisList.map(({ minis }) => ({
            minis: minis.map(
              ({ pk, title, status, created_at, modified_at, punctation }) => ({
                isB2B: minisLookup?.[pk]?.is_b2b,
                punctation: punctation,
                label: title,
                value: pk,
                status: status,
                created: created_at,
                lastUpdate: modified_at,
              }),
            ),
          }))
        : [],
    [minisLookup],
  );

  const handleSave = (days: DaysList) => {
    if (seriesPk) {
      const payload = {
        pk: seriesPk,
        lvl: level,
        minis_pks_per_day: days.map(({ minis }) => ({
          minis: minis.map(({ value }) => value),
        })),
      };
      mutate(payload);
    }
  };

  const handleReorder = (list: DaysList) => {
    handleSave(list);
  };

  const { list: days, getDraggableProps, reinit } = useDnDList({
    initList,
    disabled: !isDraggable,
    onReorder: handleReorder,
  });

  const handleUpdate = (dayToUpdate: number, minisToUpdate: MiniOption[]) => {
    const updatedDays: DaysList = days.map(({ minis }, position) => ({
      minis: position + 1 === dayToUpdate ? minisToUpdate : minis,
    }));
    reinit(updatedDays);
    handleSave(updatedDays);
  };

  const handleError = (error: MINIS_PICKER_ERROR) => {
    if (error === MINIS_PICKER_ERROR.VALUE_STATUS_INACTIVE) {
      toast(toastMessages.ERROR.INACTIVE_DAY_IN_HABITS, 'error');
    } else if (error === MINIS_PICKER_ERROR.VALUE_EMPTY) {
      toast(toastMessages.ERROR.EMPTY_DAY_IN_HABITS, 'error');
    }
  };

  const toggleAllOpen = () => {
    if (opened.length) {
      setOpen([]);
    } else {
      const openedDaysArray: number[] = [];
      days.map((day, index) => {
        openedDaysArray.push(index + 1);
      });
      setOpen(openedDaysArray as number[]);
    }
  };

  const toggleOpen = (day?: number) => {
    if (isNumber(day)) {
      if (opened.includes(day)) {
        setOpen((opened) => opened.filter((openedDay) => openedDay !== day));
      } else {
        setOpen((opened) => [...opened, day]);
      }
    }
  };

  return (
    <Container padding={[0, 0, 5]}>
      <Row>
        <Wrapper margin={[2, 0, 2, 0]}>
          <Button
            size={BUTTON_SIZES.SMALL}
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={() => toggleAllOpen()}
            disabled={isDraggable}
          >
            {opened.length > 0 ? 'Hide all' : 'Show all'}
          </Button>
        </Wrapper>
      </Row>
      {isDraggable
        ? days.map(({ minis }, position) => (
            <SelectedItem
              key={`reorder-day-${position}`}
              {...getDraggableProps({ position })}
            >
              <Label>
                <span>Day {position + 1}</span>
                {minis.length && <Badge>{minis.length}</Badge>}
                {!minis.length && position === 0 && <Badge>Loading</Badge>})
              </Label>
            </SelectedItem>
          ))
        : days.map(({ minis }, position) => {
            const day = position + 1;
            return (
              <SeriesDayContainer key={`day-${day}`} isOpen={opened.includes(day)}>
                <DayHeader onClick={() => toggleOpen(day)}>
                  <span>Day {day}</span>
                  {!!minis.length && <Badge>{minis.length}</Badge>}
                  {!minis.length && position === 0 && <Badge>Loading</Badge>}
                </DayHeader>
                <MinisPicker
                  options={options}
                  defaultValues={minis}
                  onUpdate={(miniOptions: MiniOption[]) => {
                    handleUpdate(day, miniOptions);
                  }}
                  onError={handleError}
                  draggable
                />
              </SeriesDayContainer>
            );
          })}
    </Container>
  );
};

const SeriesDayContainer = styled.div<{ isOpen: boolean }>`
  width: 100%;
  margin: ${({ theme }) => theme.spacer.standard} 0;
  height: ${({ theme, isOpen }) => (isOpen ? 'auto' : theme.spacer.times(8))};
  background-color: ${({ theme }) => theme.color.greySuperLight};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  overflow: hidden;
`;

const DayHeader = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacer.times(8)};
  padding: 0 ${({ theme }) => theme.spacer.times(3)};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  cursor: pointer;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacer.times(4)};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export default HabitsAssembler;
