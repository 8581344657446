import * as React from 'react';

import { CloseModal } from 'common/hooks/useModal';
import { Button, CloseButton, Modal, Wrapper } from 'common/components';
import styled from 'styled-components';
import { BUTTON_VARIANTS } from 'common/consts';

interface CreateDialogProps {
  isOpen: boolean;
  close: CloseModal;
  onClose: (confirmed: boolean) => void;
}

const ConfirmationDialog = ({ isOpen, close, onClose }: CreateDialogProps) => {
  const handleClose = (confirmed: boolean) => {
    close();
    onClose(confirmed);
  };

  return (
    <Modal isOpen={isOpen} close={() => handleClose(false)} autoWidth={true}>
      <Container>
        <ReverseRow>
          <Wrapper margin={[2]}>
            <CloseButton onClose={() => handleClose(false)} />
          </Wrapper>
        </ReverseRow>
        <Title>Delete admin</Title>
        <Subtitle>Are you sure you want to delete this admin?</Subtitle>
        <Divider />
        <Row>
          <Wrapper margin={[2, 5]}>
            <Button
              variant={BUTTON_VARIANTS.SECONDARY}
              onClick={() => handleClose(false)}
            >
              Cancel
            </Button>
          </Wrapper>
          <Wrapper margin={[2, 5]}>
            <Button variant={BUTTON_VARIANTS.PRIMARY} onClick={() => handleClose(true)}>
              Delete
            </Button>
          </Wrapper>
        </Row>
      </Container>
    </Modal>
  );
};

const modalPadding = '16px';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${modalPadding};
  position: relative;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ReverseRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const Divider = styled.div`
  left: ${({ theme }) => theme.spacer.times(3)};
  right: ${({ theme }) => theme.spacer.times(3)};
  height: 1px;
  margin: 0 -${modalPadding};
  background-color: ${({ theme }) => theme.color.blueGrey};
`;

const Title = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const Subtitle = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.normal};
`;

export default ConfirmationDialog;
