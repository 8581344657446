import * as React from 'react';

import { Row, Wrapper } from 'common/components';
import { DATE_FORMATS, PROGRAM_STATUSES } from 'common/consts';
import { formatDate } from 'common/utils';
import { ScenarioCompany } from 'modules/scenarios/consts';

import LinkedCompany from '../LinkedCompany';
import CalendarIndicator from './CalendarIndicator';
import OngoingIndicator from './OngoingIndicator';

interface ScenarioCompaniesProps {
  defaultValues: ScenarioCompany[];
}

const ScenarioCompanies = ({ defaultValues }: ScenarioCompaniesProps) => {
  return (
    <Wrapper margin={[0, 0, 3, 0]}>
      {defaultValues.map((scenarioCompany) => {
        const isOngoing = scenarioCompany.program.status === PROGRAM_STATUSES.STARTED;
        const scenarioDate = formatDate(
          new Date(scenarioCompany.program.startDate),
          DATE_FORMATS.HUMAN,
        );

        return (
          <Row marginBottom="standard" key={scenarioCompany.pk}>
            <Wrapper margin={[0, 1, 0, 0]}>
              <LinkedCompany>{scenarioCompany.name}</LinkedCompany>
            </Wrapper>
            {isOngoing ? (
              <OngoingIndicator>
                Ongoing ({scenarioCompany.program.currentWeek})
              </OngoingIndicator>
            ) : (
              <CalendarIndicator>{scenarioDate}</CalendarIndicator>
            )}
          </Row>
        );
      })}
    </Wrapper>
  );
};

export default ScenarioCompanies;
