import styled from 'styled-components';

import { Link } from 'common/components';
import { resolveSpacersArray } from 'common/styles/utils';

interface StyledLinkProps {
  $isActiveItem: boolean;
}

const StyledLink = styled(Link)<StyledLinkProps>`
  display: flex;
  align-items: center;
  user-select: none;
  padding: ${({ theme }) => resolveSpacersArray(theme, [0, 3])};
  height: ${({ theme }) => theme.spacer.times(5)};
  color: ${({ theme, $isActiveItem }) =>
    $isActiveItem ? theme.color.black : theme.color.pink};

  &:hover {
    color: ${({ theme }) => theme.color.pinkDark};
  }
`;

export default StyledLink;
