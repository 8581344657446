import styled from 'styled-components';

const TopBarContent = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  max-width: ${({ theme }) => `calc(${theme.media.max} + 2 * ${theme.spacer.big})`};
  height: 100%;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacer.times(3)};
`;

export default TopBarContent;
