import * as React from 'react';

import { CollapsibleSection } from 'common/components';
import { PK } from 'common/types';
import { useMinisTags, useMinisTagsUpdate } from 'modules/minis/queries';
import { TAGGING_CONTENT_TYPES } from 'modules/tagging/consts';
import { minisTagsValidationSchema } from 'modules/minis/consts';
import { TagsForm } from 'modules/tagging/components';

type Props = {
  setIsTags: (isTags: boolean) => void;
  pk?: PK;
};

const MinisTagsPicker = ({ pk, setIsTags }: Props) => {
  const { data: miniTagData } = useMinisTags(pk);
  const isTags =
    miniTagData?.category &&
    Array.isArray(miniTagData?.category) &&
    miniTagData?.category.length > 0;

  React.useEffect(() => {
    setIsTags(!!isTags);
  }, [isTags, setIsTags]);

  const { mutate: updateMini, isSuccess, isLoading, isError } = useMinisTagsUpdate();
  const saveTags = (values: any) => {
    const payload = {
      ...(values.amount && { amount: Number(values.amount) }),
      ...(values.amount_unit && { amount_unit: values.amount_unit[0].value }),
      ...(Array.isArray(values.contribution_rating) &&
        values.contribution_rating.length && {
          contribution_rating: values.contribution_rating[0].value,
        }),
      ...(Array.isArray(values.category) &&
        values.category.length && {
          category: values.category.map(({ value }: any) => value),
        }),
      ...(values.frequency && { frequency: Number(values.frequency) }),
      ...(Array.isArray(values.habit) &&
        values.habit.length && { habit: values.habit.map(({ value }: any) => value) }),
      ...(Array.isArray(values.habit_replacement) &&
        values.habit_replacement.length && {
          habit_replacement: values.habit_replacement[0].value,
        }),
      ...(Array.isArray(values.holiday_dependant) &&
        values.holiday_dependant.length && {
          holiday_dependant: values.holiday_dependant[0].value,
        }),
      ...(Array.isArray(values.location_dependent) &&
        values.location_dependent.length && {
          location_dependent: values.location_dependent[0].value,
        }),

      ...(values.min_number_people && {
        min_number_people: values.min_number_people,
      }),
      ...(values.subcategory.length && {
        subcategory: values.subcategory.map(({ value }: any) => value),
      }),
      ...(Array.isArray(values.term) &&
        values.term.length && {
          term: values.term[0].value,
        }),
      ...(Array.isArray(values.weather_condition) &&
        values.weather_condition.length && {
          weather_condition: values.weather_condition[0].value,
        }),
      ...(Array.isArray(values.time_bound) &&
        values.time_bound.length && {
          time_bound: values.time_bound.map(({ value }: any) => value),
        }),
      ...(Array.isArray(values.season) &&
        values.season.length && {
          season: values.season.map(({ value }: any) => value),
        }),
      ...(Array.isArray(values.requisites) &&
        values.requisites.length && {
          requisites: values.requisites.map(({ value }: any) => {
            return value;
          }),
        }),
      pk,
    };
    updateMini(payload as any);
  };

  return (
    <>
      <CollapsibleSection
        title="Add tags"
        saveStatus={{ isSuccess, isLoading, isError }}
        disabled={!pk}
        divider={false}
      >
        <TagsForm
          validationSchema={minisTagsValidationSchema}
          contentType={TAGGING_CONTENT_TYPES.MINI}
          defaultValues={miniTagData}
          onSubmit={saveTags}
          isSubmitting={isLoading}
        />
      </CollapsibleSection>
    </>
  );
};

export default MinisTagsPicker;
