import styled from 'styled-components';

import { resolveSpacersArray } from 'common/styles/utils';
import { ThemeColor } from 'shared/theme';

const Highlight = styled.div<{ $color: ThemeColor; $highlightColor: ThemeColor }>`
  display: flex;
  flex-shrink: 0;
  line-height: 125%;
  align-items: center;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme, $color }) => theme.color[$color]};
  padding: ${({ theme }) => resolveSpacersArray(theme, [1, 2])};
  background-color: ${({ theme, $highlightColor }) => theme.color[$highlightColor]};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
`;

export default Highlight;
