import * as React from 'react';
import { useTheme } from 'styled-components';

import { Svg } from 'common/components';
import { ThemeColor } from 'shared/theme';
import { IconProps } from 'common/types';

interface StarProps extends IconProps {
  color: ThemeColor;
  backgroundColor: ThemeColor;
}

const Star = ({ size = '20px', color, backgroundColor, ...rest }: StarProps) => {
  const theme = useTheme();
  const colorValue = theme.color[color];
  const backgroundColorValue = theme.color[backgroundColor];

  return (
    <Svg viewBox="0 0 20 20" size={size} color={backgroundColor} {...rest}>
      <rect width="20" height="20" rx="10" fill={backgroundColorValue} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99993 4.28577L11.7656 7.86291L15.7142 8.43662L12.8571 11.2218L13.5314 15.1543L9.99993 13.2983L6.4685 15.1543L7.14279 11.2218L4.28564 8.43662L8.23422 7.86291L9.99993 4.28577Z"
        stroke={colorValue}
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Star;
