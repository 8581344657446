import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { ifVariant } from 'common/utils';
import { STATUSES, STATUSES_OPTIONS, STATUS_SIZES, STATUS_VARIANTS } from 'common/consts';
import { ThemeColor, ThemeFontSize, ThemeSpacer } from 'shared/theme';
import { resolveSpacer, resolveSpacersArray } from 'common/styles/utils';
import { Check, Warning } from 'common/icons';

export const ifStatusSize = ifVariant(Object.values(STATUS_SIZES));
export const ifStatusVariant = ifVariant(Object.values(STATUS_VARIANTS));

export interface StatusChipProps {
  status: STATUSES;
  size?: STATUS_SIZES;
  label?: string;
  className?: string;
  children?: React.ReactNode;
}

const StatusChip = ({
  className,
  status,
  label,
  children,
  size = STATUS_SIZES.BIG,
}: StatusChipProps) => {
  const theme = useTheme();
  const statusOption = STATUSES_OPTIONS.find(({ value }) => value === status);
  const isIcon = ifStatusSize<boolean>(size, [false, true]);
  const Icon = ifStatusVariant<JSX.Element>(statusOption?.variant, [
    <Warning
      key="blue-warning-icon"
      color={theme.keys.quaternaryColor}
      hoverColor={theme.keys.quaternaryColor}
    />,
    <Check key="check-icon" />,
    <Warning
      key="pink-warning-icon"
      color={theme.keys.tertiaryColor}
      hoverColor={theme.keys.tertiaryColor}
    />,
    <Warning
      key="yellow-warning-icon"
      color={theme.keys.sixfoldColor}
      hoverColor={theme.keys.sixfoldColor}
    />,
  ]);

  return statusOption ? (
    <div className={className}>
      <Chip size={size} variant={statusOption.variant}>
        {isIcon ? (
          React.cloneElement(Icon, { size: '20px' })
        ) : (
          <Dot variant={statusOption.variant} />
        )}
        <span>{label || children || statusOption.label}</span>
      </Chip>
    </div>
  ) : null;
};

type ChipProps = {
  size: STATUS_SIZES;
  variant: STATUS_VARIANTS;
};

const Chip = styled.div<ChipProps>`
  position: relative;
  display: flex;
  align-items: center;

  border-radius: ${({ theme, size }) =>
    resolveSpacer(theme, ifStatusSize(size, [1.5, 2.5]))};
  height: ${({ theme, size }) =>
    resolveSpacer(
      theme,
      ifStatusSize<ThemeSpacer>(size, [3, 5]),
    )};
  padding: ${({ theme, size }) =>
    resolveSpacersArray(theme, [
      0,
      ifStatusSize<ThemeSpacer>(size, ['standard', 2.5]),
      0,
      ifStatusSize<ThemeSpacer>(size, [2.5, 2.5]),
    ])};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  font-size: ${({ theme, size }) =>
    theme.font.size[ifStatusSize<ThemeFontSize>(size, ['tiny', 'small'])]};
  line-height: ${({ theme, size }) =>
    theme.font.size[ifStatusSize<ThemeFontSize>(size, ['tiny', 'small'])]};
  text-transform: ${({ size }) => ifStatusSize(size, ['inheriet', 'capitalize'])};

  color: ${({ theme, variant }) =>
    theme.color[
      ifStatusVariant<ThemeColor>(
        variant,
        theme.components.statusChip.statusChipColor as ThemeColor[],
      )
    ]};
  background-color: ${({ theme, variant }) =>
    theme.color[
      ifStatusVariant<ThemeColor>(
        variant,
        theme.components.statusChip.statusChipBgColor as ThemeColor[],
      )
    ]};

  span {
    margin-left: ${({ theme, size }) =>
      resolveSpacer(
        theme,
        ifStatusSize<ThemeSpacer>(size, [0, 'standard']),
      )};
  }
`;

type DotProps = {
  variant: STATUS_VARIANTS;
};

const Dot = styled.div<DotProps>`
  position: absolute;
  left: ${({ theme }) => theme.spacer.standard};
  top: ${({ theme }) => theme.spacer.standard};
  width: ${({ theme }) => theme.spacer.standard};
  height: ${({ theme }) => theme.spacer.standard};
  border-radius: 50%;
  background-color: ${({ theme, variant }) =>
    theme.color[
      ifStatusVariant<ThemeColor>(
        variant,
        theme.components.statusChip.statusChipColor as ThemeColor[],
      ) || 'black'
    ]};
`;

const StyledStatusChip = styled(StatusChip)``;

export default StyledStatusChip;
