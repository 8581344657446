import * as Yup from 'yup';

import { Fields, PK, SelectOption } from 'common/types';
import { combineSchemas } from 'common/utils';

export type ProgramFields = {
  start_date: Date;
  scenario_pk: SelectOption<PK>[];
};

export const programFields: Fields<ProgramFields> = {
  start_date: {
    name: 'start_date',
    label: 'Start date',
    schema: Yup.date().required(),
  },
  scenario_pk: {
    name: 'scenario_pk',
    label: 'Scenario',
    schema: Yup.array().required(),
  },
};

export const programSchema = Yup.object().shape<ProgramFields>(
  combineSchemas(programFields),
);
