import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Pen = ({ ...props }: IconProps) => {
  return (
    <Svg viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8.1 3.5.3 11.3c-.2.2-.3.4-.3.7v3c0 .6.4 1 1 1h3c.3 0 .5-.1.7-.3l7.8-7.8-4.4-4.4ZM15.7 3.3l-3-3c-.4-.4-1-.4-1.4 0L9.5 2.1l4.4 4.4 1.8-1.8c.4-.4.4-1 0-1.4Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default Pen;
