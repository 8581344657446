import * as React from 'react';

import { PK } from 'common/types';

import { fields, ScenarioFields } from './consts';
import { useScenarioCreate, useScenarioUpdate } from './queries';

export const useSave = (pk?: PK) => {
  const [created, setCreated] = React.useState(pk);
  const create = useScenarioCreate();
  const update = useScenarioUpdate();

  const isSaving = create.isLoading || update.isLoading;
  const error = create.error || update.error;

  const save = (values: ScenarioFields) => {
    const payload = {
      title: values[fields.title.name],
    };

    if (created) {
      update.mutate({
        ...payload,
        pk: created,
      });
    } else {
      create.mutate(payload, {
        onSuccess: ({ pk }) => {
          setCreated(pk);
        },
      });
    }
  };

  return { save, created, isSaving, error };
};
