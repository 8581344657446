import * as React from 'react';
import styled from 'styled-components';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import _get from 'lodash/get';

import { yupResolver } from '@hookform/resolvers/yup';

import {
  Button,
  CollapsibleSection,
  Wrapper,
  DropdownPicker,
  RemoveButton,
} from 'common/components';
import { PlainInput } from 'common/components/Input';
import { PlainCheckbox } from 'common/components/Checkbox';
import { BUTTON_SIZES } from 'common/consts';
import { PK } from 'common/types';
import { useIngredientsList } from 'modules/ingredients/queries';

import { useRecipeIngredients, useRecipeIngredientsUpdate } from '../../queries';
import {
  fieldNames,
  IngredientOption,
  IngredientsFields,
  IngredientType,
  INGREDIENT_PLACEHOLDER_NAME,
  schema,
} from './consts';

interface IngredientsProps {
  pk?: PK;
}

const Ingredients = ({ pk }: IngredientsProps) => {
  const { data: ingredientsList } = useIngredientsList();
  const { data: recipeIngredients } = useRecipeIngredients(pk);
  const { mutate, isSuccess, isLoading, isError } = useRecipeIngredientsUpdate();
  const saveStatus = { isSuccess, isLoading, isError };

  const defaultValues = ingredientsList &&
    recipeIngredients && {
      ingredients: recipeIngredients.ingredients.map((ingredientRow) => ({
        pk: ingredientRow.ingredient_pk,
        name:
          ingredientsList.values.find(
            (ingredient) => ingredient.pk === ingredientRow.ingredient_pk,
          )?.name_singular || INGREDIENT_PLACEHOLDER_NAME,
        amount: ingredientRow.amount,
        unit: ingredientRow.unit_name,
        isFixed: ingredientRow.fixed_amount,
      })),
    };

  const handleSave: SubmitHandler<IngredientsFields> = (values) => {
    if (pk) {
      const payload = {
        pk,
        ingredients: (values[fieldNames.ingredients] || []).map(
          ({ pk, isFixed, amount }) => ({
            ingredient_pk: pk,
            amount,
            fixed_amount: isFixed || false,
          }),
        ),
      };

      mutate(payload);
    }
  };

  return (
    <CollapsibleSection title="Add ingredients" saveStatus={saveStatus} disabled={!pk}>
      {defaultValues && (
        <IngredientForm
          defaultValues={defaultValues}
          onSubmit={handleSave}
          isLoading={isLoading}
        />
      )}
    </CollapsibleSection>
  );
};

interface FormProps {
  defaultValues: IngredientsFields;
  onSubmit: SubmitHandler<IngredientsFields>;
  isLoading?: boolean;
}

const IngredientForm = ({ defaultValues, onSubmit, isLoading }: FormProps) => {
  const ingredientsList = useIngredientsList();
  const form = useForm<IngredientsFields>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const { control, register, handleSubmit } = form;
  const { fields, remove, append } = useFieldArray({
    control: control,
    name: fieldNames.ingredients,
  });
  const options: IngredientOption[] =
    ingredientsList.data?.values
      .filter(({ pk }) => !fields.some((field) => field.pk === pk))
      .map(({ name_singular, pk, unit }) => ({
        label: name_singular,
        value: pk,
        unit: unit?.name_singular || '',
      })) || [];

  const handleRemove = (index: number) => () => {
    remove(index);
  };

  const handleAppend = (ingredient: IngredientType) => {
    append(ingredient);
  };

  const handlePick = (values: IngredientOption[]) => {
    const { label, unit, value } = values[0];

    handleAppend({ name: label, pk: value, unit, amount: 0 });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper margin={[2, 0, 5]}>
          <Wrapper padding={[2, 3, 3]}>
            {fields.map((field, index) => {
              const { id, name, unit, amount, isFixed, pk } = field;
              const fieldName = `${fieldNames.ingredients}[${index}]`;
              return (
                <FieldsRow key={id}>
                  <Label>
                    {name} {unit && `(${unit})`}
                  </Label>
                  <FieldColumn>
                    <PlainInput
                      isValue
                      name={`${fieldName}.amount`}
                      type="number"
                      step="0.01"
                      label="Amount"
                      error={_get(form.errors, `${fieldName}.amount`)}
                      defaultValue={amount}
                      ref={register()}
                    />

                    <Wrapper padding={[1, 0, 0]}>
                      <PlainCheckbox
                        name={`${fieldName}.isFixed`}
                        label="Fixed amount"
                        defaultChecked={isFixed}
                        ref={register()}
                      />
                    </Wrapper>
                  </FieldColumn>
                  <Wrapper float="left" padding={[1.5, 0]}>
                    <RemoveButton onRemove={handleRemove(index)} />
                  </Wrapper>
                  <input
                    type="hidden"
                    defaultValue={pk}
                    name={`${fieldName}.pk`}
                    ref={register()}
                  />
                  <input
                    type="hidden"
                    defaultValue={name}
                    name={`${fieldName}.name`}
                    ref={register()}
                  />
                  <input
                    type="hidden"
                    defaultValue={unit}
                    name={`${fieldName}.unit`}
                    ref={register()}
                  />
                </FieldsRow>
              );
            })}
            <Wrapper padding={[0, 0, 3]}>
              <DropdownPicker
                label="Search..."
                options={options}
                selected={[]}
                onPick={handlePick}
              />
            </Wrapper>
            <Button type="submit" isLoading={isLoading} size={BUTTON_SIZES.SMALL}>
              Save
            </Button>
          </Wrapper>
        </Wrapper>
      </form>
    </FormProvider>
  );
};

const FieldsRow = styled.fieldset`
  display: flex;
  border: 0;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacer.times(2)};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  padding-top: ${({ theme }) => theme.spacer.times(2)};
  background-color: ${({ theme }) => theme.color.greySuperLight};
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const FieldColumn = styled.div`
  width: 100%;
  max-width: 300px;
  padding-right: ${({ theme }) => theme.spacer.times(1)};
`;

export default Ingredients;
