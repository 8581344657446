import * as React from 'react';

import appUrls from 'base/app/urls';
import { Container, Wrapper, TopBar } from 'common/components';
import {
  ContentProvider,
  ContentList,
  FiltersForm,
  Sort,
  StatusFilter,
  SearchInput,
} from 'templates/content';
import { DetailsSwitch } from 'templates/details';

import { useCollectionsList } from '../queries';
import CollectionDetails from './CollectionDetails';
import CollectionRow from '../components/CollectionRow';
import { useSaveCollectionOrder } from '../hooks';

const fields = {
  search: 'search',
  filter: 'filter',
  sort: 'sort',
};

const CollectionsList = () => {
  const { data, isError } = useCollectionsList();
  const { save: handleReorder } = useSaveCollectionOrder();
  const context = {
    data: data?.values,
    path: {
      list: appUrls.COLLECTIONS.LIST,
      details: appUrls.COLLECTIONS.DETAILS,
    },
  };

  return (
    <Container>
      <ContentProvider {...context}>
        <TopBar title="Collections">
          <FiltersForm
            search={['title', fields.search]}
            filter={['status', fields.filter]}
          >
            <Wrapper margin={[0, 0.5]}>
              <SearchInput name={fields.search} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <StatusFilter name={fields.filter} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <Sort name={fields.sort} />
            </Wrapper>
          </FiltersForm>
        </TopBar>
        <ContentList isError={isError} reorder onReorder={handleReorder}>
          {CollectionRow}
        </ContentList>
        <DetailsSwitch>
          <CollectionDetails />
        </DetailsSwitch>
      </ContentProvider>
    </Container>
  );
};

export default CollectionsList;
