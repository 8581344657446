import * as React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { ThemeColor, ThemeFontSize } from 'shared/theme';

export interface LinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  to?: string;
  size?: ThemeFontSize;
  color?: ThemeColor;
  hoverColor?: ThemeColor;
  underlined?: boolean;
  exact?: boolean;
  newCard?: boolean;
  hoverUnderlined?: boolean;
}

const Link = ({ to, children, newCard, ...props }: LinkProps) => {
  if (!to) {
    return <>{children}</>;
  }

  return to.startsWith('http') ? (
    <a href={to} rel="noreferrer noopener" target={newCard ? '_blank' : ''} {...props}>
      {children}
    </a>
  ) : (
    <NavLink to={to} activeClassName="active" target={newCard ? '_blank' : ''} {...props}>
      {children}
    </NavLink>
  );
};

const StyledLink = styled(Link)`
  color: ${({ theme, color }) => (color ? theme.color[color] : theme.color.black)};
  font-size: ${({ size, theme }) => (size ? theme.font.size[size] : 'inherit')};
  text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};

  &:hover {
    text-decoration: ${({ hoverUnderlined }) => (hoverUnderlined ? 'underline' : 'none')};
    color: ${({ theme, color, hoverColor }) =>
      hoverColor
        ? theme.color[hoverColor]
        : color
        ? theme.color[color]
        : theme.color.black};
  }
`;

export default StyledLink;
