import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { ifVariant } from 'common/utils';
import { BUTTON_SIZES, BUTTON_VARIANTS } from 'common/consts';
import { ThemeButton, ThemeColor, ThemeFontSize, ThemeSpacer } from 'shared/theme';
import { resolveSpacer, resolveSpacersArray } from 'common/styles/utils';
import { Spinner } from 'common/components';

export const ifButtonVariant = ifVariant(Object.values(BUTTON_VARIANTS));
export const ifButtonSize = ifVariant(Object.values(BUTTON_SIZES));

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  variant?: BUTTON_VARIANTS;
  size?: BUTTON_SIZES;
  type?: 'button' | 'submit';
  disabled?: boolean;
  isLoading?: boolean;
  noMinWidth?: boolean;
  spinnerColor?: ThemeColor;
  width?: ThemeSpacer | '100%';
  noNegativeMargin?: boolean;
}

const Button = ({
  disabled,
  isLoading,
  children,
  spinnerColor = 'white',
  /* eslint-disable @typescript-eslint/no-unused-vars */
  noMinWidth,
  noNegativeMargin,
  /* eslint-enable @typescript-eslint/no-unused-vars */
  ...props
}: ButtonProps) => {
  const isDisabled = disabled || isLoading;
  const theme = useTheme();

  return (
    <button {...props} disabled={isDisabled}>
      {isLoading ? (
        <Spinner size={theme.font.size.small} circleColor={spinnerColor} />
      ) : (
        <>{children}</>
      )}
    </button>
  );
};

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: ${({ theme, width }) =>
    width === '100%' ? width : resolveSpacer(theme, width)};
  min-width: ${({ variant, size, noMinWidth, width }) =>
    ifButtonVariant(variant, [
      noMinWidth || width ? 'auto' : ifButtonSize(size, ['200px', '120px']),
      noMinWidth || width ? 'auto' : ifButtonSize(size, ['200px', '120px']),
      noMinWidth || width ? 'auto' : ifButtonSize(size, ['200px', '120px']),
      'auto',
      'auto',
    ])};
  padding: ${({ theme, variant, size }) =>
    resolveSpacersArray(theme, [
      ifButtonVariant(variant, [
        ifButtonSize(size, [2, 1]),
        ifButtonSize(size, [2, 1]),
        ifButtonSize(size, [2, 1]),
        ifButtonSize(size, [2, 1]),
        1,
      ]),
      ifButtonVariant(variant, [3, 3, 3, 3, 1]),
    ])};
  border: none;
  border-radius: ${({ variant, size, theme }) =>
    resolveSpacer(
      theme,
      ifButtonVariant(variant, [
        ifButtonSize(size, [3, 2]),
        ifButtonSize(size, [3, 2]),
        ifButtonSize(size, [3, 2]),
        3,
      ]),
    )};
  margin: ${({ variant, size, noNegativeMargin, theme }) =>
    resolveSpacersArray(theme, [
      ifButtonVariant(variant, [
        0,
        0,
        0,
        ifButtonSize(size, noNegativeMargin ? [0] : [-2, -1]),
        noNegativeMargin ? 0 : -1,
      ]),
      ifButtonVariant(variant, [
        0,
        0,
        0,
        ifButtonSize(size, noNegativeMargin ? [0] : [-3, -2]),
        noNegativeMargin ? 0 : -1,
      ]),
      ifButtonVariant(variant, [
        0,
        0,
        0,
        ifButtonSize(size, noNegativeMargin ? [0] : [-2, -1]),
        noNegativeMargin ? 0 : -1,
      ]),
      ifButtonVariant(variant, [
        0,
        0,
        0,
        ifButtonSize(size, noNegativeMargin ? [0] : [-3, -2]),
        noNegativeMargin ? 0 : -1,
      ]),
    ])};
  background-color: ${({ theme, variant }) =>
    theme.components.button[
      ifButtonVariant<ThemeButton>(variant, [
        'primaryColor',
        'secondaryColor',
        'tertiaryColor',
        'transparent',
        'transparent',
      ])
    ]};
  color: ${({ theme, variant }) =>
    theme.components.button[
      ifButtonVariant<ThemeButton>(variant, [
        'primaryTextColor',
        'secondaryTextColor',
        'primaryTextColor',
        'secondaryTextColor',
        'secondaryTextColor',
      ])
    ]};
  font-family: ${({ theme }) => theme.font.family.sans};
  font-size: ${({ theme, size }) =>
    theme.font.size[ifButtonSize<ThemeFontSize>(size, ['standard', 'small'])]};
  line-height: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  :hover,
  :focus {
    color: ${({ theme, variant }) =>
      theme.components.button[
        ifButtonVariant<ThemeButton>(variant, [
          'primaryTextColor',
          'quaternaryColor',
          'primaryTextColor',
          'quaternaryColor',
          'quaternaryColor',
        ])
      ]};
    background-color: ${({ theme, variant }) =>
      theme.components.button[
        ifButtonVariant<ThemeButton>(variant, [
          'primaryColor',
          'quinaryColor',
          'tertiaryColor',
          'transparent',
          'transparent',
        ])
      ]};
    outline: none;
  }

  :disabled {
    color: ${({ theme, variant }) =>
      theme.components.button[
        ifButtonVariant<ThemeButton>(variant, [
          'primaryDisabledTextColor',
          'secondaryDisabledTextColor',
          'primaryDisabledTextColor',
          'secondaryDisabledTextColor',
          'transparent',
        ])
      ]};
    background-color: ${({ theme, variant }) =>
      theme.components.button[
        ifButtonVariant<ThemeButton>(variant, [
          'primaryDisabledColor',
          'secondaryDisabledColor',
          'tertiaryDisabledColor',
          'transparent',
          'transparent',
        ])
      ]};
    cursor: initial;
  }

  span {
    margin-left: ${({ theme }) => theme.spacer.small};
  }
`;

StyledButton.defaultProps = {
  type: 'button',
  variant: BUTTON_VARIANTS.PRIMARY,
};

export default StyledButton;
