import * as React from 'react';
import styled, { css } from 'styled-components';
import ReactPlayer from 'react-player';

import { Button, ErrorBox } from 'common/components';
import { AZURE_PARAM, BUTTON_VARIANTS } from 'common/consts';
import { Pause, Play, Speaker, SpeakerMute, FullScreen } from 'common/icons';
import { useModal } from 'common/hooks';
import Lightbox from './Lightbox';

const parseTime = (time: number): string => {
  const min = String(Math.floor(time / 60));
  let sec = String(Math.floor(time % 60));
  while (sec.length < 2) {
    sec = Number(sec) < 10 ? '0' + sec : sec + '0';
  }
  return `${min}:${sec}`;
};

type Progress = {
  percent: number;
  seconds: string;
  duration: string;
};

type Props = {
  url: string;
  poster?: string;
  hideControls?: boolean;
  initialState?: {
    muted?: boolean;
    playing?: boolean;
  };
  controls?: {
    progress?: boolean;
    fullscreen?: boolean;
  };
};

const Player: React.FunctionComponent<Props> = ({
  poster,
  url,
  hideControls,
  initialState,
  controls,
}) => {
  const lightbox = useModal();
  const initials = { playing: true, muted: true, ...initialState };
  const ctrls = { progress: true, fullscreen: true, ...controls };
  const [playing, setPlaying] = React.useState(initials.playing);
  const [muted, setMuted] = React.useState(initials.muted);
  const [isReady, setReady] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const [progress, setProgress] = React.useState<Progress>({
    percent: 0,
    seconds: '0:00',
    duration: '0:00',
  });

  const togglePlay = () => {
    setPlaying((playing) => !playing);
  };

  const toggleMute = () => {
    setMuted((muted) => !muted);
  };

  const handleError = () => {
    setError(true);
  };

  const handleReady = () => {
    setReady(true);
  };

  const handleEnd = () => {
    setPlaying(false);
  };

  const requestFullScreen = () => {
    lightbox.open();
    handleEnd();
  };

  const trackProgress = ({
    played,
    playedSeconds,
  }: {
    played: number;
    playedSeconds: number;
  }) => {
    const seconds = parseTime(playedSeconds);
    setProgress((progress) => ({ ...progress, seconds, percent: played }));
  };

  const handleDuration = (duration: number) => {
    const durationTime = parseTime(duration);
    setProgress((progress) => ({ ...progress, duration: durationTime }));
  };

  return (
    <>
      <PlayerContainer hideControls={hideControls}>
        <ReactPlayer
          url={url + AZURE_PARAM}
          light={poster ? poster + AZURE_PARAM : true}
          height="100%"
          controls={false}
          muted={muted}
          playing={playing}
          onReady={handleReady}
          onEnded={handleEnd}
          onError={handleError}
          onProgress={trackProgress}
          onDuration={handleDuration}
          wrapper={PlayerWrapper}
          playIcon={
            <ControlsContainer hideControls={hideControls}>
              <Play title="play" size="16px" color="black" />
            </ControlsContainer>
          }
        />
        {isReady && (
          <ControlsContainer hideControls={hideControls}>
            <Button variant={BUTTON_VARIANTS.ICON} onClick={togglePlay}>
              {playing ? (
                <Pause title="pause" />
              ) : (
                <Play title="play" size="16px" color="black" />
              )}
            </Button>
            {ctrls.progress && (
              <>
                <ProgressTime>
                  <span>{progress.seconds}</span>
                  <span>/</span>
                  <span>{progress.duration}</span>
                </ProgressTime>
                <ProgressBar progress={progress.percent} />
              </>
            )}
            <Button variant={BUTTON_VARIANTS.ICON} onClick={toggleMute}>
              {muted ? <SpeakerMute title="unmute" /> : <Speaker title="mute" />}
            </Button>
            {ctrls.fullscreen && (
              <Button variant={BUTTON_VARIANTS.ICON} onClick={requestFullScreen}>
                <FullScreen title="fullscreen" />
              </Button>
            )}
          </ControlsContainer>
        )}
        <ErrorBox isError={isError} />
      </PlayerContainer>
      <Lightbox isOpen={lightbox.isOpen} close={lightbox.close} url={url} />
    </>
  );
};

const ControlsContainer = styled.div<Pick<Props, 'hideControls'>>`
  position: absolute;
  z-index: 3;
  bottom: ${({ theme }) => theme.spacer.times(3)};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${({ theme }) => theme.spacer.times(7)};
  height: ${({ theme }) => theme.spacer.times(7)};
  padding: 0 ${({ theme }) => theme.spacer.standard};
  border-radius: ${({ theme }) => theme.spacer.times(3.5)};
  background-color: ${({ theme }) => theme.color.white};
  transition: opacity 0.2s ease, transform 0.2s ease;
  opacity: ${({ hideControls }) => (hideControls ? 0 : 1)};
  transform: ${({ hideControls }) =>
    hideControls ? 'translateY(100%)' : 'translateY(0)'};

  ${Button} {
    margin: 0 ${({ theme }) => theme.spacer.standard};
  }
`;

const PlayerContainer = styled.div<Pick<Props, 'hideControls'>>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-top-left-radius: ${({ theme }) => theme.border.radius.standard};
  border-top-right-radius: ${({ theme }) => theme.border.radius.standard};

  ${({ hideControls }) =>
    hideControls &&
    css`
      &:hover {
        ${ControlsContainer} {
          opacity: 1;
          transform: translateY(0%);
        }
      }
    `}

  ${ErrorBox} {
    position: absolute;
    z-index: 3;
    width: auto;
    left: ${({ theme }) => theme.spacer.times(3)};
    right: ${({ theme }) => theme.spacer.times(3)};
    bottom: ${({ theme }) => theme.spacer.times(3)};
  }
`;

const PlayerWrapper = styled.div`
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.blueGreyDark};
  border-top-left-radius: ${({ theme }) => theme.border.radius.standard};
  border-top-right-radius: ${({ theme }) => theme.border.radius.standard};

  .react-player__preview {
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const ProgressTime = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.font.size.tiny};
`;

const ProgressBar = styled.div<{ progress: number }>`
  position: relative;
  width: ${({ theme }) => theme.spacer.times(8)};
  height: 4px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.blueSuperLight};
  margin: 0 ${({ theme }) => theme.spacer.standard};
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.color.blue};
    transform: ${({ progress }) => `translateX(-${-100 * progress + 100}%)`};
  }
`;

export default Player;
