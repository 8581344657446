import { useBusinessStore } from 'common/hooks/useBusiness';
import { STATUSES, STATUSES_OPTIONS } from 'common/consts';

const useCreationDefaults = () => {
  const { isBusiness } = useBusinessStore();
  const defaultValues = {
    status: STATUSES_OPTIONS.filter(({ value }) => value === STATUSES.DRAFT),
    is_b2b: isBusiness ? ('true' as const) : ('false' as const),
  };

  return defaultValues;
};

export default useCreationDefaults;
