import * as React from 'react';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import _get from 'lodash/get';

import { yupResolver } from '@hookform/resolvers/yup';

import { PK } from 'common/types';
import { BUTTON_SIZES, BUTTON_VARIANTS } from 'common/consts';
import {
  Button,
  CollapsibleSection,
  Row,
  Textarea,
  Wrapper,
  RemoveButton,
} from 'common/components';
import { PlainTextarea } from 'common/components/Textarea';

import { useRecipePreparationSteps, useRecipePreparationStepsUpdate } from '../queries';

type PreparationStepsProps = {
  pk?: PK;
};

const fieldNames = {
  steps: 'steps',
  tips: 'tips',
} as const;

interface PreparationStepsFields {
  steps: { value: string }[];
  tips: string;
}

const schema = yup.object().shape({
  [fieldNames.steps]: yup.array(
    yup.object().shape({
      value: yup.string().required().label('Step'),
    }),
  ),
});

const PreparationSteps = ({ pk }: PreparationStepsProps) => {
  const { data } = useRecipePreparationSteps(pk);
  const { mutate, isSuccess, isLoading, isError } = useRecipePreparationStepsUpdate();
  const saveStatus = { isSuccess, isLoading, isError };

  const defaultValues = React.useMemo(
    () =>
      data && {
        [fieldNames.steps]: data.steps.map((step) => ({ value: step })),
        [fieldNames.tips]: data.tips,
      },
    [data],
  );
  const form = useForm<PreparationStepsFields>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { control, handleSubmit, register } = form;
  const fieldArray = useFieldArray({ control: control, name: fieldNames.steps });

  const { reset } = form;
  React.useEffect(() => {
    if (defaultValues) {
      reset(defaultValues, { isDirty: false });
    }
  }, [reset, defaultValues]);

  const handleRemove = (index: number) => () => fieldArray.remove(index);
  const handleAppend = () => fieldArray.append({ value: '' });
  const handleSave: SubmitHandler<PreparationStepsFields> = (values) => {
    if (pk) {
      const payload = {
        pk,
        steps: (values[fieldNames.steps] || []).map(({ value }) => value),
        tips: values[fieldNames.tips],
      };

      mutate(payload);
    }
  };

  return (
    <CollapsibleSection
      title="Add preparation steps"
      saveStatus={saveStatus}
      disabled={!pk}
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <Button
          type="button"
          size={BUTTON_SIZES.SMALL}
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={handleAppend}
        >
          Add
        </Button>

        <Wrapper margin={[2, 0, 5]}>
          <Section>
            <Wrapper padding={[2, 3, 3]}>
              <FormProvider {...form}>
                {fieldArray.fields.map((field, index) => (
                  <Row marginBottom={2} key={field.id}>
                    <TextareaWrapper>
                      <PlainTextarea
                        isValue
                        ref={register()}
                        label={`Step #${index + 1}`}
                        defaultValue={field.value}
                        error={_get(form.errors, `${fieldNames.steps}[${index}].value`)}
                        name={`${fieldNames.steps}[${index}].value`}
                      />
                    </TextareaWrapper>
                    <RemoveButton onRemove={handleRemove(index)} />
                  </Row>
                ))}

                <Wrapper margin={[4, 0, 3]}>
                  <Textarea label="Preparation tips" name={fieldNames.tips} />
                </Wrapper>
              </FormProvider>
              <Button type="submit" isLoading={isLoading} size={BUTTON_SIZES.SMALL}>
                Save
              </Button>
            </Wrapper>
          </Section>
        </Wrapper>
      </form>
    </CollapsibleSection>
  );
};

const TextareaWrapper = styled.div`
  width: 100%;
`;

const Section = styled.div`
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  background-color: ${({ theme }) => theme.color.greySuperLight};
`;

export default PreparationSteps;
