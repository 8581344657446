import create from 'zustand';

type AuthStore = {
  isAuthorized: boolean;
  isValidating: boolean;
  valid: () => void;
  invalid: () => void;
  revalidate: () => void;
};

const useAuthStore = create<AuthStore>((set) => ({
  isAuthorized: false,
  isValidating: true,
  valid: () => set({ isAuthorized: true, isValidating: false }),
  invalid: () => set({ isAuthorized: false, isValidating: false }),
  revalidate: () => set({ isAuthorized: false, isValidating: true }),
}));

export default useAuthStore;
