import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Bin = ({
  color = 'pink',
  hoverColor = 'pink',
  size = '24px',
  ...props
}: IconProps) => {
  return (
    <Svg viewBox="0 0 24 24" color={color} hoverColor={hoverColor} size={size} {...props}>
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g stroke="currentColor" strokeWidth="2">
          <g>
            <g>
              <path
                d="M19 8v12c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2V8M0 4L22 4M7 4L7 0 15 0 15 4M14 10.985L8 16.985M14 16.985L8 10.985"
                transform="translate(-1005 -788) translate(381 768) translate(625 21)"
              />
            </g>
          </g>
        </g>
      </g>
    </Svg>
  );
};

export default Bin;
