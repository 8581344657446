import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Check = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 32 32" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx="16" cy="16" r="16" fill="#00c9a5" />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6.957 16.304L12.522 21.87 23.652 10.739"
        />
      </g>
    </Svg>
  );
};

Check.defaultProps = {
  size: '32px',
};

export default Check;
