import { MediaOptions } from 'common/types';
import { MEDIA_OPTIONS, MEDIA_TYPES } from 'common/consts';

const useMediaOptions = (type: MEDIA_TYPES): MediaOptions => {
  const mediaOptions =
    MEDIA_OPTIONS.find((media) => media.type === type) || MEDIA_OPTIONS[0];
  return mediaOptions;
};

export default useMediaOptions;
