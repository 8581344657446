import * as React from 'react';

import { CONTENT_TYPES } from 'common/consts';
import { PK } from 'common/types';
import { useSeriesList } from 'modules/series/queries';

import StaticPicker from './StaticPicker';

interface PickerProps {
  pk?: PK;
}

const SeriesPicker = ({ pk }: PickerProps) => {
  const { data: seriesListData } = useSeriesList();
  const seriesList = seriesListData?.values;

  return (
    <StaticPicker
      pk={pk}
      sectionTitle="Add series"
      contentList={seriesList ?? []}
      contentType={CONTENT_TYPES.SERIES}
    />
  );
};

export default SeriesPicker;
