import React from 'react';
import { ThemeColor } from 'shared/theme';
import styled from 'styled-components';

type SubtitleProps = {
  label: string;
};

const Subtitle = ({ label }: SubtitleProps) => {
  return <SubtitleStyle $color="greenDark">{label}</SubtitleStyle>;
};

const SubtitleStyle = styled.div<{ $color: ThemeColor }>`
  color: ${({ theme, $color }) => theme.color[$color]};
  font-size: 1.25rem;
`;

export default Subtitle;
