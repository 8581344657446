import * as React from 'react';
import * as Yup from 'yup';
import isAfter from 'date-fns/isAfter';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { Fields, PK } from 'common/types';
import {
  Button,
  CollapsibleSection,
  DatePicker,
  SelectedItem,
  Title,
  Wrapper,
} from 'common/components';
import {
  useFunfactDateAdd,
  useFunfactDateDelete,
  useFunfactDetails,
  useFunfactUpdate,
} from '../queries';
import { BUTTON_SIZES, DATE_FORMATS } from 'common/consts';
import { combineSchemas, formatDate } from 'common/utils';

type ScheduleFields = {
  date: Date;
};

const fields: Fields<ScheduleFields> = {
  date: {
    label: 'Date',
    name: 'date',
    schema: Yup.date().required(),
  },
};

const validationSchema = Yup.object().shape<ScheduleFields>(combineSchemas(fields));

type Props = {
  pk?: PK;
};

const FunfactScheduler = ({ pk: funfactPk }: Props) => {
  const details = useFunfactDetails(funfactPk);
  const remove = useFunfactDateDelete();
  const { isSuccess, isLoading, isError } = useFunfactUpdate();
  const saveStatus = { isSuccess, isLoading, isError };

  const handleDelete = (datePk: PK) => () => {
    if (funfactPk) {
      remove.mutate({ pk: funfactPk, funfact_date_pk: datePk });
    }
  };

  return (
    <CollapsibleSection
      title="Schedule"
      disabled={!funfactPk}
      divider={false}
      saveStatus={saveStatus}
    >
      <Wrapper padding={[0, 0, 3]}>
        <Title size="standard">Dates</Title>
        {details.data?.schedule
          .sort((a, b) => (isAfter(new Date(a.date), new Date(b.date)) ? 1 : -1))
          .map(({ pk, date }, position) => (
            <SelectedItem
              key={pk}
              position={position}
              draggable={false}
              label={formatDate(new Date(date), DATE_FORMATS.HUMAN)}
              onDelete={handleDelete(pk)}
            />
          ))}
        <DateForm pk={funfactPk} />
      </Wrapper>
    </CollapsibleSection>
  );
};

type DateFormProps = {
  pk?: PK;
};

const DateForm = ({ pk: funfactPk }: DateFormProps) => {
  const add = useFunfactDateAdd();
  const form = useForm({
    mode: 'onSubmit',
    resolver: validationSchema && yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = form;

  const handleSave = (values: ScheduleFields) => {
    if (funfactPk) {
      const date = formatDate(values.date, DATE_FORMATS.DATABASE);
      add.mutate(
        { pk: funfactPk, date },
        {
          onSuccess: () => reset(),
        },
      );
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Wrapper padding={[2, 0]}>
          <DatePicker name={fields.date.name} label={fields.date.label} />
        </Wrapper>
        <Button type="submit" size={BUTTON_SIZES.SMALL} isLoading={add.isLoading}>
          Add date
        </Button>
      </form>
    </FormProvider>
  );
};

export default FunfactScheduler;
