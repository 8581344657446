import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';
import { ThemeColor } from 'shared/theme';
import { useTheme } from 'styled-components';

interface PadlockIconProps extends IconProps {
  padlockColor: ThemeColor;
}

const Padlock = ({ padlockColor, ...rest }: PadlockIconProps) => {
  const theme = useTheme();
  const iconColor = theme.color[padlockColor];
  return (
    <Svg viewBox="0 0 20 20" {...rest}>
      <path
        d="m0.30859 10c0-5.3516 4.3398-9.6914 9.6914-9.6914 5.3516 0 9.6914 4.3398 9.6914 9.6914 0 5.3516-4.3398 9.6914-9.6914 9.6914-5.3516 0-9.6914-4.3398-9.6914-9.6914z"
        fill="currentColor"
      />
      <path d="m5.2227 9.3828h9.5546v6.2422h-9.5546v-6.2422z" fill={iconColor} />
      <path
        d="m13.66 13.285c-0.3008 0-0.5469-0.2461-0.5469-0.5469v-5.3125c0-1.7188-1.3985-3.1172-3.1172-3.1172-1.7188 0-3.1172 1.3984-3.1172 3.1172v5.3125c0 0.3008-0.2461 0.5469-0.54688 0.5469s-0.54687-0.2461-0.54687-0.5469v-5.3125c0-2.3203 1.8867-4.207 4.207-4.207 2.3203 0 4.207 1.8867 4.207 4.207v5.3125c0.0078 0.3047-0.2383 0.5469-0.539 0.5469z"
        fill={iconColor}
      />
      <path
        d="m11.039 12.082c0-0.5742-0.4649-1.039-1.0391-1.039-0.57422 0-1.0391 0.4648-1.0391 1.039 0 0.3672 0.1875 0.6875 0.47265 0.8711v0.4492c0 0.3125 0.25391 0.5665 0.56641 0.5665s0.5664-0.254 0.5664-0.5665v-0.4492c0.2852-0.1836 0.4727-0.5078 0.4727-0.8711z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default Padlock;
