import styled from 'styled-components';
import { Img } from 'common/components';

const BlogPlaceholder = styled.div`
  margin: 0 auto;
  max-width: 400px;
  max-height: 300px;
  ${Img} {
    max-height: 300px;
    object-fit: contain;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.color.blueGrayMediumDark};
  }
`;

export default BlogPlaceholder;
