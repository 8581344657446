import * as React from 'react';
import styled from 'styled-components';

import twirl from 'assets/graphics/brand_twirl.svg';
import frieslandBrand from 'assets/graphics/brand-friesland.svg';
import { getIsFriesland } from 'common/utils/config';

type Props = {
  className?: string;
};

const LoginBackground = ({ className }: Props) => {
  const isFriesland = getIsFriesland();
  const loginBackground = isFriesland ? frieslandBrand : twirl;
  return (
    <StyledLoginBackground className={className} loginBackground={loginBackground} />
  );
};

type LoginBackgroundProps = {
  loginBackground: string;
};

const StyledLoginBackground = styled.div<LoginBackgroundProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(${(props) => props.loginBackground});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: max(200vw, 200vh) max(200vh, 1300px);
  background-position: ${({ theme }) => theme.components.login.backgroundPosition};
`;

export default LoginBackground;
