import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const EyeFilled = ({ size = '24px', ...rest }: IconProps) => {
  return (
    <Svg viewBox="0 0 24 18" size={size} {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.806 9.738C23.616 10.074 19.06 18 12 18 4.903 18 .38 10.07.192 9.733a1.497 1.497 0 0 1-.003-1.462C.376 7.933 4.867 0 11.999 0c7.097 0 11.62 7.93 11.809 8.268a1.5 1.5 0 0 1-.002 1.47zM20.726 9C19.555 7.253 16.259 3 12 3 7.719 3 4.437 7.253 3.27 8.998 4.443 10.744 7.738 15 12 15c4.255 0 7.55-4.251 8.727-6z"
        fill="currentColor"
      />
      <circle cx="12" cy="9" r="3" fill="currentColor" />
    </Svg>
  );
};

export default EyeFilled;
