import { useMutation, useQueryClient } from 'react-query';
import Axios from 'axios';

import apiUrls from 'base/api/urls';
import { useDetailsQuery, useListQuery } from 'base/api/hooks';
import { RecipesIngredient } from 'base/api/types';
import { StandardError } from 'base/api/errors';
import { composeUrl } from 'common/utils';
import { CommonVars, PK } from 'common/types';

export const useIngredientsList = () => {
  return useListQuery<RecipesIngredient>(apiUrls.INGREDIENTS.LIST);
};

export const useIngredientsDetails = (pk?: PK) => {
  return useDetailsQuery<RecipesIngredient>({
    queryKey: [apiUrls.INGREDIENTS.DETAILS, { pk }],
    pk,
  });
};

interface IngredientsCreateVars {
  name_plural: string;
  name_singular: string;
  unit_pk?: PK | null;
  category_pk: PK;
}

export const useIngredientsCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<RecipesIngredient, StandardError, IngredientsCreateVars>(
    (data) => Axios.post(apiUrls.INGREDIENTS.LIST, data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.INGREDIENTS.LIST);
        queryClient.setQueryData([apiUrls.INGREDIENTS.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

interface IngredientsUpdateVars extends CommonVars, IngredientsCreateVars {}

export const useIngredientsUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<RecipesIngredient, StandardError, IngredientsUpdateVars>(
    ({ pk, ...data }) =>
      Axios.patch(composeUrl(apiUrls.INGREDIENTS.DETAILS, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.INGREDIENTS.LIST);
        queryClient.setQueryData([apiUrls.INGREDIENTS.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

export const useIngredientsDelete = () => {
  const queryClient = useQueryClient();

  return useMutation<never, StandardError, CommonVars>(
    ({ pk }) => Axios.delete(composeUrl(apiUrls.INGREDIENTS.DETAILS, { params: { pk } })),
    {
      onSuccess: (data, vars) => {
        queryClient.invalidateQueries(apiUrls.INGREDIENTS.LIST);
        queryClient.invalidateQueries(
          composeUrl(apiUrls.INGREDIENTS.DETAILS, {
            params: { pk: vars.pk },
          }),
        );
      },
    },
  );
};
