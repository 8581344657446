import * as React from 'react';

import { useCreationDefaults } from 'common/hooks';
import { getIsFriesland } from 'common/utils/config';
import { translate as t } from 'common/utils/translate';

import { Section } from 'templates/core';
import { Title } from 'common/components';

import MiniMediaPicker from '../components/MiniMediaPicker';
import MinisCore from '../components/MinisCore';
import MinisBlogPicker from 'modules/blogs/components/MinisBlogPicker';

const MinisCreate = () => {
  const isFriesland = getIsFriesland();
  const defaultValues = useCreationDefaults();

  return (
    <>
      <Section>
        <Title size="big">{t("Create mini's")}</Title>
      </Section>
      {isFriesland ? (
        <MinisCore defaultValues={defaultValues} layout="creation">
          <MiniMediaPicker />
          <MinisBlogPicker />
        </MinisCore>
      ) : (
        <MinisCore defaultValues={defaultValues} layout="creation" />
      )}
    </>
  );
};

export default MinisCreate;
