import * as React from 'react';

import { CoreProps } from 'common/types';
import { Input } from 'common/components';
import { CoreFormLayout, Section, SectionsWrapper } from 'templates/core';
import { TitleInput } from 'templates/details';

import { fields, TagsFields, validationSchema } from '../consts';
import { useSave } from '../hooks';

const TagsCore = ({
  pk,
  defaultValues,
  children,
  layout = 'overview',
}: CoreProps<TagsFields>) => {
  const { save, isSaving, error, created } = useSave(pk);

  if (!pk) {
    layout = 'creation';
  }
  return (
    <>
      <CoreFormLayout
        onSubmit={save}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isSubmitting={isSaving}
        error={error}
      >
        <Section>
          {layout === 'overview' && <TitleInput name={fields.name.name} />}
          {layout === 'creation' && (
            <Input name={fields.name.name} label={fields.name.label} />
          )}
        </Section>
      </CoreFormLayout>
      <SectionsWrapper pk={created}>{children}</SectionsWrapper>
    </>
  );
};

export default TagsCore;
