import * as React from 'react';
import { ThemeColor } from 'shared/theme';
import styled from 'styled-components';

interface ProgressBarProps extends React.ComponentPropsWithoutRef<'div'> {
  value: number;
  max: number;
  color: ThemeColor;
  animationDuration: number;
}

const ProgressBar = ({
  value,
  max,
  color,
  animationDuration,
  ...rest
}: ProgressBarProps) => {
  const progressPercentage = (value / max) * 100;

  return (
    <ProgressContainer {...rest}>
      <Progress
        $animationDuration={animationDuration}
        style={{ width: `${progressPercentage}%` }}
        $color={color}
      />
    </ProgressContainer>
  );
};

type ProgressProps = {
  $color: ThemeColor;
  $animationDuration: number;
};

const Progress = styled.div<ProgressProps>`
  transition: width ${({ $animationDuration }) => $animationDuration}ms linear;
  background-color: ${({ theme, $color }) => theme.color[$color]};
  height: 100%;
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 10px;
  overflow: hidden;
  margin: 0;
  padding: 0;
`;

export default ProgressBar;
