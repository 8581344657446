import * as React from 'react';
import FrieslandLogin from './FrieslandLogin';
import { getIsFriesland } from 'common/utils/config';
import LoginEmail from './LoginEmail';

const Login = () => {
  const isFriesland = getIsFriesland();

  if (!isFriesland) {
    return <LoginEmail />;
  } else {
    return <FrieslandLogin />;
  }
};

export default Login;
