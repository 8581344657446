import * as React from 'react';

import { PK } from 'common/types';
import { useMappedValues } from 'common/hooks';
import { CollapsibleSection, RelationSelect } from 'common/components';
import { RelationOption } from 'common/components/RelationSelect';
import { useSeriesFollowup, useSeriesFollowupUpdate, useSeriesList } from '../queries';
import { STATUSES } from 'common/consts';

type Props = {
  pk?: PK;
};

const SeriesFollowup = ({ pk: seriesPk }: Props) => {
  const { mutate, isSuccess, isLoading, isError } = useSeriesFollowupUpdate();
  const saveStatus = { isSuccess, isLoading, isError };
  const { data } = useSeriesFollowup(seriesPk);
  const seriesList = useSeriesList();

  const options = useMappedValues(
    seriesList.data?.values.filter(({ status }) => status === STATUSES.ACTIVE),
    ({ pk, title, status, modified_at, created_at }) => ({
      label: title,
      value: pk,
      status,
      created: created_at,
      lastUpdate: modified_at,
    }),
  );

  const defaults = useMappedValues(
    data?.values,
    ({ pk, title, status, modified_at, created_at }) => ({
      label: title,
      value: pk,
      status,
      created: created_at,
      lastUpdate: modified_at,
    }),
  );

  const handleSave = (values: RelationOption[]) => {
    if (seriesPk) {
      const payload = {
        pk: seriesPk,
        series_pks: values.map(({ value }) => value),
      };
      mutate(payload);
    }
  };

  return (
    <CollapsibleSection
      title="Add followup series"
      saveStatus={saveStatus}
      disabled={!seriesPk}
    >
      <RelationSelect onSave={handleSave} options={options} defaultValues={defaults} />
    </CollapsibleSection>
  );
};

export default SeriesFollowup;
