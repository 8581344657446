import * as Yup from 'yup';

import { Fields } from 'common/types';
import { combineSchemas } from 'common/utils';

export type SetAdminFields = {
  email: string;
};

export const fields: Fields<SetAdminFields> = {
  email: {
    name: 'email',
    label: 'e-mail',
    schema: Yup.string().required().email(),
  },
};

export const validationSchema = Yup.object().shape<SetAdminFields>(
  combineSchemas(fields),
);
