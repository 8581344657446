import * as React from 'react';
import styled from 'styled-components';

import { AZURE_PARAM } from 'common/consts';

import placeholder from 'assets/graphics/placeholder.jpeg';

type Props = {
  src: string;
  alt?: string;
  className?: string;
};

const Img = ({ className, src, alt, ...props }: Props) => {
  return (
    <img
      src={src + AZURE_PARAM}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = placeholder;
      }}
      alt={alt}
      className={className}
      {...props}
    />
  );
};

const StyledImg = styled(Img)`
  display: block;
`;

export default StyledImg;
