import styled from 'styled-components';

const SelectOptionLabel = styled.span`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: ${({ theme }) => theme.spacer.big};
  font-size: ${({ theme }) => theme.font.size.small};
`;

export default SelectOptionLabel;
