import * as React from 'react';

import { Container, Wrapper, TopBar } from 'common/components';
import {
  ContentProvider,
  ContentList,
  FiltersForm,
  Sort,
  SearchInput,
} from 'templates/content';
import { useFactsList } from '../queries';
import { DetailsSwitch } from 'templates/details';
import FactsDetails from './FactsDetails';
import appUrls from 'base/app/urls';

const fields = {
  search: 'search',
  sort: 'sort',
};

const FactsList = () => {
  const { data, isError } = useFactsList();
  const context = {
    data: data?.values.map(({ fact_text, ...fact }) => ({ ...fact, title: fact_text })),
    path: {
      details: appUrls.FACTS.DETAILS,
      list: appUrls.FACTS.LIST,
    },
  };

  return (
    <Container>
      <ContentProvider {...context}>
        <TopBar title="Facts">
          <FiltersForm sort={['title', fields.sort]} search={['title', fields.search]}>
            <Wrapper margin={[0, 0.5]}>
              <SearchInput name={fields.search} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <Sort name={fields.sort} />
            </Wrapper>
          </FiltersForm>
        </TopBar>
        <ContentList isError={isError} />
        <DetailsSwitch>
          <FactsDetails />
        </DetailsSwitch>
      </ContentProvider>
    </Container>
  );
};

export default FactsList;
