import * as React from 'react';
import styled, { css } from 'styled-components';

import { Title } from 'common/components';
import SaveStatus, { SaveStatusProps } from './SaveStatus';
import { Check, Chevron } from 'common/icons';

export type CollapsibleSectionProps = {
  title: string;
  divider?: boolean;
  saveStatus?: SaveStatusProps;
  disabled?: boolean;
  shouldOpen?: boolean;
  children?: React.ReactNode;
  isEmpty?: boolean;
  isSmall?: boolean;
};

const isSectionFilled = (children: React.ReactNode | React.ReactNode[]): boolean => {
  type ChildProps = {
    defaultValues: string[];
  };

  if (React.isValidElement(children)) {
    const { defaultValues } = children.props;
    // Minnis in series, preparation steps in Recipes
    if (Array.isArray(children.props.children) && children.props.children.length) {
      return true;
    }
    // Add recipes, meditations, podcasts, facts, workouts in Minis menu, followup series in Series menu, categories in recipes menu,
    if (Array.isArray(defaultValues) && defaultValues.length) {
      return true;
    }
    //Blog Post
    if (typeof defaultValues === 'object') {
      if (defaultValues.pk && defaultValues.url) {
        return true;
      }
    }
    //add Tags, add ingredients in Recipes menu, Nutritional values
    if (typeof defaultValues === 'object') {
      if (
        (defaultValues.category && defaultValues.category.length) ||
        (defaultValues.ingredients && defaultValues.ingredients.length) ||
        (defaultValues.nutritional_values && defaultValues.nutritional_values.length)
      ) {
        return true;
      }
    }
  }
  if (Array.isArray(children) && children.length > 1 && !React.isValidElement(children)) {
    const lastChild = children[children.length - 1] as React.ReactElement;
    if (lastChild.props) {
      const { defaultValues, children } = lastChild.props;
      if (
        React.isValidElement<ChildProps>(lastChild) &&
        defaultValues !== null &&
        Array.isArray(defaultValues) &&
        defaultValues.length
      ) {
        if (defaultValues && defaultValues[0] !== null) {
          return true;
        }
      }
      // Mini's and Series in Collections menu
      if (
        React.isValidElement<ChildProps>(children) &&
        children.props.defaultValues &&
        children.props.defaultValues.length
      ) {
        return true;
      }
    }
  }
  return false;
};

const CollapsibleSection = ({
  children,
  title,
  saveStatus,
  divider = true,
  disabled = true,
  shouldOpen = false,
  isSmall,
}: CollapsibleSectionProps) => {
  const [isOpen, setOpen] = React.useState(shouldOpen);
  const toggleOpen = () => {
    if (!disabled) {
      setOpen((isOpen) => !isOpen);
    }
  };

  React.useEffect(() => setOpen(shouldOpen), [shouldOpen]);
  return (
    <SectionContainer $isSmall={isSmall} divider={divider}>
      <SectionHeader onClick={toggleOpen}>
        <Title size={isSmall ? 'standard' : undefined}>{title}</Title>
        {saveStatus && <SaveStatus {...saveStatus} />}
        {!isSmall && (
          <CheckContainer>
            {isSectionFilled(children) && <Check size="24px" />}
          </CheckContainer>
        )}
        <ChevronContainer>
          <ChevronButton $isSmall={isSmall} checked={isOpen} />
        </ChevronContainer>
      </SectionHeader>
      <CollapsibleContainer isOpen={isOpen}>{!disabled && children}</CollapsibleContainer>
    </SectionContainer>
  );
};

const SectionContainer = styled.div<{ $isSmall?: boolean; divider?: boolean }>`
  padding: 0
    ${({ theme, $isSmall }) => ($isSmall ? theme.spacer.times(0) : theme.spacer.times(5))};
  ${({ theme, divider }) =>
    divider &&
    css`
      border-top: 1px solid ${theme.themeColor.tertiaryLightColor};
    `};
`;

const ChevronButton = styled(({ ...props }) => <Chevron {...props} />)<{
  checked: boolean;
}>`
  transform: ${({ checked }) => (checked ? 'rotate(90deg);' : 'rotate(0deg);')};
  height: ${({ $isSmall, theme }) =>
    $isSmall ? theme.font.size.small : theme.font.size.medium};
`;

const SectionHeader = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
  padding: ${({ theme }) => theme.spacer.times(3)} 0;
`;

const CollapsibleContainer = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  height: ${({ isOpen }) => (isOpen ? 'auto' : 0)};
`;

const ChevronContainer = styled.div`
  margin-left: auto;
`;

const CheckContainer = styled.div`
  margin-right: auto;
  transform: translate(-10px, 3px);
`;

export default CollapsibleSection;
