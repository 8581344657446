import Axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import apiUrls from 'base/api/urls';
import {
  ListResponse,
  MediaObject,
  Mini,
  Series,
  SeriesFollowup,
  Tags,
} from 'base/api/types';
import { StandardError } from 'base/api/errors';
import { useDetailsQuery, useListQuery } from 'base/api/hooks';
import { DATE_FORMATS, STATUSES } from 'common/consts';
import { CommonVars, DateString, PK } from 'common/types';
import { composeUrl } from 'common/utils';

export const useSeriesList = () => {
  return useListQuery<Series>(apiUrls.SERIES.LIST, { isBusinessSensitive: true });
};

interface SeriesCreateVars {
  title: string;
  description: string;
  short_description: string;
  punctation: number;
  content_goal_pks: PK[];
  start_date: DateString<DATE_FORMATS.DATABASE> | null;
  end_date: DateString<DATE_FORMATS.DATABASE> | null;
  blog_pk?: PK;
  is_challenge: boolean;
  challenge_start_date: DateString<DATE_FORMATS.DATABASE> | null;
}

export const useSeriesCreate = () => {
  const queryClient = useQueryClient();
  return useMutation<Series, StandardError, SeriesCreateVars>(
    (data) => Axios.post(apiUrls.SERIES.LIST, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.SERIES.LIST);
        queryClient.invalidateQueries(apiUrls.CONTENT.LIST);
      },
    },
  );
};

type SeriesUpdateVars = Partial<SeriesCreateVars & CommonVars> & {
  status?: STATUSES;
};
export const useSeriesUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<Series, StandardError, SeriesUpdateVars>(
    ({ pk, ...data }) =>
      Axios.patch(composeUrl(apiUrls.SERIES.DETAILS, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.SERIES.LIST);
        queryClient.setQueryData([apiUrls.SERIES.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

export const useSeriesDetails = (pk?: PK) => {
  return useQuery<Series, StandardError>({
    queryKey: [apiUrls.SERIES.DETAILS, { pk }],
    enabled: !!pk,
  });
};

type SeriesDays = {
  days: {
    day: number;
    minis: Mini[];
  }[];
};

export const useSeriesDays = (pk?: PK) => {
  return useDetailsQuery<SeriesDays>({
    queryKey: [apiUrls.SERIES.DAYS, { pk }],
    pk,
  });
};

interface SeriesMinisVars extends CommonVars {
  days: {
    minis: PK[];
  }[];
}

export const useSeriesAllDaysUpdate = () => {
  return useMutation<unknown, StandardError, SeriesMinisVars>(({ pk, ...data }) =>
    Axios.put(composeUrl(apiUrls.SERIES.DAYS, { params: { pk } }), data),
  );
};

interface SeriesMinisDayVars extends CommonVars {
  day: number;
  minis: PK[];
}

export const useSeriesDayUpdate = () => {
  return useMutation<unknown, StandardError, SeriesMinisDayVars>(({ pk, day, ...data }) =>
    Axios.put(composeUrl(apiUrls.SERIES.DAYS, { params: { pk, day } }), data),
  );
};

type BackupList = {
  backup_minis: Mini[];
};

export const useSeriesBackup = (pk?: PK) => {
  return useDetailsQuery<BackupList>({
    queryKey: [apiUrls.SERIES.BACKUP, { pk }],
    pk,
  });
};

type SeriesMedia = {
  images: MediaObject[];
};

export const useSeriesMedia = (pk?: PK) => {
  return useDetailsQuery<SeriesMedia>({
    queryKey: [apiUrls.SERIES.MEDIA, { pk }],
    pk,
  });
};

interface SeriesMediaVars extends CommonVars {
  media_file_pks: PK[];
}

export const useSeriesMediaUpdate = () => {
  return useMutation<SeriesMedia, StandardError, SeriesMediaVars>(({ pk, ...data }) =>
    Axios.put(composeUrl(apiUrls.SERIES.MEDIA, { params: { pk } }), data),
  );
};

export const useSeriesFollowup = (pk?: PK) => {
  return useDetailsQuery<ListResponse<SeriesFollowup>>({
    queryKey: [apiUrls.SERIES.FOLLOWUP, { pk }],
    pk,
  });
};

interface SeriesFollowupVars extends CommonVars {
  series_pks: PK[];
}

export const useSeriesFollowupUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, StandardError, SeriesFollowupVars>(
    ({ pk, ...data }) =>
      Axios.put(composeUrl(apiUrls.SERIES.FOLLOWUP, { params: { pk } }), data),
    {
      onSuccess: (_, { pk }) => {
        queryClient.invalidateQueries([apiUrls.SERIES.FOLLOWUP, { pk }]);
      },
    },
  );
};

export const useSeriesDuplicate = () => {
  const queryClient = useQueryClient();

  return useMutation<CommonVars, StandardError, CommonVars>(
    ({ pk, ...data }) =>
      Axios.post(composeUrl(apiUrls.SERIES.DUPLICATE, { params: { pk } }), data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([apiUrls.SERIES.LIST]);
      },
    },
  );
};

export const useSeriesTags = (pk?: PK) => {
  return useDetailsQuery<Tags>({ pk, queryKey: [apiUrls.SERIES.TAGS, { pk }] });
};

interface SeriesTagsUpdateVars extends CommonVars {
  payload: Tags;
}

export const useSeriesTagsUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, StandardError, SeriesTagsUpdateVars>(
    ({ pk, ...payload }) =>
      Axios.put(composeUrl(apiUrls.SERIES.TAGS, { params: { pk } }), payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.SERIES.TAGS);
      },
    },
  );
};
