import * as React from 'react';
import { useClickAway } from 'react-use';
import styled, { useTheme } from 'styled-components';

import { BUTTON_VARIANTS } from 'common/consts';
import { Dots } from 'common/icons';

import { Button } from '..';

interface DropdownMenuProps {
  children?: React.ReactNode;
}

const DropdownMenu = ({ children }: DropdownMenuProps) => {
  const theme = useTheme();
  const containerRef = React.useRef(null);
  const [isOpen, setOpen] = React.useState(false);
  useClickAway(containerRef, () => setOpen(false));

  return (
    <DropdownContainer ref={containerRef}>
      <Button variant={BUTTON_VARIANTS.ICON} onClick={() => setOpen((isOpen) => !isOpen)}>
        <Dots title="show more" fill={theme.keys.secondaryColor} />
      </Button>
      {isOpen && (
        <Dropdown>
          {React.Children.map(children, (child) => (
            <ListItem>{child}</ListItem>
          ))}
        </Dropdown>
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
`;

const Dropdown = styled.ul`
  position: absolute;
  list-style: none;
  width: 180px;
  margin: 0;
  right: ${({ theme }) => theme.spacer.times(3.5)};
  padding: ${({ theme }) => theme.spacer.standard};
  border: 1px solid ${({ theme }) => theme.themeColor.tertiarySuperDarkColor};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  background-color: ${({ theme }) => theme.color.white};
  z-index: ${({ theme }) => theme.layer.top};
`;

const ListItem = styled.li`
  padding: ${({ theme }) => theme.spacer.standard};
  font-size: ${({ theme }) => theme.font.size.small};
  border-radius: ${({ theme }) => theme.border.radius.tiny};

  &:hover {
    background-color: ${({ theme }) => theme.themeColor.secondaryLightColor};
  }
`;

export default DropdownMenu;
