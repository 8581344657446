import * as Yup from 'yup';

import { combineSchemas } from 'common/utils';
import { DateString, Fields, PK } from 'common/types';
import { DATE_FORMATS, PROGRAM_STATUSES } from 'common/consts';

export interface ScenarioProgram {
  status: PROGRAM_STATUSES;
  currentWeek: number;
  startDate: DateString<DATE_FORMATS.DATABASE>;
}

export interface ScenarioCompany {
  pk: PK;
  name: string;
  program: ScenarioProgram;
}

export interface ScenarioFields {
  title: string;
  weeks: PK[];
  companies: ScenarioCompany[];
}

export const fields: Fields<ScenarioFields> = {
  title: {
    label: 'Title',
    name: 'title',
    schema: Yup.string().required(),
  },
  weeks: {
    label: 'Weeks',
    name: 'weeks',
  },
  companies: {
    label: 'Companies',
    name: 'companies',
  },
};

export const validationSchema = Yup.object().shape<ScenarioFields>(
  combineSchemas(fields),
);
