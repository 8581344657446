import * as React from 'react';
import styled from 'styled-components';

import { Mini } from 'base/api/types';
import { DateString, PK } from 'common/types';
import { isNumber } from 'common/utils';
import { translate as t } from 'common/utils/translate';
import { useDnDList, useMappedValues } from 'common/hooks';
import { BUTTON_SIZES, BUTTON_VARIANTS, DATE_FORMATS } from 'common/consts';
import {
  Button,
  Container,
  Wrapper,
  Row,
  CollapsibleSection,
  SelectedItem,
} from 'common/components';
import { RelationOption } from 'common/components/RelationSelect';
import { useMinisList } from 'modules/minis/queries';

import { useSeriesDays, useSeriesAllDaysUpdate } from '../queries';
import SeriesDay from './SeriesDay';

export interface MiniOption extends RelationOption {
  created?: DateString<DATE_FORMATS.DATABASE>;
  lastUpdate?: DateString<DATE_FORMATS.DATABASE>;
  punctation: number;
  isB2B?: boolean;
}

type DaysList = {
  minis: MiniOption[];
}[];

type Props = {
  pk?: PK;
};

const SeriesAssembler = ({ pk: seriesPk }: Props) => {
  const { mutate, isSuccess, isLoading, isError } = useSeriesAllDaysUpdate();
  const saveStatus = { isSuccess, isLoading, isError };
  const [isDraggable, setDraggable] = React.useState<boolean>(false);
  const [opened, setOpen] = React.useState<number[]>([]);

  const minisList = useMinisList();
  const minisListValues = minisList.data?.values;
  const options = useMappedValues(
    minisListValues,
    ({ pk, title, status, created_at, modified_at, is_b2b, punctation }) => ({
      isB2B: is_b2b,
      punctation: punctation,
      label: title,
      value: pk,
      status,
      created: created_at,
      lastUpdate: modified_at,
    }),
  );
  const minisLookup = React.useMemo(
    () =>
      minisListValues?.reduce<Record<string, Mini>>((acc, mini) => {
        acc[mini.pk] = mini;

        return acc;
      }, {}),
    [minisListValues],
  );

  const seriesMinis = useSeriesDays(seriesPk);
  const initList: DaysList = React.useMemo(
    () =>
      seriesMinis.data?.days
        ? seriesMinis.data?.days.map(({ minis }) => ({
            minis: minis.map(
              ({ pk, title, status, created_at, modified_at, punctation }) => ({
                isB2B: minisLookup?.[pk]?.is_b2b,
                punctation: punctation,
                label: title,
                value: pk,
                status: status,
                created: created_at,
                lastUpdate: modified_at,
              }),
            ),
          }))
        : [],
    [seriesMinis.data?.days, minisLookup],
  );

  const handleSave = (days: DaysList) => {
    if (seriesPk) {
      const payload = {
        pk: seriesPk,
        days: days.map(({ minis }) => ({
          minis: minis.map(({ value }) => value),
        })),
      };
      mutate(payload);
    }
  };

  const handleReorder = (list: DaysList) => {
    handleSave(list);
  };

  const { list: days, getDraggableProps, addItems, reinit } = useDnDList({
    initList,
    disabled: !isDraggable,
    onReorder: handleReorder,
  });

  const handleUpdate = (dayToUpdate: number, minisToUpdate: MiniOption[]) => {
    const updatedDays: DaysList = days.map(({ minis }, position) => ({
      minis: position + 1 === dayToUpdate ? minisToUpdate : minis,
    }));
    reinit(updatedDays);
    handleSave(updatedDays);
  };

  const toggleOpen = (day?: number) => {
    if (isNumber(day)) {
      if (opened.includes(day)) {
        setOpen((opened) => opened.filter((openedDay) => openedDay !== day));
      } else {
        setOpen((opened) => [...opened, day]);
      }
    } else {
      if (opened.length > 0 || !seriesMinis.data) {
        setOpen([]);
      } else {
        setOpen(seriesMinis.data.days.map(({ day }) => day));
      }
    }
  };

  const toggleReorder = () => {
    setDraggable((isDraggable) => !isDraggable);
    setOpen([]);
  };

  const handleAddDay = (dayToDuplicate?: number) => {
    const newDay = days.length + 1;
    if (isNumber(dayToDuplicate)) {
      const duplicate = days.find((_, position) => position + 1 === dayToDuplicate);
      if (duplicate) {
        const updatedDays = [...days, { day: newDay, minis: duplicate.minis }];
        reinit(updatedDays);
        handleSave(updatedDays);
      }
    } else {
      addItems([{ minis: [] }]);
    }
  };

  const handleRemoveDay = (dayToRemove: number) => {
    setOpen((opened) =>
      opened
        .filter((day) => day !== dayToRemove)
        .map((day) => (day > dayToRemove ? day - 1 : day)),
    );
    const updatedDays = days
      .filter((_, position) => position + 1 !== dayToRemove)
      .map((item, index) => ({ ...item, day: index + 1 }));
    reinit(updatedDays);
    handleSave(updatedDays);
  };

  return (
    <CollapsibleSection
      title={t("Add mini's")}
      saveStatus={saveStatus}
      disabled={!seriesPk}
    >
      <Container padding={[0, 0, 5]}>
        <Row>
          <Wrapper margin={[0, 1, 2, 0]}>
            <Button size={BUTTON_SIZES.SMALL} onClick={() => handleAddDay()}>
              Add day
            </Button>
          </Wrapper>
          <Wrapper margin={[0, 0, 2, 0]}>
            <Button
              size={BUTTON_SIZES.SMALL}
              variant={BUTTON_VARIANTS.SECONDARY}
              onClick={() => toggleOpen()}
              disabled={isDraggable}
            >
              {opened.length > 0 ? 'Hide all' : 'Show all'}
            </Button>
          </Wrapper>
          <Wrapper margin={[0, 0, 2, 'auto']}>
            <Button
              size={BUTTON_SIZES.SMALL}
              variant={BUTTON_VARIANTS.SECONDARY}
              onClick={() => toggleReorder()}
            >
              {isDraggable ? 'Done' : 'Reorder days'}
            </Button>
          </Wrapper>
        </Row>
        {isDraggable
          ? days.map(({ minis }, position) => (
              <SelectedItem
                key={`reorder-day-${position}`}
                {...getDraggableProps({ position })}
              >
                <Label>
                  <span>Day {position + 1}</span>
                  <Badge>{minis.length}</Badge>
                </Label>
              </SelectedItem>
            ))
          : days.map(({ minis }, position) => {
              const day = position + 1;
              return (
                <SeriesDayContainer key={`day-${day}`} isOpen={opened.includes(day)}>
                  <DayHeader onClick={() => toggleOpen(day)}>
                    <span>Day {day}</span>
                    <Badge>{minis.length}</Badge>
                  </DayHeader>
                  <SeriesDay
                    day={day}
                    options={options}
                    defaultValues={minis}
                    onUpdate={handleUpdate}
                  />
                  <Row>
                    <Wrapper margin={[0, 1, 3, 3]}>
                      <Button
                        size={BUTTON_SIZES.SMALL}
                        onClick={() => handleAddDay(day)}
                        disabled={minis.length === 0}
                      >
                        Duplicate day
                      </Button>
                    </Wrapper>
                    <Wrapper margin={[0, 0, 3, 0]}>
                      <Button
                        size={BUTTON_SIZES.SMALL}
                        variant={BUTTON_VARIANTS.SECONDARY}
                        onClick={() => handleRemoveDay(day)}
                      >
                        Remove day
                      </Button>
                    </Wrapper>
                  </Row>
                </SeriesDayContainer>
              );
            })}
      </Container>
    </CollapsibleSection>
  );
};

const SeriesDayContainer = styled.div<{ isOpen: boolean }>`
  width: 100%;
  margin: ${({ theme }) => theme.spacer.standard} 0;
  height: ${({ theme, isOpen }) => (isOpen ? 'auto' : theme.spacer.times(8))};
  background-color: ${({ theme }) => theme.color.greySuperLight};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  overflow: hidden;
`;

const DayHeader = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacer.times(8)};
  padding: 0 ${({ theme }) => theme.spacer.times(3)};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  cursor: pointer;
`;

const Badge = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 ${({ theme }) => theme.spacer.standard};
  padding: 0 ${({ theme }) => theme.spacer.times(1.5)};
  border-radius: ${({ theme }) => theme.spacer.times(1.5)};
  height: ${({ theme }) => theme.spacer.times(3)};
  line-height: ${({ theme }) => theme.spacer.times(3.3)};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.greenDark};
  background-color: ${({ theme }) => theme.color.greenSuperLight};
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacer.times(4)};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export default SeriesAssembler;
