import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Warning = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <g id="WEB-|-Visual" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ic_error_24px-copy" fill="currentColor" fillRule="nonzero">
          <path
            d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M10,15 C9.44771525,15 9,14.5522847 9,14 C9,13.4477153 9.44771525,13 10,13 C10.5522847,13 11,13.4477153 11,14 C11,14.5522847 10.5522847,15 10,15 Z M10,11 C9.44771525,11 9,10.5522847 9,10 L9,6 C9,5.44771525 9.44771525,5 10,5 C10.5522847,5 11,5.44771525 11,6 L11,10 C11,10.5522847 10.5522847,11 10,11 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </Svg>
  );
};

Warning.defaultProps = {
  size: '32px',
  color: 'pink',
  hoverColor: 'pink',
};

export default Warning;
