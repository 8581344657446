import styled from 'styled-components';

import { ifStatusSize } from 'common/components/StatusChip';
import { STATUS_SIZES } from 'common/consts';
import { resolveSpacer, resolveSpacersArray } from 'common/styles/utils';
import { ThemeFontSize, ThemeSpacer } from 'shared/theme';

type ChipProps = {
  size: STATUS_SIZES;
};

const Chip = styled.div<ChipProps>`
  position: relative;
  display: flex;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.color.blueGrey};
  border-radius: ${({ theme, size }) =>
    resolveSpacer(theme, ifStatusSize(size, [1.5, 2.5]))};
  height: ${({ theme, size }) =>
    resolveSpacer(
      theme,
      ifStatusSize<ThemeSpacer>(size, [3, 5]),
    )};
  padding: ${({ theme }) => resolveSpacersArray(theme, [0, 0.5])};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  font-size: ${({ theme, size }) =>
    theme.font.size[ifStatusSize<ThemeFontSize>(size, ['tiny', 'small'])]};
  line-height: ${({ theme, size }) =>
    theme.font.size[ifStatusSize<ThemeFontSize>(size, ['tiny', 'small'])]};
  text-transform: ${({ size }) => ifStatusSize(size, ['inheriet', 'capitalize'])};
  margin: ${({ theme }) => resolveSpacersArray(theme, [0, 1, 0, 0])};
`;

export default Chip;
