import * as Yup from 'yup';

import { RelationOption } from 'common/components/RelationSelect';
import { PK } from 'common/types';

export interface IngredientOption extends RelationOption {
  unit?: string;
}

export const fieldNames = {
  ingredients: 'ingredients',
} as const;

export interface IngredientType {
  pk: PK;
  name: string;
  amount: number;
  unit?: string;
  isFixed?: boolean;
}

export interface IngredientsFields {
  ingredients: IngredientType[];
}

export const schema = Yup.object().shape({
  [fieldNames.ingredients]: Yup.array(
    Yup.object().shape({
      amount: Yup.number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .required()
        .moreThan(0)
        .label('Amount'),
    }),
  ),
});

export const INGREDIENT_PLACEHOLDER_NAME = '[...]';
