import * as React from 'react';
import { PK } from 'common/types';

type Props = {
  pk?: PK | null;
  children?: React.ReactNode;
};

const SectionsWrapper = ({ pk, children }: Props) => {
  return (
    <>
      {React.Children.map(children, (child) =>
        React.isValidElement(child) ? React.cloneElement(child, { pk }) : child,
      )}
    </>
  );
};

export default SectionsWrapper;
