import { useMutation, useQuery, useQueryClient } from 'react-query';
import Axios from 'axios';

import apiUrls from 'base/api/urls';
import {
  Fact,
  ListResponse,
  MediaObject,
  Meditation,
  Mini,
  MinisRecipe,
  Podcast,
  Tags,
  Workout,
} from 'base/api/types';
import { useDetailsQuery, useListQuery } from 'base/api/hooks';
import { StandardError } from 'base/api/errors';
import { composeUrl } from 'common/utils';
import { STATUSES } from 'common/consts';
import { CommonVars, PK } from 'common/types';

const LIST_STALE_TIME = 45 * 1000;

export const useMinisList = () => {
  return useListQuery<Mini>(apiUrls.MINIS.LIST, {
    isBusinessSensitive: true,
    staleTime: LIST_STALE_TIME,
  });
};

export const useMiniDetails = (pk?: PK) => {
  return useQuery<Mini, StandardError>([apiUrls.MINIS.DETAILS, { pk }]);
};

interface MiniCreateVars {
  title: string;
  description: string;
  short_description: string;
  punctation: number;
  content_goal_pks: PK[];
  is_b2b?: boolean;
  blog_pk?: PK;
}

export const useMinisCreate = () => {
  const queryClient = useQueryClient();
  return useMutation<Mini, StandardError, MiniCreateVars>(
    (data) => Axios.post(apiUrls.MINIS.LIST, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.MINIS.LIST);
        queryClient.invalidateQueries(apiUrls.CONTENT.LIST);
      },
    },
  );
};

type MinisUpdateVars = Partial<MiniCreateVars & CommonVars> & {
  status?: STATUSES;
};

export const useMinisUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<Mini, StandardError, MinisUpdateVars>(
    ({ pk, ...data }) =>
      Axios.patch(composeUrl(apiUrls.MINIS.DETAILS, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.MINIS.LIST);
        queryClient.setQueryData([apiUrls.MINIS.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

type MinisMedia = {
  audio: MediaObject[];
  images: MediaObject[];
  video: MediaObject[];
};

export const useMinisMedia = (pk?: PK) => {
  return useDetailsQuery<MinisMedia>({
    queryKey: [apiUrls.MINIS.MEDIA, { pk }],
    pk,
  });
};

interface MinisMediaVars extends CommonVars {
  media_file_pks: PK[];
}

export const useMinisMediaUpdate = () => {
  return useMutation<unknown, StandardError, MinisMediaVars>(({ pk, ...data }) =>
    Axios.put(composeUrl(apiUrls.MINIS.MEDIA, { params: { pk } }), data),
  );
};

export const useMinisFacts = (pk?: PK) => {
  return useDetailsQuery<ListResponse<Fact>>({
    queryKey: [apiUrls.MINIS.FACTS, { pk }],
    pk,
  });
};

interface MinisFactVars extends CommonVars {
  fact_pks: PK[];
}

export const useMinisFactsUpdate = () => {
  return useMutation<unknown, StandardError, MinisFactVars>(({ pk, ...data }) =>
    Axios.put(composeUrl(apiUrls.MINIS.FACTS, { params: { pk } }), data),
  );
};

export const useMinisMeditations = (pk?: PK) => {
  return useDetailsQuery<ListResponse<Meditation>>({
    queryKey: [apiUrls.MINIS.MEDITATIONS, { pk }],
    pk,
  });
};

export const useMinisPodcasts = (pk?: PK) => {
  return useDetailsQuery<ListResponse<Podcast>>({
    queryKey: [apiUrls.MINIS.PODCASTS, { pk }],
    pk,
  });
};

interface MinisMeditationsVars extends CommonVars {
  media_content_pks: PK[];
}

interface MinisPodcastsVars extends CommonVars {
  media_content_pks: PK[];
}

export const useMinisMeditationsUpdate = () => {
  return useMutation<unknown, StandardError, MinisMeditationsVars>(({ pk, ...data }) =>
    Axios.put(composeUrl(apiUrls.MINIS.MEDITATIONS, { params: { pk } }), data),
  );
};

export const useMinisPodcastsUpdate = () => {
  return useMutation<unknown, StandardError, MinisPodcastsVars>(({ pk, ...data }) =>
    Axios.put(composeUrl(apiUrls.MINIS.PODCASTS, { params: { pk } }), data),
  );
};

export const useMinisRecipes = (pk?: PK) => {
  return useDetailsQuery<ListResponse<MinisRecipe>>({
    queryKey: [apiUrls.MINIS.RECIPES, { pk }],
    pk,
  });
};

interface MinisRecipesVars extends CommonVars {
  recipe_pks: PK[];
}

export const useMinisRecipesUpdate = () => {
  return useMutation<ListResponse<MinisRecipe>, StandardError, MinisRecipesVars>(
    ({ pk, ...data }) =>
      Axios.put(composeUrl(apiUrls.MINIS.RECIPES, { params: { pk } }), data),
  );
};

export const useMinisWorkouts = (pk?: PK) => {
  return useDetailsQuery<ListResponse<Workout>>({
    queryKey: [apiUrls.MINIS.WORKOUTS, { pk }],
    pk,
  });
};

interface MinisWorkoutsVars extends CommonVars {
  workout_pks: PK[];
}

export const useMinisWorkoutsUpdate = () => {
  return useMutation<unknown, StandardError, MinisWorkoutsVars>(({ pk, ...data }) =>
    Axios.put(composeUrl(apiUrls.MINIS.WORKOUTS, { params: { pk } }), data),
  );
};

export const useMinisTags = (pk?: PK) => {
  return useDetailsQuery<Tags>({ pk, queryKey: [apiUrls.MINIS.TAGS, { pk }] });
};

interface MinisTagsUpdateVars extends CommonVars {
  payload: Tags;
}

export const useMinisTagsUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, StandardError, MinisTagsUpdateVars>(
    ({ pk, ...payload }) =>
      Axios.put(composeUrl(apiUrls.MINIS.TAGS, { params: { pk } }), payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.MINIS.TAGS);
      },
    },
  );
};
