import * as React from 'react';
import styled from 'styled-components';

import { Arrow, CircleCross } from 'common/icons';
import { Link, Title } from 'common/components';

const GenericErrorMessage = () => {
  return (
    <>
      <Title center size="big" marginBottom="big">
        Something went wrong
      </Title>
      <IconContainer>
        <CircleCross hoverColor="greenSuperDark" color="greenSuperDark" size="56px" />
      </IconContainer>
      <StyledLink underlined to="/login">
        <Arrow $size="7px" $margin={[0.5, 0, 0, 0]} />
        <StyledTitle weight="normal" size="standard">
          Please try again
        </StyledTitle>
      </StyledLink>
    </>
  );
};
const IconContainer = styled.div`
  width: ${({ theme }) => theme.spacer.times(7)};
  margin: 0 auto;
`;

const StyledLink = styled(Link)`
  margin-top: ${({ theme }) => theme.spacer.times(3)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled(Title)`
  margin-left: ${({ theme }) => theme.spacer.times(2)};
`;

export default GenericErrorMessage;
