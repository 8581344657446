import styled, { css } from 'styled-components';

type Props = {
  compact?: boolean;
};

const GridItem = styled.div<Props>`
  ${({ compact }) =>
    !compact &&
    css`
      grid-column: 1 / 3;
    `};
`;

export default GridItem;
