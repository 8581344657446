import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const CircleAdd = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M12 0a12 12 0 1 0 12 12A12.035 12.035 0 0 0 12 0Zm6 13h-5v5h-2v-5H6v-2h5V6h2v5h5v2Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default CircleAdd;
