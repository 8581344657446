import * as React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';

import { PK } from 'common/types';
import { CollapsibleSection } from 'common/components';
import { getMetadata, parseBlogContent } from 'common/utils';
import { useBlogDetails } from 'modules/blogs/queries';
import {
  BlogFields,
  fields,
  CLEAR_FORM_VALUES,
  validationSchema,
} from 'modules/blogs/consts';
import BlogPickerForm from './BlogPickerForm';
import { SaveStatusProps } from 'common/components/SaveStatus';
import { BlogQueryVars } from '../types';

type Props = {
  blog_pk?: PK;
  pk?: PK;
  onBlogUpdate: (values: BlogQueryVars) => void;
  onSave: (blogValues: BlogFields) => void;
  saveStatus: SaveStatusProps;
};

const BlogPicker = ({ pk, onBlogUpdate, onSave, saveStatus, blog_pk }: Props) => {
  const [blogHtml, setBlogHtml] = React.useState('');

  const { data: blogData } = useBlogDetails(blog_pk);

  const form = useForm<BlogFields>({
    resolver: yupResolver(validationSchema),
  });
  const { setValue, control, handleSubmit, reset: formReset } = form;

  const blogUrl = useWatch<BlogFields['url']>({ control, name: fields.url.name });
  const imageUrl = useWatch<BlogFields['image_url']>({
    control,
    name: fields.image_url.name,
  });

  React.useEffect(() => {
    if (blogData) {
      getMetadata(blogData.url).then((res) => {
        const blogContent = res?.content && parseBlogContent(res.content);
        setBlogHtml(blogContent || '');
      });
    }
  }, [blogData, setValue]);

  const handleCheckClick = () => {
    if (blogUrl) {
      getMetadata(blogUrl)
        .then((res) => {
          const blogContent = res?.content && parseBlogContent(res.content);
          setBlogHtml(blogContent || '');
          setValue(fields.title.name, res.title);
          setValue(fields.description.name, res.description);
          setValue(fields.image_url.name, res.image_url);
        })
        .catch(() => {
          formReset(CLEAR_FORM_VALUES);
        });
    }
  };

  const isBlogAssigned = !!blog_pk;
  const disabled = !blogUrl && !blogData;

  const handleSave: SubmitHandler<BlogFields> = (values) => {
    isBlogAssigned ? onBlogUpdate({ pk: blog_pk, ...values }) : onSave(values);
  };
  return (
    <FormProvider {...form}>
      <CollapsibleSection title="Add blog" saveStatus={saveStatus} disabled={!pk}>
        {(blogData || !isBlogAssigned) && (
          <BlogPickerForm
            onSubmit={handleSubmit(handleSave)}
            handleCheckClick={handleCheckClick}
            isSubmitting={saveStatus.isLoading}
            imageUrl={imageUrl}
            defaultValues={blogData}
            disabled={disabled}
            blogHtml={blogHtml}
            isError={saveStatus.isError}
            pk={pk}
          />
        )}
      </CollapsibleSection>
    </FormProvider>
  );
};

export default BlogPicker;
