import { useMemo } from 'react';
import { RelationOption } from 'common/components/RelationSelect';

const useMappedValues = function <TItem, TResult = RelationOption>(
  list: TItem[] | null | undefined,
  callback: (value: TItem, index: number) => TResult,
): TResult[] {
  return useMemo<TResult[]>(() => {
    const result: TResult[] = [];
    if (list) {
      return list.map(callback);
    }
    return result;
  }, [list]);
};

export default useMappedValues;
