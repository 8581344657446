import * as React from 'react';
import { useParams } from 'react-router';

import { RecipesIngredient } from 'base/api/types';
import { PK } from 'common/types';
import { DetailsCard, DetailsSuspense } from 'templates/details';

import IngredientsCore from '../components/IngredientsCore';
import { fields, IngredientsFields } from '../consts';
import { useIngredientsDetails } from '../queries';

const IngredientsDetails = () => {
  const { pk } = useParams<{ pk: PK }>();
  const { data, ...query } = useIngredientsDetails(pk);
  const transformIngredients = (raw: RecipesIngredient): IngredientsFields => ({
    [fields.name_singular.name]: raw.name_singular,
    [fields.name_plural.name]: raw.name_plural,
    [fields.unit.name]: raw.unit
      ? [
          {
            label: raw.unit.name_singular,
            value: raw.unit.pk,
          },
        ]
      : [],
    [fields.category.name]: [
      {
        label: raw.category.name,
        value: raw.category.pk,
      },
    ],
  });
  const defaultValues = data && transformIngredients(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading} error={query.error}>
        <IngredientsCore pk={pk} key={pk} defaultValues={defaultValues} />
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default IngredientsDetails;
