import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Collection, CollectionHandler } from 'base/api/types';
import { PK, SelectOption } from 'common/types';
import {
  getCategoryOptions,
  HANDLER_TYPE_OPTIONS,
  HANDLER_TYPES,
  STATUSES_OPTIONS,
} from 'common/consts';
import { DetailsCard, DetailsSuspense } from 'templates/details';

import CollectionsCore from '../components/CollectionsCore';
import MinisPicker from '../components/MinisPicker';
import SeriesPicker from '../components/SeriesPicker';
import { CollectionFields } from '../consts';
import { useCollectionDetails } from '../queries';
import { getIsFriesland } from 'common/utils/config';

const transformCollection = (raw: Collection): CollectionFields => {
  const handlerTypeValue =
    raw.handler === null ? HANDLER_TYPES.STATIC : HANDLER_TYPES.DYNAMIC;

  const isFriesland = getIsFriesland();
  const CATEGORY_OPTIONS = getCategoryOptions(isFriesland);

  const transformHandler = (
    handler: CollectionHandler,
  ): SelectOption<CollectionHandler>[] => [
    {
      label: handler,
      value: handler,
    },
  ];

  return {
    title: raw.title,
    status: STATUSES_OPTIONS.filter(({ value }) => value === raw.status),
    color: CATEGORY_OPTIONS.filter(({ value }) => value === raw.color),
    handlerType: HANDLER_TYPE_OPTIONS.filter(({ value }) => value == handlerTypeValue),
    handler: raw.handler && transformHandler(raw.handler),
    icon: raw.icon,
  };
};

const CollectionDetails = () => {
  const { pk } = useParams<{ pk?: PK }>();
  const { data, ...query } = useCollectionDetails(pk);
  const defaultValues = data && transformCollection(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading} error={query.error}>
        <CollectionsCore defaultValues={defaultValues} pk={pk} key={pk}>
          <MinisPicker />
          <SeriesPicker />
        </CollectionsCore>
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default CollectionDetails;
