import * as React from 'react';

import { PrimaryKey } from 'common/components';
import { TITLE_VARIANTS } from 'common/consts';
import { ContentRow } from 'templates/content';
import { PK } from 'common/types';
import { Text, TextCell, SectionRow, SectionColumn } from 'modules/recipe-subsections';
import { ContentRowProps } from 'templates/content/components/ContentRow';

import UnitsMenu from './UnitsMenu';

export interface RecipesUnitRow {
  pk: PK;
  title: string;
  name_plural: string;
  isDetailsView?: boolean;
}

const UnitsContentRow: React.FC<ContentRowProps<RecipesUnitRow>> = (props) => {
  const { index } = props;
  const { pk, title, name_plural } = props.data[index];
  const unitData = props.data;

  return (
    <ContentRow {...props} data={unitData} menuComponent={UnitsMenu}>
      <SectionColumn>
        <SectionRow>
          <TextCell>
            <Text size="standard">{title}</Text>
          </TextCell>
          <TextCell>
            <Text size="standard" variant={TITLE_VARIANTS.SUBTITLE}>
              {name_plural}
            </Text>
          </TextCell>
        </SectionRow>
        <PrimaryKey pk={pk} />
      </SectionColumn>
    </ContentRow>
  );
};

export default UnitsContentRow;
