import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';

import { DateString } from 'common/types';
import { useAutoSize } from 'common/hooks';
import { DATE_FORMATS } from 'common/consts';
import { resolveSpacersArray } from 'common/styles/utils';
import { Cross } from 'common/icons';
import { DateChip, Wrapper } from 'common/components';
import { useContentContext } from 'templates/content';

type Props = {
  created?: DateString<DATE_FORMATS.DATABASE> | null;
  lastUpdate?: DateString<DATE_FORMATS.DATABASE> | null;
  children?: React.ReactNode;
};

const DetailsCard = ({ created, lastUpdate, children }: Props) => {
  const theme = useTheme();
  const { path } = useContentContext();
  const { height } = useAutoSize([23 * 8, 0]);

  return (
    <DetailsContainer height={height}>
      <DetailsCardContainer>
        <DetailsHeader>
          <DateChip label="created" date={created} />
          <DateChip label="last update" date={lastUpdate} />
          <Wrapper float="left">
            {path ? (
              <Link to={path.list}>
                <CloseButtonWrapper>
                  <Cross
                    size="16px"
                    color={theme.keys.secondaryColor}
                    hoverColor={theme.keys.secondaryColor}
                  />
                </CloseButtonWrapper>
              </Link>
            ) : null}
          </Wrapper>
        </DetailsHeader>
        {children}
      </DetailsCardContainer>
    </DetailsContainer>
  );
};

const DetailsContainer = styled.div<{ height: number }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  min-height: ${({ height }) => `${height}px`};
  padding: ${({ theme }) => resolveSpacersArray(theme, [3, 4, 3, 2])};
  overflow: auto;
`;

const DetailsCardContainer = styled.div`
  position: relative;
  min-height: 100%;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
`;

const DetailsHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => resolveSpacersArray(theme, [4, 3, 1, 3])};

  ${DateChip} {
    background-color: ${({ theme }) => theme.color.greySuperLight};
    margin-right: ${({ theme }) => theme.spacer.standard};
  }
`;

const clickablePadding = 1;
const CloseButtonWrapper = styled.div`
  padding: ${({ theme }) => theme.spacer.times(clickablePadding)};
  margin: ${({ theme }) => theme.spacer.times(-clickablePadding)};
`;

export default DetailsCard;
