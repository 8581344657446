import * as React from 'react';
import styled, { keyframes } from 'styled-components';

export interface DotsLoaderProps {
  center?: boolean;
  fullScreen?: boolean;
}

const DotsLoader = ({ fullScreen, center = false }: DotsLoaderProps) => {
  return fullScreen ? (
    <LoaderContainer>
      <DotsContainer center={center}>
        <Dot />
        <Dot />
        <Dot />
      </DotsContainer>
    </LoaderContainer>
  ) : (
    <DotsContainer center={center}>
      <Dot />
      <Dot />
      <Dot />
    </DotsContainer>
  );
};

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

const DotsContainer = styled.div<{ center: boolean }>`
  display: flex;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 6px;
  margin: ${({ center }) => (center ? 'auto' : 0)};
`;

const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  35% {
    transform: translateY(6px);
  }
  70% {
    transform: translateY(0);
  }
`;

const Dot = styled.div`
  position: relative;
  box-sizing: border-box;
  float: none;
  background-color: ${({ theme }) => theme.themeColor.primaryColor};
  border: 0 solid ${({ theme }) => theme.themeColor.primaryColor};
  width: 12px;
  height: 12px;
  margin: 6px;
  border-radius: 100%;
  animation: ${bounce} 1s infinite ease-in-out;

  &:nth-child(1) {
    animation-delay: -0.14s;
  }
  &:nth-child(2) {
    animation-delay: -0.07s;
  }
  &:nth-child(3) {
    animation-delay: 0;
  }
`;

export default DotsLoader;
