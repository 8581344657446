import styled from 'styled-components';

const FieldsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacer.big};
  margin-bottom: ${({ theme }) => theme.spacer.big};
`;

export default FieldsGrid;
