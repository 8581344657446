import * as React from 'react';
import styled from 'styled-components';

import { STATUS_SIZES } from 'common/consts';

import { StatusChip } from '..';
import { PlainCheckbox } from '../Checkbox';
import SelectOption from './SelectOption';
import SelectOptionLabel from './SelectOptionLabel';
import { RelationOption } from '../RelationSelect';

interface DropdownSelectOptionProps<TOption extends RelationOption> {
  item?: TOption;
  selected?: boolean;
  isMulti?: boolean;
  handlePick?: (item: TOption) => void;
}

const DropdownSelectOption = <TOption extends RelationOption>({
  item,
  selected,
  isMulti,
  handlePick,
}: DropdownSelectOptionProps<TOption>) =>
  item ? (
    <SelectOption onClick={() => handlePick?.(item)}>
      {isMulti && <PlainCheckbox name={item.label} checked={!!selected} readOnly />}
      <SelectOptionLabel>{item.label}</SelectOptionLabel>
      {item.status && (
        <SelectOptionStatus>
          <StatusChip status={item.status} size={STATUS_SIZES.SMALL} />
        </SelectOptionStatus>
      )}
    </SelectOption>
  ) : null;

const SelectOptionStatus = styled.div`
  flex: 0 0 110px;
  display: flex;
`;

export default DropdownSelectOption;
