import { useQuery } from 'react-query';

import apiUrls from 'base/api/urls';
import { StandardError } from 'base/api/errors';
import { TaggingSchema } from 'base/api/types';

import { TAGGING_CONTENT_TYPES } from './consts';

export const useTaggingSchema = (contentType: TAGGING_CONTENT_TYPES) => {
  return useQuery<TaggingSchema, StandardError>({
    queryKey: [apiUrls.TAGGING.SCHEMA, { contentType }],
  });
};
