import * as Yup from 'yup';

import { Fields, PK, SelectOption, StatusOption } from 'common/types';
import { combineSchemas } from 'common/utils';
import { ThemeColor } from 'shared/theme';
import { STATUSES, STATUSES_OPTIONS } from 'common/consts';

export const CriteriaMinimumCountOptions = [3, 4, 5, 6, 7, 8, 9, 10];

export enum TOAST_MESSAGES {
  MINIMUM_MINI_COUNT_NOT_REACHED = 'Required minis count is set to {REQUIRED}. Please provide at least {REQUIRED} active qualified minis or change value of required minis count field.',
}

export const BADGE_STATUSES_OPTIONS = STATUSES_OPTIONS.filter(
  // NOTE We currently disable the ability to deactivate badges (https://actifyleusden.atlassian.net/browse/Y2021-7841)
  (match) => match.value !== STATUSES.INACTIVE,
);

export type BadgeFields = {
  status: StatusOption[];
  title: string;
  collection: SelectOption<string>[];
  criteria_minimum_count: SelectOption<number>[];
  criteria_possible_pks: PK[] | null;
  descriptions_not_collected: string;
  descriptions_in_progress: string | null;
  descriptions_collected: string;
  is_hidden: boolean;
  images_inactive_url: string;
  images_in_progress_url: string | null;
  images_granted_url: string;
};

export enum DESCRIPTION_FIELDS {
  descriptions_not_collected = 'descriptions_not_collected',
  descriptions_in_progress = 'descriptions_in_progress',
  descriptions_collected = 'descriptions_collected',
}

export enum IMAGE_FIELDS {
  images_inactive_url = 'images_inactive_url',
  images_in_progress_url = 'images_in_progress_url',
  images_granted_url = 'images_granted_url',
}

export const fields: Fields<BadgeFields> = {
  status: {
    label: 'Status',
    name: 'status',
    schema: Yup.array().required(),
  },
  title: {
    name: 'title',
    label: 'Badge title',
    schema: Yup.string()
      .required('Name is a required field')
      .max(100, 'Name can not be longer than 100 characters'),
  },
  collection: {
    name: 'collection',
    label: 'Collection',
    schema: Yup.array().required('Collection is a required field'),
  },
  criteria_minimum_count: {
    name: 'criteria_minimum_count',
    label: 'Required minis count',
    schema: Yup.array(),
  },
  criteria_possible_pks: {
    name: 'criteria_possible_pks',
    label: 'Qualified minis',
    schema: Yup.array(),
  },
  [DESCRIPTION_FIELDS.descriptions_not_collected]: {
    name: DESCRIPTION_FIELDS.descriptions_not_collected,
    label: 'Inactive description',
    schema: Yup.string()
      .required('Inactive description is a required field')
      .max(300, 'Inactive description can not be longer than 300 characters'),
  },
  [DESCRIPTION_FIELDS.descriptions_in_progress]: {
    name: DESCRIPTION_FIELDS.descriptions_in_progress,
    label: 'Active description (Optional)',
    schema: Yup.string(),
  },
  [DESCRIPTION_FIELDS.descriptions_collected]: {
    name: DESCRIPTION_FIELDS.descriptions_collected,
    label: 'Completed description',
    schema: Yup.string()
      .required('Completed description is a required field')
      .max(300, 'Completed description can not be longer than 300 characters'),
  },
  is_hidden: {
    label: 'Is hidden?',
    name: 'is_hidden',
  },
  [IMAGE_FIELDS.images_inactive_url]: {
    name: IMAGE_FIELDS.images_inactive_url,
    label: 'Inactive image',
    schema: Yup.string().required('Inactive image is required'),
  },
  [IMAGE_FIELDS.images_in_progress_url]: {
    name: IMAGE_FIELDS.images_in_progress_url,
    label: 'Active image (Optional)',
    schema: Yup.string(),
  },
  [IMAGE_FIELDS.images_granted_url]: {
    name: IMAGE_FIELDS.images_granted_url,
    label: 'Completed image',
    schema: Yup.string().required('Completed image is required'),
  },
};

export const validationSchema = Yup.object().shape<BadgeFields>(combineSchemas(fields));

export const CollectionsColorMap: Record<string, ThemeColor> = {
  Algemeen: 'yellowLight',
  Bewegen: 'pinkPale',
  Gewoontes: 'yellowLight',
  'Gezond eten': 'greenSuperLight',
  Ontspannen: 'blueSuperLight',
};
