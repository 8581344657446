import * as React from 'react';
import { generatePath, useHistory } from 'react-router';

import appUrls from 'base/app/urls';
import { PK } from 'common/types';

import { fields, UnitsFields } from './consts';
import { useUnitsCreate, useUnitsUpdate } from './queries';

export const useSave = (pk?: PK) => {
  const [created, setCreated] = React.useState(pk);
  const create = useUnitsCreate();
  const update = useUnitsUpdate();
  const history = useHistory();

  const isSaving = create.isLoading || update.isLoading;
  const error = create.error || update.error;

  const save = (values: UnitsFields) => {
    const payload = {
      name_singular: values[fields.name_singular.name],
      name_plural: values[fields.name_plural.name],
    };

    if (created) {
      update.mutate({
        ...payload,
        pk: created,
      });
    } else {
      create.mutate(payload, {
        onSuccess: ({ pk }) => {
          setCreated(pk);
          history.push(generatePath(appUrls.UNITS.DETAILS, { pk }));
        },
      });
    }
  };

  return { save, created, isSaving, error };
};
