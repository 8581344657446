import React from 'react';

import { PK } from 'common/types';
import { CONTENT_TYPES, HANDLER_TYPES, STATUSES } from 'common/consts';
import { RelationOption } from 'common/components/RelationSelect';
import { CommonData } from 'templates/content/const';

import { CollectionFields, CollectionOption } from './consts';
import {
  useCollectionContentCreate,
  useCollectionItemsAdd,
  useCollectionItemsOrder,
  useCollectionItemsRemove,
  useCollectionCreate,
  useCollectionOrderUpdate,
  useCollectionUpdate,
  useCollectionItemVisibility,
} from './queries';

export const useSaveDetails = (pk?: PK) => {
  const [created, setCreated] = React.useState(pk);
  const create = useCollectionCreate();
  const update = useCollectionUpdate();
  const isSaving = create.isLoading || update.isLoading;
  const error = create.error || update.error;

  const save = (values: CollectionFields) => {
    const handler =
      values.handlerType[0].value === HANDLER_TYPES.STATIC
        ? null
        : values.handler?.[0].value;

    const payload = {
      title: values.title,
      color: values.color[0].value,
      icon_pk: values.icon?.pk,
      handler,
    };

    if (created) {
      update.mutate({ ...payload, status: values.status[0].value, pk: created });
    } else {
      create.mutate(payload, {
        onSuccess: ({ pk }) => {
          setCreated(pk);
        },
      });
    }
  };

  return { save, created, isSaving, error };
};

interface SaveContentArguments {
  pk?: PK;
  contentPk?: PK;
  contentItems: PK[];
  contentType: CONTENT_TYPES;
}

export const useSaveContentItems = ({
  pk,
  contentPk,
  contentItems,
  contentType,
}: SaveContentArguments) => {
  const {
    mutate: createContent,
    isSuccess: isCreateSuccess,
    isLoading: isCreateLoading,
    isError: isCreateError,
  } = useCollectionContentCreate();
  const {
    mutate: addItems,
    isSuccess: isAddItemsSuccess,
    isLoading: isAddItemsLoading,
    isError: isAddItemsError,
  } = useCollectionItemsAdd();
  const {
    mutate: removeItems,
    isSuccess: isRemoveItemsSuccess,
    isLoading: isRemoveItemsLoading,
    isError: isRemoveItemsError,
  } = useCollectionItemsRemove();

  const handleSave = (values: RelationOption[]) => {
    if (pk) {
      const saveContent = (contentPk: PK) => {
        const newItems = values.map((item) => item.value);
        const itemsAdded = newItems.filter((newItem) => !contentItems.includes(newItem));
        const itemsRemoved = contentItems.filter(
          (contentItem) => !newItems.includes(contentItem),
        );

        const itemAddPayload = {
          contentPk,
          collectionPk: pk,
          items_pks: itemsAdded,
        };
        const itemRemovePayload = {
          contentPk,
          collectionPk: pk,
          items_pks: itemsRemoved,
        };

        addItems(itemAddPayload);
        removeItems(itemRemovePayload);
      };

      if (!contentPk) {
        createContent(
          {
            collectionPk: pk,
            content_type: contentType,
          },
          { onSuccess: ({ pk }) => saveContent(pk) },
        );
      } else {
        saveContent(contentPk);
      }
    }
  };

  return {
    save: handleSave,
    isSuccess: isAddItemsSuccess || isRemoveItemsSuccess || isCreateSuccess,
    isLoading: isAddItemsLoading || isRemoveItemsLoading || isCreateLoading,
    isError: isAddItemsError || isRemoveItemsError || isCreateError,
  };
};

interface ReorderContentArguments {
  contentPk: PK;
  items: RelationOption[];
}

export const useReorderContentItems = (pk?: PK) => {
  const {
    mutate: reorderItems,
    isSuccess: isReorderSuccess,
    isLoading: isReorderLoading,
    isError: isReorderError,
  } = useCollectionItemsOrder();

  const handleReorder = ({ contentPk, items }: ReorderContentArguments) => {
    if (pk) {
      const payload = {
        contentPk,
        collectionPk: pk,
        items_pks: items.map((item) => item.value),
      };

      reorderItems(payload);
    }
  };

  return {
    save: handleReorder,
    isSuccess: isReorderSuccess,
    isLoading: isReorderLoading,
    isError: isReorderError,
  };
};

interface ActiveFilterArguments {
  status: STATUSES;
}

export const useActiveFilter = (initiallyEnabled = false) => {
  const [filterEnabled, setFilterEnabled] = React.useState(initiallyEnabled);
  const toggleActiveFilter = () => setFilterEnabled((filterEnabled) => !filterEnabled);
  const activeFilter = ({ status }: ActiveFilterArguments) =>
    !filterEnabled || status === STATUSES.ACTIVE;

  return {
    filterEnabled,
    toggleActiveFilter,
    activeFilter,
  };
};

interface ChangeVisibleContentArguments {
  contentPk: PK;
  item: CollectionOption;
}

export const useChangeVisibleContent = (pk?: PK) => {
  const {
    mutate: changeVisibility,
    isSuccess,
    isLoading,
    isError,
  } = useCollectionItemVisibility();

  const handleSave = ({ contentPk, item }: ChangeVisibleContentArguments) => {
    if (pk) {
      const payload = {
        contentPk,
        collectionPk: pk,
        itemPk: item.value,
        is_visible: item.isVisible,
      };

      changeVisibility(payload);
    }
  };

  return {
    save: handleSave,
    isSuccess,
    isLoading,
    isError,
  };
};

export const useSaveCollectionOrder = () => {
  const { mutate, isSuccess, isLoading, isError } = useCollectionOrderUpdate();

  const handleReorder = (values: CommonData[]) => {
    const payload = {
      collections_order: values.map((value) => value.pk),
    };

    mutate(payload);
  };

  return {
    save: handleReorder,
    isSuccess,
    isLoading,
    isError,
  };
};
