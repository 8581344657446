import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { Toggle } from 'common/components';
import { useBusinessStore } from 'common/hooks';

const BusinessToggle = () => {
  const theme = useTheme();
  const { isBusiness, setBusiness } = useBusinessStore();

  const handleSetBusiness = () => {
    setBusiness(!isBusiness);
  };
  return (
    <Toggle
      checked={isBusiness}
      onClick={handleSetBusiness}
      colors={theme.keys.components.businessTogglePrimaryColors}
      width={15}
      dotSize={5}
    >
      <ToggleLabel isBusiness={isBusiness}>B2B</ToggleLabel>
      <ToggleLabel isBusiness={isBusiness}>B2C</ToggleLabel>
    </Toggle>
  );
};

type Props = {
  isBusiness: boolean;
};

const ToggleLabel = styled.span<Props>`
  position: absolute;
  top: 0;
  margin-top: 14px;
  font-size: 20px;
  color: ${({ theme }) => theme.color.white};
  transition: opacity 0.2s ease;

  &:first-of-type {
    opacity: ${({ isBusiness }) => (isBusiness ? 1 : 0)};
    left: ${({ theme }) => theme.spacer.times(3)};
  }

  &:last-of-type {
    opacity: ${({ isBusiness }) => (isBusiness ? 0 : 1)};
    right: ${({ theme }) => theme.spacer.times(3)};
  }
`;

export default BusinessToggle;
