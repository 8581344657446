import Axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import apiUrls from 'base/api/urls';
import {
  getListQueryKey,
  SetQueryDataFn,
  useDetailsQuery,
  useListQuery,
  useSetQueryData,
} from 'base/api/hooks';
import {
  Collection,
  CollectionContent,
  CollectionContentItem,
  CollectionHandler,
  CollectionHandlers,
  ListResponse,
} from 'base/api/types';
import { StandardError } from 'base/api/errors';
import { composeUrl } from 'common/utils';
import { CATEGORIES, CONTENT_TYPES, FRIESLAND_CATEGORIES, STATUSES } from 'common/consts';
import { CommonVars, PK } from 'common/types';

const noValues = { values: [] };

export const useCollectionsList = () => {
  return useListQuery<Collection>(apiUrls.COLLECTIONS.LIST);
};

export const useHandlersList = () => {
  return useQuery<CollectionHandlers, StandardError>(apiUrls.COLLECTIONS.HANDLERS);
};

export const useCollectionDetails = (pk?: PK) => {
  return useDetailsQuery<Collection>({
    queryKey: [apiUrls.COLLECTIONS.DETAILS, { pk }],
    pk,
  });
};

export const useCollectionContent = (pk?: PK) => {
  return useDetailsQuery<ListResponse<CollectionContent>>({
    queryKey: [apiUrls.COLLECTIONS.CONTENT, { pk }],
    pk,
  });
};

interface CollectionCreateVars {
  title: string;
  color: FRIESLAND_CATEGORIES | CATEGORIES;
  handler: CollectionHandler | null;
}

export const useCollectionCreate = () => {
  const queryClient = useQueryClient();
  return useMutation<Collection, StandardError, CollectionCreateVars>(
    (data) => Axios.post(apiUrls.COLLECTIONS.LIST, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.COLLECTIONS.LIST);
        queryClient.invalidateQueries(apiUrls.CONTENT.LIST);
      },
    },
  );
};

interface CollectionContentCreateVars {
  collectionPk: PK;
  content_type: CONTENT_TYPES;
  handler?: CollectionHandler;
}

export const useCollectionContentCreate = () => {
  const queryClient = useQueryClient();
  return useMutation<CollectionContent, StandardError, CollectionContentCreateVars>(
    ({ collectionPk, ...data }) =>
      Axios.post(
        composeUrl(apiUrls.COLLECTIONS.CONTENT, { params: { pk: collectionPk } }),
        data,
      ),
    {
      onSuccess: (data, vars) => {
        queryClient.invalidateQueries([
          apiUrls.COLLECTIONS.CONTENT,
          { pk: vars.collectionPk },
        ]);
      },
    },
  );
};

interface CollectionUpdateVars extends CollectionCreateVars, CommonVars {
  status: STATUSES;
}

export const useCollectionUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<Collection, StandardError, CollectionUpdateVars>(
    ({ pk, ...data }) =>
      Axios.patch(composeUrl(apiUrls.COLLECTIONS.DETAILS, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.COLLECTIONS.LIST);
        queryClient.invalidateQueries([apiUrls.COLLECTIONS.CONTENT, { pk: data.pk }]);
        queryClient.setQueryData([apiUrls.COLLECTIONS.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

interface CollectionOrderUpdateVars {
  collections_order: PK[];
}

export const useCollectionOrderUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<void, StandardError, CollectionOrderUpdateVars>(
    (data) => Axios.put(apiUrls.COLLECTIONS.ORDER, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.COLLECTIONS.LIST);
      },
    },
  );
};

export const useCollectionRemove = () => {
  const queryClient = useQueryClient();

  return useMutation<void, StandardError, CommonVars>(
    ({ pk, ...data }) =>
      Axios.delete(composeUrl(apiUrls.COLLECTIONS.DETAILS, { params: { pk } }), data),
    {
      onSuccess: (data, vars) => {
        queryClient.setQueryData<ListResponse<Collection>>(
          getListQueryKey(apiUrls.COLLECTIONS.LIST),
          (oldData) => ({
            values:
              oldData?.values.filter((collection) => collection.pk !== vars.pk) ?? [],
          }),
        );
        queryClient.invalidateQueries(apiUrls.COLLECTIONS.LIST);
        queryClient.invalidateQueries([apiUrls.COLLECTIONS.DETAILS, { pk: vars.pk }]);
      },
    },
  );
};

interface CollectionContentVars {
  collectionPk: PK;
  contentPk: PK;
}

interface CollectionContentItemsVars extends CollectionContentVars {
  items_pks: PK[];
}

export const useCollectionItemsAdd = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, StandardError, CollectionContentItemsVars>(
    ({ collectionPk, contentPk, items_pks }) => {
      const mutationRequests = items_pks.map((item_pk) =>
        Axios.put(
          composeUrl(apiUrls.COLLECTIONS.ITEMS.LIST, {
            params: { collectionPk, contentPk },
          }),
          { item_pk },
        ),
      );

      return Promise.all(mutationRequests);
    },
    {
      onSuccess: (data, { collectionPk }) => {
        queryClient.invalidateQueries(apiUrls.COLLECTIONS.LIST);
        queryClient.invalidateQueries([
          apiUrls.COLLECTIONS.CONTENT,
          { pk: collectionPk },
        ]);
      },
    },
  );
};

export const useCollectionItemsRemove = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, StandardError, CollectionContentItemsVars>(
    ({ collectionPk, contentPk, items_pks }) => {
      const mutationRequests = items_pks.map((itemPk) =>
        Axios.delete(
          composeUrl(apiUrls.COLLECTIONS.ITEMS.DETAILS, {
            params: { collectionPk, contentPk, itemPk },
          }),
        ),
      );

      return Promise.all(mutationRequests);
    },
    {
      onSuccess: (data, { collectionPk }) => {
        queryClient.invalidateQueries(apiUrls.COLLECTIONS.LIST);
        queryClient.invalidateQueries([
          apiUrls.COLLECTIONS.CONTENT,
          { pk: collectionPk },
        ]);
      },
    },
  );
};

export const useCollectionItemsOrder = () => {
  const setQueryData = useSetQueryData();

  return useMutation<unknown, StandardError, CollectionContentItemsVars>(
    ({ collectionPk, contentPk, ...data }) =>
      Axios.put(
        composeUrl(apiUrls.COLLECTIONS.ITEMS.ORDER, {
          params: { collectionPk, contentPk },
        }),
        data,
      ),
    {
      onSuccess: (data, { collectionPk, contentPk, items_pks }) => {
        setQueryData(
          [apiUrls.COLLECTIONS.CONTENT, { pk: collectionPk }],
          updateItemOrderCache({ contentPk, items_pks }),
          true,
        );
      },
    },
  );
};

interface CollectionContentItemVisibilityVars extends CollectionContentVars {
  itemPk: PK;
  is_visible: boolean;
}

export const useCollectionItemVisibility = () => {
  const setQueryData = useSetQueryData();

  return useMutation<unknown, StandardError, CollectionContentItemVisibilityVars>(
    ({ collectionPk, contentPk, itemPk, ...data }) =>
      Axios.patch(
        composeUrl(apiUrls.COLLECTIONS.ITEMS.DETAILS, {
          params: { collectionPk, contentPk, itemPk },
        }),
        data,
      ),
    {
      onSuccess: (data, { collectionPk, contentPk, itemPk }) => {
        setQueryData(
          [apiUrls.COLLECTIONS.CONTENT, { pk: collectionPk }],
          updateItemVisibilityCache({ contentPk, itemPk }),
        );
      },
    },
  );
};

type UpdateItemOrderCacheArguments = Pick<
  CollectionContentItemsVars,
  'contentPk' | 'items_pks'
>;

const updateItemOrderCache = ({
  contentPk,
  items_pks,
}: UpdateItemOrderCacheArguments): SetQueryDataFn<ListResponse<CollectionContent>> => (
  oldData,
) => {
  const modifiedContent =
    oldData && oldData.values.find((content) => content.pk === contentPk);

  const newValues = oldData &&
    modifiedContent && [
      ...oldData.values.filter((content) => content !== modifiedContent),
      {
        ...modifiedContent,
        items: items_pks
          .map((itemPk) => modifiedContent.items.find((item) => item.pk === itemPk))
          .filter(
            (item: CollectionContentItem | undefined): item is CollectionContentItem =>
              !!item,
          ),
      },
    ];

  return newValues ? { values: newValues } : noValues;
};

type UpdateItemVisibilityCacheArguments = Pick<
  CollectionContentItemVisibilityVars,
  'contentPk' | 'itemPk'
>;

const updateItemVisibilityCache = ({
  contentPk,
  itemPk,
}: UpdateItemVisibilityCacheArguments): SetQueryDataFn<
  ListResponse<CollectionContent>
> => (oldData) => {
  const modifiedContent =
    oldData && oldData.values.find((content) => content.pk === contentPk);

  const modifiedItem =
    modifiedContent && modifiedContent.items.find((item) => item.pk === itemPk);

  const newValues = oldData &&
    modifiedContent &&
    modifiedItem && [
      ...oldData.values.filter((content) => content !== modifiedContent),
      {
        ...modifiedContent,
        items: modifiedContent.items.map((item) =>
          item === modifiedItem
            ? { ...modifiedItem, is_visible: !modifiedItem.is_visible }
            : item,
        ),
      },
    ];

  return newValues ? { values: newValues } : noValues;
};
