import * as React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { ThemeColor } from 'shared/theme';

type ContainerProps = {
  color: ThemeColor;
  disabled?: boolean;
  readOnly?: boolean;
  dimmed?: boolean;
};

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  color?: ThemeColor;
}

const Checkbox = (props: CheckboxProps & Partial<ContainerProps>) => {
  const { register } = useFormContext();
  return <PlainCheckbox ref={register} {...props} />;
};

// eslint-disable-next-line react/display-name
export const PlainCheckbox = React.forwardRef<
  HTMLInputElement,
  CheckboxProps & Partial<ContainerProps>
>(({ label, color = 'blue', ...props }, ref) => {
  return (
    <CheckboxContainer
      color={color}
      disabled={props.disabled}
      readOnly={props.readOnly}
      dimmed={props.dimmed || false}
    >
      <input ref={ref} {...props} type="checkbox" />
      <div />
      {label && <span>{label}</span>}
    </CheckboxContainer>
  );
});

const CheckboxContainer = styled.label<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  transition: color 0.1s ease;
  pointer-events: ${({ readOnly }) => readOnly && 'none'};
  padding: 1px 0 0 1px;

  input {
    opacity: 0;
    position: absolute;
  }

  & > span {
    display: block;
    width: 100%;
    margin-left: ${({ theme }) => theme.spacer.standard};
    font-size: ${({ theme }) => theme.font.size.small};
    font-weight: ${({ theme, dimmed }) => theme.font.weight[dimmed ? 'normal' : 'bold']};
    color: ${({ theme, dimmed }) => (dimmed ? 'auto' : theme.themeColor.primaryColor)};
    cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  }

  input + div {
    display: block;
    position: relative;
    left: 0;
    flex-shrink: 0;
    width: ${({ theme }) => theme.spacer.times(3)};
    height: ${({ theme }) => theme.spacer.times(3)};
    border: 1px solid ${({ theme }) => theme.themeColor.tertiaryLightColor};
    border-radius: ${({ theme }) => theme.border.radius.tiny};
    background-color: ${({ theme }) => theme.color.white};

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      width: 16px;
      height: 16px;
      border-radius: ${({ theme }) => theme.border.radius.tiny};
    }
  }

  input:checked + div {
    &::after {
      background-color: ${({ theme, color }) => theme.color[color]};
    }
  }

  input:focus + div {
    box-shadow: 0 0 0 1px ${({ theme, color }) => theme.color[color]};
  }
`;

export default Checkbox;
