import styled from 'styled-components';

import { resolveSpacersArray } from 'common/styles/utils';

const WeekDay = styled.div<{ isOpen: boolean }>`
  width: 100%;
  display: flex;
  cursor: pointer;
  line-height: 225%;
  align-items: center;
  flex-direction: row;
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  background: ${({ theme }) => theme.color.greySuperLight};
  padding: ${({ theme }) => resolveSpacersArray(theme, [2, 3])};
  margin: ${({ theme, isOpen }) => resolveSpacersArray(theme, [0, 0, isOpen ? 0 : 1])};
`;

export default WeekDay;
