const appUrls = {
  DASHBOARD: '/',
  MEDIA: '/media',
  SETTINGS: '/settings',
  AUTH: {
    LOGIN: '/login',
    LOGIN_CONFIRMATION: '/login-confirmation/',
  },
  MINIS: {
    LIST: '/minis',
    DETAILS: '/minis/:pk',
  },
  SERIES: {
    LIST: '/series',
    DETAILS: '/series/:pk',
  },
  COMPANIES: {
    LIST: '/companies',
    DETAILS: '/companies/:pk',
  },
  COLLECTIONS: {
    LIST: '/collections',
    DETAILS: '/collections/:pk',
  },
  FUNFACTS: {
    LIST: '/fun-facts',
    DETAILS: '/fun-facts/:pk',
  },
  MEDITATIONS: {
    LIST: '/meditations',
    DETAILS: '/meditations/:pk',
  },
  PODCASTS: {
    LIST: '/podcast',
    DETAILS: '/podcast/:pk',
  },
  HABITS: {
    LIST: '/habits',
    DETAILS: '/habits/:pk',
  },
  WORKOUTS: {
    LIST: '/workouts',
    DETAILS: '/workouts/:pk',
  },
  RECIPES: {
    LIST: '/recipes',
    DETAILS: '/recipes/:pk',
  },
  FACTS: {
    LIST: '/facts',
    DETAILS: '/facts/:pk',
  },
  BADGES: {
    LIST: '/badges',
    DETAILS: '/badges/:pk',
  },
  AUTHORS: {
    LIST: '/authors',
    CREATE: '/authors/create',
    DETAILS: '/authors/:pk',
  },
  CATEGORIES: {
    LIST: '/categories',
    CREATE: '/categories/create',
    DETAILS: '/categories/:pk',
  },
  INGREDIENTS: {
    LIST: '/ingredients',
    CREATE: '/ingredients/create',
    DETAILS: '/ingredients/:pk',
  },
  NUTRITIONAL_VALUES: {
    LIST: '/nutritional-values',
    CREATE: '/nutritional-values/create',
    DETAILS: '/nutritional-values/:pk',
  },
  TAGS: {
    LIST: '/tags',
    CREATE: '/tags/create',
    DETAILS: '/tags/:pk',
  },
  UNITS: {
    LIST: '/units',
    CREATE: '/units/create',
    DETAILS: '/units/:pk',
  },
  SCENARIOS: {
    LIST: '/scenarios',
    DETAILS: '/scenarios/:pk',
    WEEK: {
      LIST: '/scenarios/:pk/weeks',
      DETAILS: '/scenarios/:pk/weeks/:weekPk',
    },
  },
  TASKS: {
    LIST: '/tasks',
  },
  SSO: {
    LOGIN: '/friesland-sso',
  },
} as const;

export default appUrls;
