import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const CalendarDate = ({ ...props }: IconProps) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <path
        d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1Zm-1 12H2V5h12v9Z"
        fill="currentColor"
      />
      <path
        d="M6 7H4v2h2V7ZM9 7H7v2h2V7ZM6 10H4v2h2v-2ZM9 10H7v2h2v-2ZM12 7h-2v2h2V7ZM12 10h-2v2h2v-2Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default CalendarDate;
