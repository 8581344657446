import styled from 'styled-components';
import { Column } from 'common/components';

const SectionColumn = styled(Column)`
  width: 100%;
  min-width: 0;
  align-items: flex-start;
`;

export default SectionColumn;
