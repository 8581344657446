import { useMutation, useQueryClient } from 'react-query';
import Axios from 'axios';

import apiUrls from 'base/api/urls';
import { RecipesTag } from 'base/api/types';
import { useDetailsQuery, useListQuery } from 'base/api/hooks';
import { StandardError } from 'base/api/errors';
import { CommonVars, PK } from 'common/types';
import { composeUrl } from 'common/utils';

export const useTagsList = () => {
  return useListQuery<RecipesTag>(apiUrls.TAGS.LIST);
};

export const useTagsDetails = (pk?: PK) => {
  return useDetailsQuery<RecipesTag>({
    queryKey: [apiUrls.TAGS.DETAILS, { pk }],
    pk,
  });
};

interface TagsCreateVars {
  name: string;
}

export const useTagsCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<RecipesTag, StandardError, TagsCreateVars>(
    (data) => Axios.post(apiUrls.TAGS.LIST, data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.TAGS.LIST);
        queryClient.setQueryData([apiUrls.TAGS.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

interface TagsUpdateVars extends CommonVars, TagsCreateVars {}

export const useTagsUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<RecipesTag, StandardError, TagsUpdateVars>(
    ({ pk, ...data }) =>
      Axios.patch(composeUrl(apiUrls.TAGS.DETAILS, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.TAGS.LIST);
        queryClient.setQueryData([apiUrls.TAGS.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

export const useTagsDelete = () => {
  const queryClient = useQueryClient();

  return useMutation<never, StandardError, CommonVars>(
    ({ pk }) => Axios.delete(composeUrl(apiUrls.TAGS.DETAILS, { params: { pk } })),
    {
      onSuccess: (data, vars) => {
        queryClient.invalidateQueries(apiUrls.TAGS.LIST);
        queryClient.invalidateQueries(
          composeUrl(apiUrls.TAGS.DETAILS, {
            params: { pk: vars.pk },
          }),
        );
      },
    },
  );
};
