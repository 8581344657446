import * as React from 'react';
import styled from 'styled-components';

import { PK } from 'common/types';
import { Company } from 'base/api/types';
import { Button, Container, PrimaryKey, Title } from 'common/components';
import ContentRow, { ContentRowProps } from 'templates/content/components/ContentRow';
import { BUTTON_SIZES, BUTTON_VARIANTS, STATUSES } from 'common/consts';
import { useTaskRestart } from '../queries';

class TaskRow extends React.PureComponent<ContentRowProps<Company>> {
  render() {
    const { index } = this.props;
    const { pk, title } = this.props.data[index];
    const data = [...this.props.data].map((item) => ({
      ...item,
      status: STATUSES.INACTIVE,
    }));
    return (
      <ContentRow {...this.props} data={data}>
        <TitleCell>
          <Title size="standard">{title}</Title>
          <PrimaryKey pk={pk} />
        </TitleCell>
        <Container margin={[0, 0, 0, 'auto']}>
          <RestartButton pk={pk} />
        </Container>
      </ContentRow>
    );
  }
}

type Props = {
  pk: PK;
};

const RestartButton = ({ pk }: Props) => {
  const { mutate, isLoading } = useTaskRestart();

  const handleRestart = () => {
    mutate({ pk });
  };

  return (
    <Button
      onClick={handleRestart}
      variant={BUTTON_VARIANTS.SECONDARY}
      size={BUTTON_SIZES.SMALL}
      isLoading={isLoading}
    >
      Restart
    </Button>
  );
};

const TitleCell = styled.div`
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default TaskRow;
