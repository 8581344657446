import { useQuery } from 'react-query';

import apiUrls from 'base/api/urls';
import { StandardError } from 'base/api/errors';
import { DashboardContent } from 'base/api/types';
import { useBusinessStore } from 'common/hooks';

export const useDashboardContent = () => {
  const { isBusiness } = useBusinessStore();
  return useQuery<DashboardContent, StandardError>([
    apiUrls.CONTENT.LIST,
    null,
    { is_b2b: isBusiness },
  ]);
};
