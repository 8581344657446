import * as React from 'react';

import { CoreProps, PK } from 'common/types';
import { Input, Title } from 'common/components';
import { TitleInput } from 'templates/details';
import {
  CoreFormLayout,
  FieldsGrid,
  GridItem,
  Section,
  SectionsWrapper,
} from 'templates/core';
import { CompanyFields, fields, validationSchema } from '../consts';
import { useCompanyUpdate, useCompanyCreate } from '../queries';

const useSave = (pk?: PK) => {
  const [created, setCreated] = React.useState(pk);
  const create = useCompanyCreate();
  const update = useCompanyUpdate();
  const isSaving = create.isLoading || update.isLoading;
  const error = create.error || update.error;

  const save = (values: CompanyFields) => {
    const payload = {
      title: values[fields.title.name],
      branch: values[fields.branch.name],
      kvk_number: String(values[fields.kvk_number.name]),
    };
    if (created) {
      update.mutate({ pk: created, ...payload });
    } else {
      create.mutate(
        {
          ...payload,
          min_team_size: values[fields.min_team_size.name],
          max_team_size: values[fields.max_team_size.name],
        },
        {
          onSuccess: ({ pk }) => setCreated(pk),
        },
      );
    }
  };

  return { save, created, isSaving, error };
};

const CompanyCore = ({
  pk,
  defaultValues,
  children,
  layout = 'overview',
}: CoreProps<CompanyFields>) => {
  const { save, isSaving, error, created } = useSave(pk);

  return (
    <>
      <CoreFormLayout
        onSubmit={save}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isSubmitting={isSaving}
        error={error}
      >
        {layout === 'creation' && (
          <Section>
            <Title>General information</Title>
          </Section>
        )}
        <Section>
          <FieldsGrid>
            {Object.values(fields).map(({ label, name, props }) => {
              const isTitle = name === fields.title.name;
              const inputProps = props || {};
              const readOnly =
                !!created &&
                (name === fields.min_team_size.name ||
                  name === fields.max_team_size.name);
              return isTitle && layout === 'overview' ? (
                <GridItem key={name}>
                  <TitleInput name={fields.title.name} />
                </GridItem>
              ) : (
                <GridItem key={name}>
                  <Input name={name} label={label} {...inputProps} readOnly={readOnly} />
                </GridItem>
              );
            })}
          </FieldsGrid>
        </Section>
      </CoreFormLayout>
      <SectionsWrapper pk={created}>{children}</SectionsWrapper>
    </>
  );
};

export default CompanyCore;
