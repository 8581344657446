import * as React from 'react';

import { STATUSES_OPTIONS } from 'common/consts';
import { CoreProps, PK } from 'common/types';
import { ContentGoalSelect, Input, Select } from 'common/components';
import {
  CoreFormLayout,
  FieldsGrid,
  GridItem,
  Section,
  SectionsWrapper,
} from 'templates/core';

import { useFactCreate, useFactUpdate } from '../queries';
import { FactFields, fields, validationSchema } from '../consts';

const useSave = (pk?: PK) => {
  const [created, setCreated] = React.useState(pk);
  const create = useFactCreate();
  const update = useFactUpdate();
  const isSaving = create.isLoading || update.isLoading;
  const error = create.error || update.error;

  const save = (values: FactFields) => {
    const payload = {
      fact_text: values[fields.fact_text.name],
      status: values[fields.status.name][0].value,
      content_goal_pks:
        values[fields.content_goals.name]?.map((option) => option.value) || [],
    };

    if (created) {
      update.mutate({
        ...payload,
        pk: created,
      });
    } else {
      create.mutate(payload, {
        onSuccess: ({ pk }) => {
          setCreated(pk);
        },
      });
    }
  };

  return { save, created, isSaving, error };
};

const FactCore = ({ pk, defaultValues, children }: CoreProps<FactFields>) => {
  const { save, isSaving, error, created } = useSave(pk);

  return (
    <>
      <CoreFormLayout
        onSubmit={save}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isSubmitting={isSaving}
        error={error}
      >
        <Section>
          <FieldsGrid>
            <GridItem compact>
              <Select
                name={fields.status.name}
                options={STATUSES_OPTIONS}
                readOnly={!created}
              />
            </GridItem>
            <GridItem compact>
              <ContentGoalSelect
                isMulti
                name={fields.content_goals.name}
                label={fields.content_goals.label}
              />
            </GridItem>
            <GridItem>
              <Input name={fields.fact_text.name} label={fields.fact_text.label} />
            </GridItem>
          </FieldsGrid>
        </Section>
      </CoreFormLayout>
      <SectionsWrapper pk={created}>{children}</SectionsWrapper>
    </>
  );
};

export default FactCore;
