import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const EditPencil = ({ size = '16px', color = 'none', ...rest }: IconProps) => {
  return (
    <Svg viewBox="0 0 16 16" size={size} color={color} {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12H1C0.4 12 0 11.6 0 11V8C0 7.7 0.1 7.5 0.3 7.3L7.3 0.3C7.7 -0.1 8.3 -0.1 8.7 0.3L11.7 3.3C12.1 3.7 12.1 4.3 11.7 4.7L4.7 11.7C4.5 11.9 4.3 12 4 12ZM8 2.4L2 8.4V10H3.6L9.6 4L8 2.4Z"
        fill="currentColor"
      />
      <rect y="14" width="16" height="2" fill="currentColor" />
    </Svg>
  );
};

export default EditPencil;
