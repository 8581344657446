import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { StatusChip, Wrapper } from 'common/components';
import { CONTENT_TYPES, STATUSES, STATUS_SIZES } from 'common/consts';
import { useSearchParams } from 'common/hooks';
import { composeUrl } from 'common/utils';
import { resolveSpacersArray } from 'common/styles/utils';
import { translate as t } from 'common/utils/translate';

import Chip from './Chip';

type StatusCellProps = {
  urlPath?: string;
  status: STATUSES;
  isActive: boolean;
  minisCount: number;
  seriesCount: number;
};

const StatusCell = ({
  urlPath,
  isActive,
  status,
  minisCount,
  seriesCount,
}: StatusCellProps) => {
  const { contentType } = useSearchParams();

  return (
    <StyledStatusCell>
      <NavigationChip
        active={isActive && contentType === CONTENT_TYPES.SERIES}
        label={t('Series')}
        count={seriesCount}
        path={urlPath}
        type={CONTENT_TYPES.SERIES}
      />
      <NavigationChip
        active={isActive && contentType === CONTENT_TYPES.MINIS}
        label={t('Minis')}
        count={minisCount}
        path={urlPath}
        type={CONTENT_TYPES.MINIS}
      />
      {status && (
        <Wrapper margin={[0, 0, 0, 2]}>
          <StatusChip status={status} />
        </Wrapper>
      )}
    </StyledStatusCell>
  );
};

type NavigationChipProps = {
  active?: boolean;
  label: string;
  count: number;
  path?: string;
  type: CONTENT_TYPES;
};

const NavigationChip = ({
  active = false,
  label,
  count,
  path,
  type,
}: NavigationChipProps) => {
  const url = path ? composeUrl(path, { query: { contentType: type } }) : '';

  return count > 0 ? (
    <StyledLink>
      <StyledNavLink to={url}>
        <StyledChip $active={active} size={STATUS_SIZES.BIG}>
          <ChipText>{label}</ChipText>
          <Dot>{count}</Dot>
        </StyledChip>
      </StyledNavLink>
    </StyledLink>
  ) : null;
};

const StyledStatusCell = styled.div`
  flex-shrink: 0;
  display: flex;
  margin-left: auto;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

const StyledLink = styled.div`
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
`;

const minDotSize = '32px';
const Dot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${minDotSize};
  min-height: ${minDotSize};
  border-radius: calc(${minDotSize} / 2);
  color: ${({ theme }) => theme.color.blue};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  background-color: ${({ theme }) => theme.color.blueGrey};
`;

const ChipText = styled.span`
  color: ${({ theme }) => theme.color.blue};
  padding: ${({ theme }) => resolveSpacersArray(theme, [0, 1])};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

type ChipProps = {
  $active?: boolean;
};

const StyledChip = styled(Chip)<ChipProps>`
  background-color: ${({ theme, $active = false }) =>
    $active ? theme.color.blue : theme.color.transparent};

  & > ${ChipText} {
    color: ${({ theme, $active = false }) =>
      $active ? theme.color.blueGrey : theme.color.blue};
  }
`;

export default StatusCell;
