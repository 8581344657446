import * as React from 'react';
import { useParams } from 'react-router';

import { Mini } from 'base/api/types';
import { transformContentGoals } from 'base/api/utils';
import { POINTS_OPTIONS, STATUSES_OPTIONS } from 'common/consts';
import { PK } from 'common/types';
import MinisCore from '../components/MinisCore';
import { DetailsCard, DetailsSuspense } from 'templates/details';

import { MiniFields, fields } from '../consts';
import { useMiniDetails } from '../queries';

const transformMini = (raw: Mini): MiniFields => ({
  [fields.title.name]: raw.title,
  [fields.short_description.name]: raw.short_description,
  [fields.description.name]: raw.description,
  [fields.status.name]: STATUSES_OPTIONS.filter(({ value }) => value === raw.status),
  [fields.content_goals.name]:
    raw.content_goals && transformContentGoals(raw.content_goals),
  [fields.punctation.name]: POINTS_OPTIONS.filter(
    ({ value }) => value === raw.punctation,
  ),
  [fields.is_b2b.name]: raw.is_b2b ? ('true' as const) : ('false' as const),
});

const MinisDetails = () => {
  const { pk } = useParams<{ pk?: PK }>();
  const { data, ...query } = useMiniDetails(pk);
  const defaultValues = data && transformMini(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading} error={query.error}>
        <MinisCore pk={pk} blog_pk={data?.blog_pk} defaultValues={defaultValues} />
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default MinisDetails;
