import React from 'react';

import { Tooltip } from 'common/components';
import { Info } from 'common/icons';

interface Props {
  message: string;
  className?: string;
  disabled?: boolean;
}

const iconSize = 16;

const InfoLabel = ({ message }: Props) => {
  return (
    <Tooltip message={message} iconSize={iconSize}>
      <Info size={`${iconSize}px`} />
    </Tooltip>
  );
};

export default InfoLabel;
