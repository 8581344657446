import { useMutation, useQueryClient } from 'react-query';
import Axios from 'axios';

import { useDetailsQuery, useListQuery } from 'base/api/hooks';
import apiUrls from 'base/api/urls';
import { RecipeCategoryType, RecipesCategory } from 'base/api/types';
import { CommonVars, PK } from 'common/types';
import { StandardError } from 'base/api/errors';
import { composeUrl } from 'common/utils';

export const useCategoriesList = () => {
  return useListQuery<RecipesCategory>(apiUrls.CATEGORIES.LIST);
};

export const useCategoriesDetails = (pk?: PK) => {
  return useDetailsQuery<RecipesCategory>({
    queryKey: [apiUrls.CATEGORIES.DETAILS, { pk }],
    pk,
  });
};

interface CategoriesCreateVars {
  name: string;
  category_type: RecipeCategoryType;
}

export const useCategoriesCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<RecipesCategory, StandardError, CategoriesCreateVars>(
    (data) => Axios.post(apiUrls.CATEGORIES.LIST, data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.CATEGORIES.LIST);
        queryClient.setQueryData([apiUrls.CATEGORIES.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

interface CategoriesUpdateVars extends CommonVars, CategoriesCreateVars {}

export const useCategoriesUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<RecipesCategory, StandardError, CategoriesUpdateVars>(
    ({ pk, ...data }) =>
      Axios.patch(composeUrl(apiUrls.CATEGORIES.DETAILS, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.CATEGORIES.LIST);
        queryClient.setQueryData([apiUrls.CATEGORIES.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

export const useCategoriesDelete = () => {
  const queryClient = useQueryClient();

  return useMutation<never, StandardError, CommonVars>(
    ({ pk }) => Axios.delete(composeUrl(apiUrls.CATEGORIES.DETAILS, { params: { pk } })),
    {
      onSuccess: (data, vars) => {
        queryClient.invalidateQueries(apiUrls.CATEGORIES.LIST);
        queryClient.invalidateQueries(
          composeUrl(apiUrls.CATEGORIES.DETAILS, {
            params: { pk: vars.pk },
          }),
        );
      },
    },
  );
};
