import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Calendar = ({ size = '22px', ...props }: IconProps) => {
  return (
    <Svg size={size} viewBox="0 0 24 23" {...props}>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-399.000000, -1180.000000)"
          stroke="currentColor"
          strokeWidth="2"
        >
          <g transform="translate(340.000000, 82.000000)">
            <g transform="translate(40.000000, 1078.500000)">
              <g transform="translate(20.000000, 20.500000)">
                <line x1="0" y1="7" x2="22" y2="7"></line>
                <rect x="0" y="2" width="22" height="19" rx="2"></rect>
                <line x1="5" y1="0" x2="5" y2="3"></line>
                <line x1="17" y1="0" x2="17" y2="3"></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </Svg>
  );
};

export default Calendar;
