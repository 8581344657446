import * as React from 'react';

import { Select } from 'common/components';
import { SelectProps } from 'common/components/Select';
import { PK, SelectOption } from 'common/types';

import { useContentGoalsList } from './queries';

type ContentGoalOption = SelectOption<PK>;
type ContentGoalSelectProps = Omit<SelectProps<ContentGoalOption>, 'options'>;

const ContentGoalSelect = (props: ContentGoalSelectProps) => {
  const { data } = useContentGoalsList();

  const options: ContentGoalOption[] =
    data?.values
      .filter(({ label }) => !['COZY', 'FRIENDLY'].includes(label))
      .map(({ label, pk }) => ({ label, value: pk })) || [];

  return <Select {...props} options={options} />;
};

export default ContentGoalSelect;
