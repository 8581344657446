import { getIsFriesland } from './config';

export enum Feature {
  challenges = 'challenges',
  oldVersionButton = 'oldVersionButton',
}

// TODO Move the reminder of features to feature flags
export const FeatureFlags = {
  [Feature.challenges]: () => true,
  [Feature.oldVersionButton]: () => !getIsFriesland(),
};
