import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Copy = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 17 17" {...props}>
      <path
        d="M12 1H1v11h11V1Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 5h2v11H5v-2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Copy;
