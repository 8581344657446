import * as React from 'react';

import { PK } from 'common/types';

import { CollectionOption } from '../consts';
import { useChangeVisibleContent, useReorderContentItems } from '../hooks';
import { useCollectionContent } from '../queries';
import DynamicSection from './DynamicSection';

interface DynamicPickerProps {
  pk?: PK;
}

const DynamicPicker = ({ pk }: DynamicPickerProps) => {
  const [lastUsedContent, setLastUsedContent] = React.useState<PK>();
  const [lastChangedItem, setLastChangedItem] = React.useState<PK>();
  const { data: collectionContentData } = useCollectionContent(pk);
  const contentList = collectionContentData?.values ?? [];

  const {
    save: changeVisibility,
    isSuccess: isVisibilityChangeSuccess,
    isLoading: isVisibilityChangeLoading,
    isError: isVisibilityChangeError,
  } = useChangeVisibleContent(pk);
  const {
    save: reorderItems,
    isSuccess: isReorderSuccess,
    isLoading: isReorderLoading,
    isError: isReorderError,
  } = useReorderContentItems(pk);
  const saveStatus = {
    isSuccess: isVisibilityChangeSuccess || isReorderSuccess,
    isLoading: isVisibilityChangeLoading || isReorderLoading,
    isError: isVisibilityChangeError || isReorderError,
  };

  const handleVisibilityChange = (contentPk: PK, item: CollectionOption) => {
    changeVisibility({ contentPk, item });
    setLastUsedContent(contentPk);
    setLastChangedItem(item.value);
  };

  const handleReorder = (contentPk: PK, items: CollectionOption[]) => {
    reorderItems({ contentPk, items });
    setLastUsedContent(contentPk);
  };

  return (
    <>
      {contentList
        .sort((a, b) => (a.content_type < b.content_type ? -1 : 1))
        .map((content, index) => (
          <DynamicSection
            pk={pk}
            key={content.pk}
            content={content}
            isFirst={index === 0}
            onReorder={handleReorder}
            onChangeVisibility={handleVisibilityChange}
            {...(content.pk === lastUsedContent && { saveStatus })}
            {...(isVisibilityChangeLoading && { changedItem: lastChangedItem })}
          />
        ))}
    </>
  );
};

export default DynamicPicker;
