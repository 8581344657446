import * as React from 'react';

import { Checkbox, DatePicker, Title } from 'common/components';
import { FieldsGrid, GridItem, Section } from 'templates/core';
import { fields } from '../consts';
import { useWatch } from 'react-hook-form';

export const SeriesChallengeFields = ({
  isCurrentlyActiveChallenge,
}: {
  isCurrentlyActiveChallenge: boolean;
}) => {
  const isChallenge = useWatch({ name: fields.is_challenge.name });

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <Section>
      <Title marginBottom={2.5}>Challenge</Title>
      <FieldsGrid>
        <GridItem>
          <Checkbox
            name={fields.is_challenge.name}
            label={fields.is_challenge.label}
            dimmed
            disabled={isCurrentlyActiveChallenge}
          />
        </GridItem>
        <GridItem>
          <DatePicker
            name={fields.challenge_start_date.name}
            label={fields.challenge_start_date.label}
            minDate={tomorrow}
            disabled={!isChallenge || isCurrentlyActiveChallenge}
          />
        </GridItem>
      </FieldsGrid>
    </Section>
  );
};
