import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { PK } from 'common/types';
import { MediaObject } from 'base/api/types';
import { Wrapper, MediaField } from 'common/components';
import { useMediaList } from 'modules/media/queries';
import { MediaFieldProps } from './MediaField/MediaField';

type Fields = Record<string, (MediaObject | File)[] | undefined>;

const isMedia = (items?: any[]): items is MediaObject[] => {
  return items ? items.every((item) => (item?.media_type ? true : false)) : false;
};

interface MediaPickerProps
  extends Pick<MediaFieldProps, 'name' | 'mediaType' | 'fileType'> {
  onSave: (mediaPks: PK[], media: MediaObject[]) => void;
  defaultValues?: MediaObject[] | null;
}

const MediaPicker = ({
  onSave,
  defaultValues,
  name,
  mediaType,
  fileType,
}: MediaPickerProps) => {
  const form = useForm<Fields>();
  const { data: mediaList } = useMediaList();
  const { reset, getValues } = form;

  React.useEffect(() => {
    if (mediaList && defaultValues && defaultValues.length > 0) {
      const defaultImage = mediaList.values.filter(
        ({ pk }) => pk === defaultValues[0]?.pk,
      );

      if (!defaultImage.length) {
        defaultImage.push(defaultValues[0]);
      }

      reset({ ...getValues(), [name]: defaultImage });
    }
  }, [getValues, mediaList, name, defaultValues, reset]);

  const handleUpload = () => {
    const values = getValues(name);
    const media = isMedia(values) && values;

    if (media) {
      const mediaPks = [...media.map(({ pk }) => pk)];
      onSave(mediaPks, media);
    }
  };

  const handleRemove = (itemPk: PK) => {
    const values = getValues(name);
    const media = isMedia(values) && values;
    if (media) {
      const mediaPks = [
        ...media.filter(({ pk: mediaPk }) => mediaPk !== itemPk).map(({ pk }) => pk),
      ];
      onSave(mediaPks, media);
    }
  };

  return (
    <FormProvider {...form}>
      <Wrapper margin={[0, 0, 5]}>
        <MediaField
          name={name}
          mediaType={mediaType}
          fileType={fileType}
          onUpload={handleUpload}
          onRemove={handleRemove}
        />
      </Wrapper>
    </FormProvider>
  );
};

export default MediaPicker;
