import Axios from 'axios';
import Cookies from 'js-cookie';

import { ACCESS_TOKEN } from 'modules/auth/consts';
import { getError, ApiError } from './errors';
import { getConfig } from 'common/config';

Axios.defaults.baseURL = getConfig().API_URL;

Axios.interceptors.request.use((config) => {
  const token = Cookies.get(ACCESS_TOKEN);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

Axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error: ApiError) => {
    return Promise.reject(getError(error));
  },
);
