import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { ListChildComponentProps, FixedSizeList } from 'react-window';
import styled from 'styled-components';

import { ErrorBox } from 'common/components';
import { useAutoSize, useDnDList } from 'common/hooks';
import { getIsFunFactsPage, getIsMinisPage, matchUrl } from 'common/utils';
import { resolveSpacersArray } from 'common/styles/utils';
import { PK } from 'common/types';

import { CommonData } from '../const';
import { useContentContext } from '../context';
import DefaultContentRow from './ContentRow';
import SkeletonRow from './SkeletonRow';

type Props<TData> = {
  isError?: boolean;
  offset?: number;
  reorder?: boolean;
  onReorder?: (values: TData[]) => void;
  children?: React.ComponentType<ListChildComponentProps>;
};

const UNIT_HEIGHT = 8;

const ContentList = <TData extends CommonData>({
  children,
  isError,
  offset = 23,
  reorder = false,
  onReorder,
}: Props<TData>) => {
  const { data, filteredData, renderer, path } = useContentContext<TData>();
  const { height: listHeight } = useAutoSize([offset * UNIT_HEIGHT, 0]);
  const location = useLocation();
  const detailsMatch = matchUrl(path?.details, location.pathname);
  const collapse = !!detailsMatch;

  const isMinis = getIsMinisPage(location.pathname);
  const isFunFactsPage = getIsFunFactsPage(location.pathname);
  const { list: itemList, reinit, getDraggableProps } = useDnDList<TData>({
    disabled: !reorder,
    initList: filteredData,
    onReorder,
  });

  const itemDataList = itemList.map((item) => ({
    ...item,
    getDraggableProps,
    is_minis_page: isMinis,
    is_fun_facts_page: isFunFactsPage,
  }));
  React.useEffect(() => {
    if (filteredData) {
      reinit(filteredData);
    }
  }, [reinit, filteredData, renderer]);

  return (
    <>
      {data === null ? (
        <LimitedContainer collapse={collapse}>
          {Array.from({ length: 5 }).map((_, index) => (
            <SkeletonRow key={`skeleton-${index}`} />
          ))}
        </LimitedContainer>
      ) : isError ? (
        <LimitedContainer collapse={collapse}>
          <ErrorBox isError={true} />
        </LimitedContainer>
      ) : (
        <LimitedContainer collapse={collapse} vertical>
          <StyledFixedSizeList
            width="100%"
            height={listHeight}
            itemSize={10 * UNIT_HEIGHT}
            itemCount={itemDataList.length}
            itemData={itemDataList}
            itemKey={itemKey}
            renderer={renderer}
          >
            {children || DefaultContentRow}
          </StyledFixedSizeList>
        </LimitedContainer>
      )}
    </>
  );
};

const itemKey = (index: number, filteredData: CommonData[]): PK | number => {
  const item = filteredData[index];
  return item && item.pk ? item.pk : index;
};

const StyledFixedSizeList = styled(FixedSizeList)`
  padding: ${({ theme }) => resolveSpacersArray(theme, [3, 0, 10])};
`;

const LimitedContainer = styled.div<{ collapse?: boolean; vertical?: boolean }>`
  width: ${({ collapse }) => (collapse ? '50%' : '100%')};
  padding: ${({ theme, vertical }) => (vertical ? '0' : theme.spacer.times(3))};
`;

export default ContentList;
