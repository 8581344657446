import * as React from 'react';
import styled from 'styled-components';

import { Button, Link } from 'common/components';
import { BUTTON_VARIANTS } from 'common/consts';

interface CreateProps {
  path: string;
  children: React.ReactNode;
}

const CreateButton = ({ path, children }: CreateProps) => {
  return (
    <Link to={path}>
      <StyledButton variant={BUTTON_VARIANTS.TEXT}>{children}</StyledButton>
    </Link>
  );
};

const StyledButton = styled(Button)`
  padding: 0;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.blue};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  margin-right: ${({ theme }) => theme.spacer.times(0.5)};
  width: ${({ theme }) => theme.spacer.times(14)};
  height: ${({ theme }) => theme.spacer.times(7)};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.blueMediumDark};
  }
`;

export default CreateButton;
