import * as React from 'react';
import styled from 'styled-components';

import { ThemeColor } from 'shared/theme';

interface DotProps {
  checked: boolean;
  dotSize?: number;
  width?: number;
}

export interface ToggleProps extends DotProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  colors?: [ThemeColor, ThemeColor];
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const Toggle = ({
  className,
  onClick,
  checked,
  dotSize,
  width,
  disabled,
  children,
}: ToggleProps) => {
  return (
    <button type="button" className={className} onClick={onClick} disabled={disabled}>
      <ToggleDot checked={checked} dotSize={dotSize} width={width} />
      {children}
    </button>
  );
};

const StyledToggle = styled(Toggle)`
  display: block;
  position: relative;
  width: ${({ theme, width = 7 }) => theme.spacer.times(width)};
  height: ${({ theme, dotSize = 3 }) => theme.spacer.times(dotSize + 1)};
  border: none;
  border-radius: ${({ theme, dotSize = 3 }) => theme.spacer.times((dotSize + 1) / 2)};
  background-color: ${({ theme, checked, colors }) =>
    checked
      ? theme.color[colors ? colors[0] : 'green']
      : theme.color[colors ? colors[1] : 'greyLight']};
  transition: background-color 0.2s ease;
  cursor: pointer;
  -webkit-appearance: none;

  &:focus,
  &:hover {
    outline: none;
  }
`;

const ToggleDot = styled.div<DotProps>`
  position: absolute;
  top: ${({ theme }) => theme.spacer.small};
  left: ${({ theme }) => theme.spacer.small};
  width: ${({ theme, dotSize = 3 }) => theme.spacer.times(dotSize)};
  height: ${({ theme, dotSize = 3 }) => theme.spacer.times(dotSize)};
  border-radius: ${({ theme, dotSize = 3 }) => theme.spacer.times(dotSize / 2)};
  background-color: ${({ theme }) => theme.color.white};

  transform: translateX(
    ${({ theme, checked, width = 7, dotSize = 3 }) =>
      checked ? theme.spacer.times(width - dotSize - 1) : 0}
  );
  transition: transform 0.2s ease;
`;

export default StyledToggle;
