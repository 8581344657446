import styled from 'styled-components';

const Panel = styled.section`
  padding: ${({ theme }) => theme.spacer.times(5)};
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.border.radius.standard};
  box-shadow: 0 1px 1px 1px ${({ theme }) => theme.color.blueSuperLight};
`;

export default Panel;
