import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Eye = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 22 16" {...props}>
      <g
        id="WEB-|-Visual"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Onboarding_2-5"
          transform="translate(-1001.000000, -456.000000)"
          stroke="currentColor"
          strokeWidth="2"
        >
          <g id="preview" transform="translate(1002.000000, 457.000000)">
            <path
              d="M0,7 C0,7 4,0 10,0 C16,0 20,7 20,7 C20,7 16,14 10,14 C4,14 0,7 0,7 Z"
              id="Path"
            ></path>
            <circle id="Oval" cx="10" cy="7" r="3"></circle>
          </g>
        </g>
      </g>
    </Svg>
  );
};

Eye.defaultProps = {
  size: '22px',
  color: 'black',
  hoverColor: 'black',
};

export default Eye;
