import * as React from 'react';

const useAutoSize = (
  offset: [number, number] = [0, 0],
): { height: number; width: number } => {
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  const handleResize = React.useCallback(() => {
    setHeight(window.innerHeight - offset[0]);
    setWidth(window.innerWidth - offset[1]);
  }, [offset]);

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return { height, width: Math.min(2048, width) };
};

export default useAutoSize;
