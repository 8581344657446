import * as React from 'react';
import styled, { css } from 'styled-components';

import { Star } from 'common/icons';
import { resolveSpacersArray } from 'common/styles/utils';

interface Props {
  border?: boolean;
  value?: number;
  fontSize?: number;
}
const PointsDisplay = ({ value, border, fontSize }: Props) => {
  return (
    <PointsWrapper $border={!!border} $fontSize={fontSize}>
      <Star color="greenDark" backgroundColor="greenLight" />
      <PointsValue>{value}</PointsValue>
    </PointsWrapper>
  );
};

const PointsWrapper = styled.div<{ $border: boolean; $fontSize: number | undefined }>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacer.tiny};
  margin-right: ${({ theme }) => theme.spacer.standard};
  background: ${({ theme }) => theme.color.white};
  border: ${({ theme, $border }) => $border && css`1px solid ${theme.color.blueLight}`};
  border-radius: ${({ theme }) => theme.border.radius.big};
  min-width: ${({ theme }) => theme.spacer.times(6.5)};
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : 12)}px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const PointsValue = styled.span`
  color: ${({ theme }) => theme.color.blue};
  margin: ${({ theme }) => resolveSpacersArray(theme, [0, 1])};
`;

export default PointsDisplay;
