import * as React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import appUrls from 'base/app/urls';
import Route from './Route';
import routes from './routing';
import Layout from 'common/styles/Layout';

const Router = () => {
  return (
    <Switch>
      {routes.map(({ component, routeProps, layoutProps }) => (
        <Route key={routeProps.path} {...routeProps}>
          <Layout {...layoutProps}>{component}</Layout>
        </Route>
      ))}
      <Redirect to={appUrls.DASHBOARD} />
    </Switch>
  );
};

export default Router;
