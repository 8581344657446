import * as React from 'react';
import styled from 'styled-components';

import { Header } from 'common/components';
import { resolveSpacer } from './utils';

export interface LayoutProps {
  topbar?: boolean;
  header?: boolean;
  logoOnly?: boolean;
  children?: React.ReactNode;
}

const LayoutContent = styled.div<LayoutProps>`
  max-width: ${({ theme }) => `calc(${theme.media.max} + 2 * ${theme.spacer.times(3)})`};
  padding-top: ${({ theme, header, topbar }) => {
    let padTop = 0;
    if (header) padTop = padTop + 11;
    if (topbar) padTop = padTop + 12;
    return resolveSpacer(theme, padTop);
  }};
  margin: 0 auto;
`;

const Layout = ({ children, logoOnly, header = true, topbar = true }: LayoutProps) => {
  return (
    <>
      {header && <Header logoOnly={logoOnly} />}
      <LayoutContent header={header} topbar={topbar}>
        {children}
      </LayoutContent>
    </>
  );
};

export default Layout;
