import * as Yup from 'yup';

import { Fields } from 'common/types';
import { combineSchemas } from 'common/utils';

export type AdminFields = {
  email: string;
  first_name: string;
  last_name: string;
  phone_number?: number;
  is_contact_person?: boolean;
};

export type AdminFormReset = (values: AdminFields) => void;

export const adminFields: Fields<AdminFields> = {
  email: {
    name: 'email',
    label: 'Email',
    schema: Yup.string().required().email(),
  },
  first_name: {
    label: 'First name',
    name: 'first_name',
  },
  last_name: {
    label: 'Last name',
    name: 'last_name',
  },
  phone_number: {
    label: 'Phone number',
    name: 'phone_number',
  },
  is_contact_person: {
    label: 'Contact person',
    name: 'is_contact_person',
  },
};

export const adminsSchema = Yup.object().shape<AdminFields>(combineSchemas(adminFields));
