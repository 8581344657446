import { useMutation, useQueryClient } from 'react-query';
import Axios from 'axios';

import apiUrls from 'base/api/urls';
import { StandardError } from 'base/api/errors';
import { useDetailsQuery, useListQuery } from 'base/api/hooks';
import { RecipesNutritionalValue } from 'base/api/types';
import { CommonVars, PK } from 'common/types';
import { composeUrl } from 'common/utils';

export const useNutritionalValuesList = () => {
  return useListQuery<RecipesNutritionalValue>(apiUrls.NUTRITIONAL_VALUES.LIST);
};

export const useNutritionalValuesDetails = (pk?: PK) => {
  return useDetailsQuery<RecipesNutritionalValue>({
    queryKey: [apiUrls.NUTRITIONAL_VALUES.DETAILS, { pk }],
    pk,
  });
};

interface NutritionalValuesCreateVars {
  name: string;
  unit_pk: PK;
}

export const useNutritionalValuesCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<RecipesNutritionalValue, StandardError, NutritionalValuesCreateVars>(
    (data) => Axios.post(apiUrls.NUTRITIONAL_VALUES.LIST, data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.NUTRITIONAL_VALUES.LIST);
        queryClient.setQueryData(
          [apiUrls.NUTRITIONAL_VALUES.DETAILS, { pk: data.pk }],
          data,
        );
      },
    },
  );
};

interface NutritionalValuesUpdateVars extends CommonVars, NutritionalValuesCreateVars {}

export const useNutritionalValuesUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<RecipesNutritionalValue, StandardError, NutritionalValuesUpdateVars>(
    ({ pk, ...data }) =>
      Axios.patch(
        composeUrl(apiUrls.NUTRITIONAL_VALUES.DETAILS, { params: { pk } }),
        data,
      ),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.NUTRITIONAL_VALUES.LIST);
        queryClient.setQueryData(
          [apiUrls.NUTRITIONAL_VALUES.DETAILS, { pk: data.pk }],
          data,
        );
      },
    },
  );
};

export const useNutritionalValuesDelete = () => {
  const queryClient = useQueryClient();

  return useMutation<never, StandardError, CommonVars>(
    ({ pk }) =>
      Axios.delete(composeUrl(apiUrls.NUTRITIONAL_VALUES.DETAILS, { params: { pk } })),
    {
      onSuccess: (data, vars) => {
        queryClient.invalidateQueries(apiUrls.NUTRITIONAL_VALUES.LIST);
        queryClient.invalidateQueries(
          composeUrl(apiUrls.NUTRITIONAL_VALUES.DETAILS, {
            params: { pk: vars.pk },
          }),
        );
      },
    },
  );
};
