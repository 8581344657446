import * as React from 'react';

import { toastMessages } from 'common/toastMessages';
import { Bin } from 'common/icons';
import { PK } from 'common/types';
import { Menu } from 'common/components/DropdownMenu';

import { useCategoriesDelete } from '../queries';
import { useToast } from 'modules/toast';

interface MenuProps {
  pk: PK;
}

const CategoriesMenu = ({ pk }: MenuProps) => {
  const { mutate: removeCategory, isLoading, isError } = useCategoriesDelete();
  const { toast } = useToast();

  const handleDelete = () => {
    removeCategory(
      { pk },
      {
        onSuccess: () => {
          toast(toastMessages.SUCCESS.GENERIC_SUCCESS, 'success');
        },
        onError: (error) => {
          toast(error.message, 'error');
        },
      },
    );
  };

  const menuItems = [
    {
      isError,
      isLoading,
      label: 'Delete',
      icon: <Bin />,
      onClick: handleDelete,
    },
  ];

  return <Menu menuItems={menuItems} />;
};

export default CategoriesMenu;
