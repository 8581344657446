import * as React from 'react';
import { SubmitHandler } from 'react-hook-form';

import { ErrorBox, Form } from 'common/components';
import { SaveButton, Section } from 'templates/core';

import { Tags } from 'base/api/types';
import { StandardError } from 'base/api/errors';
import { SelectOption } from 'common/types';

import { TAGGING_CONTENT_TYPES } from '../consts';
import { useTaggingSchema } from '../queries';
import TagsFields from './TagsFields';

// eslint-disable-next-line @typescript-eslint/ban-types
interface CoreFormLayoutProps {
  validationSchema?: any;
  defaultValues: Tags | undefined;
  isSubmitting?: boolean;
  contentType: TAGGING_CONTENT_TYPES;
  error?: StandardError | null;
  onSubmit: SubmitHandler<Tags>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const TagsForm = ({
  contentType,
  isSubmitting,
  error,
  defaultValues,
  validationSchema,
  ...props
}: CoreFormLayoutProps) => {
  const { data: taggingSchema } = useTaggingSchema(contentType);
  const transformedDefaultValues = defaultValues ? transformTagData(defaultValues) : [];
  return (
    // FIXME: `any` typing
    <Form
      error={error}
      defaultValues={transformedDefaultValues as any}
      validationSchema={validationSchema}
      {...props}
    >
      <Section>
        <TagsFields taggingSchema={taggingSchema?.metadata} />
      </Section>
      {error && (
        <Section>
          <ErrorBox error={error} />
        </Section>
      )}
      <SaveButton isLoading={isSubmitting} />
    </Form>
  );
};

const transformTagData = (raw: Tags): Record<string, number | SelectOption<string>[]> =>
  Object.keys(raw).reduce((acc, tagName) => {
    const tagValue = raw[tagName];
    if (typeof tagValue === 'number') {
      acc[tagName] = tagValue;
    } else if (typeof tagValue === 'string') {
      acc[tagName] = [{ label: tagValue, value: tagValue }];
    } else {
      acc[tagName] = tagValue?.map((key) => ({ label: key, value: key })) || null;
    }
    return acc;
  }, {} as Record<string, number | SelectOption<string>[]>);

export default TagsForm;
