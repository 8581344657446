import * as React from 'react';
import { PK } from 'common/types';

import { useSaveSeriesBlog } from '../hooks';
import BlogPicker from './BlogPicker';
import { useSeriesDetails } from 'modules/series/queries';
import { BlogFields } from '../consts';

type Props = {
  pk?: PK;
};

const SeriesBlogPicker = ({ pk }: Props) => {
  const { data } = useSeriesDetails(pk);
  const blog_pk = data?.blog_pk;
  const { save, update, isLoading, isError, isSuccess } = useSaveSeriesBlog(pk);
  const saveStatus = { isLoading, isError, isSuccess };

  const handleSave = (blogValues: BlogFields) => {
    save(blogValues, data);
  };

  return (
    <BlogPicker
      blog_pk={blog_pk}
      pk={pk}
      onBlogUpdate={update}
      onSave={handleSave}
      saveStatus={saveStatus}
    />
  );
};

export default SeriesBlogPicker;
