import * as React from 'react';

import { CoreProps } from 'common/types';
import { ContentGoalSelect, Input, Select, Textarea, Title } from 'common/components';
import { POINTS_OPTIONS, STATUSES_OPTIONS } from 'common/consts';
import {
  BusinessRadio,
  CoreFormLayout,
  FieldsGrid,
  GridItem,
  Section,
  SectionsWrapper,
} from 'templates/core';
import { TitleInput } from 'templates/details';

import { MiniFields, fields, validationSchema } from '../consts';
import { useSave } from '../hooks';

import MiniMediaPicker from './MiniMediaPicker';
import RecipesPicker from './RecipesPicker';
import MeditationsPicker from './MeditationsPicker';
import PodcastsPicker from './PodcastsPicker';
import FactsPicker from './FactsPicker';
import WorkoutsPicker from './WorkoutsPicker';
import MinisTagsPicker from './MinisTagsPicker';
import MinisBlogPicker from 'modules/blogs/components/MinisBlogPicker';

const MinisCore = ({
  pk,
  blog_pk,
  defaultValues,
  layout = 'overview',
}: CoreProps<MiniFields>) => {
  const [isTags, setIsTags] = React.useState(false);
  const { save, isSaving, error, created } = useSave(isTags, pk, blog_pk);

  return (
    <>
      <CoreFormLayout
        onSubmit={save}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isSubmitting={isSaving}
        error={error}
      >
        {layout === 'overview' && (
          <Section>{<TitleInput name={fields.title.name} />}</Section>
        )}
        <Section>
          <FieldsGrid>
            <GridItem compact>
              <Select
                name={fields.status.name}
                options={STATUSES_OPTIONS}
                readOnly={!created}
              />
            </GridItem>
            {layout === 'creation' && (
              <GridItem>
                <Input name={fields.title.name} label={fields.title.label} />
              </GridItem>
            )}
          </FieldsGrid>
        </Section>
        <Section>
          <Title marginBottom={2.5}>General</Title>
          <FieldsGrid>
            <GridItem>
              <Textarea
                name={fields.short_description.name}
                label={fields.short_description.label}
              />
            </GridItem>
            <GridItem>
              <Textarea name={fields.description.name} label={fields.description.label} />
            </GridItem>
            <GridItem compact>
              <Select
                name={fields.punctation.name}
                label={fields.punctation.label}
                options={POINTS_OPTIONS}
              />
            </GridItem>
            <GridItem compact>
              <ContentGoalSelect
                isMulti
                name={fields.content_goals.name}
                label={fields.content_goals.label}
              />
            </GridItem>
            <GridItem compact>
              <BusinessRadio />
            </GridItem>
          </FieldsGrid>
        </Section>
      </CoreFormLayout>
      <SectionsWrapper pk={created}>
        <MinisTagsPicker setIsTags={setIsTags} />
        <MiniMediaPicker />
        <MinisBlogPicker />
        <RecipesPicker />
        <MeditationsPicker />
        <PodcastsPicker />
        <FactsPicker />
        <WorkoutsPicker />
      </SectionsWrapper>
    </>
  );
};

export default MinisCore;
