import * as React from 'react';

import { CollapsibleSection } from 'common/components';
import { PK } from 'common/types';
import { TAGGING_CONTENT_TYPES } from 'modules/tagging/consts';
import { TagsForm } from 'modules/tagging/components';
import { meditationsTagsValidationSchema } from '../consts';
import { useMeditationsTags, useMeditationsTagsUpdate } from '../queries';

type Props = {
  setIsTags: (isTags: boolean) => void;
  pk?: PK;
};

const MeditationsTagsPicker = ({ pk, setIsTags }: Props) => {
  const { data: meditationsTagData } = useMeditationsTags(pk);
  const {
    mutate: updateMeditation,
    isSuccess,
    isLoading,
    isError,
  } = useMeditationsTagsUpdate();

  const isTags =
    meditationsTagData?.category &&
    Array.isArray(meditationsTagData?.category) &&
    meditationsTagData?.category.length > 0;

  React.useEffect(() => {
    setIsTags(!!isTags);
  }, [isTags, setIsTags]);

  const saveTags = (values: any) => {
    const payload = {
      ...(values.amount && { amount: Number(values.amount) }),
      ...(values.amount_unit && { amount_unit: values.amount_unit[0].value }),
      ...(Array.isArray(values.contribution_rating) &&
        values.contribution_rating.length && {
          contribution_rating: values.contribution_rating[0].value,
        }),
      ...(Array.isArray(values.category) &&
        values.category.length && {
          category: values.category.map(({ value }: any) => value),
        }),
      ...(values.frequency && { frequency: Number(values.frequency) }),
      ...(Array.isArray(values.habit) &&
        values.habit.length && { habit: values.habit.map(({ value }: any) => value) }),

      ...(values.subcategory.length && {
        subcategory: values.subcategory.map(({ value }: any) => value),
      }),
      ...(Array.isArray(values.term) &&
        values.term.length && {
          term: values.term[0].value,
        }),

      pk,
    };
    updateMeditation(payload as any);
  };

  return (
    <>
      <CollapsibleSection
        title="Add tags"
        saveStatus={{ isSuccess, isLoading, isError }}
        disabled={!pk}
        divider={false}
      >
        <TagsForm
          validationSchema={meditationsTagsValidationSchema}
          contentType={TAGGING_CONTENT_TYPES.MEDITATION}
          defaultValues={meditationsTagData}
          onSubmit={saveTags}
          isSubmitting={isLoading}
        />
      </CollapsibleSection>
    </>
  );
};

export default MeditationsTagsPicker;
