import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Series } from 'base/api/types';
import { transformContentGoals } from 'base/api/utils';
import { POINTS_OPTIONS, STATUSES_OPTIONS } from 'common/consts';
import { PK } from 'common/types';
import { DetailsCard, DetailsSuspense } from 'templates/details';

import SeriesCore from '../components/SeriesCore';
import { fields, SeriesFields } from '../consts';
import { useSeriesDetails } from '../queries';
import { FeatureFlags } from 'common/utils/featureFlags';

const { challenges } = FeatureFlags;

const transformSeries = (raw: Series): SeriesFields => ({
  [fields.title.name]: raw.title,
  [fields.short_description.name]: raw.short_description,
  [fields.description.name]: raw.description,
  [fields.status.name]: STATUSES_OPTIONS.filter(({ value }) => value === raw.status),
  [fields.content_goals.name]:
    raw.content_goals && transformContentGoals(raw.content_goals),
  [fields.punctation.name]: POINTS_OPTIONS.filter(
    ({ value }) => value === raw.punctation,
  ),
  [fields.start_date.name]: raw.start_date ? new Date(raw.start_date) : null,
  [fields.end_date.name]: raw.end_date ? new Date(raw.end_date) : null,
  [fields.is_challenge.name]: challenges() ? raw.is_challenge : false,
  [fields.challenge_start_date.name]: raw.challenge_start_date
    ? new Date(raw.challenge_start_date)
    : null,
});

const SeriesDetails = () => {
  const { pk } = useParams<{ pk?: PK }>();
  const { data, ...query } = useSeriesDetails(pk);
  const defaultValues = data && transformSeries(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading} error={query.error}>
        <SeriesCore pk={pk} blog_pk={data?.blog_pk} defaultValues={defaultValues} />
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default SeriesDetails;
