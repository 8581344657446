import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Upload = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 22 22" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path
          d="M10 16L10 0M6 4L10 0 14 4M5 9L3 9 0 20 20 20 17 9 15 9"
          transform="translate(1 1)"
        />
      </g>
    </Svg>
  );
};

Upload.defaultProps = {
  size: '22px',
  color: 'blue',
  hoverColor: 'blue',
};

export default Upload;
