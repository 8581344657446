import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Search = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 22 22" {...props}>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Media"
          transform="translate(-1125.000000, -122.000000)"
          stroke="currentColor"
          strokeWidth="2"
        >
          <g id="Navigation_CMS" transform="translate(0.000000, 88.000000)">
            <g id="Group-4-Copy" transform="translate(1102.000000, 16.000000)">
              <g id="zoom-2" transform="translate(24.000000, 19.000000)">
                <line x1="20" y1="20" x2="13.656" y2="13.656" id="Path"></line>
                <circle id="Oval" cx="8" cy="8" r="8"></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </Svg>
  );
};

Search.defaultProps = {
  size: '22px',
  color: 'black',
};

export default Search;
