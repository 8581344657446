import Axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import apiUrls from 'base/api/urls';
import { StandardError } from 'base/api/errors';
import { BackgroundTask, ListResponse } from 'base/api/types';
import { CommonVars } from 'common/types';
import { composeUrl } from 'common/utils';

export const useTasksList = () => {
  return useQuery<ListResponse<BackgroundTask>, StandardError>({
    queryKey: apiUrls.TASKS.LIST,
  });
};

export const useTaskRestart = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, StandardError, CommonVars>(
    ({ pk }) => Axios.post(composeUrl(apiUrls.TASKS.RESTART, { params: { pk } })),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.TASKS.LIST);
      },
    },
  );
};
