import { DefaultTheme } from 'styled-components';

import { isNumber } from 'common/utils';
import { ThemeSpacer } from 'shared/theme';

export const resolveSpacer = (theme: DefaultTheme, value?: ThemeSpacer): string => {
  if (!value && value !== 0) {
    return '';
  } else if (isNumber(value)) {
    return theme.spacer.times(value);
  } else {
    return theme.spacer[value];
  }
};

export const resolveSpacersArray = (
  theme: DefaultTheme,
  list?: ThemeSpacer | ThemeSpacer[],
): string => {
  if (!list) {
    return '';
  } else if (Array.isArray(list)) {
    return list.map((value) => resolveSpacer(theme, value)).join(' ');
  } else {
    return resolveSpacer(theme, list);
  }
};
