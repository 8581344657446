import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Pause = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 17 17" {...props}>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Media"
          transform="translate(-522.000000, -350.000000)"
          stroke="currentColor"
          strokeWidth="2"
        >
          <g id="button-pause" transform="translate(523.000000, 351.000000)">
            <rect id="Rectangle" x="0" y="0" width="4.5" height="15"></rect>
            <rect id="Rectangle" x="10.5" y="0" width="4.5" height="15"></rect>
          </g>
        </g>
      </g>
    </Svg>
  );
};

Pause.defaultProps = {
  size: '16px',
  color: 'black',
  hoverColor: 'black',
};

export default Pause;
