import { Series } from 'base/api/types';
import { toastMessages } from 'common/toastMessages';
import { PK } from 'common/types';
import { useMinisUpdate } from 'modules/minis/queries';
import { useSeriesUpdate } from 'modules/series/queries';
import { useToast } from 'modules/toast';

import { BlogFields } from './consts';
import { useBlogUpdate, useBlogCreate } from './queries';
import { BlogQueryVars } from './types';
import { FeatureFlags } from 'common/utils/featureFlags';

const { challenges } = FeatureFlags;

export const useSaveSeriesBlog = (pk?: PK) => {
  const updateBlog = useBlogUpdate();
  const updateSeries = useSeriesUpdate();
  const createBlog = useBlogCreate();
  const { toast } = useToast();

  const isLoading = createBlog.isLoading || updateBlog.isLoading;
  const isError = createBlog.isError || updateBlog.isError;
  const isSuccess = createBlog.isSuccess || updateBlog.isSuccess;

  const save = (blogValues: BlogFields, data?: Series) => {
    if (!pk) {
      return null;
    }
    createBlog.mutate(blogValues, {
      onSuccess: (res) => {
        const payload = {
          blog_pk: res.pk,
          pk: data?.pk,
          title: data?.title,
          description: data?.description,
          short_description: data?.short_description,
          punctation: data?.punctation,
          status: data?.status,
          start_date: data?.start_date,
          end_date: data?.end_date,
          content_goal_pks: data?.content_goals.map((goal) => goal.pk),
          is_challenge: challenges() ? data?.is_challenge ?? false : false,
          challenge_start_date: data?.challenge_start_date,
        };
        updateSeries.mutate(payload, {
          onSuccess: () => {
            toast(toastMessages.SUCCESS.GENERIC_SUCCESS, 'success');
          },
          onError: (error) => {
            toast(error.message, 'error');
          },
        });
      },
    });
  };
  const update = (blogValues: BlogQueryVars) => {
    updateBlog.mutate(blogValues);
  };

  return { save, update, isLoading, isError, isSuccess };
};

export const useSaveMinisBlog = (pk?: PK) => {
  const updateBlog = useBlogUpdate();
  const updateMini = useMinisUpdate();
  const createBlog = useBlogCreate();

  const isLoading = createBlog.isLoading || updateBlog.isLoading;
  const isError = createBlog.isError || updateBlog.isError;
  const isSuccess = createBlog.isSuccess || updateBlog.isSuccess;

  const save = (values: BlogFields) => {
    if (!pk) {
      return null;
    }
    createBlog.mutate(values, {
      onSuccess: ({ pk: blog_pk }) => {
        updateMini.mutate({
          pk: pk,
          blog_pk: blog_pk,
        });
      },
    });
  };
  const update = (values: BlogQueryVars) => {
    updateBlog.mutate(values);
  };

  return { save, update, isLoading, isError, isSuccess };
};
