import * as React from 'react';
import styled from 'styled-components';
import { GridChildComponentProps } from 'react-window';

import { MediaObject } from 'base/api/types';
import { resolveSpacersArray } from 'common/styles/utils';
import { MEDIA_OPTIONS, MEDIA_TYPES } from 'common/consts';
import { Img, PrimaryKey } from 'common/components';

import Player from '../Player';
import MediaCardMenu from './MediaCardMenu';

export interface MediaCardProps extends GridChildComponentProps {
  data: MediaObject[];
}

class MediaCard extends React.PureComponent<MediaCardProps> {
  render() {
    const { style, columnIndex, rowIndex, data, children } = this.props;
    const index = columnIndex + rowIndex * 4;
    const itemData = data && data[index];

    if (!itemData) {
      return null;
    }

    const { pk, url, name, media_type, thumbnail } = itemData;
    const type = MEDIA_OPTIONS.find(({ type }) => type === media_type);

    if (!type) {
      return null;
    }

    return (
      <GridItemContainer style={style}>
        <MediaCardContainer>
          <TypeChip>{type.label}</TypeChip>
          {children || (
            <>
              <PreviewContainer>
                {type.type === MEDIA_TYPES.VIDEO ? (
                  <Player
                    url={url}
                    poster={thumbnail || ''}
                    controls={{ progress: false }}
                    hideControls
                  />
                ) : type.type === MEDIA_TYPES.AUDIO ? (
                  <Player
                    url={url}
                    initialState={{ muted: false }}
                    controls={{ fullscreen: false }}
                  />
                ) : (
                  <Img src={thumbnail || url} alt={name} />
                )}
              </PreviewContainer>
              <MediaCardFooter>
                <FooterRow>
                  <MediaTitle>{name}</MediaTitle>
                  <MediaCardMenu pk={pk} />
                </FooterRow>
                <PrimaryKey pk={pk} />
              </MediaCardFooter>
            </>
          )}
        </MediaCardContainer>
      </GridItemContainer>
    );
  }
}

const GridItemContainer = styled.div`
  padding: ${({ theme }) => theme.spacer.times(2)};
  height: 302px;

  &:nth-child(4n + 1) {
    padding-left: ${({ theme }) => theme.spacer.times(4)};
  }

  &:nth-child(4n + 4) {
    padding-right: ${({ theme }) => theme.spacer.times(4)};
  }
`;

const MediaCardContainer = styled.div`
  position: relative;
  height: 100%;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.border.radius.standard};
  box-shadow: 0 1px 1px 1px ${({ theme }) => theme.color.blueSuperLight};
`;

const MediaCardFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => resolveSpacersArray(theme, [2, 2, 2, 3])};
`;

const FooterRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const MediaTitle = styled.p`
  font-size: ${({ theme }) => theme.font.size.standard};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0 0 ${({ theme }) => theme.spacer.standard};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PreviewContainer = styled.div`
  width: 100%;
  height: 200px;
  background-color: ${({ theme }) => theme.color.blueSuperLight};
  border-top-left-radius: ${({ theme }) => theme.border.radius.standard};
  border-top-right-radius: ${({ theme }) => theme.border.radius.standard};

  ${Img} {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: ${({ theme }) => theme.border.radius.standard};
    border-top-right-radius: ${({ theme }) => theme.border.radius.standard};
  }
`;

const TypeChip = styled.div`
  position: absolute;
  z-index: 3;
  top: ${({ theme }) => theme.spacer.times(3)};
  left: ${({ theme }) => theme.spacer.times(3)};
  height: ${({ theme }) => theme.spacer.times(3)};
  padding: 0 ${({ theme }) => theme.spacer.big};
  font-size: ${({ theme }) => theme.font.size.tiny};
  line-height: ${({ theme }) => theme.spacer.times(3)};
  border-radius: ${({ theme }) => theme.border.radius.standard};
  color: ${({ theme }) => theme.color.black};
  background-color: ${({ theme }) => theme.color.white};
  text-transform: lowercase;
  letter-spacing: 0.5px;
`;

export default MediaCard;
