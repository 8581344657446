import styled from 'styled-components';

import { ThemeSpacer } from 'shared/theme';
import { resolveSpacersArray } from 'common/styles/utils';

type Props = {
  padding?: ThemeSpacer[] | ThemeSpacer;
  margin?: ThemeSpacer[] | ThemeSpacer;
  float?: 'left';
};

const Wrapper = styled.div<Props>`
  margin: ${({ theme, margin, float }) =>
    resolveSpacersArray(theme, float ? [0, 0, 0, 'auto'] : margin || [0])};
  padding: ${({ theme, padding }) => resolveSpacersArray(theme, padding || [0])};
`;

export default Wrapper;
