import * as React from 'react';
import styled from 'styled-components';

import { CollectionContent } from 'base/api/types';
import { CONTENT_TYPES } from 'common/consts';
import { CollapsibleSection, Wrapper } from 'common/components';
import PlainSelect from 'common/components/PlainSelect';
import { SaveStatusProps } from 'common/components/SaveStatus';
import { useSearchParams } from 'common/hooks';
import { getProjectContent } from 'common/utils';
import { PK } from 'common/types';
import { FieldsGrid, GridItem } from 'templates/core';

import {
  CollectionOption,
  VISIBILITY_FILTERS,
  VISIBILITY_FILTER_OPTIONS,
} from '../consts';
import DraggableList from './DraggableList';

interface DynamicSectionProps {
  pk?: PK;
  changedItem?: PK;
  isFirst: boolean;
  content: CollectionContent;
  saveStatus?: SaveStatusProps;
  onReorder: (contentPk: PK, items: CollectionOption[]) => void;
  onChangeVisibility: (contentPk: PK, item: CollectionOption) => void;
}

const DynamicSection = ({
  pk,
  isFirst,
  content,
  saveStatus,
  changedItem,
  onReorder,
  onChangeVisibility,
}: DynamicSectionProps) => {
  const contentPk = content.pk;
  const contentType = content.content_type;
  const { contentType: selectedContentType } = useSearchParams();
  const [visibilityFilter, setVisibilityFilter] = React.useState(VISIBILITY_FILTERS.ALL);

  const contentTitle = getContentDescription(contentType)?.title ?? '';
  const allItems = content.items.map(
    (item): CollectionOption => ({
      label: item.title,
      value: item.pk,
      isVisible: item.is_visible,
      isLoading: item.pk === changedItem,
    }),
  );
  const defaultValues = allItems.filter(filterItemsByVisibility(visibilityFilter));
  const isEmpty = defaultValues.length === 0;

  const renderSection = !!contentTitle && content.items.length > 0;

  return renderSection ? (
    <CollapsibleSection
      key={content.content_type}
      title={contentTitle}
      saveStatus={saveStatus}
      disabled={!pk}
      divider={!isFirst}
      shouldOpen={selectedContentType === contentType}
    >
      <StyledWrapper $isEmpty={isEmpty}>
        <FieldsGrid>
          <GridItem compact>
            <PlainSelect
              label="Content"
              options={VISIBILITY_FILTER_OPTIONS}
              onChange={(selected) => setVisibilityFilter(selected.value)}
            />
          </GridItem>
        </FieldsGrid>
      </StyledWrapper>
      <Wrapper margin={[1, 0, 1]}>
        <DraggableList
          defaultValues={defaultValues}
          isDraggable={visibilityFilter === VISIBILITY_FILTERS.ALL}
          onReorder={(items) => onReorder(contentPk, items)}
          onChangeVisibility={(item) => onChangeVisibility(contentPk, item)}
        />
      </Wrapper>
    </CollapsibleSection>
  ) : null;
};

const StyledWrapper = styled.div<{ $isEmpty: boolean }>`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacer.times(2)};
  min-height: ${({ theme, $isEmpty }) => ($isEmpty ? theme.spacer.times(20) : 0)};
`;

const filterItemsByVisibility = (visibility: VISIBILITY_FILTERS) => (
  item: CollectionOption,
) =>
  visibility === VISIBILITY_FILTERS.ALL ||
  (visibility === VISIBILITY_FILTERS.VISIBLE && item.isVisible) ||
  (visibility === VISIBILITY_FILTERS.HIDDEN && !item.isVisible);

const getContentDescription = (contentType: CONTENT_TYPES) => {
  const content = getProjectContent();
  return content.find((value) => value.type === contentType);
};

export default DynamicSection;
