import * as React from 'react';
import styled from 'styled-components';
import { useWatch } from 'react-hook-form';

import { PK } from 'common/types';
import { MediaObject } from 'base/api/types';
import MediaItem from './MediaItem';
import placeholder from 'assets/graphics/placeholder.jpeg';
import { MEDIA_TYPES } from 'common/consts';

type Props = {
  name: string;
  onRemove: (pk: PK) => () => void;
  mediaType: MEDIA_TYPES;
};

const MediaValues = ({ name, onRemove, mediaType }: Props) => {
  const values = useWatch<Array<MediaObject | null>>({ name }) || [];
  const shouldHaveThumbnail =
    mediaType === MEDIA_TYPES.AUDIO || mediaType === MEDIA_TYPES.VIDEO;
  return (
    <MediaItemsContainer number={values.length}>
      {(values.filter(Boolean) as MediaObject[]).map(({ pk, name, url, thumbnail }) => {
        const previewUrl = thumbnail || (shouldHaveThumbnail ? placeholder : url);
        return (
          <MediaItem key={pk} name={name} url={previewUrl} onRemove={onRemove(pk)} />
        );
      })}
    </MediaItemsContainer>
  );
};

const MediaItemsContainer = styled.div<{ number: number }>`
  padding: ${({ theme }) => theme.spacer.big} 0;
  height: auto;
  max-height: ${({ number, theme }) => theme.spacer.times(number * 20)};
  transition: max-height 0.4s ease-out 0.4s;
`;

export default MediaValues;
