import * as React from 'react';

import { ErrorBox, Input } from 'common/components';
import { CoreProps, PK } from 'common/types';
import { TitleInput } from 'templates/details';
import { CoreFormLayout, GridItem, Section, SectionsWrapper } from 'templates/core';

import { fields, ScenarioFields, validationSchema } from '../consts';
import { useSave } from '../hooks';
import {
  useScenarioWeeksAdd,
  useScenarioWeeksOrder,
  useScenarioWeeksRemove,
} from '../queries';
import ScenarioCompanies from './ScenarioCompanies';
import ScenarioWeeks from './ScenarioWeeks';

const ScenarioCore = ({
  pk,
  defaultValues,
  children,
  layout = 'overview',
}: CoreProps<ScenarioFields>) => {
  const { save, isSaving, error, created: createdPk } = useSave(pk);
  const addWeeks = useScenarioWeeksAdd();
  const reorderWeeks = useScenarioWeeksOrder();
  const removeWeeks = useScenarioWeeksRemove();
  const saveStatus = {
    isSuccess: reorderWeeks.isSuccess || addWeeks.isSuccess || removeWeeks.isSuccess,
    isLoading: reorderWeeks.isLoading || addWeeks.isLoading || removeWeeks.isLoading,
    isError: reorderWeeks.isError || addWeeks.isError || removeWeeks.isError,
  };
  const combinedError =
    error || reorderWeeks.error || addWeeks.error || removeWeeks.error;
  const companyList = defaultValues?.companies || [];

  const handleAdd = () => {
    if (createdPk) {
      addWeeks.mutate({ pk: createdPk });
    }
  };

  const handleReorder = (items: PK[]) => {
    if (createdPk) {
      const payload = {
        pk: createdPk,
        weeks_pks: items,
      };

      reorderWeeks.mutate(payload);
    }
  };

  const handleRemove = (weekPk: PK) => {
    if (createdPk) {
      removeWeeks.mutate({ pk: createdPk, weekPk });
    }
  };

  return (
    <>
      <CoreFormLayout
        onSubmit={save}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isSubmitting={isSaving}
        error={error}
      >
        {layout === 'overview' && (
          <Section>
            <TitleInput name={fields.title.name} />
          </Section>
        )}
        <Section>
          {layout === 'creation' && (
            <GridItem>
              <Input name={fields.title.name} label={fields.title.label} />
            </GridItem>
          )}
        </Section>
        <Section>
          <ScenarioCompanies defaultValues={companyList} />
          {createdPk && (
            <ScenarioWeeks
              {...saveStatus}
              onAdd={handleAdd}
              onRemove={handleRemove}
              onReorder={handleReorder}
              scenarioPk={createdPk}
            />
          )}
          <ErrorBox error={combinedError} />
        </Section>
      </CoreFormLayout>
      <SectionsWrapper pk={createdPk}>{children}</SectionsWrapper>
    </>
  );
};

export default ScenarioCore;
