import React from 'react';

import { PK } from 'common/types';

import { MiniFields, fields } from './consts';
import { useMinisCreate, useMinisUpdate } from './queries';
import { useToast } from 'modules/toast';
import { toastMessages } from 'common/toastMessages';

export const useSave = (isTags: boolean, pk?: PK, blog_pk?: PK) => {
  const [created, setCreated] = React.useState(pk);
  const { toast } = useToast();
  const create = useMinisCreate();
  const update = useMinisUpdate();
  const isSaving = create.isLoading || update.isLoading;
  const error = create.error || update.error;

  const save = (values: MiniFields) => {
    if (values?.status[0].value === 'ACTIVE' && !isTags) {
      toast(toastMessages.ERROR.CHANGING_STATUS_WITHOUT_TAGS, 'error');
      return;
    }
    const payload = {
      title: values[fields.title.name],
      description: values[fields.description.name],
      short_description: values[fields.short_description.name],
      punctation: values[fields.punctation.name][0].value,
      content_goal_pks: values[fields.content_goals.name].map((option) => option.value),
      is_b2b: values[fields.is_b2b.name] === 'true',
      blog_pk,
    };
    if (created) {
      update.mutate({ ...payload, pk: created, status: values.status[0].value });
    } else {
      create.mutate(payload, {
        onSuccess: ({ pk }) => {
          setCreated(pk);
        },
      });
    }
  };

  return { save, created, isSaving, error };
};
