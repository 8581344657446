import * as React from 'react';

import { MEDIA_TYPES, STATUSES } from 'common/consts';
import { CoreProps, Field, PK } from 'common/types';
import { Input, Select, Textarea, Title, Subtitle, Checkbox } from 'common/components';
import { CoreFormLayout, FieldsGrid, GridItem, Section } from 'templates/core';
import {
  BadgeFields,
  IMAGE_FIELDS,
  DESCRIPTION_FIELDS,
  fields,
  validationSchema,
  CollectionsColorMap,
  CriteriaMinimumCountOptions,
  TOAST_MESSAGES,
  BADGE_STATUSES_OPTIONS,
} from '../consts';
import { TitleInput } from 'templates/details';
import { MediaObject } from 'base/api/types';
import { BadgeCollectionColor } from './BadgeCollectionColor';
import {
  useBadgeUpdate,
  useBadgeCreate,
  useBadgeCollectionsList,
  BadgeUpdateVars,
  BadgeCreateVars,
} from '../queries';
import BadgeMediaUrlPicker from './BadgeMediaUrlPicker';
import BadgeMinisPicker from './BadgeMinisPicker';
import { useToast } from 'modules/toast';
import { useMinisList } from 'modules/minis/queries';

type BadgeDecorativeFields = {
  isMultilevel?: boolean;
  isStreak?: boolean;
};

const useSave = (pk?: PK) => {
  const [created, setCreated] = React.useState(pk);
  const update = useBadgeUpdate();
  const create = useBadgeCreate();
  const isSaving = create.isLoading || update.isLoading;
  const error = create.error || update.error;

  const save = (values: BadgeFields) => {
    const payload: BadgeUpdateVars | BadgeCreateVars = {
      title: values[fields.title.name],
      status: values[fields.status.name][0].value,
      collection_pk: values[fields.collection.name][0].value as PK,
      descriptions: {
        collected: values[fields.descriptions_collected.name],
        in_progress:
          values[fields.descriptions_in_progress.name] ||
          values[fields.descriptions_collected.name],
        not_collected: values[fields.descriptions_not_collected.name],
      },
      is_hidden: values[fields.is_hidden.name],
      images: {
        granted_url: values[fields.images_granted_url.name],
        in_progress_url:
          values[fields.images_in_progress_url.name] ||
          values[fields.images_granted_url.name],
        inactive_url: values[fields.images_inactive_url.name],
      },
    };

    if (
      values[fields.criteria_possible_pks.name] ||
      values[fields.criteria_minimum_count.name]
    ) {
      payload.criteria = {
        possible_pks: values[fields.criteria_possible_pks.name],
        minimum_count: values[fields.criteria_minimum_count.name][0].value,
      };
    }

    if (created) {
      update.mutate({
        ...payload,
        pk: created,
      });
    } else {
      create.mutate(payload as BadgeCreateVars, {
        onSuccess: ({ pk }) => {
          setCreated(pk);
        },
      });
    }
  };

  return { save, created, isSaving, error };
};

const BadgeCore = ({
  pk,
  defaultValues,
  isMultilevel,
  isStreak,
  layout,
}: CoreProps<BadgeFields> & BadgeDecorativeFields) => {
  const { toast } = useToast();
  const minisList = useMinisList();
  const { save, isSaving, error, created } = useSave(pk);
  const { data: badgeCollections, isLoading } = useBadgeCollectionsList();

  const handleMinisValidation = (values: Partial<BadgeFields>): boolean => {
    const miniPks = values?.criteria_possible_pks ?? [];
    const criteriaMinimumCount = (
      (values?.criteria_minimum_count || [])[0] || CriteriaMinimumCountOptions[0]
    ).value;
    const activeMinisCount = miniPks.reduce<number>((total, pk) => {
      return minisList.data?.values.find((match) => match.pk === pk)?.status ===
        STATUSES.ACTIVE
        ? total + 1.0
        : total;
    }, 0);

    if (!isStreak && activeMinisCount < criteriaMinimumCount) {
      toast(
        TOAST_MESSAGES.MINIMUM_MINI_COUNT_NOT_REACHED.replaceAll(
          `{REQUIRED}`,
          `${criteriaMinimumCount}`,
        ),
        'error',
      );

      return false;
    }

    return true;
  };

  return (
    <>
      <CoreFormLayout
        onSubmit={(values) => {
          const minisValid = handleMinisValidation(values);

          if (!minisValid) {
            return;
          }

          save(values);
        }}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isSubmitting={isSaving}
        error={error}
      >
        <Section>
          {layout === 'overview' && (
            <GridItem>
              <TitleInput name={fields.title.name} />
            </GridItem>
          )}
          {layout === 'creation' && (
            <GridItem>
              <Title size="big">Create Badge</Title>
            </GridItem>
          )}
          {defaultValues && isMultilevel && <Subtitle label="multilevel" />}
        </Section>
        <Section>
          <FieldsGrid>
            <GridItem compact>
              <Select
                name={fields.status.name}
                options={BADGE_STATUSES_OPTIONS}
                readOnly={!created}
              />
            </GridItem>
            {layout === 'creation' && (
              <GridItem>
                <Input name={fields.title.name} label={fields.title.label} />
              </GridItem>
            )}
          </FieldsGrid>
        </Section>
        <Section>
          <Title marginBottom={2.5}>General</Title>
          <FieldsGrid>
            <GridItem compact>
              <Select
                isLoading={isLoading}
                name={fields.collection.name}
                label={fields.collection.label}
                options={(badgeCollections?.values || []).map((collection) => ({
                  label: collection.title,
                  value: collection.pk,
                }))}
              />
            </GridItem>
            <GridItem compact>
              <BadgeCollectionColor
                name={fields.collection.name}
                colorMap={CollectionsColorMap}
              />
            </GridItem>
            {Object.keys(DESCRIPTION_FIELDS).map((key) => {
              const field = (fields as any)[key] as Field<string>;

              return (
                <GridItem key={key}>
                  <Textarea name={field.name} label={field.label} />
                </GridItem>
              );
            })}
            <GridItem>
              <Checkbox
                name={fields.is_hidden.name}
                label={fields.is_hidden.label}
                dimmed
              />
            </GridItem>
          </FieldsGrid>
        </Section>
        <Section>
          <Title marginBottom={2.5}>Images</Title>
          <FieldsGrid>
            {Object.keys(IMAGE_FIELDS).map((key) => {
              const field = (fields as any)[key] as Field<string>;
              let defaultValue = defaultValues
                ? (defaultValues as any)[field.name]
                : null;

              if (defaultValue) {
                defaultValue = [
                  {
                    url: defaultValue,
                    media_type: MEDIA_TYPES.IMAGE,
                    name: 'images_inactive_url',
                    pk: null as any,
                    duration: null,
                    thumbnail: null,
                  },
                ];
              }

              return (
                <GridItem key={field.name}>
                  <Title size="standard" marginBottom={1.5}>
                    {field.label}
                  </Title>
                  <BadgeMediaUrlPicker
                    name={field.name}
                    defaultValue={defaultValue as MediaObject[] | null}
                  />
                </GridItem>
              );
            })}
          </FieldsGrid>
        </Section>
        {defaultValues && isMultilevel && (
          <BadgeMinisPicker defaultValues={defaultValues} />
        )}
      </CoreFormLayout>
    </>
  );
};

export default BadgeCore;
