import * as React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DeepPartial,
  FieldName,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  UnpackNestedValue,
  useForm,
} from 'react-hook-form';

import { StandardError } from 'base/api/errors';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface FormProps<TFields extends object> {
  onSubmit: SubmitHandler<TFields>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  validationSchema?: Yup.ObjectSchema<Yup.Shape<object | undefined, TFields>, object>;
  defaultValues?: UnpackNestedValue<DeepPartial<TFields>>;
  children?: React.ReactNode;
  error?: StandardError | null;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function Form<TFields extends object>({
  children,
  onSubmit,
  defaultValues,
  validationSchema,
  error,
}: FormProps<TFields>) {
  const form = useForm<TFields>({
    defaultValues,
    mode: 'onSubmit',
    resolver: validationSchema && yupResolver(validationSchema),
  });
  const { setError, handleSubmit, reset } = form;
  React.useEffect(() => {
    if (typeof defaultValues === 'object' && Object.keys(defaultValues).length) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  React.useEffect(() => {
    if (error && error.validation) {
      error.validation.forEach(({ type, name, message }) => {
        setError(name as FieldName<TFields>, { type, message });
      });
    }
  }, [error, setError]);

  const onErrorDev = React.useCallback<SubmitErrorHandler<TFields>>((error) => {
    if (process.env.NODE_ENV !== 'development') {
      return;
    }

    /* eslint-disable no-console */
    console.info({ error });
  }, []);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit, onErrorDev)}>{children}</form>
    </FormProvider>
  );
}

export default Form;
