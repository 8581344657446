import * as React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';

import { PK } from 'common/types';
import { Button, CollapsibleSection, Input, Wrapper } from 'common/components';
import { BUTTON_SIZES, BUTTON_VARIANTS } from 'common/consts';
import { Bin } from 'common/icons';
import { useCompanyEmails, useCompanyEmailsUpdate } from '../queries';
import { resolveSpacersArray } from 'common/styles/utils';

const fieldName = 'emails';

const validationSchema = Yup.object().shape({
  [fieldName]: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().required().email().label('Email'),
    }),
  ),
});

type Fields = {
  [fieldName]?: { value: string }[];
};

const margins = {
  field: [0, 0, 2],
};

const getDefaultValues = (emails?: string[]) => ({
  emails: emails?.map((value) => ({ value })),
});

type Props = {
  pk?: PK;
};

const BillingEmails = ({ pk }: Props) => {
  const { data } = useCompanyEmails(pk);
  const { mutate, isLoading, isSuccess, isError } = useCompanyEmailsUpdate();
  const saveStatus = { isLoading, isSuccess, isError };

  const onSubmit = (values: Fields, reset: (values: Fields) => void) => {
    const emails = values[fieldName]?.map(({ value }) => value) || [];
    if (pk) {
      mutate(
        { pk, emails },
        {
          onSuccess: (data) => {
            reset(getDefaultValues(data.emails));
          },
        },
      );
    }
  };

  return (
    <CollapsibleSection
      title="Add license info"
      disabled={!pk || !data}
      saveStatus={saveStatus}
    >
      <EmailsForm
        defaultValues={getDefaultValues(data?.emails)}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </CollapsibleSection>
  );
};

interface EmailFormProps {
  defaultValues: Fields;
  onSubmit: (values: Fields, reset: (values: Fields) => void) => void;
  isLoading?: boolean;
}

const EmailsForm = ({ defaultValues, onSubmit, isLoading }: EmailFormProps) => {
  const theme = useTheme();
  const methods = useForm<Fields>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
    defaultValues,
  });
  const {
    reset,
    handleSubmit,
    control,
    formState: { isDirty },
  } = methods;
  const { fields, append, remove } = useFieldArray({
    name: fieldName,
    control,
  });

  const submit = (values: Fields) => {
    onSubmit(values, reset);
  };

  const handleRemove = (index: number) => () => {
    if (fields.length === 1) {
      handleSubmit(() => {
        onSubmit({ emails: [] }, reset);
      })();
    }
    remove(index);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)}>
        <Wrapper margin={margins.field}>
          <Button
            onClick={append}
            size={BUTTON_SIZES.SMALL}
            variant={BUTTON_VARIANTS.SECONDARY}
          >
            Add email
          </Button>
        </Wrapper>
        {fields.length > 0 ? (
          <Section>
            {fields.map((field, index) => {
              return (
                <Wrapper key={field.id} margin={margins.field}>
                  <Input
                    name={`${fieldName}[${index}].value`}
                    label="Email"
                    defaultValue={field.value || ''}
                    backIcon={
                      <Button
                        onClick={handleRemove(index)}
                        variant={BUTTON_VARIANTS.ICON}
                      >
                        <Bin
                          title="remove"
                          color={theme.keys.secondaryColor}
                          hoverColor={theme.keys.secondaryColor}
                        />
                      </Button>
                    }
                  />
                </Wrapper>
              );
            })}
            <Wrapper margin={[3, 0, 0]}>
              <Button
                type="submit"
                size={BUTTON_SIZES.SMALL}
                isLoading={isLoading}
                disabled={!isDirty}
              >
                Save
              </Button>
            </Wrapper>
          </Section>
        ) : null}
      </form>
    </FormProvider>
  );
};

const Section = styled.div`
  margin-bottom: ${({ theme }) => theme.spacer.times(5)};
  padding: ${({ theme }) => resolveSpacersArray(theme, [2, 3, 3])};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  background-color: ${({ theme }) => theme.color.greySuperLight};
`;

export default BillingEmails;
