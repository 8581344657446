import Axios from 'axios';
import { QueryClient } from 'react-query';

import { composeUrl } from 'common/utils';
import { URLParams, URLQuery } from 'common/types';

import { ApiUrl } from './urls';

export type QueryKey = [ApiUrl, URLParams, URLQuery];

export type DefaultQueryParams = {
  queryKey: QueryKey;
};

const defaultQueryFn = async ({ queryKey }: DefaultQueryParams) => {
  const [endpoint, params, query] = queryKey;
  const data = await Axios.get(composeUrl(endpoint, { params, query }));

  return data;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      refetchOnWindowFocus: false,
    },
  },
});

export default queryClient;
