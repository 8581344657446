import * as React from 'react';
import styled from 'styled-components';

import { STATUS_SIZES } from 'common/consts';
import { StatusChip } from 'common/components';
import { PlainCheckbox } from 'common/components/Checkbox';
import { SelectOption, SelectOptionLabel } from 'common/components/DropdownPicker';
import { B2B } from 'common/icons';

import PointsDisplay from './PointsDisplay';
import { MiniOption } from './SeriesAssembler';

interface MiniSelectOptionProps {
  item?: MiniOption;
  selected?: boolean;
  isMulti?: boolean;
  handlePick?: (item: MiniOption) => void;
}

const MiniSelectOption = ({
  item,
  selected,
  isMulti,
  handlePick,
}: MiniSelectOptionProps) =>
  item ? (
    <SelectOption onClick={() => handlePick?.(item)}>
      {isMulti && <PlainCheckbox name={item.label} checked={selected} readOnly />}
      <SelectOptionLabel>
        {item.label}
        {item.isB2B && <B2B color="black" backgroundColor="blackSuperAlpha" margin={1} />}
      </SelectOptionLabel>
      {item.status && (
        <OptionChipContainer>
          {item.punctation && <PointsDisplay border value={item.punctation} />}
          <StatusChip status={item.status} size={STATUS_SIZES.SMALL} />
        </OptionChipContainer>
      )}
    </SelectOption>
  ) : null;

const OptionChipContainer = styled.div`
  flex: 0 0 110px;
  display: flex;
`;

export default MiniSelectOption;
