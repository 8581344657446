import * as React from 'react';

import { ErrorBox, Form } from 'common/components';
import { FormProps } from 'common/components/Form';
import { Section, SaveButton } from '.';

// eslint-disable-next-line @typescript-eslint/ban-types
interface CoreFormLayoutProps<TFields extends object> extends FormProps<TFields> {
  children?: React.ReactNode;
  isSubmitting?: boolean;
  onButtonClick?: () => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const CoreFormLayout = <TFields extends object>({
  children,
  isSubmitting,
  error,
  defaultValues,
  ...props
}: CoreFormLayoutProps<TFields>) => {
  return (
    <Form error={error} defaultValues={defaultValues} {...props}>
      {children}
      {error && (
        <Section>
          <ErrorBox error={error} />
        </Section>
      )}
      <SaveButton onClick={props.onButtonClick} isLoading={isSubmitting} />
    </Form>
  );
};

export default CoreFormLayout;
