import * as React from 'react';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { useLogin } from '../queries';
import styled from 'styled-components';
import { Wrapper } from 'common/components';

interface CodeLoginProps {
  email: string;
}

const CodeLoginForm = ({ email }: CodeLoginProps) => {
  const { mutate: login, isError, error: loginError } = useLogin();
  const [digitCode, setDigitCode] = React.useState('');

  const AuthInputRef = React.useRef<AuthCodeRef>(null);

  const handleCodeChange = React.useCallback(
    (digitCode: string) => setDigitCode(digitCode),
    [setDigitCode],
  );

  React.useEffect(() => {
    if (digitCode.length > 5 && email) {
      login({ email: email, digit_code: digitCode });
    }
  }, [email, digitCode, login]);

  return (
    <AuthCodeWrapper margin={[1, 4]}>
      {isError && <ErrorMessage>{loginError?.message}</ErrorMessage>}
      <AuthCode
        allowedCharacters="numeric"
        ref={AuthInputRef}
        onChange={handleCodeChange}
        containerClassName="container"
        inputClassName="input"
      />
    </AuthCodeWrapper>
  );
};

const AuthCodeWrapper = styled(Wrapper)`
  .container {
    padding: 16px;
    display: flex;
    justify-content: space-around;
  }
  .input {
    width: 35px;
    height: 45px;
    font-size: 24px;
    text-align: center;
    color: #494949;
    border: 1px solid ${({ theme }) => theme.color.pink};
    border-radius: 4px;
    background: #fff;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin: auto;
`;

export default CodeLoginForm;
