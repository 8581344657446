import * as React from 'react';
import styled from 'styled-components';

import {
  IconContainer,
  Row,
  SaveStatus,
  SelectedItem,
  Spinner,
  Title,
} from 'common/components';
import { TITLE_VARIANTS } from 'common/consts';
import { ReorderHandler } from 'common/hooks/useDnDList';
import { useDnDList } from 'common/hooks';
import { CircleAdd } from 'common/icons';
import { PK } from 'common/types';

import ScenarioContentWeek from './ScenarioContentItem';
import { useScenarioDetails } from '../queries';
interface ScenarioWeek {
  pk: PK;
  initialPosition?: number;
}

const emptyList: ScenarioWeek[] = [];
interface DraggableListProps {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  scenarioPk: PK;
  onAdd?: () => void;
  onRemove: (item: PK) => void;
  onReorder?: (items: PK[]) => void;
}

const ScenarioWeeks = ({
  isLoading,
  isSuccess,
  isError,
  scenarioPk,
  onReorder,
  onRemove,
  onAdd,
}: DraggableListProps) => {
  const { data: scenarioDetails } = useScenarioDetails(scenarioPk);
  const initialWeekPositions = React.useRef(new Map<PK, number>());
  const weekList: ScenarioWeek[] | null = React.useMemo(
    () =>
      scenarioDetails?.weeks.map((pk) => ({
        pk,
        initialPosition: initialWeekPositions.current.get(pk),
      })) ?? null,
    [scenarioDetails?.weeks],
  );

  React.useEffect(() => {
    const week = initialWeekPositions.current;

    weekList?.forEach((value, index) => {
      const pk = value.pk;

      if (!week.has(pk)) {
        week.set(pk, index);
      }
    });
  }, [weekList]);

  const handleReorder: ReorderHandler<ScenarioWeek> = React.useCallback(
    (data) => {
      onReorder?.(data.map((item) => item.pk));
    },
    [onReorder],
  );

  const { list: selected, getDraggableProps } = useDnDList<ScenarioWeek>({
    initList: weekList || emptyList,
    onReorder: handleReorder,
  });

  return (
    <>
      <Row>
        <Title variant={TITLE_VARIANTS.MAIN}>Weeks:</Title>
        <SaveStatus
          margin={1}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
        />
      </Row>
      <SelectedList>
        {selected.map((item, position, list) => {
          const isLastItem = position === list.length - 1;

          return (
            <StyledSelectedItem
              key={item.pk}
              {...getDraggableProps({
                pk: item.pk,
                position,
                draggable: true,
              })}
            >
              <ScenarioContentWeek
                currentPosition={position}
                initialPosition={item.initialPosition}
                onRemove={isLastItem ? () => onRemove(item.pk) : undefined}
              />
            </StyledSelectedItem>
          );
        })}
        <AddButton onClick={onAdd} type="button">
          <IconContainer>
            {isLoading ? (
              <Spinner size="24px" color="pink" circleColor="pinkSuperLight" />
            ) : (
              <CircleAdd color="pink" />
            )}
          </IconContainer>
        </AddButton>
      </SelectedList>
    </>
  );
};

const SelectedList = styled.div`
  padding-bottom: ${({ theme }) => theme.spacer.times(5)};
`;

const StyledSelectedItem = styled(SelectedItem)`
  padding: ${({ theme }) => theme.spacer.times(2)};
`;

const AddButton = styled.button`
  width: 100%;
  height: 64px;
  cursor: pointer;
  background: ${({ theme }) => theme.color.white};
  margin-top: ${({ theme }) => theme.spacer.standard};
  border: 2px dashed ${({ theme }) => theme.color.blueSuperLight};
`;

export default ScenarioWeeks;
