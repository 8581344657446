import * as React from 'react';

import { Button } from 'common/components';
import { BUTTON_SIZES, BUTTON_VARIANTS } from 'common/consts';
import { CompanyAdminInvitation } from 'base/api/types';
import { useCompanyAdminInvite } from 'modules/companies/queries';
import { companyAdminsContext } from './context';
import { AdminFields } from './consts';
import Form from './Form';

interface Invitation extends Omit<CompanyAdminInvitation, 'pk'> {
  index: number;
}

const empty: Invitation = {
  index: 0,
  email: '',
  first_name: '',
  last_name: '',
  phone_number: '',
};

type Props = {
  children?: React.ReactNode;
};

const Draft = ({ children }: Props) => {
  const [counter, setCounter] = React.useState<number>(1);
  const [list, setList] = React.useState<Invitation[]>([]);

  const handleAdd = () => {
    setList((list) => [...list, { ...empty, index: counter }]);
    setCounter((counter) => counter + 1);
  };

  const handleRemove = (index: number) => () => {
    setList((list) => list.filter((item) => item.index !== index));
  };

  return (
    <>
      <Button
        onClick={handleAdd}
        size={BUTTON_SIZES.SMALL}
        variant={BUTTON_VARIANTS.SECONDARY}
      >
        Extra admin
      </Button>
      {children}
      {list.map(({ index }) => (
        <InvitationForm
          key={`invitation-draft-${index}`}
          onRemove={handleRemove(index)}
        />
      ))}
    </>
  );
};

type FormProps = {
  onRemove: () => void;
};

const defaultValues = {
  email: '',
  first_name: '',
  last_name: '',
};

const InvitationForm = ({ onRemove }: FormProps) => {
  const { companyPk } = React.useContext(companyAdminsContext);
  const { mutate: invite, error, isLoading } = useCompanyAdminInvite();

  const handleSubmit = (values: AdminFields) => {
    if (companyPk) {
      invite(
        {
          pk: companyPk,
          body: {
            ...values,
            phone_number: String(values.phone_number),
          },
        },
        {
          onSuccess: () => {
            onRemove();
          },
        },
      );
    }
  };

  return (
    <Form
      onRemove={onRemove}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default Draft;
