import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

import { resolveSpacersArray } from 'common/styles/utils';

import { useToast } from '../context';
import Toast from './Toast';

const maxVisibleToasts = 3;
const toastContainerPadding = 3;

const animationTimeout = 400;
const animationTimeoutDelay = 300;

const ToastContainer = () => {
  const { activeToasts, remove } = useToast();
  const visibleToasts = activeToasts.slice(0, maxVisibleToasts);

  return (
    <StyledToastContainer>
      <TransitionGroup component={null}>
        {visibleToasts.map(({ message, role, id }) => (
          <CSSTransition key={id} timeout={700} classNames="toast" appear={true}>
            <Slider
              animationTimeout={animationTimeout}
              animationTimeoutDelay={animationTimeoutDelay}
            >
              <Toast message={message} role={role} close={() => remove(id)} />
            </Slider>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </StyledToastContainer>
  );
};

type SliderProps = {
  animationTimeout: number;
  animationTimeoutDelay: number;
};

const Slider = styled.div<SliderProps>`
  margin-bottom: ${({ theme }) => theme.spacer.times(1)};

  &.toast-enter {
    transform: translateX(150%);
  }

  &.toast-enter-active {
    transition: transform 500ms ease-out;
    transform: translateX(0);
  }

  &.toast-exit {
    transform: translateX(0);
    opacity: 1;
    max-height: 200px;
  }

  &.toast-exit-done,
  &.toast-exit-active {
    opacity: 0;
    max-height: 0;
    transform: translateX(150%);
    margin-bottom: 0;
  }

  &.toast-exit-active {
    transition: transform ${animationTimeout}ms linear,
      opacity ${animationTimeout}ms ease-in,
      max-height ${animationTimeoutDelay}ms linear ${animationTimeout}ms,
      margin-bottom ${animationTimeoutDelay}ms linear ${animationTimeout}ms;
  }
`;

const StyledToastContainer = styled.div`
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  height: auto;
  overflow: hidden;
  z-index: ${({ theme }) => theme.layer.top};
  width: calc(430px + ${({ theme }) => theme.spacer.times(2 * toastContainerPadding)});
  padding: ${({ theme }) =>
    resolveSpacersArray(theme, [6, toastContainerPadding, toastContainerPadding])};
`;

export default ToastContainer;
