import { useState } from 'react';

export type CloseModal = (withSideEffects?: boolean) => void;

type ModalParams = {
  onClose?: (isOpen: boolean) => void;
};

export interface ModalResult<TData = any> {
  data: TData | null;
  isOpen: boolean;
  setData: (data: TData) => void;
  open: (data?: TData) => void;
  close: CloseModal;
}

function useModal<TData>(params: ModalParams = {}): ModalResult<TData> {
  const { onClose } = params;
  const [isOpen, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<TData | null>(null);

  const open = (data?: TData | null) => {
    document.body.style.overflow = 'hidden';
    setOpen(true);
    if (data || data === null) {
      setData(data);
    }
  };

  const close = (withSideEffects = true) => {
    document.body.style.overflow = '';
    setOpen(false);
    if (withSideEffects) {
      onClose?.(isOpen);
    }
  };

  return {
    setData,
    data,
    isOpen,
    open,
    close,
  };
}

export default useModal;
