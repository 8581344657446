import Axios from 'axios';
import { useQueryClient, useMutation } from 'react-query';

import apiUrls from 'base/api/urls';
import { RecipesAuthor } from 'base/api/types';
import { useDetailsQuery, useListQuery } from 'base/api/hooks';
import { CommonVars, PK } from 'common/types';
import { composeUrl } from 'common/utils';
import { StandardError } from 'base/api/errors';

export const useAuthorsList = () => {
  return useListQuery<RecipesAuthor>(apiUrls.AUTHORS.LIST);
};

export const useAuthorsDetails = (pk?: PK) => {
  return useDetailsQuery<RecipesAuthor>({
    queryKey: [apiUrls.AUTHORS.DETAILS, { pk }],
    pk,
  });
};

interface AuthorsCreateVars {
  name?: string;
  description?: string;
  link?: string;
  image_pk?: PK;
}

export const useAuthorsCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<RecipesAuthor, StandardError, AuthorsCreateVars>(
    (data) => Axios.post(apiUrls.AUTHORS.LIST, data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.AUTHORS.LIST);
        queryClient.setQueryData([apiUrls.AUTHORS.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

interface AuthorsUpdateVars extends CommonVars {
  image_pk?: PK | null;
  name?: string;
  description?: string;
  link?: string;
}

export const useAuthorsUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<RecipesAuthor, StandardError, AuthorsUpdateVars>(
    ({ pk, ...data }) =>
      Axios.patch(composeUrl(apiUrls.AUTHORS.DETAILS, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.AUTHORS.LIST);
        queryClient.setQueryData([apiUrls.AUTHORS.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

export const useAuthorsDelete = () => {
  const queryClient = useQueryClient();

  return useMutation<never, StandardError, CommonVars>(
    ({ pk }) => Axios.delete(composeUrl(apiUrls.AUTHORS.DETAILS, { params: { pk } })),
    {
      onSuccess: (data, vars) => {
        queryClient.invalidateQueries(apiUrls.AUTHORS.LIST);
        queryClient.invalidateQueries(
          composeUrl(apiUrls.AUTHORS.DETAILS, {
            params: { pk: vars.pk },
          }),
        );
      },
    },
  );
};
