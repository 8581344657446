import * as Yup from 'yup';

import { Fields, PK, SelectOption, StatusOption } from 'common/types';
import { combineSchemas } from 'common/utils';

export type FactFields = {
  fact_text: string;
  status: StatusOption[];
  content_goals?: SelectOption<PK>[];
};

export const fields: Fields<FactFields> = {
  fact_text: {
    label: 'Fact text',
    name: 'fact_text',
    schema: Yup.string().required(),
  },
  status: {
    label: 'Status',
    name: 'status',
    schema: Yup.array().required(),
  },
  content_goals: {
    label: 'Categories',
    name: 'content_goals',
    schema: Yup.array(),
  },
};

export const validationSchema = Yup.object().shape<FactFields>(combineSchemas(fields));
