import * as React from 'react';

import { CONTENT_TYPES, STATUSES } from 'common/consts';
import { CollapsibleSection, RelationSelect, Wrapper } from 'common/components';
import { useSearchParams } from 'common/hooks';
import { PlainCheckbox } from 'common/components/Checkbox';
import { RelationOption, SaveReason } from 'common/components/RelationSelect';
import { PK } from 'common/types';

import { getContentByType } from '../consts';
import { useActiveFilter, useSaveContentItems, useReorderContentItems } from '../hooks';
import { useCollectionContent } from '../queries';

interface MinimalContent {
  pk: PK;
  title: string;
  status: STATUSES;
}

interface StaticPickerProps {
  pk?: PK;
  divider?: boolean;
  sectionTitle: string;
  contentType: CONTENT_TYPES;
  contentList: MinimalContent[];
}

const StaticPicker = ({
  pk,
  divider,
  contentType,
  contentList,
  sectionTitle,
}: StaticPickerProps) => {
  const { contentType: selectedContentType } = useSearchParams();
  const { data: collectionContentData } = useCollectionContent(pk);
  const { pk: collectionContentPk, items: contentItems } =
    (collectionContentData &&
      getContentByType(collectionContentData.values, contentType)) ??
    {};

  const { filterEnabled, toggleActiveFilter, activeFilter } = useActiveFilter();
  const options: RelationOption[] = React.useMemo(() => {
    return contentList.filter(activeFilter).map(({ pk, title }) => ({
      label: title,
      value: pk,
    }));
  }, [contentList, activeFilter]);

  const {
    save: saveItems,
    isSuccess: isSaveItemsSuccess,
    isLoading: isSaveItemsLoading,
    isError: isSaveItemsError,
  } = useSaveContentItems({
    pk,
    contentItems: contentItems?.map((item) => item.value) ?? [],
    contentPk: collectionContentPk,
    contentType,
  });
  const {
    save: reorderItems,
    isSuccess: isReorderSuccess,
    isLoading: isReorderLoading,
    isError: isReorderError,
  } = useReorderContentItems(pk);

  const saveStatus = {
    isSuccess: isSaveItemsSuccess || isReorderSuccess,
    isLoading: isSaveItemsLoading || isReorderLoading,
    isError: isSaveItemsError || isReorderError,
  };

  const handleSave = (items: RelationOption[], reason: SaveReason) => {
    switch (reason) {
      case SaveReason.ITEMS_ADDED:
      case SaveReason.ITEMS_REMOVED:
        saveItems(items);

        return;
      case SaveReason.ITEMS_REORDERED:
        if (collectionContentPk) {
          reorderItems({ contentPk: collectionContentPk, items });
        }

        return;
    }
  };

  return (
    <CollapsibleSection
      title={sectionTitle}
      saveStatus={saveStatus}
      disabled={!pk}
      divider={divider}
      shouldOpen={selectedContentType === contentType}
    >
      <PlainCheckbox
        name="active_only"
        label="Filter only active items"
        checked={filterEnabled}
        onChange={toggleActiveFilter}
      />
      <Wrapper margin={[1, 0, 1]}>
        <RelationSelect
          onSave={handleSave}
          options={options}
          defaultValues={contentItems}
          isLoading={isSaveItemsLoading || isReorderLoading}
        />
      </Wrapper>
    </CollapsibleSection>
  );
};

export default StaticPicker;
