import * as React from 'react';

import { PK } from 'common/types';
import { useMappedValues } from 'common/hooks';
import { RelationSelect, CollapsibleSection } from 'common/components';
import { RelationOption } from 'common/components/RelationSelect';
import { useFactsList } from 'modules/facts/queries';
import { useMinisFacts, useMinisFactsUpdate } from '../queries';

type Props = {
  pk?: PK;
};

const FactsPicker = ({ pk }: Props) => {
  const { mutate, isSuccess, isLoading, isError } = useMinisFactsUpdate();
  const saveStatus = { isSuccess, isLoading, isError };
  const factsList = useFactsList();
  const minisFacts = useMinisFacts(pk);
  const options = useMappedValues(factsList.data?.values, ({ pk, fact_text }) => ({
    label: fact_text,
    value: pk,
  }));

  const defaults = useMappedValues(minisFacts.data?.values, ({ pk, fact_text }) => ({
    label: fact_text,
    value: pk,
  }));

  const handleSave = (values: RelationOption[]) => {
    if (pk) {
      const payload = {
        pk: pk,
        fact_pks: values.map(({ value }) => value),
      };
      mutate(payload);
    }
  };

  return (
    <CollapsibleSection title="Add facts" saveStatus={saveStatus} disabled={!pk}>
      <RelationSelect
        onSave={handleSave}
        options={options}
        defaultValues={defaults}
        isLoading={factsList.isLoading}
      />
    </CollapsibleSection>
  );
};

export default FactsPicker;
