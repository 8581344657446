import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Dots = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="2" r="2" />
      <circle cx="12" cy="12" r="2" />
      <circle cx="12" cy="22" r="2" />
    </Svg>
  );
};

Dots.defaultProps = {
  size: '24px',
  color: 'blue',
};

export default Dots;
