import * as React from 'react';
import styled from 'styled-components';

import { IconContainer } from 'common/components';
import { DATE_FORMATS, STATUS_SIZES } from 'common/consts';
import { Reload } from 'common/icons';
import { resolveSpacersArray } from 'common/styles/utils';
import { DateString } from 'common/types';
import { formatDate } from 'common/utils';
import Chip from 'modules/collections/components/Chip';

interface Props {
  modificationDate: DateString<DATE_FORMATS.DATABASE>;
}

const ModifiedChip = ({ modificationDate }: Props) => (
  <DateChip size={STATUS_SIZES.SMALL}>
    <IconContainer size="12px" margin={[0, 1, 0, 0]}>
      <Reload size="12px" />
    </IconContainer>
    {formatDate(new Date(modificationDate), DATE_FORMATS.HUMAN)}
  </DateChip>
);

const DateChip = styled(Chip)`
  flex-shrink: 0;
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.font.size.superTiny};
  padding: ${({ theme }) => resolveSpacersArray(theme, [0, 1])};
  background-color: ${({ theme }) => theme.color.greySuperLight};
`;

export default ModifiedChip;
