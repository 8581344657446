import * as React from 'react';
import styled from 'styled-components';

import { Button, CloseButton, Column, Modal, Row, Wrapper } from 'common/components';
import { BUTTON_VARIANTS } from 'common/consts';
import { CloseModal } from 'common/hooks/useModal';

interface DeleteDialogProps {
  isOpen: boolean;
  weekCount: number;
  close: CloseModal;
  onClose: (confirmed: boolean) => void;
}

const DIALOG_PADDING = 2;

const DeletionDialog = ({ isOpen, weekCount, close, onClose }: DeleteDialogProps) => {
  const hasValuableWork = weekCount > 0;

  const handleClose = (confirmed: boolean) => {
    close();
    onClose(confirmed);
  };

  return (
    <Modal isOpen={isOpen} close={() => handleClose(false)} autoWidth={true}>
      <Column padding={DIALOG_PADDING}>
        <AlignmentWrapper>
          <CloseButton onClose={() => handleClose(false)} />
        </AlignmentWrapper>
        <Title>Delete scenario?</Title>
        {hasValuableWork ? (
          <Subtitle>This scenario contains some valuable work</Subtitle>
        ) : (
          <Subtitle>
            Scenario has linked companies, but all programs are finished.
          </Subtitle>
        )}
        <Row>
          <Wrapper margin={[2, 5]}>
            <Button
              variant={BUTTON_VARIANTS.SECONDARY}
              onClick={() => handleClose(false)}
            >
              Cancel
            </Button>
          </Wrapper>
          <Wrapper margin={[2, 5]}>
            <Button variant={BUTTON_VARIANTS.PRIMARY} onClick={() => handleClose(true)}>
              Delete
            </Button>
          </Wrapper>
        </Row>
      </Column>
    </Modal>
  );
};

const AlignmentWrapper = styled.div`
  align-self: end;
`;

const Title = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const Subtitle = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.small};
  margin-bottom: ${({ theme }) => theme.spacer.times(4)};
  font-weight: ${({ theme }) => theme.font.weight.normal};
`;

export default DeletionDialog;
