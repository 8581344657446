import * as React from 'react';
import { useTheme } from 'styled-components';

import { toastMessages } from 'common/toastMessages';
import { Bin } from 'common/icons';
import { PK } from 'common/types';
import { Menu } from 'common/components/DropdownMenu';

import { useUnitsDelete } from '../queries';
import { useToast } from 'modules/toast';

interface MenuProps {
  pk: PK;
}

const UnitsMenu = ({ pk }: MenuProps) => {
  const { mutate: removeUnit, isLoading, isError } = useUnitsDelete();
  const { toast } = useToast();
  const theme = useTheme();

  const handleDelete = () => {
    removeUnit(
      { pk },
      {
        onSuccess: () => {
          toast(toastMessages.SUCCESS.GENERIC_SUCCESS, 'success');
        },
        onError: (error) => {
          toast(error.message, 'error');
        },
      },
    );
  };

  const menuItems = [
    {
      isError,
      isLoading,
      label: 'Delete',
      icon: (
        <Bin color={theme.keys.secondaryColor} hoverColor={theme.keys.secondaryColor} />
      ),
      onClick: handleDelete,
    },
  ];

  return <Menu menuItems={menuItems} />;
};

export default UnitsMenu;
