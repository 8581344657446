import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Close = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 10 10" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path d="M9 1 1 9m8 0L1 1" />
      </g>
    </Svg>
  );
};

export default Close;
