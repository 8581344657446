import create from 'zustand';

import { CONTENT_TYPES } from 'common/consts';

type CreateDialogStore = {
  active: CONTENT_TYPES;
  isOpen: boolean;
  open: () => void;
  close: () => void;
  setActive: (type: CONTENT_TYPES) => void;
};

const useCreateContext = create<CreateDialogStore>((set, get) => ({
  isOpen: false,
  active: CONTENT_TYPES.MINIS,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  setActive: (type) => {
    if (type !== get().active) {
      return set({ active: type });
    }
  },
}));

export default useCreateContext;
