import * as React from 'react';

import { toastMessages } from 'common/toastMessages';
import { Bin } from 'common/icons';
import { PK } from 'common/types';
import { Menu } from 'common/components/DropdownMenu';

import { useNutritionalValuesDelete } from '../queries';
import { useToast } from 'modules/toast';

interface MenuProps {
  pk: PK;
}

const NutritionalValuesMenu = ({ pk }: MenuProps) => {
  const {
    mutate: removeNutritionalValue,
    isLoading,
    isError,
  } = useNutritionalValuesDelete();
  const { toast } = useToast();

  const handleDelete = () => {
    removeNutritionalValue(
      { pk },
      {
        onSuccess: () => {
          toast(toastMessages.SUCCESS.GENERIC_SUCCESS, 'success');
        },
        onError: (error) => {
          toast(error.message, 'error');
        },
      },
    );
  };

  const menuItems = [
    {
      isError,
      isLoading,
      label: 'Delete',
      icon: <Bin />,
      onClick: handleDelete,
    },
  ];

  return <Menu menuItems={menuItems} />;
};

export default NutritionalValuesMenu;
