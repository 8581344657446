import * as React from 'react';
import styled from 'styled-components';

import { pulse } from 'common/styles/animations';
import { resolveSpacer, resolveSpacersArray } from 'common/styles/utils';
import { STATUSES, STATUS_SIZES } from 'common/consts';
import { Row, Title, Graphics, Link, StatusChip } from 'common/components';
import { getIsFriesland } from 'common/utils/config';

type Props = {
  path: string;
  icon: string;
  title: string;
  isLoading?: boolean;
  className?: string;
  numbers?: {
    total: number;
    draft: number;
    published: number;
    unpublished: number;
    in_review: number;
  };
};

const Card = ({ className, path, icon, title, numbers, isLoading }: Props) => {
  const isFriesland = getIsFriesland();
  return (
    <div className={className}>
      <Link to={path}>
        <CardHeader>
          {!isFriesland && <Graphics src={icon} height="120px" />}
          <CardRow>
            <Title>{title}</Title>
            {isLoading ? (
              <Skeleton width={8} height={4} />
            ) : (
              <Title>{numbers?.total}</Title>
            )}
          </CardRow>
        </CardHeader>
        <CardFooter>
          <CardRow marginBottom="standard">
            {isLoading ? (
              <Skeleton width={11} height={3} />
            ) : (
              <>
                <StatusChip status={STATUSES.ACTIVE} size={STATUS_SIZES.SMALL} />
                <LinePointer />
                <span>{numbers?.published}</span>
              </>
            )}
          </CardRow>
          <CardRow marginBottom="standard">
            {isLoading ? (
              <Skeleton width={8} height={3} />
            ) : (
              <>
                <StatusChip status={STATUSES.DRAFT} size={STATUS_SIZES.SMALL} />
                <LinePointer />
                <span>{numbers?.draft}</span>
              </>
            )}
          </CardRow>
          <CardRow marginBottom="standard">
            {isLoading ? (
              <Skeleton width={14} height={3} />
            ) : (
              <>
                <StatusChip status={STATUSES.INACTIVE} size={STATUS_SIZES.SMALL} />
                <LinePointer />
                <span>{numbers?.unpublished}</span>
              </>
            )}
          </CardRow>
          <CardRow marginBottom="standard">
            {isLoading ? (
              <Skeleton width={14} height={3} />
            ) : (
              <>
                <StatusChip status={STATUSES.REVIEW} size={STATUS_SIZES.SMALL} />
                <LinePointer />
                <span>{numbers?.in_review}</span>
              </>
            )}
          </CardRow>
        </CardFooter>
      </Link>
    </div>
  );
};

const StyledCard = styled(Card)`
  position: relative;
  margin-top: ${({ theme }) => theme.spacer.times(7)};
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.border.radius.standard};
  box-shadow: 0 1px 1px 1px ${({ theme }) => theme.color.blueSuperLight};

  ${Graphics} {
    position: absolute;
    top: -${({ theme }) => theme.spacer.times(7)};
    left: 0;
    pointer-events: none;
  }
`;

const CardHeader = styled.div`
  padding: ${({ theme }) => resolveSpacersArray(theme, theme.themePadding.cardPadding)};

  border-bottom: 1px solid ${({ theme }) => theme.color.blueSuperLight};
`;

const CardFooter = styled.div`
  padding: ${({ theme }) => resolveSpacersArray(theme, [3, 5, 5])};
`;

const CardRow = styled(Row)`
  justify-content: space-between;
`;

const LinePointer = styled.div`
  width: 100%;
  height: 1px;
  margin: 0 ${({ theme }) => theme.spacer.big};
  background-color: ${({ theme }) => theme.color.blueSuperLight};
`;

type SkeletonProps = {
  width: number;
  height: number;
};

const Skeleton = styled.div<SkeletonProps>`
  height: ${({ theme, height }) => resolveSpacer(theme, height)};
  border-radius: ${({ theme, height }) => theme.spacer.times(0.5 * height)};
  width: ${({ theme, width }) => resolveSpacer(theme, width)};
  background-color: ${({ theme }) => theme.color.blueLight};
  animation: ${pulse} 1.25s infinite;
`;

export default StyledCard;
