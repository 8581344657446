import * as React from 'react';

import appUrls from 'base/app/urls';
import { Container, Wrapper } from 'common/components';
import {
  RecipeDetailsSwitch,
  RecipeTopBar,
  CreateButton,
} from 'modules/recipe-subsections';
import { RECIPE_SUBSECTIONS } from 'modules/recipe-subsections/consts';
import {
  ContentProvider,
  ContentList,
  FiltersForm,
  Sort,
  SearchInput,
} from 'templates/content';

import { useNutritionalValuesList } from '../queries';
import NutritionalValuesDetails from './NutritionalValuesDetails';
import NutritionalValuesContentRow from '../components/NutritionalValuesContentRow';

const fields = {
  search: 'search',
  sort: 'sort',
};

const NutritionalValuesList = () => {
  const { data, isError } = useNutritionalValuesList();
  const context = {
    data: data?.values.map(({ name, ...nutritionalValue }) => ({
      ...nutritionalValue,
      title: name,
    })),
    path: {
      list: appUrls.NUTRITIONAL_VALUES.LIST,
      details: appUrls.NUTRITIONAL_VALUES.DETAILS,
      create: appUrls.NUTRITIONAL_VALUES.CREATE,
    },
  };

  return (
    <Container>
      <ContentProvider {...context}>
        <RecipeTopBar subsection={RECIPE_SUBSECTIONS.NUTRITION}>
          <CreateButton path={appUrls.NUTRITIONAL_VALUES.CREATE}>New item</CreateButton>
          <FiltersForm sort={['title', fields.sort]} search={['title', fields.search]}>
            <Wrapper margin={[0, 0.5]}>
              <SearchInput name={fields.search} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <Sort name={fields.sort} />
            </Wrapper>
          </FiltersForm>
        </RecipeTopBar>
        <ContentList isError={isError}>{NutritionalValuesContentRow}</ContentList>
        <RecipeDetailsSwitch>
          <NutritionalValuesDetails />
        </RecipeDetailsSwitch>
      </ContentProvider>
    </Container>
  );
};

export default NutritionalValuesList;
