import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Scenario } from 'base/api/types';
import { PK } from 'common/types';
import { DetailsCard, DetailsSuspense } from 'templates/details';

import ScenarioCore from '../components/ScenarioCore';
import { fields, ScenarioFields } from '../consts';
import { useScenarioDetails } from '../queries';

const transformScenario = (raw: Scenario): ScenarioFields => ({
  [fields.title.name]: raw.title,
  [fields.weeks.name]: raw.weeks,
  [fields.companies.name]: raw.programs.map((program) => ({
    pk: program.company_pk,
    name: program.company_title,
    program: {
      status: program.status,
      startDate: program.start_date,
      currentWeek: program.current_week,
    },
  })),
});
const ScenarioDetails = () => {
  const { pk } = useParams<{ pk?: PK }>();
  const { data, ...query } = useScenarioDetails(pk);
  const defaultValues = data && transformScenario(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading} error={query.error}>
        <ScenarioCore key={pk} pk={pk} defaultValues={defaultValues} />
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default ScenarioDetails;
