import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const DragHandler = ({ size = '24px', color = 'greyMedium', ...props }: IconProps) => {
  return (
    <Svg viewBox="0 0 24 16" size={size} color={color} {...props}>
      <g
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1 8h22M1 1h22M1 15h22" />
      </g>
    </Svg>
  );
};

export default DragHandler;
