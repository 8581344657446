import * as React from 'react';

import styled, { useTheme } from 'styled-components';
import { CloseModal } from 'common/hooks/useModal';

import { Button, Container, Modal, Row, Wrapper } from 'common/components';
import { BUTTON_VARIANTS } from 'common/consts';

import { Cross } from 'common/icons';

type Props = {
  isOpen: boolean;
  close: CloseModal;
  isMaintenanceMode: boolean;
  isLoading: boolean;
  onAccept: () => void;
};

const MaintenanceModal = ({
  isOpen,
  close,
  isMaintenanceMode,
  onAccept,
  isLoading,
}: Props) => {
  const theme = useTheme();

  const closeModal = () => {
    close();
  };

  return (
    <Modal isOpen={isOpen} close={close} autoWidth={true}>
      <Container>
        <ReverseRow>
          <Button variant={BUTTON_VARIANTS.ICON} onClick={closeModal}>
            <Cross
              size="24px"
              color={theme.keys.secondaryColor}
              hoverColor={theme.keys.secondaryColor}
            />
          </Button>
        </ReverseRow>
        {isMaintenanceMode ? (
          <>
            <Title>Are you sure you want to turn off maintenance mode?</Title>
            <Subtitle>Users will have normal access to the app</Subtitle>
          </>
        ) : (
          <>
            <Title>Are you sure you want to turn on maintenance mode?</Title>
            <Subtitle>Users will have no access to the app</Subtitle>
          </>
        )}
        <Divider />
        <Row>
          <Wrapper margin={[2, 5]}>
            <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={closeModal}>
              Cancel
            </Button>
          </Wrapper>
          <Wrapper margin={[2, 5]}>
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              isLoading={isLoading}
              onClick={onAccept}
            >
              Save
            </Button>
          </Wrapper>
        </Row>
      </Container>
    </Modal>
  );
};

const ReverseRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacer.times(2)};
`;

const Divider = styled.div`
  left: ${({ theme }) => theme.spacer.times(3)};
  right: ${({ theme }) => theme.spacer.times(3)};
  height: 1px;
  background-color: ${({ theme }) => theme.color.blueGrey};
`;

const Title = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  max-width: 80%;
  margin: auto;
`;

const Subtitle = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.normal};
`;

export default MaintenanceModal;
