import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const FullScreen = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 21 17" {...props}>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Media"
          transform="translate(-584.000000, -660.000000)"
          stroke="currentColor"
          strokeWidth="2"
        >
          <g id="enlarge-59" transform="translate(585.000000, 661.000000)">
            <polyline id="Path" points="0 4.16666667 0 0 4.16666667 0"></polyline>
            <polyline
              id="Path"
              points="14.1666667 0 18.3333333 0 18.3333333 4.16666667"
            ></polyline>
            <polyline
              id="Path"
              points="18.3333333 10.8333333 18.3333333 15 14.1666667 15"
            ></polyline>
            <polyline id="Path" points="4.16666667 15 0 15 0 10.8333333"></polyline>
            <rect
              id="Rectangle"
              x="4.16666667"
              y="4.16666667"
              width="10"
              height="6.66666667"
            ></rect>
          </g>
        </g>
      </g>
    </Svg>
  );
};

FullScreen.defaultProps = {
  size: '16px',
  color: 'black',
  hoverColor: 'black',
};

export default FullScreen;
