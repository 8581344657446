import * as React from 'react';
import styled from 'styled-components';

import { DATE_FORMATS, STATUSES, STATUS_SIZES } from 'common/consts';
import { DateChip, Row, StatusChip, Wrapper } from 'common/components';
import { B2B } from 'common/icons';
import { DateString } from 'common/types';

import PointsDisplay from './PointsDisplay';

type DayContentProps = {
  label: string;
  punctation?: number;
  status?: STATUSES;
  created?: DateString<DATE_FORMATS.DATABASE>;
  lastUpdate?: DateString<DATE_FORMATS.DATABASE>;
  isDivider: boolean;
  isB2B?: boolean;
};

const DayContent = ({
  label,
  punctation,
  created,
  lastUpdate,
  status,
  isDivider,
  isB2B,
}: DayContentProps) => {
  const isExtended = !!(created || lastUpdate || status);

  return (
    <>
      {isDivider && <Divider />}
      <Label isExtended={isExtended}>
        <span>{label}</span>
        {isB2B && (
          <Wrapper margin={[0, 1]}>
            <B2B title="B2B item" color="black" backgroundColor="blackSuperAlpha" />
          </Wrapper>
        )}
      </Label>
      <Row>
        <Wrapper margin={[0, 1, 0, 0]}>
          <DateChip label="created" date={created} />
        </Wrapper>
        <Wrapper margin={[0, 1, 0, 0]}>
          <DateChip label="last update" date={lastUpdate || created} />
        </Wrapper>
        {punctation && <PointsDisplay value={punctation} />}
        {status && <StatusChip size={STATUS_SIZES.SMALL} status={status} />}
      </Row>
    </>
  );
};

const Divider = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.spacer.times(3)};
  right: ${({ theme }) => theme.spacer.times(3)};
  top: 0;
  height: 1px;
  background-color: ${({ theme }) => theme.color.blueGrey};
`;

const Label = styled.label<{ isExtended: boolean }>`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: ${({ theme, isExtended }) => (isExtended ? theme.spacer.standard : 0)};
`;

export default DayContent;
