import * as React from 'react';

import { CalendarDate } from 'common/icons';

import Indicator from '../Indicator';

const CalendarIndicator: React.FC = ({ children }) => (
  <Indicator
    color="blue"
    highlightColor="blueLight"
    icon={<CalendarDate color="blue" size="16px" />}
  >
    {children}
  </Indicator>
);

export default CalendarIndicator;
