import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const PaperClip = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 11 12" {...props}>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Collections_overview"
          transform="translate(-140.000000, -236.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g id="Tile:COLLECTION_STATIC" transform="translate(20.000000, 192.000000)">
            <path
              d="M129.735931,50.6358496 L126.766407,53.5998496 C125.987464,54.3776168 124.851844,54.6816554 123.787324,54.3974369 C122.722803,54.1132185 121.891108,53.2839226 121.60553,52.2219369 C121.319953,51.1599513 121.623878,50.0266168 122.40282,49.2488496 L125.633919,46.0268496 C126.348735,45.3478654 127.47171,45.3478654 128.186527,46.0268496 C128.52527,46.3644725 128.715605,46.822613 128.715605,47.3003496 C128.715605,47.7780863 128.52527,48.2362268 128.186527,48.5738496 L125.217003,51.5358496 C125.011236,51.7406819 124.678134,51.7406819 124.472367,51.5358496 C124.268179,51.3300837 124.268179,50.9986156 124.472367,50.7928496 L126.666187,48.5998496 C126.867282,48.4128791 126.950059,48.1312941 126.882046,47.8655607 C126.814033,47.5998274 126.606077,47.3923277 126.339758,47.3244641 C126.07344,47.2566005 125.791235,47.3391965 125.603853,47.5398496 L123.405022,49.7328496 C122.611579,50.5251021 122.612027,51.8091494 123.406024,52.6008496 C124.200021,53.3925498 125.486896,53.3921021 126.280339,52.5998496 L129.248861,49.6348496 C130.084554,48.8009917 130.41093,47.5856174 130.105045,46.4465463 C129.79916,45.3074753 128.907485,44.4177595 127.765906,44.1125463 C126.624327,43.8073332 125.406277,44.1329917 124.570583,44.9668496 L121.343493,48.1878496 C119.552365,49.9747669 119.55214,52.8721562 121.342992,54.6593496 C123.133844,56.4465431 126.037612,56.4467669 127.828741,54.6598496 L130.798264,51.6998496 C131.07423,51.4043399 131.066087,50.9438399 130.779846,50.6582278 C130.493605,50.3726158 130.032091,50.3644908 129.735931,50.6398496 L129.735931,50.6358496 Z"
              id="Icon_kopieren"
            ></path>
          </g>
        </g>
      </g>
    </Svg>
  );
};

PaperClip.defaultProps = {
  size: '12px',
  color: 'blue',
  hoverColor: 'blue',
};

export default PaperClip;
