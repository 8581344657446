import * as React from 'react';
import styled from 'styled-components';

import { Column, FormError, RemoveButton } from 'common/components';
import { resolveSpacersArray } from 'common/styles/utils';
import { PK } from 'common/types';

import { useAdminUnset } from '../queries';

interface Props {
  pk: PK;
  email: string;
}

const AdminItem = ({ pk, email }: Props) => {
  const { mutate: unsetAdmin, isLoading, isError, error } = useAdminUnset();

  const handleRemove = () => {
    unsetAdmin({ user_pk: pk });
  };

  return (
    <Column>
      <Container>
        <Email>{email}</Email>
        <RemoveButton
          onRemove={handleRemove}
          isLoading={isLoading}
          noNegativeMargin={false}
        />
      </Container>
      {isError && <FormError message={error?.message} />}
    </Column>
  );
};

const Email = styled.span`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.spacer.small};
  background-color: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => resolveSpacersArray(theme, [3, 3, 3, 5])};
`;

export default AdminItem;
