import * as React from 'react';
import { PK } from 'common/types';
import { useParams } from 'react-router-dom';

import { DetailsCard, DetailsSuspense } from 'templates/details';
import { useBadgeCollectionsList, useBadgeDetails } from '../queries';
import { BADGE_SUBTYPE, BADGE_TYPE, Badge, BadgeCollection } from 'base/api/types';
import { BadgeFields, CriteriaMinimumCountOptions, fields } from '../consts';
import BadgeCore from '../components/BadgeCore';
import { STATUSES_OPTIONS } from 'common/consts';
import { useMinisList } from 'modules/minis/queries';

const transformBadge = (raw: Badge, collections: BadgeCollection[]): BadgeFields => ({
  [fields.title.name]: raw.title,
  [fields.status.name]: STATUSES_OPTIONS.filter(({ value }) => value === raw.status),
  [fields.collection.name]: collections
    .filter((match) => match.title === raw.collection)
    .map((item) => ({ label: item.title, value: item.pk })),
  [fields.criteria_minimum_count.name]: [
    raw.criteria?.minimum_count || CriteriaMinimumCountOptions[0],
  ].map((item) => ({ label: `${item}`, value: item })),
  [fields.criteria_possible_pks.name]: raw.criteria?.possible_pks
    ? raw.criteria.possible_pks.map((pk) => pk as PK)
    : null,
  [fields.descriptions_not_collected.name]: raw.descriptions.not_collected,
  [fields.descriptions_in_progress.name]: raw.descriptions.in_progress,
  [fields.descriptions_collected.name]: raw.descriptions.collected,
  [fields.is_hidden.name]: raw.is_hidden,
  [fields.images_inactive_url.name]: raw.images.inactive_url,
  [fields.images_in_progress_url.name]: raw.images.in_progress_url,
  [fields.images_granted_url.name]: raw.images.granted_url,
});

const BadgesDetails = () => {
  const { pk } = useParams<{ pk?: PK }>();
  const { isLoading: isLoadingMinis } = useMinisList();
  const { data: badge, ...query } = useBadgeDetails(pk);
  const { data: collections } = useBadgeCollectionsList();
  const defaultValues =
    collections?.values && badge && transformBadge(badge, collections?.values);

  const isMultilevel = Boolean(
    badge && badge.type === BADGE_TYPE.ITEMS_COMPLETED && badge.criteria?.possible_pks,
  );
  const isStreak = badge?.subtype === BADGE_SUBTYPE.STREAK;

  return (
    <DetailsCard created={badge?.created_at} lastUpdate={badge?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading || isLoadingMinis} error={query.error}>
        <BadgeCore
          layout="overview"
          key={pk}
          pk={pk}
          defaultValues={defaultValues}
          isMultilevel={isMultilevel}
          isStreak={isStreak}
        />
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default BadgesDetails;
