import * as React from 'react';

import { Title } from 'common/components';
import { Section } from 'templates/core';
import ScenarioCore from '../components/ScenarioCore';

const ScenarioCreate = () => {
  return (
    <>
      <Section>
        <Title size="big">Create Scenario</Title>
      </Section>
      <ScenarioCore layout="creation" />
    </>
  );
};

export default ScenarioCreate;
