import * as React from 'react';
import { useParams } from 'react-router';

import { RecipesTag } from 'base/api/types';
import { PK } from 'common/types';
import { DetailsCard, DetailsSuspense } from 'templates/details';

import TagsCore from '../components/TagsCore';
import { fields, TagsFields } from '../consts';
import { useTagsDetails } from '../queries';

const TagsDetails = () => {
  const { pk } = useParams<{ pk: PK }>();
  const { data, ...query } = useTagsDetails(pk);
  const transformTags = (raw: RecipesTag): TagsFields => ({
    [fields.name.name]: raw.name,
  });
  const defaultValues = data && transformTags(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading} error={query.error}>
        <TagsCore pk={pk} key={pk} defaultValues={defaultValues} />
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default TagsDetails;
