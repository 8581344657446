import React from 'react';

import { Button, ErrorBox, SaveStatus, Wrapper } from 'common/components';
import { BUTTON_VARIANTS } from 'common/consts';
import { Section } from 'templates/core';

import { useClearCache } from '../queries';
import Heading from './Heading';

const ClearCache = () => {
  const { mutate: clearCache, isLoading, isError, isSuccess, error } = useClearCache();
  const saveStatus = { isLoading, isError, isSuccess };

  return (
    <>
      <Heading>
        Clear cache
        <SaveStatus {...saveStatus} hideLabel />
      </Heading>
      <p>Flush cache memory on the backend.</p>
      {error && (
        <Section>
          <ErrorBox error={error} />
        </Section>
      )}
      <Wrapper margin={[2, 0]}>
        <Button
          type="button"
          variant={BUTTON_VARIANTS.TERTIARY}
          isLoading={isLoading}
          onClick={() => clearCache()}
        >
          Clear cache
        </Button>
      </Wrapper>
    </>
  );
};

export default ClearCache;
