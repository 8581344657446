import * as Yup from 'yup';

import { CollectionContent, CollectionHandler, MediaObject } from 'base/api/types';
import {
  CATEGORIES,
  CONTENT_TYPES,
  FRIESLAND_CATEGORIES,
  HANDLER_TYPES,
  STATUSES,
} from 'common/consts';
import { Fields, PK, SelectOption } from 'common/types';
import { combineSchemas } from 'common/utils';
import { RelationOption } from 'common/components/RelationSelect';

export type CollectionFields = {
  title: string;
  icon?: MediaObject;
  status: SelectOption<STATUSES>[];
  color: SelectOption<FRIESLAND_CATEGORIES | CATEGORIES>[];
  handlerType: SelectOption<HANDLER_TYPES>[];
  handler: SelectOption<CollectionHandler>[];
};

export const fields: Fields<CollectionFields> = {
  title: {
    name: 'title',
    label: 'Title',
    schema: Yup.string().required(),
  },
  icon: {
    name: 'icon',
    label: 'Icon',
    schema: Yup.mixed().required('You need to provide an icon'),
  },
  status: {
    name: 'status',
    label: 'Status',
    schema: Yup.array().required(),
  },
  color: {
    name: 'color',
    label: 'Category',
    schema: Yup.array().required(),
  },
  handlerType: {
    name: 'handlerType',
    label: 'Handler type',
    schema: Yup.array().required(),
  },
  handler: {
    name: 'handler',
    label: 'Select handler',
    schema: Yup.array().nullable(),
  },
};

export const validationSchema = Yup.object().shape<CollectionFields>(
  combineSchemas(fields),
);

interface FilteredCollectionContent {
  pk: PK;
  items: RelationOption[];
}

export const getContentByType = (
  raw: CollectionContent[],
  contentType: CONTENT_TYPES,
): FilteredCollectionContent | null => {
  const collection = raw.find((value) => value && value.content_type === contentType);
  const content =
    collection &&
    collection.items.map((item) => ({
      label: item.title,
      value: item.pk,
    }));

  return (
    (collection &&
      content && {
        pk: collection.pk,
        items: content,
      }) ??
    null
  );
};

export interface CollectionOption extends RelationOption {
  isVisible: boolean;
  isLoading: boolean;
}

export enum VISIBILITY_FILTERS {
  ALL = 'ALL',
  VISIBLE = 'VISIBLE',
  HIDDEN = 'HIDDEN',
}

export const VISIBILITY_FILTER_OPTIONS = [
  {
    label: 'All content',
    value: VISIBILITY_FILTERS.ALL,
  },
  {
    label: 'Visible content',
    value: VISIBILITY_FILTERS.VISIBLE,
    visibility: 'visible',
  },
  {
    label: 'Hidden content',
    value: VISIBILITY_FILTERS.HIDDEN,
    visibility: 'invisible',
  },
];

export const getVisibilityByFilter = (filter?: VISIBILITY_FILTERS): string | undefined =>
  VISIBILITY_FILTER_OPTIONS.find((option) => option.value === filter)?.visibility;
