import styled, { css } from 'styled-components';

import { ThemeSpacer } from 'common/styles/theme';
import { resolveSpacersArray } from 'common/styles/utils';

interface IconContainerProps {
  size?: string;
  margin?: ThemeSpacer | ThemeSpacer[];
  padding?: ThemeSpacer | ThemeSpacer[];
}

const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;

  ${({ size }) =>
    size &&
    css`
      width: ${size};
      height: ${size};
    `};

  ${({ theme, margin }) =>
    margin &&
    css`
      margin: ${resolveSpacersArray(theme, margin)};
    `};

  ${({ theme, padding }) =>
    padding &&
    css`
      padding: ${resolveSpacersArray(theme, padding)};
    `};
`;

export default IconContainer;
