import { DATE_FORMATS } from 'common/consts';
import { resolveSpacersArray } from 'common/styles/utils';
import { DateString } from 'common/types';
import { formatDate } from 'common/utils';
import * as React from 'react';
import styled from 'styled-components';

export interface DateChipProps {
  label?: string;
  date?: DateString<DATE_FORMATS.DATABASE> | null;
  className?: string;
}

const DateChip = ({ className, label, date }: DateChipProps) => {
  if (!date) {
    return null;
  }
  return (
    <div className={className}>
      <Label>{label}</Label>
      <Value>{formatDate(new Date(date), DATE_FORMATS.HUMAN)}</Value>
    </div>
  );
};

const StyledDateChip = styled(DateChip)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  line-height: ${({ theme }) => theme.spacer.big};
  border-radius: ${({ theme }) => theme.spacer.times(1.5)};
  background-color: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => resolveSpacersArray(theme, ['small', 'big'])};
`;

const Label = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.color.grey};
  font-size: ${({ theme }) => theme.font.size.superTiny};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-transform: uppercase;
  margin-right: ${({ theme }) => theme.spacer.standard};
  letter-spacing: 0.5px;
  white-space: nowrap;
`;

const Value = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.font.size.tiny};
  color: ${({ theme }) => theme.color.greyDark};
  white-space: nowrap;
`;

export default StyledDateChip;
