import React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Info = ({ ...props }: IconProps) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 0C5.376 0 0 5.376 0 12s5.376 12 12 12 12-5.376 12-12S18.624 0 12 0Zm0 1.846c5.605 0 10.154 4.55 10.154 10.154 0 5.605-4.55 10.154-10.154 10.154-5.605 0-10.154-4.55-10.154-10.154C1.846 6.395 6.396 1.846 12 1.846Z"
        fill="currentColor"
      />
      <path
        d="M13.846 6.692a2.077 2.077 0 1 1-4.154 0 2.077 2.077 0 0 1 4.154 0ZM13.969 17.716c-.013.504.223.646.799.705l.924.018v.946H8.48v-.946l1.016-.018c.607-.019.753-.255.799-.705v-5.689c.006-.9-1.163-.76-1.986-.732v-.937l5.66-.204"
        fill="currentColor"
      />
    </Svg>
  );
};

export default Info;
