import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Chevron = ({ ...props }: IconProps) => {
  return (
    <Svg viewBox="0 0 10 16" {...props}>
      <path
        d="M1.25.77a1.074 1.074 0 0 0 0 1.512L6.918 8 1.25 13.718a1.074 1.074 0 0 0 1.525 1.512l6.412-6.467a1.086 1.086 0 0 0 0-1.526L2.775.77a1.074 1.074 0 0 0-1.525 0Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default Chevron;
