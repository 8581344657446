import * as React from 'react';
import styled from 'styled-components';

import { Check, Warning } from 'common/icons';
import Close from 'common/icons/Close';
import { ifVariant } from 'common/utils';
import { ThemeColor } from 'shared/theme';
import { Button } from 'common/components';
import { BUTTON_VARIANTS } from 'common/consts';
import { TOAST_ERROR_TITLE, TOAST_SUCCESS_TITLE } from 'common/toastMessages';
import { resolveSpacersArray } from 'common/styles/utils';
import { clamp } from 'common/utils/clamp';

import ProgressBar from './ProgressBar';
import { useCountdown } from '../hooks';
import { ToastRole } from '../types';

export interface ToastProps {
  message: string;
  role: ToastRole;
  close: () => void;
}

const ifRole = ifVariant<ToastRole[]>(['error', 'success']);

const animationDuration = 200;
const entryAnimationTime = 500;

const minToastTimeout = 2000;
const maxToastTimeoutMs = 7000;
const timePerCharacter = 50;

const Toast = ({ message, role, close }: ToastProps) => {
  const progressColor = ifRole<ThemeColor>(role, ['pinkSuperLight', 'greenSuperLight']);
  const countdownTime =
    clamp(minToastTimeout, message.length * timePerCharacter, maxToastTimeoutMs) +
    entryAnimationTime;

  const remainingTime = useCountdown(countdownTime, animationDuration);
  React.useEffect(() => {
    if (remainingTime <= 0) {
      close();
    }
  }, [close, remainingTime]);

  return (
    <ToastContainer>
      <Line $role={role} />
      <IconWrapper>
        {role === 'success' ? <Check size="24px" /> : <Warning size="24px" />}
      </IconWrapper>
      <MessageContainer>
        <MessageTitle>
          {role === 'success' ? TOAST_SUCCESS_TITLE : TOAST_ERROR_TITLE}
        </MessageTitle>
        <MessageDescription>{message}</MessageDescription>
      </MessageContainer>
      <Button variant={BUTTON_VARIANTS.ICON} onClick={close}>
        <Close size="10px" color="pink" />
      </Button>
      <StyledProgressBar
        animationDuration={animationDuration}
        max={countdownTime}
        value={remainingTime}
        color={progressColor}
      />
    </ToastContainer>
  );
};

type LineProps = {
  $role: ToastRole;
};

const ToastContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  min-height: 88px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.spacer.times(1.5)};
  box-shadow: 0 1px 24px 0 ${({ theme }) => theme.color.shadowLight};
  padding: ${({ theme }) => resolveSpacersArray(theme, [2, 3, 1.5, 2])};
  overflow: hidden;
`;

const StyledProgressBar = styled(ProgressBar)<React.ComponentProps<typeof ProgressBar>>`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
`;

const Line = styled.div<LineProps>`
  width: 5px;
  border-radius: 2px;
  background-color: ${({ theme, $role }) =>
    ifRole($role, [theme.color.pink, theme.color.greenDark])};
`;

const MessageContainer = styled.div`
  padding: ${({ theme }) => resolveSpacersArray(theme, [1, 0])};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const MessageTitle = styled.h1`
  font-size: ${({ theme }) => theme.font.size.moderate};
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.color.black};
`;

const MessageDescription = styled.div`
  font-size: ${({ theme }) => theme.font.size.small};
  color: ${({ theme }) => theme.color.greyDark};
`;

const IconWrapper = styled.div`
  padding: ${({ theme }) => resolveSpacersArray(theme, [0, 1.5, 0, 2])};
  display: flex;
  align-items: center;
`;

export default Toast;
