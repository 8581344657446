import * as React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useFormContext } from 'react-hook-form';

import { SelectOption } from 'common/types';
import { ThemeColor } from 'shared/theme';
import { STATUSES, STATUSES_OPTIONS } from 'common/consts';
import { Select, StatusChip } from 'common/components';
import { PlainCheckbox } from 'common/components/Checkbox';
import { SelectItemProps, SelectValuesContainer } from 'common/components/Select';
import { useContentContext } from '../context';

export interface FilterOption extends SelectOption<STATUSES> {
  number?: number;
  color?: ThemeColor;
}

type Props = {
  name: string;
};

const StatusFilter = ({ name }: Props) => {
  const { data } = useContentContext();
  const { getValues, setValue } = useFormContext();

  const options = React.useMemo<FilterOption[]>(() => {
    const options: FilterOption[] = [];
    if (data && data.length > 0) {
      STATUSES_OPTIONS.forEach((option) => {
        const number: number = data.filter(({ status }) => status === option.value)
          .length;
        if (number > 0) {
          options.push({ ...option, number });
        }
      });
    }
    return options;
  }, [data]);

  React.useEffect(() => {
    const values: FilterOption[] = getValues(name);
    if (values) {
      const availableValues: FilterOption[] = [];
      values.forEach(({ value }) => {
        const available = options.find((option) => value === option.value);
        if (available) {
          availableValues.push(available);
        }
      });
      setValue(name, availableValues);
    }
  }, [options, getValues, setValue, name]);

  return (
    <FilterContainer>
      <Select
        name={name}
        placeholder="All statuses"
        options={options}
        itemComponent={ItemComponent}
        isMulti
      />
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  position: relative;
  min-width: ${({ theme }) => theme.spacer.times(37)};

  ${SelectValuesContainer} {
    ${StatusChip} {
      margin-top: -10px;
      margin-bottom: -10px;
    }
  }
`;

const ItemComponent = ({ value, selected, onPick }: SelectItemProps<FilterOption>) => {
  return (
    <ItemComponentContainer color={value.color} onClick={() => onPick(value)}>
      <PlainCheckbox name={value.label} checked={selected} readOnly />
      <ItemComponentLabel>
        <StatusChip status={value.value} />
      </ItemComponentLabel>
      {value.number && (
        <ItemComponentNumber color={value.color}>{value.number}</ItemComponentNumber>
      )}
    </ItemComponentContainer>
  );
};

const ItemComponentContainer = styled.div<{ color?: ThemeColor }>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacer.standard};
  margin: ${({ theme }) => theme.spacer.small} 0;
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  color: ${({ theme, color = 'black' }) => theme.color[color]};
  cursor: pointer;
`;

const ItemComponentLabel = styled.span`
  margin-left: ${({ theme }) => theme.spacer.standard};
  font-size: ${({ theme }) => theme.font.size.small};
`;

const ItemComponentNumber = styled.span<{ color?: ThemeColor }>`
  display: flex;
  justify-content: center;
  margin-left: auto;
  padding: 0 ${({ theme }) => theme.spacer.times(0.6)};
  min-width: ${({ theme }) => theme.spacer.times(3)};
  height: ${({ theme }) => theme.spacer.times(3)};
  line-height: ${({ theme }) => theme.spacer.times(3)};
  font-size: ${({ theme }) => theme.font.size.tiny};
  border-radius: ${({ theme }) => theme.spacer.times(1.5)};
  color: ${({ theme, color = 'black' }) => theme.color[color]};
  background-color: ${({ theme, color = 'black' }) => `${rgba(theme.color[color], 0.1)}`};
`;

export default StatusFilter;
