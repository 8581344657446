import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Cross = ({ ...props }: IconProps) => (
  <Svg viewBox="0 0 16 17" {...props}>
    <path
      d="M15.7.8c-.4-.4-1-.4-1.4 0L8 7.1 1.7.8C1.3.4.7.4.3.8c-.4.4-.4 1 0 1.4l6.3 6.3-6.3 6.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3L8 9.9l6.3 6.3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4L9.4 8.5l6.3-6.3c.4-.4.4-1 0-1.4z"
      fill="currentColor"
    />
  </Svg>
);

export default Cross;
