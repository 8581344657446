import * as React from 'react';
import styled from 'styled-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import enterCode from 'assets/graphics/login_enter_code_graphic.svg';

import { LOGO_VARIANTS } from 'common/consts';
import { EMAIL_LOGIN } from '../consts';
import { Input, Button, Title, Logo, Wrapper, Graphics } from 'common/components';
import LoginBackground from '../components/LoginBackground';
import { useSendLoginEmail } from '../queries';
import { Check } from 'common/icons';
import CodeLoginForm from './CodeLoginForm';

type FormValues = {
  email: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(),
});

const LoginEmail = () => {
  const { mutate, isLoading, isError, isSuccess, error } = useSendLoginEmail();

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  const submit: SubmitHandler<FormValues> = (values) => {
    mutate(values);
  };

  const email = Cookies.get(EMAIL_LOGIN) || '';

  return (
    <LoginContainer>
      <LoginBackground />
      <LoginCardContainer>
        <Logo variant={LOGO_VARIANTS.TEXT} />
        <LoginCard>
          {isSuccess ? (
            <>
              <Title size="big" marginBottom="small" center>
                Success!
              </Title>
              <Title size="small" marginBottom="big" color="greyMediumDark" center>
                Login link was succesfully send to <EmailWrapper>{email}</EmailWrapper>.
              </Title>
              <IconContainer>
                <Check size="110px" />
              </IconContainer>
              <Title size="small" center color="greyMediumDark">
                You may now close this tab.
              </Title>
            </>
          ) : (
            <>
              <Title size="big" marginBottom="big">
                CMS Login
              </Title>
              <Title size="small" marginBottom="big" color="greyMediumDark">
                Type your CMS admin email account
              </Title>
              <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(submit)}>
                  <Wrapper margin={[2, 0]}>
                    <Input
                      name="email"
                      label="E-mailadres"
                      type="email"
                      autoComplete="username"
                    />
                  </Wrapper>
                  <ButtonContainer>
                    <Button type="submit" isLoading={isLoading}>
                      Send
                    </Button>
                  </ButtonContainer>
                  {isError && (
                    <ErrorMessage>
                      {error?.message || 'Something went wrong'}
                    </ErrorMessage>
                  )}
                </form>
              </FormProvider>
            </>
          )}
        </LoginCard>
      </LoginCardContainer>
      {isSuccess && (
        <LoginCardContainer>
          <LoginCard>
            <Title size="big" marginBottom="small" center>
              Login via code
            </Title>
            <Title size="small" marginBottom="big" color="greyMediumDark" center>
              Enter the code you received in the email
            </Title>
            <IconContainer>
              <Graphics src={enterCode} height="200px" />
            </IconContainer>
            <FormProvider {...form}>
              <CodeLoginForm email={email} />
            </FormProvider>
          </LoginCard>
        </LoginCardContainer>
      )}
    </LoginContainer>
  );
};

export const LoginWrapper = styled.div`
  display: flex;
  height: 100vh;
  padding: ${({ theme }) => `${theme.spacer.times(9)} ${theme.spacer.big}`};
`;

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: ${({ theme }) => `${theme.spacer.times(9)} ${theme.spacer.big}`};
`;

export const LoginCardContainer = styled.div`
  z-index: 1;
  width: 480px;
  margin: auto;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacer.times(3)};
`;

const EmailWrapper = styled.span`
  text-decoration: underline;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacer.times(2)};

  ${Button} {
    width: 100%;
    margin: ${({ theme }) => `${theme.spacer.small} 0 ${theme.spacer.big}`};
  }
`;

export const LoginCard = styled.div`
  position: relative;
  margin-top: ${({ theme }) => theme.spacer.times(7)};
  padding: ${({ theme }) =>
    `${theme.spacer.times(3)} ${theme.spacer.times(8)} ${theme.spacer.times(
      3,
    )} ${theme.spacer.times(8)}`};
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.border.radius.standard};
`;

const ErrorMessage = styled.span`
  font-size: ${({ theme }) => theme.font.size.tiny};
  color: ${({ theme }) => theme.color.error};
`;

export default LoginEmail;
