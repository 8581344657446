import * as React from 'react';
import { useWatch } from 'react-hook-form';
import styled, { css, useTheme } from 'styled-components';

import { Input } from 'common/components';
import { InputProps } from 'common/components/Input';
import { EditPencil } from 'common/icons';
import { resolveSpacersArray } from 'common/styles/utils';

const TitleInput = ({ name, ...props }: InputProps) => {
  const theme = useTheme();
  const [showIcon, setShowIcon] = React.useState(true);
  const inputValue = useWatch<string>({ name });

  const toggleIcon = (e: React.FocusEvent<HTMLInputElement>) => {
    setShowIcon(e.type !== 'focus');
  };

  return (
    <InputContainer>
      <Input name={name} {...props} onFocus={toggleIcon} onBlur={toggleIcon} />
      <TextMeasure>
        <TextValue>{inputValue}</TextValue>
        <Icon>{showIcon && <EditPencil color={theme.keys.secondaryColor} />}</Icon>
      </TextMeasure>
    </InputContainer>
  );
};

const fontStyles = css`
  font-size: ${({ theme }) => theme.font.size.big};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-family: ${({ theme }) => theme.font.family.sans};
  line-height: 1.5;
`;

const InputContainer = styled.div`
  position: relative;
  input {
    ${fontStyles}
    width: calc(100% + ${({ theme }) => theme.spacer.times(4)});
    border: none;
    color: ${({ theme }) => theme.color.black};
    padding: ${({ theme }) => resolveSpacersArray(theme, ['small', 6, 'small', 2])};
    margin: 0 -${({ theme }) => theme.spacer.big};

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.color.greySuperLight};
    }
  }
`;

const TextValue = styled.span`
  overflow: hidden;
`;

const Icon = styled.span`
  margin-left: ${({ theme }) => theme.spacer.standard};
`;

const TextMeasure = styled.span`
  ${fontStyles}
  display: flex;
  pointer-events: none;
  max-width: 100%;
  position: absolute;
  top: ${({ theme }) => theme.spacer.small};
  left: 0;
  color: transparent;
`;

export default TitleInput;
