import * as React from 'react';
import { useParams } from 'react-router-dom';

import { PK } from 'common/types';
import { Company } from 'base/api/types';
import { DetailsCard, DetailsSuspense } from 'templates/details';
import CompanyActivation from '../components/CompanyActivation';
import ProgramSettings from '../components/ProgramSettings';
import LogoPicker from '../components/LogoPicker';
import CompanyAdmins from '../components/CompanyAdmins';
import BillingEmails from '../components/BillingEmails';
import CompanyCore from '../components/CompanyCore';
import { fields, CompanyFields } from '../consts';
import { useCompanyDetails } from '../queries';

const transformCompany = (raw: Company): CompanyFields => ({
  [fields.title.name]: raw.title,
  [fields.branch.name]: raw.branch,
  [fields.kvk_number.name]: raw.kvk_number,
  [fields.min_team_size.name]: raw.min_team_size,
  [fields.max_team_size.name]: raw.max_team_size,
});

const CompanyDetails = () => {
  const { pk } = useParams<{ pk?: PK }>();
  const { data, ...company } = useCompanyDetails(pk);
  const defaultValues = data && transformCompany(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at} key={pk}>
      <DetailsSuspense isLoading={company.isLoading} error={company.error}>
        <CompanyCore pk={pk} defaultValues={defaultValues}>
          <CompanyAdmins />
          <BillingEmails />
          <LogoPicker />
          <ProgramSettings />
          <CompanyActivation />
        </CompanyCore>
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default CompanyDetails;
