import * as React from 'react';

import {
  ContentGoalSelect,
  DatePicker,
  Input,
  Select,
  Subtitle,
  Textarea,
  Title,
} from 'common/components';
import { DATE_FORMATS, STATUSES_OPTIONS, POINTS_OPTIONS, STATUSES } from 'common/consts';
import { CoreProps, PK } from 'common/types';
import { formatDate } from 'common/utils';
import {
  CoreFormLayout,
  FieldsGrid,
  GridItem,
  Section,
  SectionsWrapper,
} from 'templates/core';
import { TitleInput } from 'templates/details';

import { useSeriesCreate, useSeriesUpdate } from '../queries';
import { SeriesFields, validationSchema, fields } from '../consts';
import SeriesAssembler from './SeriesAssembler';
import SeriesFollowup from './SeriesFollowup';
import SeriesMediaPicker from './SeriesMediaPicker';
import SeriesTagsPicker from './SeriesTagsPicker';
import { useToast } from 'modules/toast';
import { toastMessages } from 'common/toastMessages';
import SeriesBlogPicker from 'modules/blogs/components/SeriesBlogPicker';
import { getIsFriesland } from 'common/utils/config';
import { FeatureFlags } from 'common/utils/featureFlags';
import { SeriesChallengeFields } from './SeriesChallengeFields';

const { challenges } = FeatureFlags;

const useSave = (isTags: boolean, pk?: PK, blog_pk?: PK) => {
  const [created, setCreated] = React.useState(pk);
  const { toast } = useToast();
  const create = useSeriesCreate();
  const update = useSeriesUpdate();
  const isSaving = create.isLoading || update.isLoading;
  const error = create.error || update.error;

  const save = (values: SeriesFields) => {
    if (values?.status[0].value === 'ACTIVE' && !isTags) {
      toast(toastMessages.ERROR.CHANGING_STATUS_WITHOUT_TAGS, 'error');
      return;
    }

    const startDate = values[fields.start_date.name];
    const endDate = values[fields.end_date.name];
    const challengeStartDate = values[fields.challenge_start_date.name];

    const payload = {
      title: values[fields.title.name],
      description: values[fields.description.name],
      short_description: values[fields.short_description.name],
      punctation: values[fields.punctation.name][0].value,
      content_goal_pks: values[fields.content_goals.name].map((option) => option.value),
      start_date: startDate ? formatDate(startDate, DATE_FORMATS.DATABASE) : null,
      end_date: endDate ? formatDate(endDate, DATE_FORMATS.DATABASE) : null,
      blog_pk,
      is_challenge: challenges() ? values[fields.is_challenge.name] ?? false : false,
      challenge_start_date: challengeStartDate
        ? formatDate(challengeStartDate, DATE_FORMATS.DATABASE)
        : null,
    };
    if (created) {
      update.mutate({ ...payload, pk: created, status: values.status[0].value });
    } else {
      create.mutate(payload, {
        onSuccess: ({ pk }) => {
          setCreated(pk);
        },
      });
    }
  };

  return { save, created, isSaving, error };
};

const isScheduledChallenge = (date: Date) => {
  const tomorrow = new Date();
  tomorrow.setHours(0, 0, 0, 0);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return date >= tomorrow;
};

const SeriesCore = ({
  pk,
  blog_pk,
  defaultValues,
  layout = 'overview',
}: CoreProps<SeriesFields>) => {
  const [isTags, setIsTags] = React.useState(false);
  const { save, isSaving, error, created } = useSave(isTags, pk, blog_pk);
  const isFriesland = getIsFriesland();

  const isCurrentlyActiveChallenge = Boolean(
    defaultValues?.is_challenge &&
      defaultValues?.challenge_start_date &&
      isScheduledChallenge(defaultValues?.challenge_start_date),
  );

  const availableStatusOptions = STATUSES_OPTIONS.filter((option) => {
    if (isCurrentlyActiveChallenge) {
      return option.value !== STATUSES.INACTIVE;
    } else {
      return true;
    }
  });

  return (
    <>
      <CoreFormLayout
        onSubmit={save}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isSubmitting={isSaving}
        error={error}
      >
        {layout === 'overview' && (
          <Section>
            <GridItem>
              <TitleInput name={fields.title.name} />
            </GridItem>
            {defaultValues && defaultValues.is_challenge && (
              <Subtitle label="challenge" />
            )}
          </Section>
        )}
        <Section>
          <FieldsGrid>
            <GridItem compact>
              <Select
                name={fields.status.name}
                options={availableStatusOptions}
                readOnly={!created}
              />
            </GridItem>
            {layout === 'creation' && (
              <GridItem>
                <Input name={fields.title.name} label={fields.title.label} />
              </GridItem>
            )}
          </FieldsGrid>
        </Section>
        <Section>
          <Title marginBottom={2.5}>General</Title>
          <FieldsGrid>
            <GridItem>
              <Textarea
                name={fields.short_description.name}
                label={fields.short_description.label}
              />
            </GridItem>
            <GridItem>
              <Textarea name={fields.description.name} label={fields.description.label} />
            </GridItem>
            <GridItem compact>
              <Select
                name={fields.punctation.name}
                label={fields.punctation.label}
                options={POINTS_OPTIONS}
              />
            </GridItem>
            <GridItem compact>
              <ContentGoalSelect
                isMulti
                name={fields.content_goals.name}
                label={fields.content_goals.label}
              />
            </GridItem>
          </FieldsGrid>
        </Section>
        {challenges() && (
          <SeriesChallengeFields
            isCurrentlyActiveChallenge={isCurrentlyActiveChallenge}
          />
        )}
        <Section>
          <Title marginBottom={2.5}>Availability</Title>
          <FieldsGrid>
            <GridItem>
              <DatePicker name={fields.start_date.name} label={fields.start_date.label} />
            </GridItem>
            <GridItem>
              <DatePicker name={fields.end_date.name} label={fields.end_date.label} />
            </GridItem>
          </FieldsGrid>
        </Section>
      </CoreFormLayout>
      {isFriesland ? (
        <SectionsWrapper pk={created}>
          <SeriesTagsPicker setIsTags={setIsTags} />
          <SeriesMediaPicker />
          <SeriesAssembler />
          <SeriesBlogPicker />
        </SectionsWrapper>
      ) : (
        <SectionsWrapper pk={created}>
          <SeriesTagsPicker setIsTags={setIsTags} />
          <SeriesMediaPicker />
          <SeriesAssembler />
          <SeriesBlogPicker />
          <SeriesFollowup />
        </SectionsWrapper>
      )}
    </>
  );
};

export default SeriesCore;
