import * as React from 'react';
import styled from 'styled-components';

import { MediaObject } from 'base/api/types';
import { MEDIA_TYPES } from 'common/consts';
import { resolveSpacersArray } from 'common/styles/utils';
import { Img, PrimaryKey } from 'common/components';
import MediaCard, { MediaCardProps } from './MediaCard';

interface MediaPreviewCardProps extends MediaCardProps {
  onClick?: (media: MediaObject) => void;
}

class MediaPreviewCard extends React.PureComponent<MediaPreviewCardProps> {
  handleClick = () => {
    const { columnIndex, rowIndex, data } = this.props;
    const index = columnIndex + rowIndex * 4;
    const itemData = data && data[index];
    if (itemData) {
      this.props.onClick?.(itemData);
    }
  };

  render() {
    const { columnIndex, rowIndex, data } = this.props;
    const index = columnIndex + rowIndex * 4;
    const itemData = data && data[index];

    if (!itemData) {
      return null;
    }

    const { pk, url, name, thumbnail, media_type } = itemData;

    return (
      <MediaCard {...this.props}>
        <PosterButton onClick={this.handleClick}>
          {media_type === MEDIA_TYPES.AUDIO ? null : <Img src={thumbnail || url} />}
        </PosterButton>
        <MediaCardFooter>
          <TitleButton onClick={this.handleClick}>{name}</TitleButton>
          <PrimaryKey pk={pk} />
        </MediaCardFooter>
      </MediaCard>
    );
  }
}

const ClearButton = styled.button`
  display: block;
  border: none;
  padding: 0;
  margin: 0;
  appearance: none;
  background: none;
  color: ${({ theme }) => theme.color.black};
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    color: ${({ theme }) => theme.color.pink};
  }
`;

const PosterButton = styled(ClearButton)`
  width: 100%;
  height: 200px;
  background-color: ${({ theme }) => theme.color.blueSuperLight};
  border-top-left-radius: ${({ theme }) => theme.border.radius.standard};
  border-top-right-radius: ${({ theme }) => theme.border.radius.standard};
  overflow: hidden;

  ${Img} {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: ${({ theme }) => theme.border.radius.standard};
    border-top-right-radius: ${({ theme }) => theme.border.radius.standard};
    transform: scale(1);
    transition: transform 0.2s ease;
  }

  &:hover,
  &:focus {
    ${Img} {
      transform: scale(1.05);
    }
  }
`;

const MediaCardFooter = styled.div`
  padding: ${({ theme }) => resolveSpacersArray(theme, [2, 3])};
`;

const TitleButton = styled(ClearButton)`
  font-size: ${({ theme }) => theme.font.size.standard};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-family: ${({ theme }) => theme.font.family.sans};
  margin: 0 0 ${({ theme }) => theme.spacer.standard};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
`;

export default MediaPreviewCard;
