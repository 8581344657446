import * as React from 'react';
import styled, { css } from 'styled-components';

import { Button, Img, Row } from 'common/components';
import { Bin } from 'common/icons';
import { BUTTON_VARIANTS } from 'common/consts';
import { FileInputLabelProps } from 'common/components/FileInput';
import { fadeIn } from 'common/styles/animations';

interface Props extends Partial<FileInputLabelProps> {
  imageUrl: string;
  fileName: string;
  onMediaRemove?: () => void;
}

export const FileLabel = ({ imageUrl, fileName, onMediaRemove }: Props) => {
  return (
    <LabelContainer>
      <Row>
        <StyledImg alt="uploaded icon preview" src={imageUrl} />
        <LabelMessage>
          <span>{fileName}</span>
        </LabelMessage>
      </Row>
      <Button onClick={onMediaRemove} variant={BUTTON_VARIANTS.ICON} noNegativeMargin>
        <Bin size="20px" color="pink" hoverColor="pink" />
      </Button>
    </LabelContainer>
  );
};

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${css`
    animation: ${fadeIn} 0.8s ease;
  `}
`;

const LabelMessage = styled.p`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 ${({ theme }) => theme.spacer.big} 0 0;
  color: ${({ theme }) => theme.color.greyDark};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.small};
`;

const StyledImg = styled(Img)`
  margin-right: ${({ theme }) => theme.spacer.big};
`;

export default FileLabel;
