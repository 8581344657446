import * as React from 'react';

import { Bone, ErrorBox, Title, Wrapper } from 'common/components';
import { useContentContext } from 'templates/content';
import { StandardError } from 'base/api/errors';

const margins = {
  field: [2, 0],
  section: [2, 4],
  title: [0, 0, 2],
};

interface Props {
  error?: StandardError | null;
  isLoading?: boolean;
  children?: React.ReactNode;
}

function DetailsSuspense({ isLoading, error, children }: Props) {
  const { active } = useContentContext();
  return (
    <>
      {isLoading ? (
        <Wrapper margin={margins.section}>
          <Wrapper margin={margins.title}>
            {active ? (
              <Title size="big">{active.title}</Title>
            ) : (
              <Bone padding={3} width={20} />
            )}
          </Wrapper>
          <Bone padding={4} margin={margins.field} />
          <Bone padding={4} margin={margins.field} />
          <Bone padding={4} margin={margins.field} />
          <Bone padding={4} margin={margins.field} />
        </Wrapper>
      ) : error ? (
        <Wrapper margin={margins.section}>
          <Title size="big">{active?.title}</Title>
          <ErrorBox error={error} />
        </Wrapper>
      ) : (
        children
      )}
    </>
  );
}

export default DetailsSuspense;
