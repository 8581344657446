import * as React from 'react';

import { CalendarDate } from 'common/icons';

import Indicator from './Indicator';

const CalendarIndicator: React.FC = ({ children }) => (
  <Indicator
    color="greenDark"
    highlightColor="greenSuperLight"
    icon={<CalendarDate color="greenDark" size="16px" />}
  >
    {children}
  </Indicator>
);

export default CalendarIndicator;
