import { theme as ActifyTheme } from 'shared/actifyTheme';
import { theme as FrieslandTheme } from 'shared/frieslandTheme';

import minisIcon from 'assets/graphics/minis_icon.svg';
import seriesIcon from 'assets/graphics/series_icon.svg';
import companiesIcon from 'assets/graphics/companies_icon.svg';
import collectionsIcon from 'assets/graphics/collections_icon.svg';
import funfactsIcon from 'assets/graphics/funfacts_icon.svg';
import meditationsIcon from 'assets/graphics/meditations_icon.svg';
import podcastsIcon from 'assets/graphics/podcasts_icon.svg';
import podcastsIconBlack from 'assets/graphics/podcasts_icon_black.svg';
import workoutsIcon from 'assets/graphics/workouts_icon.svg';
import recipesIcon from 'assets/graphics/recipes_icon.svg';
import factsIcon from 'assets/graphics/facts_icon.svg';
import badgesIcon from 'assets/graphics/badges_icon.png';
import scenariosIcon from 'assets/graphics/scenarios_icon.svg';
import minisIconBlack from 'assets/graphics/minis_icon_black.svg';
import seriesIconBlack from 'assets/graphics/series_icon_black.svg';
import companiesIconBlack from 'assets/graphics/companies_icon_black.svg';
import collectionsIconBlack from 'assets/graphics/collections_icon_black.svg';
import funfactsIconBlack from 'assets/graphics/funfacts_icon_black.svg';
import meditationsIconBlack from 'assets/graphics/meditations_icon_black.svg';
import workoutsIconBlack from 'assets/graphics/workouts_icon_black.svg';
import recipesIconBlack from 'assets/graphics/recipes_icon_black.svg';
import factsIconBlack from 'assets/graphics/facts_icon_black.svg';
import scenariosIconBlack from 'assets/graphics/scenarios_icon_black.svg';
import habitsIcon from 'assets/graphics/habits_icon.svg';
import appUrls from 'base/app/urls';
import { MediaOptions, StatusOption } from 'common/types';
import { getConfig } from 'common/config';

const noIcon = '';

export const AZURE_PARAM =
  getConfig().AZURE_STORAGE_PARAM ||
  '?sv=2021-06-08&ss=bfqt&srt=sco&sp=rltfx&se=2030-01-18T17:22:20Z&st=2023-01-18T09:22:20Z&spr=https&sig=nrBVQ5BrhlGbwVoo7jjxJGNtCxkK7qYPBlMLmovNJH4%3D';

export enum CONTENT_TYPES {
  MINIS = 'MINI',
  SERIES = 'SERIES',
  COLLECTIONS = 'COLLECTION',
  FUN_FACTS = 'FUN_FACT',
  MEDITATIONS = 'MEDITATION',
  PODCASTS = 'PODCAST',
  WORKOUTS = 'WORKOUT',
  RECIPES = 'RECIPE',
  FACTS = 'FACT',
  COMPANIES = 'COMPANY',
  SCENARIOS = 'SCENARIO',
  HABITS = 'HABIT',
  BADGES = 'BADGE',
}

export type Content = {
  type: CONTENT_TYPES;
  title: string;
  icon: string;
  iconInactive: string;
  path: string;
  isBusiness: boolean;
  isMixed?: boolean;
};

export const FRIESLAND_CONTENT: Content[] = [
  {
    type: CONTENT_TYPES.MINIS,
    title: "Mini's",
    icon: minisIcon,
    iconInactive: minisIconBlack,
    path: appUrls.MINIS.LIST,
    isBusiness: true,
    isMixed: true,
  },
  {
    type: CONTENT_TYPES.COMPANIES,
    title: 'Companies',
    icon: companiesIcon,
    iconInactive: companiesIconBlack,
    path: appUrls.COMPANIES.LIST,
    isBusiness: true,
  },
  {
    type: CONTENT_TYPES.SCENARIOS,
    title: 'Scenarios',
    icon: scenariosIcon,
    iconInactive: scenariosIconBlack,
    path: appUrls.SCENARIOS.LIST,
    isBusiness: true,
  },
  {
    type: CONTENT_TYPES.SERIES,
    title: "Serie's",
    icon: seriesIcon,
    iconInactive: seriesIconBlack,
    path: appUrls.SERIES.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.COLLECTIONS,
    title: 'Collections',
    icon: collectionsIcon,
    iconInactive: collectionsIconBlack,
    path: appUrls.COLLECTIONS.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.FUN_FACTS,
    title: 'Fun facts',
    icon: funfactsIcon,
    iconInactive: funfactsIconBlack,
    path: appUrls.FUNFACTS.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.MEDITATIONS,
    title: 'Meditations',
    icon: meditationsIcon,
    iconInactive: meditationsIconBlack,
    path: appUrls.MEDITATIONS.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.PODCASTS,
    title: 'Podcasts',
    icon: podcastsIcon,
    iconInactive: podcastsIconBlack,
    path: appUrls.PODCASTS.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.HABITS,
    title: 'Habits',
    icon: habitsIcon,
    iconInactive: habitsIcon, // TODO: change to black icon if available
    path: appUrls.HABITS.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.WORKOUTS,
    title: 'Workouts',
    icon: workoutsIcon,
    iconInactive: workoutsIconBlack,
    path: appUrls.WORKOUTS.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.RECIPES,
    title: 'Recipes',
    icon: recipesIcon,
    iconInactive: recipesIconBlack,
    path: appUrls.RECIPES.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.FACTS,
    title: 'Facts',
    icon: factsIcon,
    iconInactive: factsIconBlack,
    path: appUrls.FACTS.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.BADGES,
    title: 'Badges',
    icon: badgesIcon,
    iconInactive: noIcon,
    path: appUrls.BADGES.LIST,
    isBusiness: false,
  },
];

export const CONTENT: Content[] = [
  {
    type: CONTENT_TYPES.MINIS,
    title: "Mini's",
    icon: minisIcon,
    iconInactive: minisIconBlack,
    path: appUrls.MINIS.LIST,
    isBusiness: true,
    isMixed: true,
  },
  {
    type: CONTENT_TYPES.COMPANIES,
    title: 'Companies',
    icon: companiesIcon,
    iconInactive: companiesIconBlack,
    path: appUrls.COMPANIES.LIST,
    isBusiness: true,
  },
  {
    type: CONTENT_TYPES.SCENARIOS,
    title: 'Scenarios',
    icon: scenariosIcon,
    iconInactive: scenariosIconBlack,
    path: appUrls.SCENARIOS.LIST,
    isBusiness: true,
  },
  {
    type: CONTENT_TYPES.SERIES,
    title: "Serie's",
    icon: seriesIcon,
    iconInactive: seriesIconBlack,
    path: appUrls.SERIES.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.COLLECTIONS,
    title: 'Collections',
    icon: collectionsIcon,
    iconInactive: collectionsIconBlack,
    path: appUrls.COLLECTIONS.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.FUN_FACTS,
    title: 'Fun facts',
    icon: funfactsIcon,
    iconInactive: funfactsIconBlack,
    path: appUrls.FUNFACTS.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.MEDITATIONS,
    title: 'Meditations',
    icon: meditationsIcon,
    iconInactive: meditationsIconBlack,
    path: appUrls.MEDITATIONS.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.PODCASTS,
    title: 'Podcasts',
    icon: podcastsIcon,
    iconInactive: podcastsIconBlack,
    path: appUrls.PODCASTS.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.HABITS,
    title: 'Habits',
    icon: habitsIcon,
    iconInactive: habitsIcon, // TODO: change to black icon if available
    path: appUrls.HABITS.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.WORKOUTS,
    title: 'Workouts',
    icon: workoutsIcon,
    iconInactive: workoutsIconBlack,
    path: appUrls.WORKOUTS.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.RECIPES,
    title: 'Recipes',
    icon: recipesIcon,
    iconInactive: recipesIconBlack,
    path: appUrls.RECIPES.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.FACTS,
    title: 'Facts',
    icon: factsIcon,
    iconInactive: factsIconBlack,
    path: appUrls.FACTS.LIST,
    isBusiness: false,
  },
  {
    type: CONTENT_TYPES.BADGES,
    title: 'Badges',
    icon: badgesIcon,
    iconInactive: badgesIcon,
    path: appUrls.BADGES.LIST,
    isBusiness: false,
  },
].filter(Boolean) as Content[];

export enum TITLE_VARIANTS {
  MAIN = 'main',
  HEADER = 'header',
  SUBTITLE = 'subtitle',
}

export enum BUTTON_VARIANTS {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  TEXT = 'text',
  ICON = 'icon',
}

export enum BUTTON_SIZES {
  BIG = 'big',
  SMALL = 'small',
}

export enum LOGO_VARIANTS {
  TEXT = 'text',
  GRAPHIC = 'graphic',
}

export enum STATUS_SIZES {
  SMALL = 'small',
  BIG = 'big',
}

export enum STATUS_VARIANTS {
  DRAFT = 'draft',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  REVIEW = 'review',
}

export enum STATUSES {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REVIEW = 'IN_REVIEW',
}

export enum PROGRAM_STATUSES {
  SCHEDULED = 'SCHEDULED',
  STARTED = 'STARTED',
}

export enum HANDLER_TYPES {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

export enum MINI_SUGGESTIONS_MODE_TYPES {
  BML = 'BML',
}

export const HANDLER_TYPE_OPTIONS = [
  {
    label: 'Static',
    value: HANDLER_TYPES.STATIC,
  },
  {
    label: 'Dynamic',
    value: HANDLER_TYPES.DYNAMIC,
  },
];

export const STATUSES_OPTIONS: StatusOption[] = [
  {
    label: 'Draft',
    value: STATUSES.DRAFT,
    variant: STATUS_VARIANTS.DRAFT,
  },
  {
    label: 'Active',
    value: STATUSES.ACTIVE,
    variant: STATUS_VARIANTS.ACTIVE,
  },
  {
    label: 'Inactive',
    value: STATUSES.INACTIVE,
    variant: STATUS_VARIANTS.INACTIVE,
  },
  {
    label: 'Review',
    value: STATUSES.REVIEW,
    variant: STATUS_VARIANTS.REVIEW,
  },
];

export const defaultStatus = {
  status: STATUSES_OPTIONS.filter(({ value }) => value === STATUSES.DRAFT),
};

export enum MEDIA_TYPES {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  ICON = 'ICON',
}

export const MEDIA_OPTIONS: MediaOptions[] = [
  {
    type: MEDIA_TYPES.IMAGE,
    label: 'Image',
    regexp: /image\/.*/,
    accept: 'image/*',
    errorMessage: 'Incorrect file type. File has to be an image.',
  },
  {
    type: MEDIA_TYPES.VIDEO,
    label: 'Video',
    regexp: /video\/.*/,
    accept: 'video/*',
    errorMessage: 'Incorrect file type. File has to contain video.',
  },
  {
    type: MEDIA_TYPES.AUDIO,
    label: 'Audio',
    regexp: /audio\/.*/,
    accept: 'audio/*',
    errorMessage: 'Incorrect file type. File has to contain audio.',
  },
  {
    type: MEDIA_TYPES.ICON,
    label: 'Icon',
    regexp: /image\/svg\+xml/,
    accept: 'image/svg+xml',
    errorMessage: 'Incorrect file type. File has be an SVG image.',
  },
];

export const POINTS_OPTIONS = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  { label: '25', value: 25 },
  { label: '30', value: 30 },
  { label: '35', value: 35 },
  { label: '40', value: 40 },
  { label: '45', value: 45 },
  { label: '50', value: 50 },
];

export enum CATEGORIES {
  MIXED = '#e6c800',
  EAT = '#00e0b8',
  MOVE = '#ff0098',
  RELAX = '#0055ff',
}

export enum FRIESLAND_CATEGORIES {
  MIXED = '#ECDCEF',
  EAT = '#FBE3E3',
  MOVE = '#E0EAF5',
  RELAX = '#FFF1CC',
}

export const getCategoryOptions = (isFriesland: boolean) => {
  const categories = isFriesland ? FRIESLAND_CATEGORIES : CATEGORIES;

  return [
    {
      label: 'Mixed',
      value: categories.MIXED,
    },
    {
      label: 'Eat',
      value: categories.EAT,
    },
    {
      label: 'Move',
      value: categories.MOVE,
    },
    {
      label: 'Relax',
      value: categories.RELAX,
    },
  ];
};

export enum DATE_FORMATS {
  HUMAN = 'dd-MM-yyyy',
  DATABASE = 'yyyy-MM-dd',
  SCHEDULED = 'dd/MM/yyyy',
}

export enum PROJECT_NAME {
  ACTIFY = 'actify',
  FRIESLAND = 'friesland',
}

export const ProjectSettings = {
  [PROJECT_NAME.ACTIFY]: {
    title: 'Actify CMS',
    theme: ActifyTheme,
    favicon: 'favicon.ico',
  },
  [PROJECT_NAME.FRIESLAND]: {
    title: 'Samen Fitter CMS',
    theme: FrieslandTheme,
    favicon: 'samenfitter.ico',
  },
};

export enum WEEK_DAYS {
  Monday = 0,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
}

export const WEEK_DAYS_OPTIONS = [
  {
    label: 'Monday',
    value: WEEK_DAYS.Monday,
  },
  {
    label: 'Tuesday',
    value: WEEK_DAYS.Tuesday,
  },
  {
    label: 'Wednesday',
    value: WEEK_DAYS.Wednesday,
  },
  {
    label: 'Thursday',
    value: WEEK_DAYS.Thursday,
  },
  {
    label: 'Friday',
    value: WEEK_DAYS.Friday,
  },
] as const;
