import styled from 'styled-components';

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 ${({ theme }) => theme.spacer.standard};
  padding: 0 ${({ theme }) => theme.spacer.times(1.5)};
  border-radius: ${({ theme }) => theme.spacer.times(1.5)};
  height: ${({ theme }) => theme.spacer.times(3)};
  line-height: ${({ theme }) => theme.spacer.times(3.3)};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.greenDark};
  background-color: ${({ theme }) => theme.color.greenSuperLight};
`;
