import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import apiUrls from 'base/api/urls';
import { Habits } from 'base/api/types';
import { StandardError } from 'base/api/errors';
import { useListQuery } from 'base/api/hooks';
import { CommonVars, PK } from 'common/types';
import { composeUrl } from 'common/utils';

export const useHabitsList = () => {
  return useListQuery<Habits>(apiUrls.HABITS.LIST, { isBusinessSensitive: true });
};

interface HabitsCreateVars {
  title: string;
  description: string;
}

export const useHabitsCreate = () => {
  const queryClient = useQueryClient();
  return useMutation<Habits, StandardError, HabitsCreateVars>(
    (data) => axios.post(apiUrls.HABITS.LIST, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.HABITS.LIST);
        queryClient.invalidateQueries(apiUrls.CONTENT.LIST);
      },
    },
  );
};

type HabitsUpdateVars = Partial<HabitsCreateVars & CommonVars> & {
  lvl: number;
};

const updateHabitLevel = async ({ pk, lvl, description }: HabitsUpdateVars) => {
  const url = `/cms/habits/${pk}/levels/${lvl}`;
  const payload = { description, lvl };
  await axios.put(url, payload);
  return null;
};

export const useUpdateHabitLevel = () => {
  return useMutation<null, StandardError, HabitsUpdateVars>(updateHabitLevel, {});
};

export const useHabitsDetails = (pk?: PK) => {
  return useQuery<Habits, StandardError>({
    queryKey: [apiUrls.HABITS.DETAILS, { pk }],
    enabled: !!pk,
  });
};

interface HabitsMinisVars extends CommonVars {
  lvl: number;
  minis_pks_per_day: {
    minis: PK[];
  }[];
}

export const useHabitsAllDaysUpdate = () => {
  return useMutation<unknown, StandardError, HabitsMinisVars>(({ pk, ...data }) => {
    const url = `/cms/habits/${pk}/levels/${data.lvl}/minis`; //TODO, build URL the correct way.
    function transformMinis(data: Partial<HabitsMinisVars>) {
      const transformedArray = data.minis_pks_per_day?.map((item) => item.minis);
      return {
        minis_pks_per_day: transformedArray,
      };
    }

    const transformedData = transformMinis(data);

    return axios.put(url, transformedData);
  });
};

export const useSeriesDuplicate = () => {
  const queryClient = useQueryClient();

  return useMutation<CommonVars, StandardError, CommonVars>(
    ({ pk, ...data }) =>
      axios.post(composeUrl(apiUrls.SERIES.DUPLICATE, { params: { pk } }), data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([apiUrls.SERIES.LIST]);
      },
    },
  );
};
