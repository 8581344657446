import * as React from 'react';

import { PK } from 'common/types';
import { MEDIA_TYPES } from 'common/consts';
import { CollapsibleSection, MediaPicker, Title } from 'common/components';
import { useRecipeMedia, useRecipeMediaUpdate } from '../queries';

const fieldName = 'media_image_pks';

type Props = {
  pk?: PK;
};

const RecipeImageMediaPicker = ({ pk }: Props) => {
  const { data } = useRecipeMedia(pk);
  const { mutate, isSuccess, isLoading, isError } = useRecipeMediaUpdate();
  const saveStatus = { isSuccess, isLoading, isError };
  const defaultValues = data?.image && [data.image];

  const handleSave = (imagePks: PK[]) => {
    if (pk) {
      const payload = {
        pk,
        video: data?.video?.pk ?? null,
        image: data?.image?.pk ?? null,
      };

      if (imagePks.length > 0) {
        payload.image = imagePks[0];
      } else {
        payload.image = null;
      }

      mutate(payload);
    }
  };

  return (
    <CollapsibleSection title="Add image" saveStatus={saveStatus} disabled={!pk}>
      <Title size="standard" marginBottom={1.5}>
        Linked image
      </Title>
      <MediaPicker
        onSave={handleSave}
        name={fieldName}
        mediaType={MEDIA_TYPES.IMAGE}
        defaultValues={defaultValues}
      />
    </CollapsibleSection>
  );
};

export default RecipeImageMediaPicker;
