import styled from 'styled-components';

import { Link } from 'common/components';

export const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transform: translateZ(0);
  height: ${({ theme }) => theme.spacer.times(8)};
  background-color: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.themeColor.tertiaryLightColor};

  @media (min-width: ${({ theme }) => theme.media.sm}) {
    height: ${({ theme }) => theme.spacer.times(11)};
  }
`;

export const HeaderContent = styled.div`
  height: 100%;
  max-width: ${({ theme }) => `calc(${theme.media.max} + 2 * ${theme.spacer.big})`};
  padding: 0 ${({ theme }) => theme.spacer.times(3)};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.media.sm}) {
    justify-content: left;
  }
`;

export const LogoContainer = styled.div`
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.media.sm}) {
    margin: 0 100px 0 0;
  }
`;

export const HeaderNav = styled.nav`
  display: none;

  @media (min-width: ${({ theme }) => theme.media.sm}) {
    display: flex;
    align-items: center;

    &:last-child {
      margin-left: auto;
    }
  }
`;

export const NavItem = styled.div`
  margin: 0 ${({ theme }) => theme.spacer.big};

  ${Link} {
    position: relative;
    color: ${({ theme }) => theme.themeColor.primaryColor};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-decoration: none;

    &::after {
      content: '';
      display: none;
      position: absolute;
      bottom: -34px;
      height: 3px;
      left: 0;
      right: 0;
      background-color: ${({ theme }) => theme.color.black};
    }

    &.active {
      color: ${({ theme }) => theme.color.black};

      &::after {
        display: block;
      }
    }
  }
`;

export const VersionSwitch = styled.a`
  text-decoration: none;
`;
