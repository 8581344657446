import * as React from 'react';

import {
  ContentGoalSelect,
  DatePicker,
  Input,
  Select,
  Textarea,
  Title,
} from 'common/components';
import { DATE_FORMATS, STATUSES_OPTIONS } from 'common/consts';
import { CoreProps, PK } from 'common/types';
import { formatDate } from 'common/utils';
import {
  CoreFormLayout,
  FieldsGrid,
  GridItem,
  Section,
  SectionsWrapper,
} from 'templates/core';
import { TitleInput } from 'templates/details';
import CategoriesPicker from './CategoriesPicker';
import Ingredients from './Ingredients';
import NutritionalValues from './NutritionalValues';
import PreparationSteps from './PreparationSteps';
import RecipeImageMediaPicker from './RecipesImageMediaPicker';
import RecipeVideoMediaPicker from './RecipesVideoMediaPicker';
import RecipesTagsPicker from './RecipesTagsPicker';

import { useRecipeCreate, useRecipeUpdate } from '../queries';
import { fields, RecipeFields, validationSchema } from '../consts';
import AuthorSelect from './AuthorSelect';
import { useToast } from 'modules/toast';
import { toastMessages } from 'common/toastMessages';

const useSave = (isTags: boolean, pk?: PK) => {
  const [created, setCreated] = React.useState(pk);
  const { toast } = useToast();
  const create = useRecipeCreate();
  const update = useRecipeUpdate();
  const isSaving = create.isLoading || update.isLoading;
  const error = create.error || update.error;

  const save = (values: RecipeFields) => {
    if (values?.status[0].value === 'ACTIVE' && !isTags) {
      toast(toastMessages.ERROR.CHANGING_STATUS_WITHOUT_TAGS, 'error');
      return;
    }
    const payload = {
      title: values.title,
      why_its_healthy: values.description,
      why_its_healthy_author_pk: values.description_author?.[0]
        ? values.description_author[0].value
        : null,
      author_pk: values.author[0].value,
      status: values.status[0].value,
      portions: values.portions,
      calories: values.calories,
      preparation_time: values.preparation_time,
      oven_time: values.oven_time,
      waiting_time: values.waiting_time,
      date_active_start: values.date_active_start
        ? formatDate(values.date_active_start, DATE_FORMATS.DATABASE)
        : null,
      date_active_end: values.date_active_end
        ? formatDate(values.date_active_end, DATE_FORMATS.DATABASE)
        : null,
      content_goal_pks: values.content_goals.map((option) => option.value),
    };
    if (created) {
      update.mutate({ ...payload, pk: created });
    } else {
      create.mutate(
        { ...payload },
        {
          onSuccess: ({ pk }) => {
            setCreated(pk);
          },
        },
      );
    }
  };

  return { save, created, isSaving, error };
};

const RecipesCore = ({
  pk,
  defaultValues,
  layout = 'overview',
}: CoreProps<RecipeFields>) => {
  const [isTags, setIsTags] = React.useState(false);
  const { save, isSaving, error, created } = useSave(isTags, pk);

  return (
    <>
      <CoreFormLayout
        onSubmit={save}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isSubmitting={isSaving}
        error={error}
      >
        {layout === 'overview' && (
          <Section>
            <TitleInput name={fields.title.name} />
          </Section>
        )}
        <Section>
          <FieldsGrid>
            <GridItem compact>
              <Select
                name={fields.status.name}
                options={STATUSES_OPTIONS}
                readOnly={!created}
              />
            </GridItem>
            <GridItem compact>
              <ContentGoalSelect
                isMulti
                name={fields.content_goals.name}
                label={fields.content_goals.label}
              />
            </GridItem>
            {layout === 'creation' && (
              <GridItem>
                <Input name={fields.title.name} label={fields.title.label} />
              </GridItem>
            )}
          </FieldsGrid>
        </Section>
        <Section>
          <Title marginBottom={2.5}>General</Title>
          <FieldsGrid>
            <GridItem compact>
              <AuthorSelect name={fields.author.name} label={fields.author.label} />
            </GridItem>
            <GridItem compact>
              <AuthorSelect
                name={fields.description_author.name}
                label={fields.description_author.label}
              />
            </GridItem>
            <GridItem>
              <Textarea name={fields.description.name} label={fields.description.label} />
            </GridItem>
            <GridItem compact>
              <Input
                name={fields.portions.name}
                label={fields.portions.label}
                type="number"
              />
            </GridItem>
            <GridItem compact>
              <Input
                name={fields.calories.name}
                label={fields.calories.label}
                type="number"
              />
            </GridItem>
          </FieldsGrid>
        </Section>
        <Section>
          <Title marginBottom={2.5}>Time</Title>
          <p>Given in minutes</p>
          <FieldsGrid>
            <GridItem>
              <Input
                name={fields.preparation_time.name}
                label={fields.preparation_time.label}
                type="number"
              />
            </GridItem>
            <GridItem>
              <Input
                name={fields.oven_time.name}
                label={fields.oven_time.label}
                type="number"
              />
            </GridItem>
            <GridItem>
              <Input
                name={fields.waiting_time.name}
                label={fields.waiting_time.label}
                type="number"
              />
            </GridItem>
          </FieldsGrid>
        </Section>
        <Section>
          <Title marginBottom={2.5}>Dates</Title>
          <FieldsGrid>
            <GridItem>
              <DatePicker
                name={fields.date_active_start.name}
                label={fields.date_active_start.label}
              />
            </GridItem>
            <GridItem>
              <DatePicker
                name={fields.date_active_end.name}
                label={fields.date_active_end.label}
              />
            </GridItem>
          </FieldsGrid>
        </Section>
      </CoreFormLayout>
      <SectionsWrapper pk={created}>
        <RecipesTagsPicker setIsTags={setIsTags} />
        <RecipeImageMediaPicker />
        <RecipeVideoMediaPicker />
        <Ingredients />
        <PreparationSteps />
        <NutritionalValues />
        <CategoriesPicker />
      </SectionsWrapper>
    </>
  );
};

export default RecipesCore;
