import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ScenarioWeek } from 'base/api/types';
import { Container, Row, Title, Wrapper } from 'common/components';
import { WEEK_DAYS_OPTIONS } from 'common/consts';
import { resolveSpacersArray } from 'common/styles/utils';
import { PK } from 'common/types';

import MiniIndicator from '../components/MiniIndicator';
import ScenarioDay from '../components/ScenarioDay';
import ScenarioTopBar, { WeekSelector } from '../components/ScenarioTopBar';
import { useScenarioDetails, useScenarioWeeksList } from '../queries';

const ScenarioWeekList = () => {
  const { pk: scenarioPk } = useParams<{ pk?: PK }>();
  const [currentWeekPk, setCurrentWeekPk] = React.useState<PK>();
  const { data: scenarioData } = useScenarioDetails(scenarioPk);
  const { data: weekDataList } = useScenarioWeeksList(scenarioPk);

  const weekDataMap = React.useMemo(
    () =>
      weekDataList?.weeks.reduce(
        (map, week) => map.set(week.pk, week),
        new Map<PK, ScenarioWeek>(),
      ),
    [weekDataList?.weeks],
  );
  const weekOrderMap = React.useMemo(
    () =>
      scenarioData?.weeks.reduce(
        (map, week, index) => map.set(week, index + 1),
        new Map<PK, number>(),
      ),
    [scenarioData?.weeks],
  );

  React.useEffect(() => {
    const firstWeekPk = scenarioData?.weeks[0];

    if (firstWeekPk) {
      setCurrentWeekPk(firstWeekPk);
    }
  }, [scenarioData?.weeks]);

  const currentWeekNumber = currentWeekPk && weekOrderMap?.get(currentWeekPk);
  const currentWeek = currentWeekPk && weekDataMap?.get(currentWeekPk);
  const miniInWeek =
    currentWeek?.days.reduce((dayTotal, day) => dayTotal + day.minis.length, 0) || 0;

  return (
    <Container>
      <ScenarioTopBar pk={scenarioPk} title={scenarioData?.title || 'Scenario'}>
        <WeekSelector
          weeks={scenarioData?.weeks}
          selected={currentWeekPk}
          onSelect={(weekPk) => setCurrentWeekPk(weekPk)}
        />
      </ScenarioTopBar>
      <LayoutContainer>
        {currentWeekPk && scenarioPk && (
          <Panel>
            <Row>
              <Title>Week {currentWeekNumber || ''}</Title>
              <Wrapper margin={[0, 2]}>
                <MiniIndicator count={miniInWeek} style="grey" />
              </Wrapper>
            </Row>
            <Wrapper margin={[2, 0]}>
              {WEEK_DAYS_OPTIONS.map((day) => (
                <ScenarioDay
                  key={dayKey(currentWeekPk, day.value)}
                  weekPk={currentWeekPk}
                  scenarioPk={scenarioPk}
                  label={day.label}
                  weekDay={day.value}
                  scenarioDay={currentWeek?.days[day.value]}
                />
              ))}
            </Wrapper>
          </Panel>
        )}
      </LayoutContainer>
    </Container>
  );
};

const dayKey = (weekPk: PK, dayIndex: number) => `${weekPk}-${dayIndex}`;

const Panel = styled.section`
  width: 750px;
  padding: ${({ theme }) => resolveSpacersArray(theme, [3, 4, 3])};
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.border.radius.standard};
  box-shadow: 0 1px 1px 1px ${({ theme }) => theme.color.blueSuperLight};
`;

const LayoutContainer = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacer.times(3)};
`;

export default ScenarioWeekList;
