import * as React from 'react';

import { ThemeColor } from 'shared/theme';

import Highlight from './Highlight';

interface MiniIndicatorProps {
  count: number;
  style: 'green' | 'grey';
}

const MiniIndicator = ({ count, style }: MiniIndicatorProps) => {
  const color: ThemeColor = style === 'green' ? 'greenDark' : 'black';
  const highlightColor: ThemeColor =
    style === 'green' ? 'greenSuperLight' : 'greySuperLight';

  return (
    <Highlight $color={color} $highlightColor={highlightColor}>
      {count} {count !== 1 ? 'minis' : 'mini'}
    </Highlight>
  );
};

export default MiniIndicator;
