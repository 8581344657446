import * as React from 'react';
import styled from 'styled-components';

import { useDnDList } from 'common/hooks';
import { STATUSES } from 'common/consts';
import { resolveSpacersArray } from 'common/styles/utils';
import { Wrapper, SelectedItem, DropdownPicker } from 'common/components';
import { PlainCheckbox } from 'common/components/Checkbox';

import MiniSelectOption from './MiniSelectOption';
import { MiniOption, MINIS_PICKER_ERROR } from './consts';
import SelectionContent from './SelectionContent';

type MinisPickerProps = {
  options: MiniOption[];
  defaultValues: MiniOption[];
  draggable?: boolean;
  frozen?: boolean;
  onUpdate?: (minis: MiniOption[]) => void;
  onError?: (error: MINIS_PICKER_ERROR, minis: MiniOption[]) => void;
  onPick?: (values: MiniOption[], reinit: (minis: MiniOption[]) => void) => void;
};

const MinisPicker = ({
  options,
  defaultValues,
  draggable,

  /**
   * (Optional) If true:
   *  - New minis can be added to the list
   *  - Only new minis can be removed from the list
   *  - Any previously saved minis cannot be removed from the list
   *
   * Allows for the list to be expanded, but never reduced.
   */
  frozen,

  /**
   * NOTE (Optional) Callback called when minis value change.
   */
  onUpdate = () => {},

  /**
   * NOTE (Optional) Callback called when eiher (1) an inactive mini is picked
   *      or (2) use is trying to select less than one mini.
   *      Keep in mind this callback prevents unselecting all minis,
   *      requiring at least one mini to be selected AFTER at least
   *      one mini was selected already.
   */
  onError = () => {},

  /**
   * NOTE (Optional) Callback called when mini is selected.
   *      Passing this callback DISABLES default validation, be sure to validate
   *      minis in the callback.
   */
  onPick,
}: MinisPickerProps) => {
  const [activeOnly, setActiveOnly] = React.useState(false);
  const [frozenList, setFrozenList] = React.useState<MiniOption[]>([]);

  const isMiniFrozen = (pk: string) => {
    return frozenList.some(({ value }) => value === pk);
  };

  const filteredOptions = React.useMemo(
    () =>
      (activeOnly
        ? options.filter(({ status }) => status === STATUSES.ACTIVE)
        : options
      ).filter(({ value }) => !isMiniFrozen(value)),
    [activeOnly, options, frozenList],
  );

  const handleCheck = () => {
    setActiveOnly((activeOnly) => !activeOnly);
  };

  const handleUpdate = (minis: MiniOption[]) => {
    onUpdate(minis);
  };

  const { list: minis, getDraggableProps, reinit, removeItem } = useDnDList({
    initList: defaultValues,
    onReorder: handleUpdate,
  });

  const handlePick = (values: MiniOption[]) => {
    if (onPick) {
      onPick(values, reinit);

      return;
    }

    const isInactive = values.some(
      ({ status }) => typeof status !== 'undefined' && status !== STATUSES.ACTIVE,
    );

    if (isInactive) {
      onError(MINIS_PICKER_ERROR.VALUE_STATUS_INACTIVE, values);
    } else {
      if (values.length >= 1) {
        reinit(values);
        handleUpdate(values);
      } else {
        onError(MINIS_PICKER_ERROR.VALUE_EMPTY, values);
      }
    }
  };

  const handleDelete = (position: number) => {
    if (isMiniFrozen(minis[position].value)) {
      return;
    }

    if (minis.length > 1) {
      removeItem(position);
      handleUpdate(minis.filter((_, index) => index !== position));
    } else {
      onError(MINIS_PICKER_ERROR.VALUE_EMPTY, minis);
    }
  };

  React.useEffect(() => {
    if (onPick) {
      onPick(minis, reinit);
    }
  }, []);

  React.useEffect(() => {
    if (frozen) {
      setFrozenList(defaultValues);
    }

    return () => {
      setFrozenList([]);
    };
  }, [frozen, defaultValues]);

  return (
    <>
      {minis.map(({ label, status, value, created, lastUpdate, isB2B }, position) => (
        <SelectedItem
          hideBinIcon={minis.length <= 1 || isMiniFrozen(value)}
          key={value}
          {...getDraggableProps({
            position,
            value,
            onDelete: handleDelete,
          })}
          draggable={draggable}
        >
          <SelectionContent
            label={label}
            status={status}
            created={created}
            lastUpdate={lastUpdate}
            isB2B={isB2B}
            isDivider={position !== 0}
          />
        </SelectedItem>
      ))}
      <DayFooter>
        <PlainCheckbox
          name="active_only"
          label="Filter only published items"
          checked={activeOnly}
          onChange={handleCheck}
        />
        <Wrapper margin={[1, 0, 2]}>
          <DropdownPicker
            label="Search..."
            options={filteredOptions}
            selected={minis}
            onPick={handlePick}
            isMulti
          >
            <MiniSelectOption />
          </DropdownPicker>
        </Wrapper>
      </DayFooter>
    </>
  );
};

const DayFooter = styled.div`
  padding: ${({ theme }) => resolveSpacersArray(theme, [3, 3, 0])};
  border-top: 1px solid ${({ theme }) => theme.color.blueGrey};
`;

export default MinisPicker;
