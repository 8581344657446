import * as React from 'react';

import { CollapsibleSection, RelationSelect } from 'common/components';
import { RelationOption } from 'common/components/RelationSelect';
import { useCategoriesList } from 'modules/categories/queries';
import { PK } from 'common/types';

import { useRecipeCategories, useRecipeCategoriesUpdate } from '../queries';

type Props = {
  pk?: PK;
};

const CategoriesPicker = ({ pk }: Props) => {
  const categoriesList = useCategoriesList();
  const { data } = useRecipeCategories(pk);
  const { mutate, isSuccess, isLoading, isError } = useRecipeCategoriesUpdate();
  const saveStatus = { isSuccess, isLoading, isError };

  const values = categoriesList.data?.values;
  const options = React.useMemo(() => {
    return (values ?? [])
      .filter(({ category_type }) => category_type === 'RECIPE')
      .map(({ pk, name }): RelationOption => ({ label: name, value: pk }));
  }, [values]);

  const defaults = React.useMemo(() => {
    const categoryList = data?.categories ?? [];

    return categoryList
      .map((categoryPk) => options.find((option) => option.value === categoryPk))
      .filter((option: RelationOption | undefined): option is RelationOption => !!option);
  }, [data?.categories, options]);

  const handleSave = (values: RelationOption[]) => {
    if (pk) {
      const payload = {
        pk,
        categories: values.map(({ value }) => value),
      };

      mutate(payload);
    }
  };

  return (
    <>
      <CollapsibleSection title="Add categories" saveStatus={saveStatus} disabled={!pk}>
        <RelationSelect
          onSave={handleSave}
          options={options}
          defaultValues={defaults}
          disableReorder={true}
          isLoading={categoriesList.isLoading}
        />
      </CollapsibleSection>
    </>
  );
};

export default CategoriesPicker;
