import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';
import { ThemeColor } from 'shared/theme';
import { useTheme } from 'styled-components';

interface PlayProps extends IconProps {
  fill?: ThemeColor;
}

const Play = ({ fill = 'none', ...rest }: PlayProps) => {
  const theme = useTheme();
  const fillColor = theme.color[fill];

  return (
    <Svg viewBox="0 0 14 17" fill={fill} {...rest}>
      <path d="m1 1 12 7.5L1 16z" stroke="currentColor" fill={fillColor} />
    </Svg>
  );
};

export default Play;
