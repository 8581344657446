import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const CircleChevron = ({ ...props }: IconProps) => {
  return (
    <Svg viewBox="0 0 32 32" {...props}>
      <rect width="32" height="32" rx="16" fill="currentColor" />
      <path
        d="M12.5 22.5a1 1 0 0 1 0-1.414l5.293-5.293L12.5 10.5a1 1 0 1 1 1.414-1.414l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0Z"
        fill="#fff"
      />
    </Svg>
  );
};

export default CircleChevron;
