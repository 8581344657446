import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Ingredients = ({ size = '16px', color = 'none', ...rest }: IconProps) => {
  return (
    <Svg viewBox="0 0 16 16" size={size} color={color} {...rest}>
      <path
        d="M9.5 15.5h-8V.5h13v10l-5 5z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 15.5v-5h5M7.5 4.5h4M7.5 7.5h4M4.5 10.5h2M4.5 4.5h1M4.5 7.5h1"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Ingredients;
