import * as React from 'react';

import appUrls from 'base/app/urls';
import { Link, Button, Logo } from 'common/components';
import { LOGO_VARIANTS, BUTTON_VARIANTS } from 'common/consts';
import { useLogout } from 'modules/auth/queries';
import useCreateContext from 'modules/createdialog/useCreateContext';
import {
  HeaderContainer,
  HeaderContent,
  HeaderNav,
  NavItem,
  LogoContainer,
  VersionSwitch,
} from './Header.styled';
import { nav } from './consts';
import BusinessToggle from './BusinessToggle';
import { FeatureFlags } from 'common/utils/featureFlags';

const { oldVersionButton } = FeatureFlags;

export interface HeaderProps {
  logoOnly?: boolean;
}

const Header = ({ logoOnly }: HeaderProps) => {
  const { mutate: logout } = useLogout();
  const { open } = useCreateContext();

  const handleLogout = () => {
    logout();
  };

  return (
    <HeaderContainer>
      <HeaderContent>
        <LogoContainer>
          <Link to={appUrls.DASHBOARD}>
            <Logo variant={LOGO_VARIANTS.TEXT} />
          </Link>
        </LogoContainer>
        {!logoOnly && (
          <>
            <HeaderNav>
              {nav.map(({ label, url }) => (
                <NavItem key={label}>
                  <Link exact to={url}>
                    {label}
                  </Link>
                </NavItem>
              ))}
              <NavItem>
                <Button
                  onClick={open}
                  noMinWidth
                  width={16}
                  variant={BUTTON_VARIANTS.PRIMARY}
                >
                  Create
                </Button>
              </NavItem>
            </HeaderNav>
            <HeaderNav as="div">
              {oldVersionButton() && (
                <NavItem>
                  <Button
                    as={VersionSwitch}
                    href="/_old/"
                    variant={BUTTON_VARIANTS.SECONDARY}
                    noMinWidth
                  >
                    Old version
                  </Button>
                </NavItem>
              )}
              <NavItem>
                <BusinessToggle />
              </NavItem>
              <NavItem>
                <Button variant={BUTTON_VARIANTS.TEXT} onClick={handleLogout}>
                  Log out
                </Button>
              </NavItem>
            </HeaderNav>
          </>
        )}
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
