import * as React from 'react';
import { useTheme } from 'styled-components';

import { Button } from 'common/components';
import { BUTTON_VARIANTS } from 'common/consts';
import { Bin } from 'common/icons';

interface RemoveButtonProps {
  isLoading?: boolean;
  noNegativeMargin?: boolean;
  onRemove: () => void;
}

const RemoveButton = ({
  onRemove,
  isLoading,
  noNegativeMargin = true,
}: RemoveButtonProps) => {
  const theme = useTheme();
  return (
    <Button
      variant={BUTTON_VARIANTS.ICON}
      noNegativeMargin={noNegativeMargin}
      onClick={onRemove}
      isLoading={isLoading}
    >
      <Bin
        title="remove"
        color={theme.keys.secondaryColor}
        hoverColor={theme.keys.secondaryColor}
      />
    </Button>
  );
};

export default RemoveButton;
