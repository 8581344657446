import { PROJECT_NAME } from 'common/consts';

export const getConfig = () => {
  return (window as any)._env_ as {
    API_URL: string;
    AZURE_STORAGE_PARAM: string;
    REACT_APP_PROJECT_NAME: PROJECT_NAME;
    FRIESLAND_SSO_LOGIN: string;
    REACT_APP_ENV: 'dev' | 'prod' | 'test';
  };
};
