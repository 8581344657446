import * as React from 'react';
import styled from 'styled-components';

import { RemoveButton, Wrapper } from 'common/components';

interface Props {
  currentPosition: number;
  initialPosition?: number;
  onRemove?: () => void;
}

const ScenarioContentWeek = ({ currentPosition, initialPosition, onRemove }: Props) => {
  return (
    <Row>
      <Wrapper>
        <Label>{createLabel(currentPosition)}</Label>
        {initialPosition !== undefined && currentPosition !== initialPosition && (
          <PreviousLabel>(previously {createLabel(initialPosition)})</PreviousLabel>
        )}
      </Wrapper>
      {onRemove && <RemoveButton noNegativeMargin={false} onRemove={onRemove} />}
    </Row>
  );
};

const createLabel = (position: number) => 'Week ' + (position + 1);

const Label = styled.label`
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PreviousLabel = styled.i`
  font-style: italic;
  margin-left: ${({ theme }) => theme.spacer.big};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.normal};
`;

export default ScenarioContentWeek;
