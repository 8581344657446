import * as React from 'react';
import styled from 'styled-components';

import { DotsLoader, Link, Logo, Title } from 'common/components';
import {
  IconContainer,
  LoginCard,
  LoginCardContainer,
  LoginContainer,
} from './LoginEmail';
import LoginBackground from '../components/LoginBackground';
import { useSearchParams } from 'common/hooks';
import Cookies from 'js-cookie';
import { EMAIL_LOGIN } from '../consts';
import { LOGO_VARIANTS } from 'common/consts';
import { Arrow, Padlock } from 'common/icons';
import appUrls from 'base/app/urls';
import { resolveSpacersArray } from 'common/styles/utils';
import { useLogin } from '../queries';

const LoginConfirmation = () => {
  const { mutate, isError, error: loginError } = useLogin();
  const { token } = useSearchParams();
  const email = Cookies.get(EMAIL_LOGIN);

  React.useEffect(() => {
    if (token && email) {
      mutate({ token, email });
    }
  }, [email, mutate, token]);

  return (
    <LoginContainer>
      <LoginBackground />
      <LoginCardContainer>
        <Logo variant={LOGO_VARIANTS.TEXT} />
        <LoginCard>
          {isError || !token || !email ? (
            <>
              <Title size="big" marginBottom="big">
                Something went wrong!
              </Title>
              <IconContainer>
                <Padlock size="100px" padlockColor="white" color="pink" />
              </IconContainer>
              <Title size="small" marginBottom="big" color="greyMediumDark" center>
                {!email
                  ? 'Cannot read email address from cookies'
                  : loginError?.message || 'Please try again.'}
              </Title>

              <LinkWrapper to={appUrls.AUTH.LOGIN}>
                <Arrow $width="2px" $size="10px" />
                <StyledTitle size="tiny" center color="greyMediumDark">
                  Go back to login page
                </StyledTitle>
              </LinkWrapper>
            </>
          ) : (
            <>
              <Title color="greyMediumDark" center marginBottom="big">
                Loading...
              </Title>
              <DotsLoader />
            </>
          )}
        </LoginCard>
      </LoginCardContainer>
    </LoginContainer>
  );
};

const StyledTitle = styled(Title)`
  margin: ${({ theme }) => resolveSpacersArray(theme, [0, 0, 0, 1])};
`;

const LinkWrapper = styled(Link)`
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default LoginConfirmation;
