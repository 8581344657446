import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { useLockBodyScroll } from 'react-use';

import { useBusinessStore } from 'common/hooks';
import { resolveSpacersArray } from 'common/styles/utils';
import { BUTTON_VARIANTS, CONTENT_TYPES, TITLE_VARIANTS } from 'common/consts';
import { Button, Modal, Row, Title, Wrapper } from 'common/components';
import { Cross } from 'common/icons';
import { Section } from 'templates/core';
import TypeRadio from './components/TypeRadio';
import useCreateContext from './useCreateContext';

import MinisCreate from 'modules/minis/views/MinisCreate';
import WorkoutCreate from 'modules/workouts/views/WorkoutCreate';
import SeriesCreate from 'modules/series/views/SeriesCreate';
import CompanyCreate from 'modules/companies/views/CompanyCreate';
import MeditationsCreate from 'modules/meditations/views/MeditationsCreate';
import PodcastsCreate from 'modules/podcasts/views/PodcastsCreate';
import FactsCreate from 'modules/facts/views/FactsCreate';
import FunfactsCreate from 'modules/funfacts/views/FunfactsCreate';
import RecipeCreate from 'modules/recipes/views/RecipeCreate';
import CollectionCreate from 'modules/collections/views/CollectionCreate';
import ScenarioCreate from 'modules/scenarios/views/ScenarioCreate';
import HabitsCreate from 'modules/habits/views/HabitsCreate';
import BadgesCreate from 'modules/badges/views/BadgesCreate';

const registry: Record<CONTENT_TYPES, React.ReactNode> = {
  [CONTENT_TYPES.MINIS]: <MinisCreate />,
  [CONTENT_TYPES.SERIES]: <SeriesCreate />,
  [CONTENT_TYPES.COMPANIES]: <CompanyCreate />,
  [CONTENT_TYPES.SCENARIOS]: <ScenarioCreate />,
  [CONTENT_TYPES.WORKOUTS]: <WorkoutCreate />,
  [CONTENT_TYPES.MEDITATIONS]: <MeditationsCreate />,
  [CONTENT_TYPES.PODCASTS]: <PodcastsCreate />,
  [CONTENT_TYPES.COLLECTIONS]: <CollectionCreate />,
  [CONTENT_TYPES.RECIPES]: <RecipeCreate />,
  [CONTENT_TYPES.FUN_FACTS]: <FunfactsCreate />,
  [CONTENT_TYPES.FACTS]: <FactsCreate />,
  [CONTENT_TYPES.HABITS]: <HabitsCreate />,
  [CONTENT_TYPES.BADGES]: <BadgesCreate />,
};

const CreateDialog = () => {
  const theme = useTheme();
  const { isBusiness } = useBusinessStore();
  const { active, isOpen, close, setActive } = useCreateContext();
  useLockBodyScroll(isOpen);

  React.useEffect(() => {
    setActive(CONTENT_TYPES.MINIS);
  }, [isBusiness, setActive]);

  return (
    <Modal isOpen={isOpen} width={880}>
      <Header>
        <Row>
          <Title variant={TITLE_VARIANTS.HEADER}>Add content</Title>
          {isBusiness ? (
            <Wrapper margin={[0, 0, 0, 2]}>
              <BusinessLabel>B2B</BusinessLabel>
            </Wrapper>
          ) : null}
        </Row>
        <Button variant={BUTTON_VARIANTS.ICON} onClick={close}>
          <Cross
            size="24px"
            color={theme.keys.secondaryColor}
            hoverColor={theme.keys.secondaryColor}
          />
        </Button>
      </Header>
      <Section>
        <TypeRadio />
      </Section>
      {registry[active]}
    </Modal>
  );
};

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${({ theme }) => resolveSpacersArray(theme, [4, 5, 'standard'])};
  border-bottom: 1px solid ${({ theme }) => theme.color.blueLight};
`;

export const BusinessLabel = styled.div`
  color: ${({ theme }) => theme.themeColor.tertiarySuperDarkColor};
  background-color: ${({ theme }) => theme.components.createDialog.primaryBorderColor};
  border-radius: ${({ theme }) => theme.spacer.times(2.5)};
  line-height: ${({ theme }) => theme.font.size.medium};
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  padding: ${({ theme }) => resolveSpacersArray(theme, [1, 2])};
`;

export default CreateDialog;
