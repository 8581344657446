import * as React from 'react';

import { PrimaryKey } from 'common/components';
import { TITLE_VARIANTS } from 'common/consts';
import { ContentRow } from 'templates/content';
import { PK } from 'common/types';
import { ContentRowProps } from 'templates/content/components/ContentRow';
import { Text, TextCell, SectionRow, SectionColumn } from 'modules/recipe-subsections';

import IngredientsMenu from './IngredientsMenu';
import { RecipesCategory, RecipesUnit } from 'base/api/types';

export interface RecipesIngredientRow {
  pk: PK;
  title: string;
  name_plural: string;
  unit: RecipesUnit | null;
  category: RecipesCategory;
  isDetailsView?: boolean;
}

const IngredientsContentRow: React.FC<ContentRowProps<RecipesIngredientRow>> = (
  props,
) => {
  const { index } = props;
  const { pk, title, name_plural, unit, category } = props.data[index];
  const ingredientData = props.data;

  return (
    <ContentRow {...props} data={ingredientData} menuComponent={IngredientsMenu}>
      <SectionColumn>
        <SectionRow>
          <TextCell>
            <Text size="standard">{title}</Text>
          </TextCell>
          <TextCell>
            <Text variant={TITLE_VARIANTS.SUBTITLE}>{name_plural}</Text>
          </TextCell>
          <TextCell>
            <Text variant={TITLE_VARIANTS.SUBTITLE}>{unit?.name_singular}</Text>
          </TextCell>
          <TextCell>
            <Text variant={TITLE_VARIANTS.SUBTITLE}>{category.name}</Text>
          </TextCell>
        </SectionRow>
        <PrimaryKey pk={pk} />
      </SectionColumn>
    </ContentRow>
  );
};

export default IngredientsContentRow;
