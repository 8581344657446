import * as React from 'react';
import { useTheme } from 'styled-components';

import appUrls from 'base/app/urls';
import { BUTTON_VARIANTS, TITLE_VARIANTS } from 'common/consts';
import { Arrow } from 'common/icons';
import { Button, Link, Title } from 'common/components';
import TopBarContainer from './TopBarContainer';
import TopBarContent from './TopBarContent';
import ItemsContainer from './ItemsContainer';
import TitleContainer from './TitleContainer';

export interface TopBarProps {
  title: string;
  disableNav?: boolean;
  children?: React.ReactNode;
}

const TopBar = ({ title, disableNav, children }: TopBarProps) => {
  const theme = useTheme();
  return (
    <TopBarContainer>
      <TopBarContent>
        <ItemsContainer>
          {!disableNav && (
            <Button variant={BUTTON_VARIANTS.ICON} as={Link} to={appUrls.DASHBOARD}>
              <Arrow
                $color={theme.keys.secondaryColor}
                $width="3px"
                $size="12px"
                $margin={[0.5]}
              />
            </Button>
          )}
          <TitleContainer>
            <Title variant={TITLE_VARIANTS.HEADER}>{title}</Title>
          </TitleContainer>
        </ItemsContainer>
        <ItemsContainer>{children}</ItemsContainer>
      </TopBarContent>
    </TopBarContainer>
  );
};

export default TopBar;
