import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';

import 'base/api/axios.config';

import queryClient from 'base/api/queryClient.config';
import { useBusinessInit, useChangeHeadProperties } from 'common/hooks';
import { useCookieStoreInit } from 'common/hooks/useCookieStore';
import GlobalStyles from 'common/styles/GlobalStyles';
import { getProjectSettings } from 'common/utils';
import CreateDialog from 'modules/createdialog/CreateDialog';
import { ToastProvider, ToastContainer } from 'modules/toast';

import Router from './Router';

const Root = () => {
  const { title, theme, favicon } = getProjectSettings();
  useChangeHeadProperties(title, favicon);
  useBusinessInit();
  useCookieStoreInit();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <ToastProvider>
            <ToastContainer />
            <BrowserRouter>
              <Router />
              <CreateDialog />
            </BrowserRouter>
          </ToastProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
};

export default Root;
