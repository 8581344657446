import * as React from 'react';
import styled from 'styled-components';

import { Button, Row } from 'common/components';
import { BUTTON_VARIANTS } from 'common/consts';
import { useOutsideClick } from 'common/hooks';
import { Arrow } from 'common/icons';
import { resolveSpacersArray } from 'common/styles/utils';

import {
  RecipeSubsectionOption,
  RECIPE_DROPDOWN_OPTIONS,
  RECIPE_SUBSECTIONS,
} from '../../consts';
import StyledLink from './StyledLink';

const openRotation = -45;
const closeRotation = 135;

interface SubsectionProps {
  selected?: RECIPE_SUBSECTIONS;
}

const RecipeSubsection = ({ selected }: SubsectionProps) => {
  const [isOpen, setOpen] = React.useState(false);
  const { containerRef } = useOutsideClick<HTMLDivElement>(() => setOpen(false));
  const [selectedItem, setSelectedItem] = React.useState(
    selected ?? RECIPE_SUBSECTIONS.MAIN,
  );
  const selectedItemLabel = RECIPE_DROPDOWN_OPTIONS.find(
    (option) => option.value === selectedItem,
  )?.label;

  const handleItemClick = (item: RecipeSubsectionOption) => {
    setSelectedItem(item.value);
    setOpen(false);
  };

  return (
    <Row ref={containerRef}>
      <Separator>/</Separator>
      <DropdownWrapper>
        <TitleButton
          variant={BUTTON_VARIANTS.TEXT}
          onClick={() => setOpen((isOpen) => !isOpen)}
        >
          <SelectedItem>{selectedItemLabel}</SelectedItem>
          <Arrow
            $size="10px"
            $width="2px"
            $margin={[0.5, 0, 0, 0]}
            $rotate={isOpen ? openRotation : closeRotation}
          />
        </TitleButton>
        {isOpen && (
          <Container>
            <DropdownList>
              {RECIPE_DROPDOWN_OPTIONS.map((item) => {
                const isActiveItem = item.value === selectedItem;
                const isMainSection = item.value === RECIPE_SUBSECTIONS.MAIN;

                return (
                  !isMainSection && (
                    <ListItem key={item.value} onClick={() => handleItemClick(item)}>
                      <StyledLink $isActiveItem={isActiveItem} to={item.link}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemLabel>{item.label}</ListItemLabel>
                      </StyledLink>
                    </ListItem>
                  )
                );
              })}
            </DropdownList>
          </Container>
        )}
      </DropdownWrapper>
    </Row>
  );
};

const TitleButton = styled(Button)`
  margin: 0;
  padding: ${({ theme }) => theme.spacer.standard};
  margin-left: ${({ theme }) => resolveSpacersArray(theme, [-1])};
  font-weight: ${({ theme }) => theme.font.weight.normal};

  &:focus-visible {
    outline: auto;
  }
`;

const Container = styled.div`
  position: relative;
`;

const SelectedItem = styled.label`
  font-size: ${({ theme }) => theme.font.size.medium};
  color: ${({ theme }) => theme.color.black};
  margin-right: ${({ theme }) => theme.spacer.standard};
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropdownList = styled.ul`
  position: absolute;
  display: flex;
  flex-direction: column;
  margin: 0;
  left: 0;
  padding: ${({ theme }) => resolveSpacersArray(theme, [1, 0])};
  top: ${({ theme }) => theme.spacer.standard};
  border: 1px solid ${({ theme }) => theme.color.blueLight};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  background-color: ${({ theme }) => theme.color.white};
`;

const ListItem = styled.li`
  list-style-type: none;
`;

const listItemHeight = '16px';

const ListItemLabel = styled.span`
  margin-left: ${({ theme }) => theme.spacer.standard};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  height: ${listItemHeight};
`;

const ListItemIcon = styled.span`
  height: ${listItemHeight};
`;

const Separator = styled.div`
  font-size: ${({ theme }) => theme.font.size.big};
  margin: ${({ theme }) => resolveSpacersArray(theme, [0, 1])};
`;

export default RecipeSubsection;
