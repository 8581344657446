import * as React from 'react';

import { CollapsibleSection } from 'common/components';
import { PK } from 'common/types';
import { TAGGING_CONTENT_TYPES } from 'modules/tagging/consts';
import { TagsForm } from 'modules/tagging/components';
import { useRecipeTags, useRecipesTagsUpdate } from '../queries';
import { recipesTagsValidationSchema } from '../consts';

type Props = {
  setIsTags: (isTags: boolean) => void;
  pk?: PK;
};

const RecipesTagsPicker = ({ pk, setIsTags }: Props) => {
  const { data: recipesTagData } = useRecipeTags(pk);
  const { mutate: updateRecipe, isSuccess, isLoading, isError } = useRecipesTagsUpdate();

  const isTags =
    recipesTagData?.category &&
    Array.isArray(recipesTagData?.category) &&
    recipesTagData?.category.length > 0;

  React.useEffect(() => {
    setIsTags(!!isTags);
  }, [isTags, setIsTags]);

  const saveTags = (values: any) => {
    const payload = {
      ...(Array.isArray(values.category) &&
        values.category.length && {
          category: values.category.map(({ value }: any) => value),
        }),
      ...(values.subcategory.length && {
        subcategory: values.subcategory.map(({ value }: any) => value),
      }),
      ...(Array.isArray(values.habit) &&
        values.habit.length && { habit: values.habit.map(({ value }: any) => value) }),
      ...(values.frequency && { frequency: Number(values.frequency) }),
      ...(values.amount && { amount: Number(values.amount) }),
      ...(values.amount_unit && { amount_unit: values.amount_unit[0].value }),
      ...(Array.isArray(values.term) &&
        values.term.length && {
          term: values.term[0].value,
        }),
      ...(Array.isArray(values.cousine) &&
        values.cousine.length && {
          cousine: values.cousine[0].value,
        }),
      ...(values.meal_type && { meal_type: values.meal_type[0].value }),
      ...(values.diet && { diet: values.diet[0].value }),
      ...(Array.isArray(values.recipe_category) &&
        values.recipe_category.length && {
          recipe_category: values.recipe_category.map(({ value }: any) => value),
        }),

      pk,
    };
    updateRecipe(payload as any);
  };

  return (
    <>
      <CollapsibleSection
        title="Add tags"
        saveStatus={{ isSuccess, isLoading, isError }}
        disabled={!pk}
        divider={false}
      >
        <TagsForm
          validationSchema={recipesTagsValidationSchema}
          contentType={TAGGING_CONTENT_TYPES.RECIPE}
          defaultValues={recipesTagData}
          onSubmit={saveTags}
          isSubmitting={isLoading}
        />
      </CollapsibleSection>
    </>
  );
};

export default RecipesTagsPicker;
