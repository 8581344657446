import * as React from 'react';

import { Title } from 'common/components';
import { useCreationDefaults } from 'common/hooks';
import { Section } from 'templates/core';
import PodcastCore from '../components/PodcastCore';

const PodcastsCreate = () => {
  const defaultValues = useCreationDefaults();

  return (
    <>
      <Section>
        <Title size="big">Create Podcast</Title>
      </Section>
      <PodcastCore defaultValues={defaultValues} layout="creation" />
    </>
  );
};

export default PodcastsCreate;
