import styled from 'styled-components';

import { ThemeSpacer } from 'shared/theme';
import { pulse } from 'common/styles/animations';
import { resolveSpacersArray } from 'common/styles/utils';

export interface BoneProps {
  padding?: ThemeSpacer | ThemeSpacer[];
  margin?: ThemeSpacer | ThemeSpacer[];
  float?: 'left';
  width?: ThemeSpacer;
}

const Bone = styled.div<BoneProps>`
  width: ${({ theme, width }) => resolveSpacersArray(theme, width)};
  padding: ${({ theme, padding }) => resolveSpacersArray(theme, padding || [0])};
  margin: ${({ theme, margin, float }) =>
    resolveSpacersArray(theme, float ? [0, 0, 0, 'auto'] : margin || [0])};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  background-color: ${({ theme }) => theme.color.blueGrey};
  animation: ${pulse} 1.5s linear infinite;
`;

export default Bone;
