import Axios from 'axios';
import { StandardError } from 'base/api/errors';
import apiUrls from 'base/api/urls';
import { useAuthStore } from 'common/hooks';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN } from 'modules/auth/consts';
import { useRole } from 'modules/auth/queries';
import { useMutation } from 'react-query';

type SSOLoginVars = {
  verification_key: string | string[] | null;
};

type LoginResponse = {
  [ACCESS_TOKEN]: string;
  refresh_token: string;
  token_type: 'Bearer';
  expires_in: number;
  expires_at: number;
  sub: string;
};

export const useFrieslandSSOLogin = () => {
  const { refetch: getRole } = useRole();
  const { revalidate, invalid } = useAuthStore(({ invalid, revalidate }) => ({
    invalid,
    revalidate,
  }));
  return useMutation<LoginResponse, StandardError, SSOLoginVars>(
    (data) => Axios.post(apiUrls.AUTH.SSO_LOGIN, data),
    {
      onSuccess: (data) => {
        revalidate();
        getRole();
        Cookies.set(ACCESS_TOKEN, data[ACCESS_TOKEN], {
          expires: new Date(data.expires_at),
        });
      },
      onError: () => {
        Cookies.remove(ACCESS_TOKEN);
        invalid();
      },
    },
  );
};
