import * as Yup from 'yup';

import { combineSchemas } from 'common/utils';
import { Fields } from 'common/types';

export type TagsFields = {
  name: string;
};

export const fields: Fields<TagsFields> = {
  name: {
    label: 'Name',
    name: 'name',
    schema: Yup.string().required(),
  },
};

export const validationSchema = Yup.object().shape<TagsFields>(combineSchemas(fields));
