import { DATE_FORMATS } from 'common/consts';
import { DateString } from 'common/types';
import { RelationOption } from '../RelationSelect';

export interface MiniOption extends RelationOption {
  created?: DateString<DATE_FORMATS.DATABASE>;
  lastUpdate?: DateString<DATE_FORMATS.DATABASE>;
  punctation: number;
  isB2B?: boolean;
}

export enum MINIS_PICKER_ERROR {
  VALUE_STATUS_INACTIVE = 'VALUE_STATUS_INACTIVE',
  VALUE_EMPTY = 'VALUE_EMPTY',
}
