import * as React from 'react';
import { useParams } from 'react-router';

import { PK } from 'common/types';
import { DetailsCard, DetailsSuspense } from 'templates/details';
import { useUnitsDetails } from '../queries';
import { RecipesUnit } from 'base/api/types';
import { fields, UnitsFields } from '../consts';
import UnitsCore from '../components/UnitsCore';

const UnitsDetails = () => {
  const { pk } = useParams<{ pk: PK }>();
  const { data, ...query } = useUnitsDetails(pk);
  const transformAuthors = (raw: RecipesUnit): UnitsFields => ({
    [fields.name_singular.name]: raw.name_singular,
    [fields.name_plural.name]: raw.name_plural,
  });
  const defaultValues = data && transformAuthors(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading} error={query.error}>
        <UnitsCore pk={pk} key={pk} defaultValues={defaultValues} />
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default UnitsDetails;
