import * as React from 'react';

import { PK } from 'common/types';
import { CompanyAdmin, CompanyAdminInvitation } from 'base/api/types';

type CompanyAdminsContext = {
  companyPk?: PK;
  admins: CompanyAdmin[];
  invitations: CompanyAdminInvitation[];
};

const defaultContext: CompanyAdminsContext = {
  admins: [],
  invitations: [],
};

export const companyAdminsContext = React.createContext(defaultContext);

export const CompanyAdminsProvider = companyAdminsContext.Provider;
