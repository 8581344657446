import * as React from 'react';

import { PK } from 'common/types';
import { MEDIA_TYPES } from 'common/consts';
import { CollapsibleSection, MediaPicker, Title } from 'common/components';
import { useRecipeMedia, useRecipeMediaUpdate } from '../queries';

const fieldName = 'media_image_pks';

type Props = {
  pk?: PK;
};

const RecipeVideoMediaPicker = ({ pk }: Props) => {
  const { data } = useRecipeMedia(pk);
  const { mutate, isSuccess, isLoading, isError } = useRecipeMediaUpdate();
  const saveStatus = { isSuccess, isLoading, isError };
  const defaultValues = data?.video && [data.video];

  const handleSave = (videoPks: PK[]) => {
    if (pk) {
      const payload = {
        pk,
        video: data?.video?.pk ?? null,
        image: data?.image?.pk ?? null,
      };

      if (videoPks.length > 0) {
        payload.video = videoPks[0];
      } else {
        payload.video = null;
      }

      mutate(payload);
    }
  };

  return (
    <CollapsibleSection title="Add video" saveStatus={saveStatus} disabled={!pk}>
      <Title size="standard" marginBottom={1.5}>
        Linked video file
      </Title>
      <MediaPicker
        onSave={handleSave}
        name={fieldName}
        mediaType={MEDIA_TYPES.VIDEO}
        defaultValues={defaultValues}
      />
    </CollapsibleSection>
  );
};

export default RecipeVideoMediaPicker;
