import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Authors = ({ size = '16px', color = 'none', ...rest }: IconProps) => {
  return (
    <Svg viewBox="0 0 16 16" size={size} color={color} {...rest}>
      <path
        d="M5.5 7.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM12.5 6.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM5.5 9.5a5 5 0 0 0-5 5h10a5 5 0 0 0-5-5v0zM10.84 9a3 3 0 0 1 4.659 2.5v1h-3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Authors;
