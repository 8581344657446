import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Units = ({ size = '16px', color = 'none', ...rest }: IconProps) => {
  return (
    <Svg viewBox="0 0 16 16" size={size} color={color} {...rest}>
      <path
        d="M8 6.5A6.5 6.5 0 0 0 1.5 13v2.5h13V13A6.5 6.5 0 0 0 8 6.5zM12.5 3.5h-9a3 3 0 0 1-3-3h15a3 3 0 0 1-3 3z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 12.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Units;
