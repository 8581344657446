import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { useClickAway } from 'react-use';
import { useFormContext, useWatch } from 'react-hook-form';

import { SelectOption } from 'common/types';
import { useOpen } from 'common/hooks';
import { assertFC, isEnum } from 'common/utils';
import { resolveSpacersArray } from 'common/styles/utils';
import { STATUSES } from 'common/consts';
import { Arrow } from 'common/icons';
import { Spinner, StatusChip, FormError } from 'common/components';
import { PlainCheckbox } from './Checkbox';

const isStatus = isEnum(STATUSES);

export interface SelectItemProps<TOption extends SelectOption> {
  value: TOption;
  selected: boolean;
  onPick: (value: TOption) => void;
}

export interface SelectProps<TOption extends SelectOption> {
  name: string;
  options: TOption[];
  idFromValue?: (value: TOption) => string | number;
  label?: string;
  isLoading?: boolean;
  isMulti?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  isFloating?: boolean;
  isDisabled?: boolean;
  isColored?: boolean;
  visibleFields?: number | null;
  multiRows?: boolean;
  isSingleChoiceResettable?: boolean;

  itemComponent?: React.ComponentType<SelectItemProps<TOption>>;
}

function Select<TOption extends SelectOption>(
  props: SelectProps<TOption>,
): React.ReactElement | null {
  const {
    name,
    label,
    isLoading,
    isMulti,
    idFromValue,
    readOnly,
    placeholder,
    isColored,
    isFloating = true,
    options: fullOptions,
    itemComponent: ItemComponent,
    isDisabled = false,
    visibleFields = null,
    isSingleChoiceResettable = false,
    multiRows,
  } = props;
  const ref = React.useRef(null);
  const theme = useTheme();
  const { isOpen, close, toggleOpen } = useOpen({ disabled: isLoading || readOnly });
  useClickAway(ref, close);
  const { register, unregister, setValue, errors } = useFormContext();
  const values: TOption[] = useWatch({ name }) || [];
  const error = errors[name];
  const isError = !!error;
  const options = isMulti
    ? fullOptions
    : isSingleChoiceResettable && !isMulti
    ? fullOptions
    : fullOptions.filter((option) => {
        return !values.find(({ value }) => value === option.value);
      });

  const getId = idFromValue || (({ value }: TOption) => value);
  const selectedIds = values.map(getId);
  const isSelected = (id: string | number) => selectedIds.includes(id);

  const handleSetValue = (values: TOption[]) => {
    setValue(name, values, { shouldValidate: true });
  };

  const handlePick = (item: TOption) => {
    const id = getId(item);
    const wasSelected = isSelected(id);
    if (isMulti) {
      const newValue = wasSelected
        ? values.filter((value) => getId(value) !== id)
        : [...values, item];
      handleSetValue(newValue);
    } else if (isSingleChoiceResettable) {
      const newValue = wasSelected
        ? values.filter((value) => getId(value) !== id)
        : [item];
      handleSetValue(newValue);
    } else {
      handleSetValue([item]);
      close();
    }
  };

  React.useEffect(() => {
    register(name);
    return () => unregister(name);
  }, [name, register, unregister]);

  const isValue = values.length > 0;
  const isLabel = !!label;

  return (
    <>
      <SelectContainer ref={ref}>
        <SelectButton
          isError={isError}
          onClick={toggleOpen}
          isOpen={isOpen}
          isLabel={isLabel}
          disabled={isDisabled}
        >
          <Label isValue={isValue} isError={isError}>
            {label}
          </Label>
          <SelectValuesContainer multiRows={multiRows}>
            {isValue
              ? values.map((value) => {
                  return isStatus(value.value) ? (
                    <SelectValueLabel key={value.value} multiRows={multiRows}>
                      <StatusChip status={value.value} />
                    </SelectValueLabel>
                  ) : isColored ? (
                    <SelectValueLabel key={String(value.value)} multiRows={multiRows}>
                      <SelectValueBackground>{value.label}</SelectValueBackground>
                    </SelectValueLabel>
                  ) : (
                    <SelectValueLabel key={String(value.value)} multiRows={multiRows}>
                      {value.label}
                    </SelectValueLabel>
                  );
                })
              : placeholder && <Placeholder>{placeholder}</Placeholder>}
          </SelectValuesContainer>
          <IconsContainer>
            {isLoading ? (
              <Spinner circleColor={theme.keys.primaryColor} size="16px" />
            ) : (
              <Arrow
                $width="2px"
                $color={theme.keys.primaryColor}
                $size="10px"
                $rotate={isOpen ? -45 : 135}
              />
            )}
          </IconsContainer>
        </SelectButton>
        {!isLoading && isOpen && options.length > 0 && (
          <SelectList isFloating={isFloating} visibleFields={visibleFields}>
            <Divider />
            {options.map((item) => {
              const id = getId(item);
              const selected = isSelected(id);
              return ItemComponent ? (
                <ItemComponent
                  key={String(item.value)}
                  value={item}
                  selected={selected}
                  onPick={handlePick}
                />
              ) : (
                <Option key={String(item.value)} onClick={() => handlePick(item)}>
                  {isMulti && (
                    <PlainCheckbox
                      name={item.label}
                      checked={selected}
                      readOnly
                      color={theme.keys.primaryColor}
                    />
                  )}
                  <OptionLabel>{item.label}</OptionLabel>
                </Option>
              );
            })}
          </SelectList>
        )}
      </SelectContainer>
      {isError && <FormError message={error.message} />}
    </>
  );
}

export const SelectContainer = styled.div`
  position: relative;
  min-width: ${({ theme }) => theme.spacer.times(25)};
`;

export const Label = styled.label<{ isValue: boolean; isError: boolean }>`
  position: absolute;
  top: ${({ theme }) => theme.spacer.times(3)};
  left: ${({ theme }) => theme.spacer.times(3)};
  color: ${({ theme, isError }) => (isError ? theme.color.error : theme.color.greyDark)};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-right: ${({ theme }) => theme.spacer.standard};
  transform: ${({ isValue }) => (isValue ? 'scale(0.87) translateY(-16px)' : '')};
  transform-origin: center left;
  transition: transform 0.2s ease, color 0.2s ease;
  cursor: inherit;
`;

export const SelectButton = styled.button.attrs({
  type: 'button',
})<{ isOpen: boolean; isLabel: boolean; isError: boolean }>`
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid
    ${({ theme, isOpen, isError }) =>
      isOpen
        ? theme.themeColor.tertiarySuperDarkColor
        : isError
        ? theme.color.error
        : theme.themeColor.tertiaryColor};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  padding: ${({ theme, isLabel }) =>
    resolveSpacersArray(theme, [isLabel ? 3.5 : 2, 6, isLabel ? 1.5 : 2, 3])};
  font-size: ${({ theme }) => theme.font.size.small};
  font-family: ${({ theme }) => theme.font.family.sans};
  cursor: pointer;
  -webkit-appearance: none;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.themeColor.tertiarySuperDarkColor};

    ${Label} {
      color: ${({ theme }) => theme.themeColor.tertiarySuperDarkColor};
    }
  }
`;

export const SelectValuesContainer = styled.div<{
  multiRows?: boolean;
}>`
  display: flex;
  align-items: center;
  min-height: 22px;
  flex-wrap: ${({ multiRows }) => (multiRows ? 'wrap' : 'unset')};
  margin-top: ${({ multiRows }) => (multiRows ? '8px' : '0')};
`;

export const SelectValueLabel = styled.div<{
  multiRows?: boolean;
}>`
  margin-right: ${({ theme }) => theme.spacer.standard};
  font-size: ${({ theme }) => theme.font.size.small};

  ${StatusChip} {
    min-height: ${({ multiRows }) => (multiRows ? '36px' : 'unset')};
    margin-top: -5px;
    margin-bottom: -5px;
  }
`;

const SelectValueBackground = styled.div`
  color: ${({ theme }) => theme.color.blue};
  background-color: ${({ theme }) => theme.color.blueLight};
  border-radius: ${({ theme }) => theme.border.radius.small};
  padding: ${({ theme }) => resolveSpacersArray(theme, ['tiny', 1])};
`;

const IconsContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${({ theme }) => theme.spacer.big};
  margin: 0 ${({ theme }) => theme.spacer.small};
  display: flex;
  align-items: center;

  & > svg {
    margin: ${({ theme }) => theme.spacer.small};
  }
`;

const SelectList = styled.div<{ isFloating: boolean; visibleFields: number | null }>`
  left: 0;
  right: 0;
  z-index: 1;
  position: ${({ isFloating }) => (isFloating ? 'absolute' : 'relative')};
  padding: ${({ theme }) => theme.spacer.standard};
  background-color: ${({ theme }) => theme.color.white};
  border-left: 1px solid ${({ theme }) => theme.themeColor.tertiarySuperDarkColor};
  border-right: 1px solid ${({ theme }) => theme.themeColor.tertiarySuperDarkColor};
  border-bottom: 1px solid ${({ theme }) => theme.themeColor.tertiarySuperDarkColor};
  border-bottom-left-radius: ${({ theme }) => theme.border.radius.tiny};
  border-bottom-right-radius: ${({ theme }) => theme.border.radius.tiny};
  transform: translateY(-4px);
  ${({ visibleFields }) =>
    visibleFields && `max-height: calc(${visibleFields} * 48px); overflow-y: overlay;`};
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacer.standard};
  margin: ${({ theme }) => theme.spacer.small} 0;
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.themeColor.primaryColor};
    background-color: ${({ theme }) => theme.themeColor.tertiaryColor};
  }
`;

const OptionLabel = styled.span`
  margin-left: ${({ theme }) => theme.spacer.standard};
  font-size: ${({ theme }) => theme.font.size.small};
`;

const Divider = styled.div`
  position: relative;
  top: -5px;
  border-top: 1px solid ${({ theme }) => theme.color.blueSuperLight};
`;

const Placeholder = styled.label`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.themeColor.primaryColor};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

assertFC(Select);

export default Select;
