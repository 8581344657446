import * as React from 'react';
import { useLocation } from 'react-router-dom';

import appUrls from 'base/app/urls';
import { Container, Wrapper, TopBar } from 'common/components';
import {
  ContentProvider,
  ContentList,
  FiltersForm,
  Sort,
  StatusFilter,
  SearchInput,
} from 'templates/content';
import { DetailsSwitch } from 'templates/details';
import WorkoutDetails from './WorkoutDetails';
import { useWorkoutsList } from '../queries';

const fields = {
  search: 'search',
  filter: 'filter',
  sort: 'sort',
};

const WorkoutsList = () => {
  const location = useLocation();
  const { data, isError } = useWorkoutsList();
  const context = {
    data: data?.values,
    path: {
      details: appUrls.WORKOUTS.DETAILS,
      list: appUrls.WORKOUTS.LIST,
    },
  };

  return (
    <Container>
      <ContentProvider {...context}>
        <TopBar title="Workouts">
          <FiltersForm
            sort={['title', fields.sort]}
            search={['title', fields.search]}
            filter={['status', fields.filter]}
          >
            <Wrapper margin={[0, 0.5]}>
              <SearchInput name={fields.search} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <StatusFilter name={fields.filter} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <Sort name={fields.sort} />
            </Wrapper>
          </FiltersForm>
        </TopBar>
        <ContentList isError={isError} />
        <DetailsSwitch>
          <WorkoutDetails key={location.pathname} />
        </DetailsSwitch>
      </ContentProvider>
    </Container>
  );
};

export default WorkoutsList;
