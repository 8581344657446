import { useEffect } from 'react';
import create from 'zustand';

const IS_B2B = 'is_b2b';

type BusinessStore = {
  isBusiness: boolean;
  setBusiness: (isBusiness: boolean) => void;
};

export const useBusinessStore = create<BusinessStore>((set) => ({
  isBusiness: false,
  setBusiness: (isBusiness) => {
    if (isBusiness) {
      localStorage.setItem(IS_B2B, 'true');
      return set({ isBusiness: true });
    } else {
      localStorage.setItem(IS_B2B, 'false');
      return set({ isBusiness: false });
    }
  },
}));

export const useBusinessInit = () => {
  const { setBusiness } = useBusinessStore();

  useEffect(() => {
    const clientSettings = localStorage.getItem(IS_B2B);
    if (clientSettings === 'true') {
      setBusiness(true);
    } else {
      setBusiness(false);
    }
  }, [setBusiness]);
};
