import * as React from 'react';

import appUrls from 'base/app/urls';
import { Container, Wrapper } from 'common/components';
import {
  ContentProvider,
  ContentList,
  FiltersForm,
  Sort,
  SearchInput,
} from 'templates/content';
import {
  RecipeDetailsSwitch,
  RecipeTopBar,
  CreateButton,
} from 'modules/recipe-subsections';
import { RECIPE_SUBSECTIONS } from 'modules/recipe-subsections/consts';

import { useTagsList } from '../queries';
import TagsDetails from './TagsDetails';
import TagsContentRow from '../components/TagsContentRow';

const fields = {
  search: 'search',
  sort: 'sort',
};

const TagsList = () => {
  const { data, isError } = useTagsList();
  const context = {
    data: data?.values.map(({ name, ...tag }) => ({ ...tag, title: name })),
    path: {
      list: appUrls.TAGS.LIST,
      details: appUrls.TAGS.DETAILS,
      create: appUrls.TAGS.CREATE,
    },
  };

  return (
    <Container>
      <ContentProvider {...context}>
        <RecipeTopBar subsection={RECIPE_SUBSECTIONS.TAGS}>
          <CreateButton path={appUrls.TAGS.CREATE}>New item</CreateButton>
          <FiltersForm sort={['title', fields.sort]} search={['title', fields.search]}>
            <Wrapper margin={[0, 0.5]}>
              <SearchInput name={fields.search} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <Sort name={fields.sort} />
            </Wrapper>
          </FiltersForm>
        </RecipeTopBar>
        <ContentList isError={isError}>{TagsContentRow}</ContentList>
        <RecipeDetailsSwitch>
          <TagsDetails />
        </RecipeDetailsSwitch>
      </ContentProvider>
    </Container>
  );
};

export default TagsList;
