import * as React from 'react';

import { Input } from 'common/components';
import { Search } from 'common/icons';
import { InputProps } from 'common/components/Input';

const SearchInput = (props: InputProps) => {
  return (
    <Input
      {...props}
      placeholder="Search"
      frontIcon={<Search size="22px" color="blue" />}
    />
  );
};

export default SearchInput;
