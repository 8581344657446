import * as React from 'react';
import styled from 'styled-components';

import { useConfiguration, useSetMaintenanceMode } from '../queries';
import { useModal } from 'common/hooks';

import { MINI_SUGGESTIONS_MODE_TYPES } from 'common/consts';
import { Row, SaveStatus, Toggle } from 'common/components';
import MaintenanceModal from './MaintenanceModal';
import Heading from './Heading';
import Strong from './Strong';

import { Check, Cross } from 'common/icons';

const MaintenanceMode = () => {
  const { data: configuration } = useConfiguration();
  const { mutate, isLoading, isSuccess, isError } = useSetMaintenanceMode();
  const saveStatus = { isLoading, isError, isSuccess };

  const { isOpen, open: openConfirmation, close } = useModal();

  const isMaintenanceMode = !!configuration?.maintenance_mode;

  const onAccept = () => {
    mutate(
      {
        maintenance_mode: !isMaintenanceMode,
        mini_suggestions_mode: MINI_SUGGESTIONS_MODE_TYPES.BML,
      },
      { onSuccess: () => close(), onError: () => close() },
    );
  };

  return (
    <>
      <Heading>Maintenance Mode</Heading>
      <p>
        Maintenance mode is currently <Strong>{isMaintenanceMode ? 'on' : 'off'}</Strong>
        {isMaintenanceMode
          ? ', users will have no access to app'
          : ', users have normal access to app.'}
      </p>
      <Row>
        <Toggle
          checked={isMaintenanceMode}
          onClick={openConfirmation}
          colors={['green', 'pink']}
        >
          <ToggleLabel status={isMaintenanceMode}>
            <StyledCheck size="20px" color="white" hoverColor="white" />
          </ToggleLabel>
          <ToggleLabel status={isMaintenanceMode}>
            <Cross size="12px" color="white" hoverColor="white" />
          </ToggleLabel>
        </Toggle>
        <SaveStatus {...saveStatus} />
      </Row>
      <MaintenanceModal
        isLoading={isLoading}
        onAccept={onAccept}
        isOpen={isOpen}
        close={close}
        isMaintenanceMode={isMaintenanceMode}
      />
    </>
  );
};

const StyledCheck = styled(Check)`
  circle {
    fill: none;
  }
`;

type Props = {
  status: boolean;
};

const ToggleLabel = styled.span<Props>`
  position: absolute;
  font-size: ${({ theme }) => theme.font.size.moderate};
  color: ${({ theme }) => theme.color.white};
  margin-top: 14px;
  top: -8px;
  transition: opacity 0.2s ease;

  &:first-of-type {
    opacity: ${({ status }) => (status ? 1 : 0)};
    left: 5px;
  }

  &:last-of-type {
    opacity: ${({ status }) => (status ? 0 : 1)};
    right: 9px;
    top: -10px;
  }
`;
export default MaintenanceMode;
