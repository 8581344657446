import * as Yup from 'yup';

import { combineSchemas } from 'common/utils';
import { Fields } from 'common/types';

export type BlogFields = {
  title: string;
  description: string;
  url: string;
  image_url: string;
};

export const CLEAR_FORM_VALUES: BlogFields = {
  title: '',
  description: '',
  url: '',
  image_url: '',
};

export const fields: Fields<BlogFields> = {
  title: {
    label: 'Title',
    name: 'title',
    schema: Yup.string().required(),
  },
  description: {
    label: 'Description',
    name: 'description',
    schema: Yup.string().required(),
  },
  url: {
    label: 'Url',
    name: 'url',
    schema: Yup.string().url().required(),
  },
  image_url: {
    label: 'ImageUrl',
    name: 'image_url',
    schema: Yup.string().url().required(),
  },
};

export const validationSchema = Yup.object().shape<BlogFields>(combineSchemas(fields));
