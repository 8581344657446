import { useState } from 'react';
import Axios from 'axios';
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
  useQueryClient,
} from 'react-query';

import apiUrls from 'base/api/urls';
import { composeUrl } from 'common/utils';
import { MEDIA_TYPES } from 'common/consts';
import { CommonVars } from 'common/types';
import { MediaObject } from 'base/api/types';
import { StandardError } from 'base/api/errors';
import { useListQuery } from 'base/api/hooks';

interface MutationResultProgress<TResult, TError = StandardError>
  extends UseMutationResult<TResult, TError, PostMediaVars | PostVideoVars> {
  progress: number;
  setProgress: (value: number) => void;
}

export interface PostMediaVars {
  media_type: Exclude<MEDIA_TYPES, MEDIA_TYPES.VIDEO>;
  file: File;
}

export interface PostVideoVars {
  media_type: MEDIA_TYPES.VIDEO;
  file: File;
  thumbnail: File;
}

export const usePostMedia = (
  config: UseMutationOptions<MediaObject, StandardError, PostMediaVars | PostVideoVars>,
): MutationResultProgress<MediaObject> => {
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState<number>(0);
  const mutationConfig: typeof config = {
    ...config,
    onSuccess: (...args) => {
      const [uploadedMedia] = args;
      queryClient.invalidateQueries(apiUrls.MEDIA.LIBRARY);
      queryClient.invalidateQueries([apiUrls.MEDIA.DETAILS, { pk: uploadedMedia.pk }]);

      config?.onSuccess?.(...args);
    },
  };

  const handleProgress = (event: ProgressEvent) => {
    const percent = Math.floor((100 * event.loaded) / event.total);
    setProgress(percent);
  };

  const mutation = useMutation<MediaObject, StandardError, PostMediaVars | PostVideoVars>(
    async (vars) => {
      const { file, media_type } = vars;
      const data = {
        name: file.name,
        media_type,
      };
      setProgress(0);

      return Axios.post<MediaObject>(apiUrls.MEDIA.FILES, data).then(({ pk, name }) => {
        const payload = new FormData();

        payload.append('content', file, name);
        if (vars.media_type === MEDIA_TYPES.VIDEO) {
          payload.append('thumbnail', vars.thumbnail, vars.thumbnail.name);
        }

        return Axios.patch<MediaObject>(
          composeUrl(apiUrls.MEDIA.FILES_CONTENT, { params: { pk } }),
          payload,
          {
            onUploadProgress: handleProgress,
          },
        );
      });
    },
    mutationConfig,
  );

  return { ...mutation, progress, setProgress };
};

export const useMediaList = () => {
  return useListQuery<MediaObject>(apiUrls.MEDIA.LIBRARY);
};

export const useDeleteMedia = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, StandardError, CommonVars>(
    ({ pk }) => Axios.delete(composeUrl(apiUrls.MEDIA.DETAILS, { params: { pk } })),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.MEDIA.LIBRARY);
      },
    },
  );
};
