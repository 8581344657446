import Axios from 'axios';
import Cookies from 'js-cookie';
import { useMutation, useQuery } from 'react-query';

import apiUrls from 'base/api/urls';
import { StandardError } from 'base/api/errors';
import { useAuthStore } from 'common/hooks';
import { ACCESS_TOKEN, EMAIL_LOGIN } from './consts';

type RoleResponse = {
  email: string;
  is_admin: boolean;
};

export const useRole = () => {
  const { valid, invalid } = useAuthStore(({ valid, invalid }) => ({ valid, invalid }));
  return useQuery<RoleResponse, StandardError>(apiUrls.AUTH.WHOAMI, {
    enabled: false,
    onSuccess: (data) => {
      if (data.is_admin) {
        valid();
      } else {
        invalid();
      }
    },
    onError: () => {
      Cookies.remove(ACCESS_TOKEN);
      invalid();
    },
  });
};

type LoginResponse = {
  [ACCESS_TOKEN]: string;
  refresh_token: string;
  token_type: 'Bearer';
  expires_in: number;
  expires_at: number;
  sub: string;
};

type LoginVars = {
  email: string;
  password?: string;
  token?: string | string[];
  digit_code?: string;
};

export const useLogin = () => {
  const { refetch: getRole } = useRole();
  const { invalid, revalidate } = useAuthStore(({ invalid, revalidate }) => ({
    invalid,
    revalidate,
  }));
  return useMutation<LoginResponse, StandardError, LoginVars>(
    (data) => Axios.post(apiUrls.AUTH.LOGIN, data),
    {
      onSuccess: (data) => {
        Cookies.set(ACCESS_TOKEN, data[ACCESS_TOKEN], {
          expires: new Date(data.expires_at),
        });
        Cookies.remove(EMAIL_LOGIN);

        revalidate();
        getRole();
      },
      onError: () => {
        Cookies.remove(ACCESS_TOKEN);
        invalid();
      },
    },
  );
};

type LoginEmailVars = {
  email: string;
};

export const useSendLoginEmail = () => {
  return useMutation<null, StandardError, LoginEmailVars>(
    (data) => Axios.post(apiUrls.AUTH.LOGIN_TOKEN, data),
    {
      onSuccess: (_data, variables) => {
        const in30Minutes = 1 / 48;
        Cookies.set(EMAIL_LOGIN, variables.email, {
          expires: in30Minutes,
        });
      },
    },
  );
};

export const useLogout = () => {
  const { invalid } = useAuthStore(({ invalid }) => ({ invalid }));
  return useMutation<unknown, StandardError>(() => Axios.post(apiUrls.AUTH.LOGOUT), {
    onSettled: () => {
      Cookies.remove(ACCESS_TOKEN);
      invalid();
    },
  });
};

export const useAzureLink = () => {
  return useQuery(apiUrls.AUTH.SSO_SESSION, { enabled: false });
};

export const useAzureLogin = () => {
  return useMutation((data) => Axios.post(apiUrls.AUTH.SSO_LOGIN, data));
};
