import { DateString, PK } from 'common/types';
import {
  CATEGORIES,
  CONTENT_TYPES,
  DATE_FORMATS,
  FRIESLAND_CATEGORIES,
  MEDIA_TYPES,
  MINI_SUGGESTIONS_MODE_TYPES,
  PROGRAM_STATUSES,
  STATUSES,
} from 'common/consts';

export type ListResponse<TData> = {
  values: TData[];
};

interface BasicContent {
  pk: PK;
  created_at?: DateString<DATE_FORMATS.DATABASE>;
  modified_at?: DateString<DATE_FORMATS.DATABASE>;
}

interface PublishableContent {
  published_at: DateString<DATE_FORMATS.DATABASE>;
}

export interface CmsAdmin {
  pk: PK;
  email: string;
}

export interface Configuration {
  maintenance_mode: boolean;
  mini_suggestions_mode: MINI_SUGGESTIONS_MODE_TYPES;
  modified_at: DateString<DATE_FORMATS.DATABASE>;
}

export interface ConfigurationVars {
  maintenance_mode: boolean;
  mini_suggestions_mode: MINI_SUGGESTIONS_MODE_TYPES;
}

export interface ContentGoal {
  pk: PK;
  label: string;
  color: CATEGORIES;
}

export interface Mini extends BasicContent, PublishableContent {
  title: string;
  status: STATUSES;
  description: string;
  short_description: string;
  punctation: number;
  content_goals: ContentGoal[];
  is_b2b?: boolean;
  blog_pk: PK;
}

export interface MinisRecipe {
  mini_pk: PK;
  recipe_pk: PK;
  recipe_title: string;
  order: number;
}

export type Tags = Record<string, number | string | string[]>;

export interface Series extends BasicContent, PublishableContent {
  title: string;
  status: STATUSES;
  description: string;
  short_description: string;
  punctation: number;
  content_goals: ContentGoal[];
  start_date: DateString<DATE_FORMATS.DATABASE> | null;
  end_date: DateString<DATE_FORMATS.DATABASE> | null;
  blog_pk: PK;
  is_challenge: boolean;
  challenge_start_date: DateString<DATE_FORMATS.DATABASE> | null;
}

export interface HabitLevel {
  description: string;
  minis_pks_per_day: PK[][];
}
export interface Habits extends BasicContent {
  description: string;
  levels: HabitLevel[];
}

export interface SeriesFollowup extends BasicContent {
  title: string;
  status: STATUSES;
}

export interface Company extends BasicContent {
  title: string;
  branch: string;
  kvk_number: number;
  min_team_size: number;
  max_team_size: number;
  status: STATUSES;
}

export interface CompanyProgram {
  scenario_pk: PK | null;
  start_date?: DateString<DATE_FORMATS.DATABASE>;
}

export interface CompanyAdminInvitation {
  pk: PK;
  last_name: string;
  first_name: string;
  email: string;
  phone_number: string;
}

export interface CompanyAdmin extends CompanyAdminInvitation {
  is_contact_person?: boolean;
}

export interface Theme extends BasicContent {
  title: string;
}

export type MediaObject = {
  pk: PK;
  url: string;
  name: string;
  media_type: MEDIA_TYPES;
  duration: number | null;
  thumbnail: string | null;
};

export interface BaseAuthor {
  pk: PK;
  name: string;
  description: string;
  link: string;
  image?: Pick<MediaObject, 'pk' | 'name' | 'url'>;
}

export interface RecipesAuthor extends BaseAuthor, BasicContent {}

export type RecipeCategoryType = 'RECIPE' | 'INGREDIENT';

export interface RecipesCategory extends BasicContent {
  pk: PK;
  name: string;
  category_type: RecipeCategoryType;
}

export interface RecipesUnit extends BasicContent {
  pk: PK;
  name_singular: string;
  name_plural: string;
}

export interface RecipesIngredient extends BasicContent {
  pk: PK;
  name_singular: string;
  name_plural: string;
  category: RecipesCategory;
  unit: RecipesUnit | null;
}

export interface RecipesNutritionalValue extends BasicContent {
  pk: PK;
  name: string;
  unit: RecipesUnit;
}

export interface RecipesTag extends BasicContent {
  pk: PK;
  name: string;
}

export interface RecipeBase extends BasicContent {
  title: string;
  description: string;
  author: RecipesAuthor;
  status: STATUSES;
  portions: number;
  calories: number;
  preparation_time: number | null;
  oven_time: number | null;
  waiting_time: number | null;
  date_active_end: DateString<DATE_FORMATS.DATABASE> | null;
  date_active_start: DateString<DATE_FORMATS.DATABASE> | null;
  why_its_healthy: string;
  why_its_healthy_author: BaseAuthor | null;
  content_goals: ContentGoal[];
}

export interface RecipeTags {
  tags: PK[];
}

export interface RecipeCategories {
  categories: PK[];
}

export interface RecipePreparation {
  steps: string[];
  tips: string;
}

export interface RecipeIngredients {
  ingredients: {
    pk: PK;
    amount: number;
    fixed_amount: boolean;
    ingredient_pk: PK;
    unit_name: string;
  }[];
}

export interface RecipeMedia {
  image: MediaObject | null;
  video: MediaObject | null;
}

export interface RecipeNutritionalValues {
  nutritional_values: {
    pk: PK;
    amount: number;
    nutritional_value_pk: PK;
    specified: {
      name: string;
      amount: number;
    }[];
  }[];
  formatted: string;
}

export type CollectionHandler = string & { __brand: 'CollectionHandler' };

export interface Collection extends BasicContent, PublishableContent {
  title: string;
  icon: MediaObject;
  status: STATUSES;
  color: FRIESLAND_CATEGORIES | CATEGORIES;
  handler: CollectionHandler;
  minis_count: number;
  series_count: number;
}

export interface CollectionHandlers {
  collection_handlers: CollectionHandler[];
}

export interface CollectionContentItem {
  pk: PK;
  item_type: unknown;
  status: STATUSES;
  title: string;
  description: string;
  short_description: string;
  links: unknown;
  is_visible: boolean;
}

export interface CollectionContent extends BasicContent {
  content_type: CONTENT_TYPES;
  handler: CollectionHandler;
  items: CollectionContentItem[];
}

export interface DashboardContent {
  overview: {
    content_type: CONTENT_TYPES;
    total: number;
    published: number;
    unpublished: number;
    draft: number;
    in_review: number;
  }[];
}

export interface Fact extends BasicContent {
  content_goals: ContentGoal[];
  fact_text: string;
  status: STATUSES;
}

export interface FunFact extends BasicContent {
  title: string;
  description: string;
  content_goals: ContentGoal[];
  status: STATUSES;
  schedule: {
    pk: PK;
    date: DateString<DATE_FORMATS.DATABASE>;
  }[];
  media_file: MediaObject; // FIXME Backend can return null here, most likely also for other resources' media_file fields
}

export interface Meditation extends BasicContent, PublishableContent {
  title: string;
  status: STATUSES;
  media_file: MediaObject;
  content_goals: ContentGoal[];
}

export interface Podcast extends BasicContent, PublishableContent {
  title: string;
  status: STATUSES;
  media_file: MediaObject;
  content_goals: ContentGoal[];
}

export interface Workout extends BasicContent, PublishableContent {
  title: string;
  status: STATUSES;
  media_file: MediaObject;
  content_goals: ContentGoal[];
}

export interface ScenarioWeekDayMini {
  pk: PK;
  title: string;
  image: MediaObject | null;
  modified_at: DateString<DATE_FORMATS.DATABASE> | null;
  points?: number;
}

export interface ScenarioWeekDay {
  minis: ScenarioWeekDayMini[];
}

export interface ScenarioWeek {
  pk: PK;
  days: ScenarioWeekDay[];
}

export interface ScenarioWeekList {
  weeks: ScenarioWeek[];
}

export interface ScenarioCompany {
  company_pk: PK;
  company_title: string;
  status: PROGRAM_STATUSES;
  current_week: number;
  start_date: DateString<DATE_FORMATS.DATABASE>;
}

export interface Scenario extends BasicContent {
  weeks: PK[];
  title: string;
  programs: ScenarioCompany[];
}

export interface ScenarioListItem {
  pk: PK;
  title: string;
  modified_at: DateString<DATE_FORMATS.DATABASE> | null;
  weeks_count: number;
  programs_status: 'ACTIVE' | 'FINISHED' | 'NOT_LINKED';
}

export interface BackgroundTask {
  pk: PK;
  executor: string;
  attempt: number;
  created_at: DateString<DATE_FORMATS.DATABASE>;
  last_attempt_at: DateString<DATE_FORMATS.DATABASE>;
  priority: 'LOW' | 'NORMAL' | 'HIGH';
  status: 'FAILED' | 'PENDING' | 'PROCESSED';
}

export enum TAGS_DISPLAY_TYPE {
  LIST = 'LIST',
  NUMBER = 'NUMBER',
}

export interface TaggingSchemaMetadata {
  values: string[] | null;
  display_type: TAGS_DISPLAY_TYPE;
  is_required: boolean;
  single_choice: boolean;
  selection_limit: number | null;
}
export interface TaggingSchema {
  metadata: Record<string, TaggingSchemaMetadata>;
}

export enum BADGE_TYPE {
  ITEMS_COMPLETED = 'ITEMS_COMPLETED',
  STEPS = 'STEPS',
  APP_DEFINED = 'APP_DEFINED',
}

export enum BADGE_SUBTYPE {
  MINI = 'MINI',
  REVIEW = 'REVIEW',
  CONTRIBUTOR = 'CONTRIBUTOR',
  NOTIFICATION = 'NOTIFICATION',
  MARATHON = 'MARATHON',
  REWARDS = 'REWARDS',
  STREAK = 'STREAK',
}

export enum BADGE_FAMILY {
  WALKED_MILES = 'WALKED_MILES',
}

export interface BadgeCollection extends BasicContent {
  title: string;
  order: number;
}

export interface Badge extends BasicContent {
  title: string;
  status: STATUSES;
  collection: string;
  type: BADGE_TYPE;
  subtype: BADGE_SUBTYPE | null;
  family: BADGE_FAMILY | null;
  descriptions: {
    not_collected: string;
    in_progress: string | null;
    collected: string;
  };
  is_hidden: boolean;
  images: {
    inactive_url: string;
    in_progress_url: string;
    granted_url: string;
  };
  progress_bar_color: string | null;
  criteria: {
    minimum_count: number | null;
    possible_pks: string[] | null;
  } | null;
}
