import * as React from 'react';
import styled from 'styled-components';
import { FieldError, useFormContext, useWatch } from 'react-hook-form';
import _get from 'lodash/get';

import { resolveSpacersArray } from 'common/styles/utils';
import { FormError } from 'common/components';

const autoExpand = (field: HTMLTextAreaElement) => {
  field.style.height = 'inherit';
  const computed = window.getComputedStyle(field);
  const height =
    parseInt(computed.getPropertyValue('border-top-width'), 10) +
    field.scrollHeight +
    parseInt(computed.getPropertyValue('border-bottom-width'), 10);
  field.style.height = `${height}px`;
};

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  error?: FieldError;
  label?: string;
  check?: boolean;
  isLoading?: boolean;
  onFocus?: () => void;
}

const Textarea = ({ name, ...props }: TextareaProps) => {
  const methods = useFormContext();
  const isValue = !!useWatch({ name });
  const { register, errors } = methods;
  const error = _get(errors, name);

  return (
    <PlainTextarea
      name={name}
      isValue={isValue}
      error={error}
      {...props}
      ref={register}
    />
  );
};

interface PlainTextareaProps extends TextareaProps {
  isValue: boolean;
}

export const PlainTextarea = React.forwardRef<HTMLTextAreaElement, PlainTextareaProps>(
  (
    {
      isValue,
      onChange,
      error,
      className,
      label,
      name,
      /* eslint-disable @typescript-eslint/no-unused-vars */
      check,
      isLoading,
      /* eslint-enable @typescript-eslint/no-unused-vars */
      ...props
    },
    ref,
  ) => {
    // This value is only used as a resize trigger. The actual value is handeld by RHF.
    const [value, setValue] = React.useState('');
    const txtRef = React.useRef<HTMLTextAreaElement | null>(null);
    const isError = !!error;
    const isLabel = !!label;

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(event);
      setValue(event.target.value);
    };

    React.useEffect(() => {
      if (txtRef.current) {
        autoExpand(txtRef.current);
      }
    }, [value, txtRef]);

    return (
      <>
        <TextareaContainer
          isError={isError}
          className={className}
          isValue={isValue}
          isLabel={isLabel}
        >
          <textarea
            ref={(node) => {
              typeof ref === 'function' ? ref(node) : ref ? (ref.current = node) : null;
              txtRef.current = node;
            }}
            name={name}
            onChange={handleChange}
            {...props}
          />
          <Label isValue={isValue} isError={isError}>
            {label}
          </Label>
        </TextareaContainer>
        <FormError message={error?.message} />
      </>
    );
  },
);
PlainTextarea.displayName = 'PlainTextarea';

type ContainerProps = {
  isValue: boolean;
  isError: boolean;
  isLabel: boolean;
};

const Label = styled.div<Pick<ContainerProps, 'isError' | 'isValue'>>`
  display: block;
  position: absolute;
  top: ${({ theme }) => theme.spacer.times(3)};
  left: ${({ theme }) => theme.spacer.times(3)};
  color: ${({ theme, isError }) =>
    isError ? theme.color.error : theme.themeColor.quaternaryColor};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  transform: ${({ isValue }) => (isValue ? 'scale(0.87) translateY(-16px)' : '')};
  transform-origin: center left;
  transition: transform 0.2s ease, color 0.2s ease;
`;

const TextareaContainer = styled.label<ContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  textarea {
    min-height: 8em;
    appearance: none;
    resize: none;
    width: 100%;
    padding: ${({ theme, isLabel }) =>
      resolveSpacersArray(theme, [isLabel ? 3.5 : 2, 3, isLabel ? 1.5 : 2])};
    border: 1px solid
      ${({ theme, isError }) =>
        isError ? theme.color.error : theme.themeColor.tertiaryColor};
    border-radius: ${({ theme }) => theme.border.radius.tiny};
    font-family: ${({ theme }) => theme.font.family.sans};
    font-size: ${({ theme }) => theme.font.size.small};
    font-weight: ${({ theme }) => theme.font.weight.normal};
    line-height: 22px;
    background-color: ${({ theme }) => theme.color.white};

    &::placeholder {
      color: ${({ theme }) => theme.color.greyDark};
    }

    &:read-only {
      background-color: ${({ theme }) => theme.color.blueSuperLight};
      color: ${({ theme }) => theme.color.blueMediumLight};

      & ~ ${Label} {
        color: ${({ theme }) => theme.color.blueMediumLight};
      }
    }

    &:focus {
      outline: none;

      & ~ ${Label} {
        transform: scale(0.87) translateY(-16px);
      }

      &:not(:read-only) {
        border-color: ${({ theme }) => theme.themeColor.tertiarySuperDarkColor};

        & ~ ${Label} {
          color: ${({ theme }) => theme.themeColor.tertiarySuperDarkColor};
        }
      }
    }
  }
`;

export default Textarea;
