import * as Yup from 'yup';

import { combineSchemas } from 'common/utils';
import { Fields, PK, SelectOption } from 'common/types';

export type NutritionalValuesFields = {
  name: string;
  unit: SelectOption<PK>[];
};

export const fields: Fields<NutritionalValuesFields> = {
  name: {
    label: 'Name',
    name: 'name',
    schema: Yup.string().required(),
  },
  unit: {
    label: 'Unit',
    name: 'unit',
    schema: Yup.array().required(),
  },
};

export const validationSchema = Yup.object().shape<NutritionalValuesFields>(
  combineSchemas(fields),
);
