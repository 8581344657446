import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const ChainLink = ({ ...props }: IconProps) => {
  return (
    <Svg viewBox="0 0 16 16" fill="none" {...props}>
      <g fill="currentColor">
        <path d="M4.5 16c-1.2 0-2.3-.5-3.2-1.3-1.8-1.8-1.8-4.6 0-6.4l.7-.7L3.4 9l-.7.7c-1 1-1 2.6 0 3.6s2.6 1 3.6 0l3-3c1-1 1-2.6 0-3.6L8.6 6 10 4.6l.7.7c1.8 1.8 1.8 4.6 0 6.4l-3 3c-.8.8-2 1.3-3.2 1.3Z" />
        <path d="m6 11.4-.7-.7c-1.8-1.8-1.8-4.6 0-6.4l3-3c.9-.9 2-1.3 3.2-1.3 1.2 0 2.3.5 3.2 1.3 1.8 1.8 1.8 4.6 0 6.4l-.7.7L12.6 7l.7-.7c1-1 1-2.6 0-3.6s-2.6-1-3.6 0l-3 3c-1 1-1 2.6 0 3.6l.7.7L6 11.4Z" />
      </g>
    </Svg>
  );
};

export default ChainLink;
