import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const Reload = ({ ...props }: IconProps) => {
  return (
    <Svg viewBox="0 0 12 12" fill="none" {...props}>
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M.591 4.997A5.495 5.495 0 0 1 5.73.503a5.503 5.503 0 0 1 5.556 3.967" />
        <path d="M11.5.5v3.997l-3.969-.493M11.41 6.995a5.495 5.495 0 0 1-5.14 4.494A5.503 5.503 0 0 1 .716 7.522" />
        <path d="M.5 11.492V7.495l3.969.493" />
      </g>
    </Svg>
  );
};

export default Reload;
