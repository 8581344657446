import styled from 'styled-components';

import { ThemeSpacer } from 'shared/theme';
import { resolveSpacersArray } from 'common/styles/utils';

type Props = {
  padding?: ThemeSpacer[] | ThemeSpacer;
  margin?: ThemeSpacer[] | ThemeSpacer;
};

const Column = styled.div<Props>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: ${({ theme, margin }) => resolveSpacersArray(theme, margin || [0])};
  padding: ${({ theme, padding }) => resolveSpacersArray(theme, padding || [0])};
`;

export default Column;
