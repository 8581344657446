import * as React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { StandardError } from 'base/api/errors';
import { MediaObject } from 'base/api/types';
import { MEDIA_TYPES } from 'common/consts';
import { useMediaOptions, useModal } from 'common/hooks';
import { PK } from 'common/types';
import MediaLibraryPicker from 'modules/media/views/MediaLibraryPicker';
import { usePostMedia } from 'modules/media/queries';

import { FileInput } from '..';
import UploadLabel from './UploadLabel';
import FileLabel from './FileLabel';

export interface PlainIconPickerProps {
  name: string;
  value?: MediaObject;
  error?: StandardError;
  onUpload?: (pk: PK) => void;
  onRemove?: (pk: PK) => void;
  onPick?: (media: MediaObject) => void;
}

const isMedia = (items?: any[]): items is MediaObject[] => {
  return items ? items.every((item) => (item?.media_type ? true : false)) : false;
};

const isFile = (items?: any[]): items is File[] => {
  return !isMedia(items);
};

const mediaType = MEDIA_TYPES.ICON;

const PlainIconPicker = ({
  name,
  error,
  value: uploadedIcon,
  onUpload,
  onRemove,
  onPick,
}: PlainIconPickerProps) => {
  const fieldName = name;
  const fileListName = `${name}_files`;

  const form = useForm();
  const { setValue: setInternalValue, getValues: getInternalValues } = form;
  const {
    setValue: setFormValue,
    setError: setFormError,
    clearErrors: clearFormErrors,
  } = useFormContext();
  const mediaPickerModal = useModal();
  const mediaOptions = useMediaOptions(mediaType);

  const setMediaValue = (value: MediaObject | null) => {
    clearFormErrors(fieldName);
    setFormValue(fieldName, value);
  };

  const {
    mutate: addMedia,
    progress,
    setProgress,
    isLoading,
    isSuccess,
    reset: resetMutation,
  } = usePostMedia({
    onSuccess: (data) => {
      setProgress(0);
      setMediaValue(data);
      setInternalValue(fileListName, [], { shouldValidate: true });
      onUpload?.(data.pk);
    },
    onError: () => {
      setProgress(0);
    },
  });

  const handlePick = (item: MediaObject) => {
    setMediaValue(item);
    onUpload?.(item.pk);
    onPick?.(item);
  };

  const handleRemove = (itemPk: PK) => {
    setMediaValue(null);
    onRemove?.(itemPk);
  };

  const handleUpload = () => {
    const files = getInternalValues(fileListName);
    const file = isFile(files) && files[0];

    if (file && mediaOptions.type !== MEDIA_TYPES.VIDEO) {
      if (mediaOptions.regexp.test(file.type)) {
        const payload = {
          file,
          media_type: mediaOptions.type,
        };
        addMedia(payload);
      } else {
        setFormError(fieldName, { type: 'manual', message: mediaOptions.errorMessage });
      }
    }
  };

  const inputLabel = uploadedIcon ? (
    <FileLabel
      fileName={uploadedIcon.name}
      imageUrl={uploadedIcon.url}
      onMediaRemove={() => handleRemove(uploadedIcon.pk)}
    />
  ) : (
    <UploadLabel onMediaOpen={mediaPickerModal.open} />
  );

  return (
    <>
      <FormProvider {...form}>
        <StyledFileInput
          name={fileListName}
          error={error}
          accept={mediaOptions.accept}
          onDrop={resetMutation}
          onUpload={handleUpload}
          isSuccess={isSuccess}
          isLoading={isLoading}
          progress={progress}
          label={inputLabel}
        />
      </FormProvider>
      <MediaLibraryPicker
        mediaType={mediaType}
        isOpen={mediaPickerModal.isOpen}
        close={mediaPickerModal.close}
        onPick={handlePick}
      />
    </>
  );
};

const StyledFileInput = styled(FileInput)`
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.border.radius.tiny};
  background-color: ${({ theme }) => theme.color.white};
`;

export default PlainIconPicker;
