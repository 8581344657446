import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const ErrorWarning = (props: IconProps) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-740.000000, -480.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g id="c-warning-e" transform="translate(740.000000, 480.000000)">
            <path d="M8,0 C3.6,0 0,3.6 0,8 C0,12.4 3.6,16 8,16 C12.4,16 16,12.4 16,8 C16,3.6 12.4,0 8,0 Z M8,14 C4.7,14 2,11.3 2,8 C2,4.7 4.7,2 8,2 C11.3,2 14,4.7 14,8 C14,11.3 11.3,14 8,14 Z"></path>
            <rect x="7" y="4" width="2" height="5" rx="1"></rect>
            <circle cx="8" cy="11" r="1"></circle>
          </g>
        </g>
      </g>
    </Svg>
  );
};

ErrorWarning.defaultProps = {
  size: '16px',
  color: 'error',
  hoverColor: 'error',
};

export default ErrorWarning;
