import * as React from 'react';
import styled from 'styled-components';

import { Wrapper, Title, SelectedItem, DropdownPicker } from 'common/components';
import { DateString, PK, SelectOption } from 'common/types';
import { DATE_FORMATS, STATUSES } from 'common/consts';
import { useDnDList } from 'common/hooks';

export interface RelationOption extends SelectOption<PK> {
  status?: STATUSES;
  created?: DateString<DATE_FORMATS.DATABASE>;
  lastUpdate?: DateString<DATE_FORMATS.DATABASE>;
}

export enum SaveReason {
  ITEMS_ADDED,
  ITEMS_REMOVED,
  ITEMS_REORDERED,
}

export interface RelationSelectProps {
  options: RelationOption[];
  onSave: (values: RelationOption[], reason: SaveReason) => void;
  defaultValues?: RelationOption[];
  disableReorder?: boolean;
  disableDelete?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
}

// Declaring this value prevents useDnDList hook from unwanted rerenders.
const noValues: RelationOption[] = [];

const RelationSelect = ({
  options,
  onSave,
  defaultValues,
  isLoading,
  isMulti = true,
  disableReorder = false,
  disableDelete = false,
}: RelationSelectProps) => {
  const handleReorder = (values: RelationOption[]) => {
    onSave(values, SaveReason.ITEMS_REORDERED);
  };

  const {
    list: selected,
    getDraggableProps,
    reinit,
    removeItem,
  } = useDnDList<RelationOption>({
    initList: defaultValues || noValues,
    onReorder: handleReorder,
  });

  const handlePick = (values: RelationOption[]) => {
    reinit(values);
    onSave(values, SaveReason.ITEMS_ADDED);
  };

  const handleDelete = (position: number) => {
    removeItem(position);
    onSave(
      selected.filter((_, index) => index !== position),
      SaveReason.ITEMS_REMOVED,
    );
  };

  return (
    <>
      <Wrapper margin={[0, 0, 4]}>
        <DropdownPicker
          label="Search..."
          selected={selected}
          options={options}
          onPick={handlePick}
          isLoading={isLoading}
          isMulti={isMulti}
        />
      </Wrapper>
      <SelectedList>
        {selected.length > 0 && <Title size="standard">Selected</Title>}
        {selected.map(({ value, label, status, created, lastUpdate }, position) => (
          <SelectedItem
            key={value}
            {...getDraggableProps({
              label,
              status,
              created,
              lastUpdate,
              position,
              onDelete: disableDelete ? undefined : handleDelete,
              draggable: !disableReorder,
            })}
          />
        ))}
      </SelectedList>
    </>
  );
};

const SelectedList = styled.div`
  padding-bottom: ${({ theme }) => theme.spacer.times(5)};
`;

export default RelationSelect;
