import styled from 'styled-components';

import { ThemeColor, ThemeSpacer } from 'shared/theme';
import { resolveSpacersArray } from 'common/styles/utils';

type Props = {
  $width?: string;

  $rotate?: number;
  $size?: string;
  $color?: ThemeColor;
  $margin?: ThemeSpacer[];
};

const Arrow = styled.div<Props>`
  width: ${({ $size = '16px' }) => $size};
  height: ${({ $size = '16px' }) => $size};
  margin: ${({ theme, $margin = [0] }) => resolveSpacersArray(theme, $margin)};
  border-top: ${({ theme, $color = 'black', $width = '1px' }) =>
    `${$width || '1px'} solid ${theme.color[$color]}`};
  border-right: ${({ theme, $color = 'black', $width = '1px' }) =>
    `${$width || '1px'} solid ${theme.color[$color]}`};
  transform: ${({ $rotate = -135 }) => `rotate(${$rotate}deg)`};
  transform-origin: 65% 35%;
`;

export default Arrow;
