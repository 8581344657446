import * as React from 'react';
import styled from 'styled-components';

import { useDnDList } from 'common/hooks';
import { STATUSES } from 'common/consts';
import { resolveSpacersArray } from 'common/styles/utils';
import { Wrapper, SelectedItem, DropdownPicker } from 'common/components';
import { PlainCheckbox } from 'common/components/Checkbox';

import MiniSelectOption from './MiniSelectOption';
import { MiniOption } from './SeriesAssembler';
import DayContent from './DayContent';

type Props = {
  day: number;
  options: MiniOption[];
  defaultValues: MiniOption[];
  onUpdate: (day: number, minis: MiniOption[]) => void;
};

const SeriesDay = ({ day, options, defaultValues, onUpdate }: Props) => {
  const [activeOnly, setActiveOnly] = React.useState(false);
  const filteredOptions = React.useMemo(
    () =>
      activeOnly ? options.filter(({ status }) => status === STATUSES.ACTIVE) : options,
    [activeOnly, options],
  );

  const handleCheck = () => {
    setActiveOnly((activeOnly) => !activeOnly);
  };

  const handleUpdate = (minis: MiniOption[]) => {
    onUpdate(day, minis);
  };

  const { list: minis, getDraggableProps, reinit, removeItem } = useDnDList({
    initList: defaultValues,
    onReorder: handleUpdate,
  });

  const handlePick = (values: MiniOption[]) => {
    reinit(values);
    handleUpdate(values);
  };

  const handleDelete = (position: number) => {
    removeItem(position);
    handleUpdate(minis.filter((_, index) => index !== position));
  };

  return (
    <>
      {minis.map(
        ({ label, status, value, created, lastUpdate, punctation, isB2B }, position) => (
          <SelectedItem
            key={value}
            {...getDraggableProps({
              position,
              value,
              onDelete: handleDelete,
            })}
          >
            <DayContent
              label={label}
              status={status}
              created={created}
              lastUpdate={lastUpdate}
              punctation={punctation}
              isB2B={isB2B}
              isDivider={position !== 0}
            />
          </SelectedItem>
        ),
      )}
      <DayFooter>
        <PlainCheckbox
          name="active_only"
          label="Filter only published items"
          checked={activeOnly}
          onChange={handleCheck}
        />
        <Wrapper margin={[1, 0, 2]}>
          <DropdownPicker
            label="Search..."
            options={filteredOptions}
            selected={minis}
            onPick={handlePick}
            isMulti
          >
            <MiniSelectOption />
          </DropdownPicker>
        </Wrapper>
      </DayFooter>
    </>
  );
};

const DayFooter = styled.div`
  padding: ${({ theme }) => resolveSpacersArray(theme, [3, 3, 0])};
  border-top: 1px solid ${({ theme }) => theme.color.blueGrey};
`;

export default SeriesDay;
