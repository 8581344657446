import { useCallback, useEffect, useState } from 'react';

const STORAGE_KEY_NAME = 'is_cookie_accepted';
const DISCLAIMER_ID = 'cookie-disclaimer-qtcjz';
const DISCLAIMER_ACCEPT_ID = 'cookie-disclaimer-accept-h5vEs';

type CookieStore = {
  isCookieAccepted: boolean;
  setIsCookieAccepted: (isCookieAccepted: boolean) => void;
};

export const useCookieStore = (): CookieStore => {
  const [isCookieAccepted, setIsCookieAccepted] = useState(false);

  useEffect(() => {
    const clientSettings = localStorage.getItem(STORAGE_KEY_NAME);
    if (clientSettings === 'true') {
      setIsCookieAccepted(true);
    } else {
      setIsCookieAccepted(false);
    }
  }, []);

  const setCookieAcceptedWrapper = (isCookieAccepted: boolean): void => {
    if (isCookieAccepted) {
      localStorage.setItem(STORAGE_KEY_NAME, 'true');
    } else {
      localStorage.setItem(STORAGE_KEY_NAME, 'false');
    }

    setIsCookieAccepted(isCookieAccepted);
  };

  return { isCookieAccepted, setIsCookieAccepted: setCookieAcceptedWrapper };
};

export const useCookieStoreInit = () => {
  const { isCookieAccepted, setIsCookieAccepted } = useCookieStore();
  const acceptHandler = useCallback(() => setIsCookieAccepted(true), [
    setIsCookieAccepted,
  ]);

  // These are ID references because the cookie-disclaimer has to be added in plain HTML, outside of React
  const cookieDisclaimer = document.getElementById(DISCLAIMER_ID);
  const acceptButton = document.getElementById(DISCLAIMER_ACCEPT_ID);

  useEffect(() => {
    acceptButton?.addEventListener('click', acceptHandler);

    return () => acceptButton?.removeEventListener('click', acceptHandler);
  }, [acceptButton, acceptHandler]);

  useEffect(() => {
    if (isCookieAccepted && cookieDisclaimer) {
      cookieDisclaimer.parentNode?.removeChild(cookieDisclaimer);
    }
  }, [cookieDisclaimer, isCookieAccepted]);
};
