import * as React from 'react';
import { generatePath, matchPath, useHistory, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import appUrls from 'base/app/urls';
import { ScenarioListItem } from 'base/api/types';
import { Menu } from 'common/components/DropdownMenu';
import { useModal } from 'common/hooks';
import { Bin, Copy } from 'common/icons';
import { PK } from 'common/types';
import { toastMessages } from 'common/toastMessages';
import { useToast } from 'modules/toast';
import { CommonData, MenuComponentProps } from 'templates/content/const';

import { useScenarioDelete, useScenarioDuplicate } from '../queries';
import DeletionDialog from './DeletionDialog';

interface MenuProps extends MenuComponentProps<CommonData & Partial<ScenarioListItem>> {
  pk: PK;
}

const ScenariosMenu = ({ pk: scenarioPk, itemData }: MenuProps) => {
  const theme = useTheme();
  const history = useHistory();
  const { pathname } = useLocation();
  const removeScenario = useScenarioDelete();
  const duplicateScenario = useScenarioDuplicate();
  const { isOpen, open: openConfirmation, close } = useModal();
  const { toast } = useToast();

  const match = matchPath<{ pk: PK }>(pathname, {
    path: appUrls.SCENARIOS.DETAILS,
    exact: true,
  });
  const currentPagePk = match?.params.pk;

  const handleDuplicate = () => {
    duplicateScenario.mutate(
      { pk: scenarioPk },
      {
        onSuccess: ({ pk: newPk }) => {
          history.push(generatePath(appUrls.SCENARIOS.DETAILS, { pk: newPk }));
          toast(toastMessages.SUCCESS.GENERIC_SUCCESS, 'success');
        },
        onError: (error) => {
          toast(error.message, 'error');
        },
      },
    );
  };

  const deleteScenario = (pk: PK) => {
    removeScenario.mutate(
      { pk },
      {
        onSuccess: () => {
          if (pk === currentPagePk) {
            history.push(appUrls.SCENARIOS.LIST);
          }
          toast(toastMessages.SUCCESS.GENERIC_SUCCESS, 'success');
        },
        onError: (error) => {
          toast(error.message, 'error');
        },
      },
    );
  };

  const handleDelete = () => {
    if (itemData.programs_status === 'FINISHED' || (itemData.weeks_count || 0) > 0) {
      openConfirmation();
    } else {
      deleteScenario(scenarioPk);
    }
  };

  const handleDeleteConfirmation = (confirmed: boolean) => {
    if (confirmed) {
      deleteScenario(scenarioPk);
    }
  };

  const duplicateScenarioEntry = {
    isLoading: duplicateScenario.isLoading,
    isError: duplicateScenario.isError,
    label: 'Duplicate',
    icon: <Copy />,
    onClick: handleDuplicate,
  };
  const removeScenarioEntry = {
    isLoading: removeScenario.isLoading,
    isError: removeScenario.isError,
    label: 'Delete',
    icon: (
      <Bin color={theme.keys.secondaryColor} hoverColor={theme.keys.secondaryColor} />
    ),
    onClick: handleDelete,
  };

  const menuItems = [duplicateScenarioEntry];

  if (itemData.programs_status !== 'ACTIVE') {
    menuItems.push(removeScenarioEntry);
  }

  return (
    <>
      <Menu menuItems={menuItems} />
      {isOpen && (
        <DeletionDialog
          weekCount={itemData.weeks_count || 0}
          isOpen={isOpen}
          close={close}
          onClose={handleDeleteConfirmation}
        />
      )}
    </>
  );
};

export default ScenariosMenu;
