import * as React from 'react';

import { CoreProps, PK } from 'common/types';
import { STATUSES_OPTIONS } from 'common/consts';
import { Input, Title, Textarea, Select, ContentGoalSelect } from 'common/components';
import { TitleInput } from 'templates/details';
import {
  CoreFormLayout,
  FieldsGrid,
  GridItem,
  Section,
  SectionsWrapper,
} from 'templates/core';

import { useFunfactCreate, useFunfactUpdate } from '../queries';
import { fields, FunfactFields, validationSchema } from '../consts';

const useSave = (pk?: PK) => {
  const [created, setCreated] = React.useState(pk);
  const create = useFunfactCreate();
  const update = useFunfactUpdate();
  const isSaving = create.isLoading || update.isLoading;
  const error = create.error || update.error;

  const save = (values: FunfactFields) => {
    const payload = {
      title: values[fields.title.name],
      description: values[fields.description.name],
      content_goal_pks:
        values[fields.content_goals.name]?.map((option) => option.value) || [],
      status: values.status[0].value,
    };
    if (created) {
      update.mutate({
        ...payload,
        pk: created,
      });
    } else {
      create.mutate(payload, {
        onSuccess: ({ pk }) => {
          setCreated(pk);
        },
      });
    }
  };

  return { save, created, isSaving, error };
};

const FunfactCore = ({
  pk,
  defaultValues,
  children,
  layout = 'overview',
}: CoreProps<FunfactFields>) => {
  const { save, isSaving, error, created } = useSave(pk);

  return (
    <>
      <CoreFormLayout
        onSubmit={save}
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isSubmitting={isSaving}
        error={error}
      >
        {layout === 'overview' && (
          <Section>
            <TitleInput name={fields.title.name} />
          </Section>
        )}
        <Section>
          <FieldsGrid>
            <GridItem compact>
              <Select
                name={fields.status.name}
                options={STATUSES_OPTIONS}
                readOnly={!created}
              />
            </GridItem>
            <GridItem compact>
              <ContentGoalSelect
                isMulti
                name={fields.content_goals.name}
                label={fields.content_goals.label}
              />
            </GridItem>
          </FieldsGrid>
          {layout === 'creation' && (
            <GridItem>
              <Input name={fields.title.name} label={fields.title.label} />
            </GridItem>
          )}
        </Section>
        <Section>
          <Title marginBottom={3}>General</Title>
          <FieldsGrid>
            <GridItem>
              <Textarea name={fields.description.name} label={fields.description.label} />
            </GridItem>
          </FieldsGrid>
        </Section>
      </CoreFormLayout>
      <SectionsWrapper pk={created}>{children}</SectionsWrapper>
    </>
  );
};

export default FunfactCore;
