import * as React from 'react';
import { generatePath, useHistory } from 'react-router';

import appUrls from 'base/app/urls';
import { toastMessages } from 'common/toastMessages';
import { Copy } from 'common/icons';
import { PK } from 'common/types';
import { Menu } from 'common/components/DropdownMenu';
import { useToast } from 'modules/toast';

import { useSeriesDuplicate } from '../queries';

interface MenuProps {
  pk: PK;
}

const SeriesMenu = ({ pk }: MenuProps) => {
  const history = useHistory();
  const { toast } = useToast();

  const { mutate: duplicateSeries, isLoading } = useSeriesDuplicate();

  const handleDuplicate = () => {
    duplicateSeries(
      { pk },
      {
        onSuccess: ({ pk }) => {
          history.push(generatePath(appUrls.SERIES.DETAILS, { pk }));
          toast(toastMessages.SUCCESS.GENERIC_SUCCESS, 'success');
        },
        onError: (error) => {
          toast(error.message, 'error');
        },
      },
    );
  };

  const menuItems = [
    {
      isLoading,
      label: 'Duplicate',
      icon: <Copy />,
      onClick: handleDuplicate,
    },
  ];

  return <Menu menuItems={menuItems} />;
};

export default SeriesMenu;
