import * as React from 'react';
import { useTheme } from 'styled-components';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';
import { ThemeColor } from 'shared/theme';

interface B2BProps extends IconProps {
  color: ThemeColor;
  backgroundColor: ThemeColor;
}

const B2B = ({ size = '24px', color, backgroundColor, ...rest }: B2BProps) => {
  const theme = useTheme();
  const colorValue = theme.color[color];
  const backgroundColorValue = theme.color[backgroundColor];

  return (
    <Svg viewBox="0 0 24 24" size={size} color={backgroundColor} {...rest}>
      <rect width="24" height="24" rx="12" fill={backgroundColorValue} />
      <path
        d="M8.625 11.625V6.375H15.375V10.125"
        stroke={colorValue}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.875 13.125H6.375V17.625H10.875V13.125Z"
        stroke={colorValue}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.625 11.625H13.125V17.625H17.625V11.625Z"
        stroke={colorValue}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.875 15.375H9.375"
        stroke={colorValue}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.625 13.875H16.125"
        stroke={colorValue}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.625 15.375H16.125"
        stroke={colorValue}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.875 17.625H13.125"
        stroke={colorValue}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.875 8.625H13.125"
        stroke={colorValue}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default B2B;
