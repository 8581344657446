import * as React from 'react';

import BadgeCore from '../components/BadgeCore';
import { BadgeFields, CriteriaMinimumCountOptions, fields } from '../consts';
import { STATUSES_OPTIONS } from 'common/consts';
import { BadgeCollection } from 'base/api/types';
import { useBadgeCollectionsList } from '../queries';

const createBadgeDefaults = (collections: BadgeCollection[]): BadgeFields => ({
  [fields.status.name]: [STATUSES_OPTIONS[0]],
  [fields.title.name]: '',
  [fields.collection.name]: [collections[0]].map((item) => ({
    label: item.title,
    value: item.pk,
  })),
  [fields.criteria_minimum_count.name]: [CriteriaMinimumCountOptions[0]].map((item) => ({
    label: `${item}`,
    value: item,
  })),
  [fields.criteria_possible_pks.name]: [],
  [fields.descriptions_not_collected.name]: '',
  [fields.descriptions_in_progress.name]: '',
  [fields.descriptions_collected.name]: '',
  [fields.is_hidden.name]: false,
  [fields.images_inactive_url.name]: '',
  [fields.images_in_progress_url.name]: '',
  [fields.images_granted_url.name]: '',
});

const BadgesCreate = () => {
  const { data: collections } = useBadgeCollectionsList();
  const defaultValues = collections?.values && createBadgeDefaults(collections?.values);

  return (
    <>
      <BadgeCore defaultValues={defaultValues} layout="creation" isMultilevel />
    </>
  );
};

export default BadgesCreate;
