import Axios from 'axios';
import { StandardError } from 'base/api/errors';
import { useListQuery } from 'base/api/hooks';
import { Badge, BadgeCollection } from 'base/api/types';
import apiUrls from 'base/api/urls';
import { STATUSES } from 'common/consts';
import { CommonVars, PK } from 'common/types';
import { composeUrl } from 'common/utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const useBadgesList = () =>
  useListQuery<Badge>(apiUrls.BADGES.LIST, { isBusinessSensitive: true });

export const useBadgeCollectionsList = () =>
  useQuery<{ values: BadgeCollection[] }, StandardError>(apiUrls.BADGES.COLLECTIONS.LIST);

export const useBadgeDetails = (pk?: PK) => {
  const { data, ...rest } = useQuery<Badge, StandardError>([
    apiUrls.BADGES.DETAILS,
    { pk },
  ]);

  return { ...rest, data };
};

export type BadgeUpdateVars = Partial<
  {
    title: string;
    status: STATUSES;
    collection_pk: PK;
    descriptions: {
      not_collected: string;
      in_progress: string | null;
      collected: string;
    };
    images: {
      inactive_url: string;
      in_progress_url: string | null;
      granted_url: string;
    };
    is_hidden: boolean;
    criteria: {
      possible_pks: PK[] | null;
      minimum_count: number | null;
    };
  } & CommonVars
>;

export const useBadgeUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<Badge, StandardError, BadgeUpdateVars>(
    ({ pk, ...data }) =>
      Axios.patch(composeUrl(apiUrls.BADGES.DETAILS, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.BADGES.LIST);
        queryClient.setQueryData([apiUrls.BADGES.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

export type BadgeCreateVars = {
  title: string;
  collection_pk: PK;
  descriptions: {
    not_collected: string;
    in_progress: string | null;
    collected: string;
  };
  images: {
    inactive_url: string;
    in_progress_url: string | null;
    granted_url: string;
  };
  is_hidden: boolean;
  criteria: {
    possible_pks: PK[] | null;
    minimum_count: number | null;
  };
};

export const useBadgeCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<Badge, StandardError, BadgeCreateVars>(
    (data) => Axios.post(apiUrls.BADGES.MODIFY, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.BADGES.LIST);
        queryClient.invalidateQueries(apiUrls.CONTENT.LIST);
      },
    },
  );
};
