import * as React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
}

const DropdownContainer = React.forwardRef<HTMLDivElement, Props>(
  function DropdownContainer(props, ref) {
    const { children, isOpen, ...rest } = props;

    return (
      <SelectContainer ref={ref} isOpen={isOpen} {...rest}>
        {children}
      </SelectContainer>
    );
  },
);

const SelectContainer = styled.div<{ isOpen: boolean }>`
  position: relative;
  min-width: ${({ theme }) => theme.spacer.times(30)};

  ${({ theme, isOpen }) =>
    isOpen &&
    css`
      input {
        border-color: ${theme.color.blue};
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    `}
`;

export default DropdownContainer;
