import * as React from 'react';

import appUrls from 'base/app/urls';
import { Container, Wrapper } from 'common/components';
import {
  RecipeDetailsSwitch,
  CreateButton,
  RecipeTopBar,
} from 'modules/recipe-subsections/';
import { RECIPE_SUBSECTIONS } from 'modules/recipe-subsections/consts';
import {
  ContentProvider,
  ContentList,
  FiltersForm,
  Sort,
  SearchInput,
} from 'templates/content';

import { useIngredientsList } from '../queries';
import IngredientsDetails from './IngredientsDetails';
import IngredientsContentRow from '../components/IngredientsContentRow';

const fields = {
  search: 'search',
  sort: 'sort',
};

const IngredientsList = () => {
  const { data, isError } = useIngredientsList();
  const context = {
    data: data?.values.map(({ name_singular, ...ingredient }) => ({
      ...ingredient,
      title: name_singular,
    })),
    path: {
      list: appUrls.INGREDIENTS.LIST,
      details: appUrls.INGREDIENTS.DETAILS,
      create: appUrls.INGREDIENTS.CREATE,
    },
  };

  return (
    <Container>
      <ContentProvider {...context}>
        <RecipeTopBar subsection={RECIPE_SUBSECTIONS.INGREDIENTS}>
          <CreateButton path={appUrls.INGREDIENTS.CREATE}>New item</CreateButton>
          <FiltersForm sort={['title', fields.sort]} search={['title', fields.search]}>
            <Wrapper margin={[0, 0.5]}>
              <SearchInput name={fields.search} />
            </Wrapper>
            <Wrapper margin={[0, 0.5]}>
              <Sort name={fields.sort} />
            </Wrapper>
          </FiltersForm>
        </RecipeTopBar>
        <ContentList isError={isError}>{IngredientsContentRow}</ContentList>
        <RecipeDetailsSwitch>
          <IngredientsDetails />
        </RecipeDetailsSwitch>
      </ContentProvider>
    </Container>
  );
};

export default IngredientsList;
