import { isString } from 'common/utils';

export abstract class List<TData> {
  constructor(public data: TData[]) {}

  protected asc(field: keyof TData) {
    return (a: TData, b: TData): number => {
      const first = a[field];
      const second = b[field];
      if (isString(first) && isString(second)) {
        return first.localeCompare(second, 'nl', { sensitivity: 'base' });
      } else {
        return 0;
      }
    };
  }

  protected desc(field: keyof TData) {
    return (a: TData, b: TData): number => {
      const first = a[field];
      const second = b[field];
      if (isString(first) && isString(second)) {
        return second.localeCompare(first, 'nl', { sensitivity: 'base' });
      } else {
        return 0;
      }
    };
  }

  sort(isSortDesc: boolean, field?: keyof TData): this {
    if (field) {
      this.data.sort(isSortDesc ? this.desc(field) : this.asc(field));
    }
    return this;
  }

  escapeRegExp(search: string): string {
    return search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters
  }

  search(search: string, field?: keyof TData): this {
    const safeSearch = this.escapeRegExp(search);
    const regexp = new RegExp(safeSearch, 'i');
    if (search && field) {
      this.data = this.data.filter((item): boolean => {
        const searchField = item[field];
        if (isString(searchField)) {
          return searchField.search(regexp) !== -1;
        } else {
          return true;
        }
      });
    }
    return this;
  }

  filter(filters?: { value: string }[], field?: keyof TData): this {
    if (filters && filters.length > 0 && field) {
      this.data = this.data.filter((item): boolean => {
        const filterField = item[field];
        if (isString(filterField)) {
          return !!filters.find(({ value }) => value === filterField);
        } else {
          return true;
        }
      });
    }
    return this;
  }
}

export class ContentList<TData> extends List<TData> {}

export interface ListConstructor<TData, TIinstance extends List<TData>> {
  new (data: TData[]): TIinstance;
}

export function createContentList<TData, TIinstance extends List<TData>>(
  ctor: ListConstructor<TData, TIinstance>,
) {
  return (data: TData[]) => new ctor(data);
}
