import * as React from 'react';
import styled from 'styled-components';

import { LOGO_VARIANTS } from 'common/consts';
import actifyTextLogo from 'assets/logos/actify_text_logo.svg';
import actifyLogo from 'assets/logos/actify_logo.svg';
import frieslandTextLogo from 'assets/logos/friesland_text_logo.svg';
import frieslandLogo from 'assets/logos/friesland_logo.svg';
import { getIsFriesland } from 'common/utils/config';

type Props = {
  variant: LOGO_VARIANTS;
  size?: string;
  height?: string;
  center?: boolean;
};

const Logo = (props: Props) => {
  const { height, size, variant, center } = props;
  const projectLogo = getLogo(variant);
  return (
    <LogoWrapper
      projectLogo={projectLogo}
      $height={height}
      $size={size}
      variant={variant}
      center={center}
    />
  );
};

type LogoWrapperProps = {
  projectLogo: string;
  $height?: string;
  $size?: string;
  variant: LOGO_VARIANTS;
  center?: boolean;
};

const LogoWrapper = styled.div<LogoWrapperProps>`
  width: ${({ variant, $size }) =>
    $size ? $size : variant === LOGO_VARIANTS.TEXT ? '150px' : '32px'};
  height: ${({ $height, variant }) =>
    $height ? $height : variant === LOGO_VARIANTS.TEXT ? '38px' : '32px'};
  background-image: ${({ projectLogo }) => `url(${projectLogo})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  margin: ${({ center }) => (center ? '0 auto' : '0')};
`;

const getLogo = (variant: LOGO_VARIANTS) => {
  const isFriesland = getIsFriesland();

  if (isFriesland) {
    if (variant === LOGO_VARIANTS.TEXT) {
      return frieslandTextLogo;
    } else {
      return frieslandLogo;
    }
  } else if (variant === LOGO_VARIANTS.TEXT) {
    return actifyTextLogo;
  } else {
    return actifyLogo;
  }
};

export default Logo;
