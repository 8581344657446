export const TOAST_SUCCESS_TITLE = 'Yes! Success!';
export const TOAST_ERROR_TITLE = 'Error';

export const toastMessages = {
  ERROR: {
    GENERIC_ERROR: 'Oops, something went wrong...',
    CHANGING_STATUS_WITHOUT_TAGS: 'You have to add Tags before changing status to ACTIVE',
    EMPTY_HABIT_LEVEL_DESCRIPTION: 'Habit level description can not be empty',
    EMPTY_DAY_IN_HABITS:
      'Each habits day requires at least one mini. To replace the last one, search for a new mini and then remove the old one.',
    INACTIVE_DAY_IN_HABITS: 'A habit day must contain only active minis.',
    TOOLONG_HABIT_LEVEL_DESCRIPTION:
      'Habit level description must contain up to 50 characters',
  },

  SUCCESS: {
    GENERIC_SUCCESS: 'Success!',
  },
} as const;
