import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { BUTTON_SIZES, BUTTON_VARIANTS } from 'common/consts';
import { IconProps } from 'common/types';

import { Button, Spinner, Wrapper } from '..';
import { Cross } from 'common/icons';

export interface MenuItemProps {
  label: string;
  icon: JSX.Element;
  isLoading?: boolean;
  isError?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const MenuItem = ({ label, icon, isLoading, isError, onClick }: MenuItemProps) => {
  const theme = useTheme();

  return (
    <StyledButton
      variant={BUTTON_VARIANTS.TEXT}
      size={BUTTON_SIZES.SMALL}
      onClick={onClick}
    >
      <Wrapper padding={[0, 1]}>
        {isLoading ? (
          <Spinner size="16px" circleColor="pink" color="pinkSuperLight" />
        ) : isError ? (
          <Cross size="16px" color="pink" />
        ) : (
          React.cloneElement<IconProps>(icon, {
            size: '16px',
            color: theme.keys.secondaryColor,
          })
        )}
      </Wrapper>
      <span>{label}</span>
    </StyledButton>
  );
};

const clickablePadding = 1;
const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${({ theme }) => theme.spacer.times(5)};
  width: calc(100% + ${({ theme }) => theme.spacer.times(2 * clickablePadding)});
  padding: ${({ theme }) => theme.spacer.times(clickablePadding)};
  margin: ${({ theme }) => theme.spacer.times(-clickablePadding)};
`;

export default MenuItem;
