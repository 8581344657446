import * as React from 'react';

import { Title } from 'common/components';
import { CoreProps, PK } from 'common/types';
import { CoreFormLayout, Section } from 'templates/core';

import { useHabitsCreate, useUpdateHabitLevel } from '../queries';
import { HabitsFields, validationSchema } from '../consts';
import HabitsLevel from './HabitsLevel';
import { useToast } from 'modules/toast';
import { toastMessages } from 'common/toastMessages';

export interface desctiptionToChange {
  lvl: number;
  description: string;
}

const useSave = (pk?: PK, newDescription?: desctiptionToChange[]) => {
  const [created] = React.useState(pk);
  const create = useHabitsCreate();
  const update = useUpdateHabitLevel();
  const isSaving = create.isLoading || update.isLoading;
  const error = create.error || update.error;

  const save = () => {
    newDescription?.map((descriptionsToChange) => {
      const payload = {
        description: descriptionsToChange.description,
        lvl: descriptionsToChange.lvl,
        pk: created,
      };

      update.mutate(payload);
    });
  };

  return { save, created, isSaving, error };
};

const HabitsCore = ({ pk, defaultValues }: CoreProps<HabitsFields>) => {
  const [newDescription, setNewDescription] = React.useState<desctiptionToChange[]>([]);

  const { toast } = useToast();

  const showToasts = (newDescription: desctiptionToChange[]) => {
    newDescription?.map((descriptionsToChange) => {
      if (descriptionsToChange.description === '') {
        toast(toastMessages.ERROR.EMPTY_HABIT_LEVEL_DESCRIPTION, 'error');
        return;
      }
      if (descriptionsToChange.description?.length >= 50) {
        toast(toastMessages.ERROR.TOOLONG_HABIT_LEVEL_DESCRIPTION, 'error');
        return;
      }
    });
  };

  const { save, isSaving, error } = useSave(pk, newDescription);

  return (
    <>
      <CoreFormLayout
        onSubmit={() => save()}
        onButtonClick={() => showToasts(newDescription)}
        isSubmitting={isSaving}
        error={error}
        validationSchema={validationSchema}
        defaultValues={defaultValues}
      >
        <Section>
          <Title marginBottom={2.5}>{defaultValues?.description}</Title>
          {defaultValues?.levels?.map((mappedLevel, index) => {
            return HabitsLevel(pk, index, mappedLevel, setNewDescription);
          })}
        </Section>
      </CoreFormLayout>
    </>
  );
};

export default HabitsCore;
