import * as React from 'react';

import { PK } from 'common/types';

import { useSaveMinisBlog } from '../hooks';
import BlogPicker from './BlogPicker';
import { useMiniDetails } from 'modules/minis/queries';

type Props = {
  pk?: PK;
};

const MinisBlogPicker = ({ pk }: Props) => {
  const { data } = useMiniDetails(pk);
  const blog_pk = data?.blog_pk;
  const { save, update, isLoading, isError, isSuccess } = useSaveMinisBlog(pk);
  const saveStatus = { isLoading, isError, isSuccess };

  return (
    <BlogPicker
      pk={pk}
      blog_pk={blog_pk}
      onBlogUpdate={update}
      onSave={save}
      saveStatus={saveStatus}
    />
  );
};

export default MinisBlogPicker;
