import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { useFormContext, useWatch } from 'react-hook-form';
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import en from 'date-fns/locale/en-GB';
import 'react-datepicker/dist/react-datepicker.css';

import { DATE_FORMATS } from 'common/consts';
import { resolveSpacersArray } from 'common/styles/utils';
import { FormError } from 'common/components';
import { Calendar } from 'common/icons';

registerLocale('en-GB', en);

export interface DatePickerProps extends Partial<ReactDatePickerProps> {
  name: string;
  label?: string;
  dateFormat?: DATE_FORMATS;
  children?: React.ReactNode;
}

const DatePicker = ({
  name,
  label,
  dateFormat = DATE_FORMATS.HUMAN,
  children,
  ...props
}: DatePickerProps) => {
  const { register, unregister, errors, setValue: setFormValue } = useFormContext<
    Record<string, Date>
  >();
  const error = errors[name];
  const isError = !!error;
  const value = useWatch<Date | null>({ name }) || null;

  const handleChange = (date: Date) => {
    setFormValue(name, date, { shouldValidate: true });
  };

  React.useEffect(() => {
    register(name);
    return () => unregister(name);
  }, [name, register, unregister]);

  return (
    <>
      <DatePickerWrapper>
        <ReactDatePicker
          dateFormat={dateFormat}
          selected={value}
          onChange={handleChange}
          locale={en}
          {...props}
          showPopperArrow={false}
          calendarClassName="av-date-picker"
          dayClassName={() => 'av-date-picker__day'}
          popperPlacement="top-end"
          popperModifiers={{
            offset: {
              enabled: true,
              offset: '-24px, -50px',
            },
          }}
          customInput={
            <CustomInput
              label={label}
              name={name}
              isError={isError}
              disabled={props.disabled}
            />
          }
        >
          {children}
        </ReactDatePicker>
      </DatePickerWrapper>
      {error?.message && <FormError message={error.message} />}
    </>
  );
};

type CustomInputProps = {
  name: string;
  label?: string;
  isError?: boolean;
  disabled?: boolean;
};

const CustomInput = React.forwardRef<HTMLLabelElement, CustomInputProps>(
  ({ name, label, isError, disabled, ...props }, ref) => {
    const theme = useTheme();

    return (
      <InputContainer
        ref={ref}
        isError={isError}
        style={{
          opacity: disabled ? 0.25 : 1.0,
        }}
      >
        <InputIcon>
          <Calendar
            size="22px"
            title="calendar"
            color={theme.keys.secondaryColor}
            hoverColor={theme.keys.secondaryColor}
          />
        </InputIcon>
        <input name={name} {...props} placeholder="dd-mm-yyyy" readOnly />
        <span>{label}</span>
      </InputContainer>
    );
  },
);
CustomInput.displayName = 'CustomInput';

const InputContainer = styled.label<Pick<CustomInputProps, 'isError'>>`
  display: block;
  position: relative;
  cursor: pointer;

  input {
    appearance: none;
    width: 100%;
    padding: ${({ theme }) => resolveSpacersArray(theme, [3.5, 3, 1.5, 10])};
    border: 1px solid
      ${({ theme, isError }) =>
        isError ? theme.color.error : theme.themeColor.tertiaryLightColor};
    border-radius: ${({ theme }) => theme.border.radius.tiny};
    font-family: ${({ theme }) => theme.font.family.sans};
    font-size: ${({ theme }) => theme.font.size.small};
    font-weight: ${({ theme }) => theme.font.weight.normal};
    line-height: 22px;
    background-color: ${({ theme }) => theme.color.white};

    & + span {
      position: absolute;
      top: ${({ theme }) => theme.spacer.times(1.5)};
      left: ${({ theme }) => theme.spacer.times(10)};
      color: ${({ theme, isError }) =>
        isError ? theme.color.error : theme.color.greyDark};
      font-size: ${({ theme }) => theme.font.size.tiny};
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }

    &::placeholder {
      color: ${({ theme }) => theme.color.greyDark};
    }

    &:read-only {
      background-color: transparent;
      color: ${({ theme }) => theme.color.black};
    }

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.themeColor.tertiarySuperDarkColor};

      & + span {
        color: ${({ theme }) => theme.themeColor.tertiarySuperDarkColor};
      }
    }
  }
`;

const InputIcon = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${({ theme }) => theme.spacer.times(8)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${({ theme }) => theme.color.blueLight};
`;

export const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .av-date-picker {
    background-color: white;
    padding: ${({ theme }) => theme.spacer.standard};
    border: none;
    border-radius: ${({ theme }) => theme.border.radius.tiny};
    font-family: ${({ theme }) => theme.font.family.sans};
    box-shadow: 0 0 25px -15px ${({ theme }) => theme.color.grey};

    .react-datepicker__header {
      margin: 14px;
      padding: 0;
      background-color: transparent;
      border-bottom: 1px solid ${({ theme }) => theme.color.blueLight};
    }

    .react-datepicker__current-month {
      font-size: ${({ theme }) => theme.font.size.small};
    }

    .react-datepicker__day-names {
      display: flex;
      justify-content: space-between;
      height: auto;
      padding: 32px 0 14px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }

    .react-datepicker__day-name {
      margin: 0;
      width: auto;
      height: auto;
      line-height: 1;
      font-size: ${({ theme }) => theme.font.size.small};
    }

    .react-datepicker__navigation {
      height: 8px;
      width: 8px;
      border: none;
      top: ${({ theme }) => theme.spacer.times(2)};
      padding: ${({ theme }) => theme.spacer.times(2)};
      border-radius: 50%;

      &:before {
        content: '';
        display: block;
        height: 8px;
        width: 8px;
        position: relative;
        bottom: 4px;
        right: 4px;
        border-right: 2px solid ${({ theme }) => theme.themeColor.primaryColor};
        border-top: 2px solid ${({ theme }) => theme.themeColor.primaryColor};
      }

      &:hover {
        background-color: ${({ theme }) => theme.components.datePicker.primaryBgColor};
      }

      &:focus {
        outline: none;
      }

      &--previous {
        transform: rotate(-135deg);
        left: 17px;
      }

      &--next {
        transform: rotate(45deg);
        right: 17px;
      }
    }
  }

  .av-date-picker__day {
    margin: 4px;
    color: ${({ theme }) => theme.color.greyDark};
    font-weight: ${({ theme }) => theme.font.weight.normal};
    font-size: ${({ theme }) => theme.font.size.tiny};
    line-height: ${({ theme }) => theme.spacer.times(4)};
    width: ${({ theme }) => theme.spacer.times(4)};
    border-radius: 50%;

    &.react-datepicker__day--keyboard-selected {
      background-color: transparent;
    }

    &.react-datepicker__day--selected {
      background-color: ${({ theme }) => theme.color.greenSuperLight};
      color: ${({ theme }) => theme.color.greenDark};

      &.react-datepicker__day--today {
        color: ${({ theme }) => theme.color.greenDark};
      }
    }

    &.react-datepicker__day--disabled {
      color: ${({ theme }) => theme.color.grey};
    }

    &.react-datepicker__day--today {
      color: ${({ theme }) => theme.color.greenDark};
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.greenSuperLight};
    }

    &:focus {
      outline: none;
    }
  }
`;

export default DatePicker;
