import { ThemeSpacer } from 'shared/theme';
import { resolveSpacer } from 'common/styles/utils';
import styled from 'styled-components';

type Props = {
  src: string;
  height: string;
  marginBottom?: ThemeSpacer;
};

const Graphics = styled.div<Props>`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: ${({ height }) => height || '100%'};
  width: 100%;
  margin-bottom: ${({ theme, marginBottom }) => resolveSpacer(theme, marginBottom)};
`;

export default Graphics;
