import { composeHandlers, isNumber } from 'common/utils';
import { useCallback, useEffect, useReducer } from 'react';

type ItemProps = {
  isOver?: boolean;
  isDragging?: boolean;
};

type ItemWithProps<TItem> = TItem & ItemProps;

export type ReorderHandler<TItem> = (
  list: ItemWithProps<TItem>[],
  draggingItem: number,
  drop: number,
) => void;
type DragHandler = (event: React.DragEvent<any>) => void;

type Config<TItem> = {
  initList?: TItem[];
  onReorder?: ReorderHandler<TItem>;
  disabled?: boolean;
};

export type DraggableProps<TRest> = {
  position: number;
  draggable?: boolean | 'true' | 'false';
  isDragging?: boolean;
  isOver?: boolean;
  onDrop?: DragHandler;
  onDragStart?: DragHandler;
  onDragOver?: DragHandler;
  onDragLeave?: DragHandler;
  onDragEnd?: DragHandler;
} & TRest;

type State<TItem> = {
  list: ItemWithProps<TItem>[];
  isDragging: boolean;
  draggingItem: number | null;
  overItem: number | null;
  _previewItem: number | null;
};

enum ACTION_TYPE {
  DROP = 'DROP',
  DRAG_START = 'DRAG_START',
  DRAG_OVER = 'DRAG_OVER',
  DRAG_LEAVE = 'DRAG_LEAVE',
  DRAG_END = 'DRAG_END',
  ADD_ITEMS = 'ADD_ITEMS',
  REMOVE_ITEM = 'REMOVE_ITEM',
  REINITIALIZE = 'REINITIALIZE',
}

type Actions<TItem> =
  | { type: ACTION_TYPE.DROP; position: number }
  | { type: ACTION_TYPE.DRAG_START; position: number }
  | { type: ACTION_TYPE.DRAG_OVER; position: number }
  | { type: ACTION_TYPE.DRAG_LEAVE; position: number }
  | { type: ACTION_TYPE.DRAG_END }
  | { type: ACTION_TYPE.ADD_ITEMS; items: TItem[] }
  | { type: ACTION_TYPE.REMOVE_ITEM; index: number }
  | { type: ACTION_TYPE.REINITIALIZE; initList: TItem[] };

function updateItemArray<TItem>(array: TItem[], index: number, props: ItemProps) {
  return array.map((item, currentIndex) => {
    if (index === currentIndex) {
      return {
        ...item,
        ...props,
      };
    } else {
      return item;
    }
  });
}

type ListReducer<TItem> = (state: State<TItem>, action: Actions<TItem>) => State<TItem>;

const initialState = {
  list: [],
  isDragging: false,
  draggingItem: null,
  overItem: null,
  _previewItem: null,
};

const itemInitialState: ItemProps = {
  isDragging: false,
  isOver: false,
};

function reducer<TItem>(state: State<TItem>, action: Actions<TItem>) {
  switch (action.type) {
    case ACTION_TYPE.REINITIALIZE: {
      return {
        ...state,
        list: action.initList.map((item) => ({
          ...item,
          ...itemInitialState,
        })),
      };
    }
    case ACTION_TYPE.ADD_ITEMS: {
      const { items } = action;
      const list = [...state.list];
      items.forEach((item) => {
        list.push({ ...item, ...itemInitialState });
      });
      return {
        ...state,
        list,
      };
    }
    case ACTION_TYPE.REMOVE_ITEM: {
      return {
        ...state,
        list: state.list.filter((_, index) => index !== action.index),
      };
    }
    case ACTION_TYPE.DRAG_START: {
      const drag = action.position;

      return {
        ...state,
        list: updateItemArray(state.list, drag, { isDragging: true }),
        isDragging: true,
        draggingItem: drag,
        _previewItem: drag,
      };
    }
    case ACTION_TYPE.DRAG_OVER: {
      const preview = state._previewItem;
      const over = action.position;

      let list = [...state.list];
      if (isNumber(preview) && preview !== over) {
        const itemDragged = { ...state.list[preview], isDragging: true };
        const remainingItems = state.list.filter((_, index) => index !== preview);
        list = [
          ...remainingItems.slice(0, over),
          itemDragged,
          ...remainingItems.slice(over),
        ];
      }

      return {
        ...state,
        list: updateItemArray(
          list.map((item) => ({ ...item, isOver: false })),
          over,
          { isOver: true },
        ),
        overItem: over,
        _previewItem: over,
      };
    }
    case ACTION_TYPE.DROP: {
      const { position: drop } = action;

      if (state.draggingItem === drop) {
        return {
          ...state,
          ...initialState,
          list: state.list,
        };
      }

      const list = updateItemArray(state.list, drop, itemInitialState);
      return {
        ...state,
        ...initialState,
        list,
      };
    }
    case ACTION_TYPE.DRAG_LEAVE: {
      return {
        ...state,
        overItem: null,
        list: updateItemArray(state.list, action.position, { isOver: false }),
      };
    }
    case ACTION_TYPE.DRAG_END: {
      return {
        ...state,
        ...initialState,
        list: state.list.map((item) => ({
          ...item,
          ...itemInitialState,
        })),
      };
    }
    default:
      return state;
  }
}

function initState<TItem>(initList: TItem[] = []) {
  return (state: State<TItem>) => ({
    ...state,
    list: initList.map((item) => ({
      ...item,
      ...itemInitialState,
    })),
  });
}

const defaultConfig = {
  initList: [],
};

// eslint-disable-next-line @typescript-eslint/ban-types
function useDnDList<TItem extends object>({
  initList,
  onReorder,
  disabled,
}: Config<TItem> = defaultConfig) {
  const init = initState(initList);
  const [{ list, ...state }, dispatch] = useReducer<ListReducer<TItem>, State<TItem>>(
    reducer,
    initialState,
    init,
  );

  const handleDragStart: DragHandler = (event) => {
    const position = Number(event.currentTarget.dataset.position);
    if (isNaN(position)) {
      throw new Error("Missing 'position' prop in getDraggableProps function");
    }

    dispatch({
      type: ACTION_TYPE.DRAG_START,
      position: Number(event.currentTarget.dataset.position),
    });
  };

  const handleDragOver: DragHandler = (event) => {
    event.preventDefault();
    const position = Number(event.currentTarget.dataset.position);
    if (isNaN(position)) {
      throw new Error("Missing 'position' prop in getDraggableProps function");
    }

    if (position !== state.overItem && state.isDragging) {
      dispatch({
        type: ACTION_TYPE.DRAG_OVER,
        position,
      });
    }
  };

  const handleDrop: DragHandler = (event) => {
    const position = Number(event.currentTarget.dataset.position);
    if (state.isDragging) {
      dispatch({ type: ACTION_TYPE.DROP, position });
    }
    if (isNumber(state.draggingItem) && state.draggingItem !== position) {
      onReorder?.(
        updateItemArray(list, position, itemInitialState),
        state.draggingItem,
        position,
      );
    }
  };

  const handleDragLeave: DragHandler = (event) => {
    dispatch({
      type: ACTION_TYPE.DRAG_LEAVE,
      position: Number(event.currentTarget.dataset.position),
    });
  };

  const handleDragEnd = () => {
    dispatch({ type: ACTION_TYPE.DRAG_END });
  };

  const handleAddItems = (items: TItem[]) => {
    dispatch({ type: ACTION_TYPE.ADD_ITEMS, items });
  };

  const handleRemoveItem = (index: number) => {
    dispatch({ type: ACTION_TYPE.REMOVE_ITEM, index });
    return list[index];
  };

  const handleReinit = useCallback((initList: TItem[]) => {
    dispatch({ type: ACTION_TYPE.REINITIALIZE, initList });
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-types
  function getDraggableProps<TRest extends object>({
    position,
    onDrop,
    onDragStart,
    onDragOver,
    onDragLeave,
    onDragEnd,
    ...props
  }: DraggableProps<TRest>) {
    return {
      draggable: disabled ? 'false' : 'true',
      'data-position': position,
      onDrop: composeHandlers(handleDrop, onDrop),
      onDragStart: composeHandlers(handleDragStart, onDragStart),
      onDragOver: composeHandlers(handleDragOver, onDragOver),
      onDragLeave: composeHandlers(handleDragLeave, onDragLeave),
      onDragEnd: composeHandlers(handleDragEnd, onDragEnd),
      isDragging: state._previewItem === position,
      isOver: state.overItem === position,
      position,
      ...props,
    } as DraggableProps<TRest>;
  }

  useEffect(() => {
    if (initList) {
      handleReinit(initList);
    }
  }, [handleReinit, initList]);

  return {
    list,
    listState: state,
    getDraggableProps,
    reinit: handleReinit,
    addItems: handleAddItems,
    removeItem: handleRemoveItem,
  };
}

export default useDnDList;
