import * as React from 'react';

import { Title } from 'common/components';
import { Section } from 'templates/core';
import FunfactCore from '../components/FunfactCore';
import FunfactScheduler from '../components/FunfactScheduler';
import { STATUSES, STATUSES_OPTIONS } from 'common/consts';

const defaultValues = {
  status: STATUSES_OPTIONS.filter(({ value }) => value === STATUSES.DRAFT),
};

const FunfactsCreate = () => {
  return (
    <>
      <Section>
        <Title size="big">Create Fun fact</Title>
      </Section>
      <FunfactCore defaultValues={defaultValues} layout="creation">
        <FunfactScheduler />
      </FunfactCore>
    </>
  );
};

export default FunfactsCreate;
