import * as React from 'react';
import { useParams } from 'react-router';

import { RecipesNutritionalValue } from 'base/api/types';
import { PK } from 'common/types';
import { DetailsCard, DetailsSuspense } from 'templates/details';

import NutritionalValuesCore from '../components/NutritionalValuesCore';
import { fields, NutritionalValuesFields } from '../consts';
import { useNutritionalValuesDetails } from '../queries';

const NutritionalValuesDetails = () => {
  const { pk } = useParams<{ pk: PK }>();
  const { data, ...query } = useNutritionalValuesDetails(pk);
  const transformNutritionalValues = (
    raw: RecipesNutritionalValue,
  ): NutritionalValuesFields => ({
    [fields.name.name]: raw.name,
    [fields.unit.name]: [
      {
        label: raw.unit.name_singular,
        value: raw.unit.pk,
      },
    ],
  });
  const defaultValues = data && transformNutritionalValues(data);

  return (
    <DetailsCard created={data?.created_at} lastUpdate={data?.modified_at}>
      <DetailsSuspense isLoading={query.isLoading} error={query.error}>
        <NutritionalValuesCore pk={pk} key={pk} defaultValues={defaultValues} />
      </DetailsSuspense>
    </DetailsCard>
  );
};

export default NutritionalValuesDetails;
