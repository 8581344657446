import * as React from 'react';
import styled from 'styled-components';

import { MediaObject } from 'base/api/types';
import { useAutoSize } from 'common/hooks';
import { ModalProps } from 'common/components/Modal';
import { resolveSpacersArray } from 'common/styles/utils';
import { BUTTON_VARIANTS, MEDIA_OPTIONS, MEDIA_TYPES } from 'common/consts';
import { Cross } from 'common/icons';
import { Button, Modal, Row, Select, Title, Wrapper } from 'common/components';
import { ContentProvider, FiltersForm, SearchInput } from 'templates/content';

import MediaPreviewCard from '../components/MediaPreviewCard';
import MediaGrid from '../components/MediaGrid';
import { useMediaList } from '../queries';

const fields = {
  search: 'search',
  type: 'type',
};

interface Props extends ModalProps {
  mediaType: MEDIA_TYPES;
  fileType?: string | string[];
  onPick: (item: MediaObject) => void;
}

const MediaLibraryPicker = ({ isOpen, close, onPick, mediaType, fileType }: Props) => {
  const { data, error } = useMediaList();
  const context = {
    data: data?.values.filter((object) => {
      /**
       * NOTE Do file-extension filtering
       *      Could be moved to the backend when there's more than one small use-case for it
       */
      if (typeof fileType === 'string') {
        return object.url.toLowerCase().endsWith(fileType);
      } else if (fileType instanceof Array) {
        return !!fileType.find((match) => object.url.toLowerCase().endsWith(match));
      }

      return true;
    }),
  };
  const sizes = useAutoSize([26 * 8, 12 * 8]);
  const width = Math.min(sizes.width, 1200);
  const options = MEDIA_OPTIONS.filter(
    ({ type }) => type === mediaType,
  ).map((option) => ({ ...option, value: option.type }));
  const defaultValues = {
    [fields.type]: options,
  };

  const handleClose = () => {
    close && close();
  };

  const handlePick = (item: MediaObject) => {
    onPick(item);
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} close={handleClose} width={1200}>
      <ModalContainer>
        <ContentProvider<MediaObject> {...context}>
          <Header>
            <Title>Pick media</Title>
            <Wrapper margin={[0, 3, 0, 'auto']}>
              <Row>
                <FiltersForm<MediaObject>
                  search={['name', fields.search]}
                  filter={['media_type', fields.type]}
                  defaultValues={defaultValues}
                >
                  <Wrapper margin={[0, 0.5]}>
                    <Select
                      name={fields.type}
                      placeholder="All types"
                      options={options}
                      isMulti
                      readOnly
                    />
                  </Wrapper>
                  <Wrapper margin={[0, 0.5]}>
                    <SearchInput name={fields.search} />
                  </Wrapper>
                </FiltersForm>
              </Row>
            </Wrapper>
            <Button onClick={handleClose} variant={BUTTON_VARIANTS.ICON}>
              <Cross color="pink" title="close" size="20px" />
            </Button>
          </Header>
          <MediaGrid width={width} height={sizes.height} error={error}>
            {(props) => <MediaPreviewCard {...props} onClick={handlePick} />}
          </MediaGrid>
        </ContentProvider>
      </ModalContainer>
    </Modal>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => resolveSpacersArray(theme, [3, 5])};
  background-color: ${({ theme }) => theme.color.white};
  border-top-left-radius: ${({ theme }) => theme.border.radius.standard};
  border-top-right-radius: ${({ theme }) => theme.border.radius.standard};
`;

const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.color.greyLightBlue};
  border-radius: ${({ theme }) => theme.border.radius.standard};
`;

export default MediaLibraryPicker;
