import * as React from 'react';

import { Svg } from 'common/components';
import { IconProps } from 'common/types';

const EyeCrossedFilled = ({ size = '24px', ...rest }: IconProps) => {
  return (
    <Svg viewBox="0 0 25 24" size={size} {...rest}>
      <path
        d="M23.446 10.227a22.942 22.942 0 0 0-2.492-2.948l-4.02 4.02c.038.228.061.458.068.689a5 5 0 0 1-5 5c-.23-.007-.46-.03-.688-.07l-3.257 3.258c1.249.528 2.59.804 3.945.812 5.708 0 9.905-5.062 11.445-7.24a3.058 3.058 0 0 0 0-3.521zM12.002 2.988c-5.708 0-9.9 5.062-11.445 7.24a3.058 3.058 0 0 0 0 3.52 21.273 21.273 0 0 0 4.784 4.9l3.124-3.124a5 5 0 0 1 7.071-7.072l-7.07 7.072 10.2-10.2a11.484 11.484 0 0 0-6.664-2.336z"
        fill="currentColor"
      />
      <path
        d="M1.002 23.988a1 1 0 0 1-.707-1.707l22-22a1 1 0 0 1 1.414 1.414l-22 22a1 1 0 0 1-.707.293z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default EyeCrossedFilled;
