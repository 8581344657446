import * as Yup from 'yup';

import { RelationOption } from 'common/components/RelationSelect';
import { PK } from 'common/types';

interface SpecifiedType {
  name: string;
  amount: number;
}

export interface NutritionalValuesType {
  pk: PK;
  name: string;
  amount: number;

  specified?: SpecifiedType[];
}

export interface NutritionalValuesFields {
  nutritional_values: NutritionalValuesType[];
  nutritional_values_formatted: string;
}

export type NutritionalValuesOption = RelationOption;

export const fieldNames = {
  nutritionalValues: 'nutritional_values',
  nutritionalValuesFormatted: 'nutritional_values_formatted',
} as const;

export const schema = Yup.object().shape({
  [fieldNames.nutritionalValues]: Yup.array(
    Yup.object().shape({
      amount: Yup.number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .required()
        .moreThan(0)
        .label('Amount'),
      specified: Yup.array(
        Yup.object().shape({
          name: Yup.string().required().label('Name'),
          amount: Yup.number()
            .transform((value) => (Number.isNaN(value) ? undefined : value))
            .required()
            .moreThan(0)
            .label('Amount'),
        }),
      ),
    }),
  ),
});

export const NUTRITIONAL_VALUE_PLACEHOLDER_NAME = '[...]';
