import * as React from 'react';

import { CollapsibleSection } from 'common/components';
import { PK } from 'common/types';
import { useWorkoutsTags, useWorkoutsTagsUpdate } from '../queries';
import { TAGGING_CONTENT_TYPES } from 'modules/tagging/consts';
import { workoutTagsValidationSchema } from '../consts';
import { TagsForm } from 'modules/tagging/components';

type Props = {
  setIsTags: (isTags: boolean) => void;
  pk?: PK;
};

const WorkoutTagsPicker = ({ pk, setIsTags }: Props) => {
  const { data: workoutTagData } = useWorkoutsTags(pk);
  const isTags =
    workoutTagData?.category &&
    Array.isArray(workoutTagData?.category) &&
    workoutTagData?.category.length > 0;

  React.useEffect(() => {
    setIsTags(!!isTags);
  }, [isTags, setIsTags]);

  const {
    mutate: updateWorkout,
    isSuccess,
    isLoading,
    isError,
  } = useWorkoutsTagsUpdate();

  const saveTags = (values: any) => {
    const payload = {
      ...(values.amount && { amount: Number(values.amount) }),
      ...(values.amount_unit && { amount_unit: values.amount_unit[0].value }),
      ...(Array.isArray(values.category) &&
        values.category.length && {
          category: values.category.map(({ value }: any) => value),
        }),
      ...(values.frequency && { frequency: Number(values.frequency) }),
      ...(Array.isArray(values.habit) &&
        values.habit.length && { habit: values.habit.map(({ value }: any) => value) }),
      ...(values.subcategory.length && {
        subcategory: values.subcategory.map(({ value }: any) => value),
      }),
      ...(Array.isArray(values.term) &&
        values.term.length && {
          term: values.term[0].value,
        }),
      ...(Array.isArray(values.requisites) &&
        values.requisites.length && {
          requisites: values.requisites.map(({ value }: any) => {
            return value;
          }),
        }),
      ...(Array.isArray(values.intensity) &&
        values.intensity.length && {
          intensity: values.intensity[0].value,
        }),
      ...(Array.isArray(values.muscle_group) &&
        values.muscle_group.length && {
          muscle_group: values.muscle_group.map(({ value }: any) => value),
        }),
      pk,
    };
    updateWorkout(payload as any);
  };

  return (
    <>
      <CollapsibleSection
        title="Add tags"
        saveStatus={{ isSuccess, isLoading, isError }}
        disabled={!pk}
        divider={false}
      >
        <TagsForm
          validationSchema={workoutTagsValidationSchema}
          contentType={TAGGING_CONTENT_TYPES.WORKOUT}
          defaultValues={workoutTagData}
          onSubmit={saveTags}
          isSubmitting={isLoading}
        />
      </CollapsibleSection>
    </>
  );
};

export default WorkoutTagsPicker;
