import * as React from 'react';
import styled from 'styled-components';

import { Check, ErrorWarning } from 'common/icons';
import { Spinner } from 'common/components';
import { resolveSpacersArray } from 'common/styles/utils';
import { ThemeSpacer } from 'shared/theme';

export interface SaveStatusProps {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  margin?: ThemeSpacer | ThemeSpacer[];
  hideLabel?: boolean;
}

const SaveStatus = ({
  margin,
  isSuccess,
  isLoading,
  isError,
  hideLabel,
}: SaveStatusProps) => {
  const [isDisplayed, setDisplayed] = React.useState(false);
  const timer = React.useRef<number>();

  const handleClear = React.useCallback(() => {
    clearTimeout(timer.current);
  }, []);

  const handleHide = React.useCallback(() => {
    handleClear();
    setDisplayed(false);
  }, [handleClear]);

  const handleShow = React.useCallback(() => {
    handleClear();
    timer.current = setTimeout(handleHide, 5000);
    setDisplayed(true);
  }, [handleClear, handleHide]);

  React.useEffect(() => {
    if (isSuccess) {
      handleShow();
    }
    return () => handleClear();
  }, [isSuccess, handleShow, handleClear]);

  return (
    <RequestStatus margin={margin}>
      {isLoading ? (
        <>
          <Spinner size="16px" circleColor="greenDark" color="greenLight" />
          {!hideLabel && <SuccessInfo>Saving</SuccessInfo>}
        </>
      ) : isSuccess && isDisplayed ? (
        <>
          <Check size="16px" />
          {!hideLabel && <SuccessInfo>Saved</SuccessInfo>}
        </>
      ) : isError ? (
        <>
          <ErrorWarning size="16px" />
          <ErrorInfo>Something went wrong</ErrorInfo>
        </>
      ) : null}
    </RequestStatus>
  );
};

const RequestStatus = styled.div<{ margin?: ThemeSpacer | ThemeSpacer[] }>`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.font.size.tiny};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: ${({ theme, margin = null }) =>
    resolveSpacersArray(theme, margin !== null ? margin : [0, 'big'])};

  span {
    position: relative;
    top: 1px;
    margin-left: ${({ theme }) => theme.spacer.times(0.75)};
  }
`;

const SuccessInfo = styled.span`
  color: ${({ theme }) => theme.color.greenDark};
`;

const ErrorInfo = styled.span`
  color: ${({ theme }) => theme.color.error};
`;

export default SaveStatus;
