import * as React from 'react';
import { ToastRole } from './types';

type ToastId = number & { __brand: 'ToastId' };

interface ActiveToast {
  message: string;
  role: ToastRole;
  id: ToastId;
}

interface ToastContextType {
  toast: (message: string, role: ToastRole) => void;
  remove: (id: ToastId) => void;
  activeToasts: ActiveToast[];
}

const ToastContext = React.createContext<ToastContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

export const ToastProvider = ({ children }: Props) => {
  const [lastToastId, setLastToastId] = React.useState<ToastId>(0 as ToastId);
  const [activeToasts, setActiveToasts] = React.useState<ActiveToast[]>([]);

  const toast: ToastContextType['toast'] = (message, role) => {
    setActiveToasts((prevToasts) => {
      setLastToastId((prevId) => (prevId + 1) as ToastId);
      return [...prevToasts, { message, role, id: lastToastId }];
    });
  };

  const remove: ToastContextType['remove'] = (id) => {
    setActiveToasts((prevToasts) => prevToasts.filter((el) => el.id !== id));
  };

  const value = {
    toast,
    remove,
    activeToasts,
  };

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
};

export const useToast = () => {
  const context = React.useContext(ToastContext);
  if (!context) {
    throw new Error('Missing Toast Provider');
  }
  const { toast, remove, activeToasts } = context;

  return {
    toast,
    remove,
    activeToasts,
  };
};
