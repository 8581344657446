import * as React from 'react';
import { useTheme } from 'styled-components';
import { useClickAway } from 'react-use';

import { DropdownContainer, DropdownSelectList, DropdownSelectOption } from '..';
import { Arrow } from 'common/icons';
import { useOpen } from 'common/hooks';
import { PlainInput } from 'common/components/Input';
import { ContentList } from 'templates/content/utils';
import { RelationOption } from '../RelationSelect';

export type DropdownPickerProps<TOption extends RelationOption> = {
  label: string;
  options: TOption[];
  selected: TOption[];
  onPick: (values: TOption[]) => void;
  isMulti?: boolean;
  isLoading?: boolean;
  children?: React.ReactElement;
  className?: string;
};

function DropdownPicker<TOption extends RelationOption>(
  props: DropdownPickerProps<TOption>,
) {
  const ref = React.useRef(null);
  const {
    label,
    onPick,
    isLoading,
    isMulti,
    selected,
    options: fullOptions,
    children,
    ...rest
  } = props;
  const { isOpen, close, open } = useOpen({ disabled: isLoading });
  const theme = useTheme();
  const [search, setSearch] = React.useState('');
  const options = React.useMemo(() => {
    const list = new ContentList(fullOptions);
    return list.search(search, 'label').data;
  }, [fullOptions, search]);

  useClickAway(ref, close);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <DropdownContainer ref={ref} isOpen={isOpen} {...rest}>
      <PlainInput
        name="dropdown-picker"
        placeholder={label}
        onFocus={open}
        value={search}
        isValue={!!search}
        onChange={handleSearch}
        autoComplete="off"
        isLoading={isLoading}
        backIcon={
          <Arrow
            $width="2px"
            $size="10px"
            $color={theme.keys.secondaryColor}
            $rotate={isOpen ? -45 : 135}
          />
        }
      />
      {!isLoading && options.length > 0 && isOpen && (
        <DropdownSelectList
          isOpen={isOpen}
          options={options}
          onPick={onPick}
          selected={selected}
          isMulti={isMulti}
        >
          {children || <DropdownSelectOption />}
        </DropdownSelectList>
      )}
    </DropdownContainer>
  );
}

export default DropdownPicker;
