import * as React from 'react';

import { PK } from 'common/types';
import { Wrapper, Container, CollapsibleSection } from 'common/components';
import Admins from './Admins';
import Invitations from './Invitations';
import Draft from './Draft';
import { CompanyAdminsProvider } from './context';
import { useCompanyAdmins, useCompanyInvitations } from '../../queries';

type Props = {
  pk?: PK;
};

const CompanyAdmins = ({ pk: companyPk }: Props) => {
  const admins = useCompanyAdmins(companyPk);
  const invitations = useCompanyInvitations(companyPk);

  const context = React.useMemo(
    () =>
      admins.data && invitations.data
        ? {
            companyPk,
            admins: admins.data.values,
            invitations: invitations.data.values,
          }
        : {
            companyPk,
            admins: [],
            invitations: [],
          },
    [companyPk, admins.data, invitations.data],
  );

  return (
    <CollapsibleSection title="Admins" divider={false} disabled={!companyPk}>
      <Container>
        <CompanyAdminsProvider value={context}>
          <Wrapper margin={[0, 0, 5]}>
            <Draft>
              <Admins />
              <Invitations />
            </Draft>
          </Wrapper>
        </CompanyAdminsProvider>
      </Container>
    </CollapsibleSection>
  );
};

export default CompanyAdmins;
