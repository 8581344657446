import * as React from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import appUrls from 'base/app/urls';
import { PK } from 'common/types';

import { useAuthorsCreate, useAuthorsUpdate } from './queries';
import { fields, AuthorsFields } from './consts';

export const useSave = (pk?: PK) => {
  const [created, setCreated] = React.useState(pk);
  const create = useAuthorsCreate();
  const update = useAuthorsUpdate();
  const history = useHistory();

  const isSaving = create.isLoading || update.isLoading;
  const error = create.error || update.error;

  const save = (values: AuthorsFields) => {
    const payload = {
      name: values[fields.name.name],
      description: values[fields.description.name],
      link: values[fields.link.name],
    };

    if (created) {
      update.mutate({
        ...payload,
        pk: created,
      });
    } else {
      create.mutate(payload, {
        onSuccess: ({ pk }) => {
          setCreated(pk);
          history.push(generatePath(appUrls.AUTHORS.DETAILS, { pk }));
        },
      });
    }
  };

  return { save, created, isSaving, error };
};
