import * as React from 'react';
import styled from 'styled-components';

import { PK } from 'common/types';
import { STATUSES } from 'common/consts';
import {
  Button,
  CollapsibleSection,
  ErrorBox,
  Row,
  Title,
  Wrapper,
} from 'common/components';
import { useCompanyActivate, useCompanyDeactivate, useCompanyDetails } from '../queries';

type Props = {
  pk?: PK;
};

const CompanyActivation = ({ pk }: Props) => {
  const { data } = useCompanyDetails(pk);
  const isActive = data?.status === STATUSES.ACTIVE;
  const activate = useCompanyActivate();
  const deactivate = useCompanyDeactivate();

  const handleClick = () => {
    if (pk) {
      if (isActive) {
        deactivate.mutate({ pk });
      } else {
        activate.mutate({ pk });
      }
    }
  };
  return (
    <CollapsibleSection title="Company status" disabled={!pk}>
      <InterRow>
        <Wrapper margin={[1, 0]}>
          <Title size="small" weight="normal" color="greyDark">
            Activate or deactivate the company
          </Title>
        </Wrapper>
        <Wrapper float="left">
          <Button
            onClick={handleClick}
            isLoading={activate.isLoading || deactivate.isLoading}
          >
            {isActive ? 'Deactivate' : 'Activate'}
          </Button>
        </Wrapper>
      </InterRow>
      <ErrorBox error={activate.error || deactivate.error} />
    </CollapsibleSection>
  );
};

const InterRow = styled(Row)`
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacer.times(3)};
`;

export default CompanyActivation;
