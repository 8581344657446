import styled from 'styled-components';
import { resolveSpacersArray } from 'common/styles/utils';

const Heading = styled.h1`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => resolveSpacersArray(theme, [0, 0, 2, 0])};
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export default Heading;
