import * as React from 'react';
import styled from 'styled-components';

import { Radio, Graphics } from 'common/components';
import useCreateContext from '../useCreateContext';
import { useBusinessStore } from 'common/hooks';
import { getProjectContent } from 'common/utils';
import { CONTENT_TYPES, Content } from 'common/consts';

const pointerTranslate = (width: number, n: number, active = 1) => {
  const translations = Array.from({ length: n }).map((_, k) => {
    // This formula calculates pointer offset in k-th step when n steps in total.
    // It assumes that dot size is fixed and container width is given.
    const offset = k * ((width - n * 27) / (n - 1) + 27) + 27;
    return `translateX(${offset}px)`;
  });
  return `transform: ${translations.find((_, k) => active === k + 1)};`;
};

const getRadioSelectSize = (
  contentList: Content[],
): {
  spacers: number;
  pointerTranslationOffset: number;
} => {
  if (contentList.length === 12) {
    return {
      spacers: 9,
      pointerTranslationOffset: 750,
    };
  }

  return {
    spacers: 10,
    pointerTranslationOffset: 733.5,
  };
};

const TypeRadio = () => {
  const { isBusiness } = useBusinessStore();
  const { active, setActive } = useCreateContext();
  const CONTENT = getProjectContent();

  // Remove this code if want to display Habits in create menu. and replace updatedContentList with CONTENT
  function removeHiddenContent(contentList: Content[]): Content[] {
    return contentList.filter((item) => ![CONTENT_TYPES.HABITS].includes(item.type));
  }

  const updatedContentList = removeHiddenContent(CONTENT);
  const { spacers, pointerTranslationOffset } = getRadioSelectSize(updatedContentList);

  return (
    <>
      <RadioContainer>
        {updatedContentList.map(
          ({ icon, iconInactive, title, type, isBusiness: isB2Bcontent, isMixed }) => {
            const isActive = active === type;
            return isMixed || isBusiness === isB2Bcontent ? (
              <RadioButton key={title} onClick={() => setActive(type)}>
                <Box $isActive={isActive} $spacers={spacers}>
                  <Graphics src={isActive ? icon : iconInactive} height="50px" />
                </Box>
                <ContentName>{title}</ContentName>
                <Radio name={title} value="type" checked={isActive} readOnly />
              </RadioButton>
            ) : null;
          },
        )}
        <Pointer
          $active={updatedContentList
            .filter(
              ({ isBusiness: isB2Bcontent, isMixed }) =>
                isMixed || isBusiness === isB2Bcontent,
            )
            .findIndex(({ type }) => type === active)}
          $pointerTranslationOffset={pointerTranslationOffset}
          $gaps={updatedContentList.length - 2.0}
        />
      </RadioContainer>
      <Bar></Bar>
    </>
  );
};

const RadioContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  max-width: ${({ theme }) => theme.spacer.times(87)};
  margin: ${({ theme }) => theme.spacer.big} 0;
  text-align: center;
`;

const ContentName = styled.span`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.font.size.tiny};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const RadioButton = styled.div`
  cursor: pointer;
  margin: 0 ${({ theme }) => theme.spacer.times(0.5)};

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  ${Radio} {
    justify-content: center;
    margin: ${({ theme }) => theme.spacer.standard} auto;
  }
`;

const Box = styled.div<{
  $isActive: boolean;
  $spacers: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme, $spacers }) => theme.spacer.times($spacers)};
  height: ${({ theme, $spacers }) => theme.spacer.times($spacers)};
  border: 1px solid
    ${({ theme, $isActive }) => ($isActive ? theme.color.blue : theme.color.blueLight)};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
`;

const Bar = styled.div`
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.color.blueLight};
  margin: 0 auto;
`;

const Pointer = styled.div<{
  $active: number;
  $pointerTranslationOffset: number;
  $gaps: number;
}>`
  position: absolute;
  left: 4px;
  bottom: -${({ theme }) => theme.spacer.standard};
  z-index: 1;
  transition: transform 0.5s ease;
  ${({ $active, $pointerTranslationOffset, $gaps }) =>
    pointerTranslate($pointerTranslationOffset, $gaps, $active + 1)}

  &::after {
    content: '';
    position: absolute;
    width: ${({ theme }) => theme.spacer.big};
    height: ${({ theme }) => theme.spacer.big};
    transform: rotate(45deg);
    border-top: 1px solid ${({ theme }) => theme.color.blueLight};
    border-left: 1px solid ${({ theme }) => theme.color.blueLight};
    border-top-left-radius: ${({ theme }) => theme.border.radius.tiny};
    background-color: ${({ theme }) => theme.color.white};
  }
`;

export default TypeRadio;
