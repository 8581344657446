import * as React from 'react';
import styled from 'styled-components';

import { Wrapper } from 'common/components';

import { useAdminsList } from '../queries';
import AdminItem from './AdminItem';
import Heading from './Heading';

const AdminsList = () => {
  const { data: adminsList } = useAdminsList();

  return (
    <Wrapper>
      <Heading>Admins</Heading>
      <AdminList>
        {adminsList?.values.map((admin) => (
          <Wrapper key={admin.pk} margin={[0, 0, 1, 0]}>
            <AdminItem pk={admin.pk} email={admin.email} />
          </Wrapper>
        ))}
      </AdminList>
    </Wrapper>
  );
};

const AdminList = styled.div`
  display: flex;
  flex-direction: column;
`;

export default AdminsList;
