import * as Yup from 'yup';

import { combineSchemas } from 'common/utils';
import { Fields } from 'common/types';

export type UnitsFields = {
  name_singular: string;
  name_plural: string;
};

export const fields: Fields<UnitsFields> = {
  name_singular: {
    label: 'Name (singular)',
    name: 'name_singular',
    schema: Yup.string(),
  },
  name_plural: {
    label: 'Name (plural)',
    name: 'name_plural',
    schema: Yup.string(),
  },
};

export const validationSchema = Yup.object().shape<UnitsFields>(combineSchemas(fields));
