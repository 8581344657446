import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import appUrls from 'base/app/urls';
import { ScenarioListItem } from 'base/api/types';
import { Title, IconContainer, Wrapper } from 'common/components';
import { ChainLink, Pen } from 'common/icons';
import { resolveSpacersArray } from 'common/styles/utils';
import { PK } from 'common/types';
import { composeUrl } from 'common/utils';
import { ContentRow } from 'templates/content';
import { ContentRowProps } from 'templates/content/components/ContentRow';

import CalendarIndicator from './CalendarIndicator';
import ModifiedChip from './ModifiedChip';
import ScenariosMenu from './ScenariosMenu';

export interface ScenariosRow {
  pk: PK;
  title: string;
  isDetailsView?: boolean;
}

const ScenariosContentRow = (props: ContentRowProps<ScenarioListItem>) => {
  const { data, index } = props;
  const scenarioData = data[index];
  const isLinked = scenarioData.programs_status === 'ACTIVE';
  const isEditable = scenarioData.weeks_count > 0;

  return (
    <StyledContentRow {...props} menuComponent={ScenariosMenu}>
      <TitleCell>
        <StyledTitle size="standard">{scenarioData.title}</StyledTitle>
      </TitleCell>

      {scenarioData.weeks_count > 0 && (
        <Wrapper margin={[0, 1]}>
          <CalendarIndicator>
            {scenarioData.weeks_count} {scenarioData.weeks_count === 1 ? 'week' : 'weeks'}
          </CalendarIndicator>
        </Wrapper>
      )}

      {isLinked && (
        <HighlightCircle size="38px" margin={[0, 1]}>
          <ChainLink size="16px" color="greyDark" />
        </HighlightCircle>
      )}

      <Spacer />

      {scenarioData.modified_at && (
        <ModifiedChip modificationDate={scenarioData.modified_at} />
      )}

      <StyledNavLink
        disabled={!isEditable}
        to={composeUrl(appUrls.SCENARIOS.WEEK.LIST, {
          params: { pk: scenarioData.pk },
        })}
      >
        <AdjustedIconContainer size="16px" margin={[0, 1, 0, 0]}>
          <Pen size="16px" color={isEditable ? 'pink' : 'grey'} />
        </AdjustedIconContainer>
        Edit
      </StyledNavLink>
    </StyledContentRow>
  );
};

const StyledContentRow = styled(ContentRow)`
  justify-content: space-between;
`;

const StyledTitle = styled(Title)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TitleCell = styled.div`
  flex-shrink: 1;
  margin-right: ${({ theme }) => theme.spacer.times(3)};
`;

const HighlightCircle = styled(IconContainer)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.greySuperLight};
`;

const AdjustedIconContainer = styled(IconContainer)`
  position: relative;
  top: -2px;
`;

const Spacer = styled.div`
  margin-left: auto;
`;

const StyledNavLink = styled(NavLink)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: ${({ theme }) => theme.font.size.standard};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: ${({ theme }) => resolveSpacersArray(theme, [0, 1, 0, 3])};
  color: ${({ theme, disabled }) => (disabled ? theme.color.grey : theme.color.pink)};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `};
`;

export default ScenariosContentRow;
