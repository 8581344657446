import * as React from 'react';
import styled, { css, keyframes, useTheme } from 'styled-components';

import { resolveSpacersArray } from 'common/styles/utils';
import { BUTTON_VARIANTS } from 'common/consts';
import { Button, Img } from 'common/components';
import { Bin } from 'common/icons';

type Props = {
  onRemove: () => void;
  url: string;
  name?: string;
  className?: string;
};

const MediaItem: React.FunctionComponent<Props> = ({
  url,
  name,
  onRemove,
  className,
}) => {
  const theme = useTheme();
  return (
    <div className={className}>
      <Preview src={url} />
      <RemoveContainer>
        <Button variant={BUTTON_VARIANTS.ICON} onClick={onRemove}>
          <Bin
            title="remove"
            color={theme.keys.secondaryColor}
            hoverColor={theme.keys.secondaryColor}
          />
        </Button>
      </RemoveContainer>
      {name ? <PreviewName>{name}</PreviewName> : null}
    </div>
  );
};

const Preview = styled(Img)`
  width: 100%;
  max-height: 160px;
  object-fit: contain;
`;

const RemoveContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacer.times(3)};
`;

const PreviewName = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${({ theme }) => resolveSpacersArray(theme, [1, 3])};
  font-size: ${({ theme }) => theme.font.size.tiny};
  color: ${({ theme }) => theme.color.white};
  background-color: rgba(29, 29, 29, 0.8);
`;

const fadeInTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(calc(-100% - 16px));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledMediaItem = styled(MediaItem)`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacer.big};
  background-color: ${({ theme }) => theme.color.greySuperLight};
  margin-bottom: ${({ theme }) => theme.spacer.big};
  min-height: ${({ theme }) => theme.spacer.times(12)};

  ${() => css`
    animation: ${fadeInTop} 0.4s both ease-out 0.4s;
  `}
`;

export default StyledMediaItem;
