import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'common/components';
import { ButtonProps } from 'common/components/Button';

type SaveButtonProps = ButtonProps & {
  label?: string;
};

const SaveButton = ({ label, ...props }: SaveButtonProps) => {
  return (
    <SaveButtonContainer>
      <Divider />
      <ButtonPosition>
        <Button type="submit" {...props}>
          {label || 'Save'}
        </Button>
      </ButtonPosition>
    </SaveButtonContainer>
  );
};

const SaveButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacer.times(10)} 0;
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.themeColor.tertiaryLightColor};
`;

const ButtonPosition = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.color.white};
  padding: 0 ${({ theme }) => theme.spacer.times(6)};
`;

export default SaveButton;
