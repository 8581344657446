import * as React from 'react';
import styled from 'styled-components';

import { ScenarioWeekDay, ScenarioWeekDayMini } from 'base/api/types';
import {
  Column,
  ErrorBox,
  IconContainer,
  Row,
  RemoveButton,
  SelectedItem,
  SaveStatus,
  Wrapper,
  Link,
} from 'common/components';
import { RelationOption } from 'common/components/RelationSelect';
import { WEEK_DAYS } from 'common/consts';
import { useDnDList } from 'common/hooks';
import { Chevron, CircleAdd } from 'common/icons';
import { resolveSpacersArray } from 'common/styles/utils';
import { PK } from 'common/types';
import {
  useScenarioMinisAdd,
  useScenarioMinisRemove,
  useScenarioMinisReorder,
} from 'modules/scenarios/queries';

import MiniIndicator from '../MiniIndicator';
import ModifiedChip from '../ModifiedChip';
import MiniPicker from '../MiniPicker';
import WeekDay from './WeekDay';
import PointsDisplay from 'modules/series/components/PointsDisplay';
import appUrls from 'base/app/urls';
import { generatePath } from 'react-router-dom';

interface Props {
  label: string;
  weekPk: PK;
  scenarioPk: PK;
  weekDay: WEEK_DAYS;
  scenarioDay?: ScenarioWeekDay;
}

const ScenarioDay = ({ scenarioDay, weekDay, scenarioPk, weekPk, label }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isPickerVisible, setIsPickerVisible] = React.useState(false);
  const addScenarioMini = useScenarioMinisAdd();
  const removeScenarioMini = useScenarioMinisRemove();
  const reorderScenarioMini = useScenarioMinisReorder();
  const miniInDay = scenarioDay?.minis.length || 0;
  const selectedMinis =
    scenarioDay?.minis.map((mini) => ({
      label: mini.title,
      value: mini.pk,
    })) || [];
  const saveStatus = {
    isLoading:
      addScenarioMini.isLoading ||
      removeScenarioMini.isLoading ||
      reorderScenarioMini.isLoading,
    isError:
      addScenarioMini.isError ||
      removeScenarioMini.isError ||
      reorderScenarioMini.isError,
    isSuccess:
      addScenarioMini.isSuccess ||
      removeScenarioMini.isSuccess ||
      reorderScenarioMini.isSuccess,
  };
  const error =
    addScenarioMini.error || removeScenarioMini.error || reorderScenarioMini.error;

  const handleReorder = (values: ScenarioWeekDayMini[]) => {
    const minis = values.map((mini) => mini.pk);

    reorderScenarioMini.mutate({ scenarioPk, weekPk, dayNo: weekDay, minis });
  };

  const { list: minis, getDraggableProps, removeItem } = useDnDList({
    initList: scenarioDay?.minis,
    onReorder: handleReorder,
  });

  const handleDelete = (position: number) => {
    const miniPk = minis[position]?.pk;

    if (miniPk) {
      removeItem(position);
      removeScenarioMini.mutate({ scenarioPk, weekPk, dayNo: weekDay, miniPk });
    }
  };

  const handlePick = (values: RelationOption[]) => {
    values.forEach((mini) => {
      const miniPk = mini.value;
      addScenarioMini.mutate({ scenarioPk, weekPk, miniPk, dayNo: weekDay });
    });
  };

  return (
    <Column>
      <WeekDay isOpen={isOpen} onClick={() => setIsOpen((isOpen) => !isOpen)}>
        {label}
        {miniInDay > 0 && (
          <Wrapper margin={[0, 2]}>
            <MiniIndicator count={miniInDay} style="green" />
          </Wrapper>
        )}
        <SaveStatus {...saveStatus} />
        <Wrapper margin={[0, 0, 0, 'auto']}>
          <IconContainer size="24px">
            <Chevron size="16px" color="pink" rotate={isOpen ? 90 : 0} />
          </IconContainer>
        </Wrapper>
      </WeekDay>
      {isOpen && (
        <>
          <SelectedList>
            {minis?.map(({ pk, title, modified_at, points }, position) => (
              <StyledSelectedItem
                key={miniKey(pk, position)}
                {...getDraggableProps({
                  position,
                  onDelete: handleDelete,
                })}
              >
                <Row>
                  <PointsDisplay value={points} fontSize={16} />

                  {
                    <Link
                      to={generatePath(appUrls.MINIS.DETAILS, { pk })}
                      newCard
                      hoverUnderlined
                    >
                      {title}
                    </Link>
                  }
                  {modified_at && (
                    <Wrapper margin={[0, 0, 0, 2]}>
                      <ModifiedChip modificationDate={modified_at} />
                    </Wrapper>
                  )}
                </Row>
              </StyledSelectedItem>
            ))}
            {isPickerVisible ? (
              <WhiteBox>
                <MiniPicker selected={selectedMinis} onPick={handlePick} />
                <Wrapper margin={[2, 0, 2, 2]}>
                  <RemoveButton
                    noNegativeMargin={false}
                    onRemove={() => setIsPickerVisible(false)}
                  />
                </Wrapper>
              </WhiteBox>
            ) : (
              <AddButton onClick={() => setIsPickerVisible(true)} type="button">
                <IconContainer>
                  <CircleAdd color="pink" />
                </IconContainer>
              </AddButton>
            )}
            {error && (
              <Wrapper margin={[3, 0, 0]}>
                <ErrorBox error={error} />
              </Wrapper>
            )}
          </SelectedList>
        </>
      )}
    </Column>
  );
};

const miniKey = (pk: PK, position: number) => `${pk}-${position}`;

const WhiteBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.spacer.times(2)};
`;

const StyledSelectedItem = styled(SelectedItem)`
  width: 100%;
  background: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.spacer.times(2)};
`;

const SelectedList = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.color.greySuperLight};
  margin: ${({ theme }) => resolveSpacersArray(theme, [0, 0, 1])};
  padding: ${({ theme }) => resolveSpacersArray(theme, [0, 4, 2, 3])};
`;

const AddButton = styled.button`
  border: 0;
  width: 100%;
  height: 56px;
  cursor: pointer;
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.border.radius.tiny};
`;

export default ScenarioDay;
