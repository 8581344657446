import * as Yup from 'yup';

import { RecipeCategoryType } from 'base/api/types';
import { combineSchemas } from 'common/utils';
import { Fields, SelectOption } from 'common/types';

type CategoryTypeOption = SelectOption<RecipeCategoryType>;

export const CATEGORY_TYPE_OPTIONS: CategoryTypeOption[] = [
  {
    label: 'Recipe',
    value: 'RECIPE',
  },
  {
    label: 'Ingredient',
    value: 'INGREDIENT',
  },
];

export type CategoriesFields = {
  name: string;
  type: CategoryTypeOption[];
};

export const fields: Fields<CategoriesFields> = {
  name: {
    label: 'Name',
    name: 'name',
    schema: Yup.string().required(),
  },
  type: {
    label: 'Type',
    name: 'type',
    schema: Yup.array().required(),
  },
};

export const validationSchema = Yup.object().shape<CategoriesFields>(
  combineSchemas(fields),
);
