import * as React from 'react';
import styled, { css } from 'styled-components';

import { ThemeColor } from 'shared/theme';
import { spin } from 'common/styles/animations';

export interface ProgressRingProps {
  progress?: number;
  stroke?: number;
  radius?: number;
  color?: ThemeColor;
  circleColor?: ThemeColor;
  disableLabel?: boolean;
}

const ProgressRing = ({
  progress = 0,
  radius = 16,
  stroke = 2,
  color = 'greenDark',
  circleColor = 'blueGrey',
  disableLabel = false,
}: ProgressRingProps) => {
  const normalizedRadius = radius - stroke;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  const isProcessing = progress === 100;

  return (
    <RingContainer>
      {!disableLabel && <ProgressLabel>{progress}</ProgressLabel>}
      <RingSvg radius={radius}>
        <Circle
          stroke={circleColor}
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <Circle
          stroke={color}
          strokeWidth={stroke}
          isProcessing={isProcessing}
          strokeDasharray={circumference + ' ' + circumference}
          strokeDashoffset={strokeDashoffset}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </RingSvg>
    </RingContainer>
  );
};

const RingContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressLabel = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.color.greenDark};
  line-height: ${({ theme }) => theme.font.size.superTiny};
  font-size: ${({ theme }) => theme.font.size.superTiny};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  letter-spacing: 0.5px;
  margin: 2px 0 0 1px;
`;

const RingSvg = styled.svg<Required<Pick<ProgressRingProps, 'radius'>>>`
  height: ${({ radius }) => `${2 * radius}px`};
  width: ${({ radius }) => `${2 * radius}px`};
`;

type CircleProps = {
  stroke: ThemeColor;
  isProcessing?: boolean;
  strokeDashoffset?: number;
};

const Circle = styled.circle<CircleProps>`
  stroke: ${({ theme, stroke = 'blueGrey' }) => theme.color[stroke]};
  fill: transparent;
  stroke-dashoffset: ${({ strokeDashoffset, isProcessing }) =>
    isProcessing ? -20 : strokeDashoffset};
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 0.4s;

  ${({ isProcessing }) =>
    isProcessing &&
    css`
      animation: ${spin(-90)} 1s 0s linear infinite;
    `}
`;

export default ProgressRing;
