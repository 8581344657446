import * as React from 'react';

type PlayStateType = 'running' | 'paused';

const useAnimation = () => {
  const [count, setCount] = React.useState(0);
  const [playState, setPlayState] = React.useState<PlayStateType>('running');

  return { playState, setPlayState, count, setCount };
};

export default useAnimation;
