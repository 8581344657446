import { useCallback, useEffect, useRef } from 'react';

type ClickCallback = (event: MouseEvent) => void;

interface UseOutsideClickResult<T extends HTMLElement> {
  containerRef: React.MutableRefObject<T | null>;
}

const useOutsideClick = <T extends HTMLElement>(
  onOutsideClick?: ClickCallback,
): UseOutsideClickResult<T> => {
  const containerRef: UseOutsideClickResult<T>['containerRef'] = useRef(null);

  const handleClickOutside = useCallback<ClickCallback>(
    (event) => {
      if (
        containerRef.current &&
        event.target instanceof HTMLElement &&
        !containerRef.current.contains(event.target)
      ) {
        onOutsideClick?.(event);
      }
    },
    [onOutsideClick],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return { containerRef };
};

export default useOutsideClick;
