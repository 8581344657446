import * as React from 'react';
import Cookies from 'js-cookie';
import {
  useLocation,
  useHistory,
  Redirect,
  Route as ReactRoute,
  RouteProps as ReactRouteProps,
} from 'react-router-dom';

import appUrls from 'base/app/urls';
import { useAuthStore } from 'common/hooks';
import { ACCESS_TOKEN } from 'modules/auth/consts';
import { useRole } from 'modules/auth/queries';
import { DotsLoader } from 'common/components';
import Layout from 'common/styles/Layout';

export interface RouteProps extends ReactRouteProps {
  path: string;
  authRequired?: boolean;
}

const Route = ({ children, authRequired = true, ...props }: RouteProps) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { refetch: getRole } = useRole();
  const { isAuthorized, isValidating, invalid } = useAuthStore(
    ({ isAuthorized, isValidating, invalid }) => ({
      isAuthorized,
      isValidating,
      invalid,
    }),
  );

  React.useEffect(() => {
    const isToken = !!Cookies.get(ACCESS_TOKEN);
    if (isToken) {
      getRole();
    } else {
      invalid();
    }
  }, [getRole, invalid]);

  if (isValidating) {
    return (
      <Layout logoOnly>
        <DotsLoader fullScreen center />
      </Layout>
    );
  } else if (!authRequired || isAuthorized) {
    if (
      (appUrls.AUTH.LOGIN === pathname ||
        appUrls.SSO.LOGIN === pathname ||
        appUrls.AUTH.LOGIN_CONFIRMATION === pathname) &&
      isAuthorized
    ) {
      return <Redirect to={appUrls.DASHBOARD} />;
    }
    return <ReactRoute {...props}>{children}</ReactRoute>;
  } else {
    setTimeout(() => history.replace(appUrls.AUTH.LOGIN), 500);
    return (
      <Layout logoOnly>
        <DotsLoader fullScreen center />
      </Layout>
    );
  }
};

export default Route;
